import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Error } from 'components/fields'
import Margin from 'components/Margin'
import MultipleChoice from 'components/Input/MultipleChoice'

export enum TireCondition {
  DEFLATING = 'Losing air',
  FLAT = 'Completely flat',
  MULTIPLEFLATS = 'Two Flats',
  STOLENTIRES = 'Three or More Tires Flat/Stolen',
}

type Props = { name: string }

const choices = Object.values(TireCondition).reduce<Record<string, string>>((values, value) => {
  values[value] = value
  return values
}, {})

function TireConditionRadio({ name }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Margin spacing="md">
        <MultipleChoice
          name={name}
          choices={choices}
          orientation="vertical"
          label={t('tires.condition')}
        />
        <Error name={name} />
      </Margin>
    </>
  )
}

export default TireConditionRadio
