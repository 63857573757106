import React from 'react'
import * as Ariakit from '@ariakit/react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FormApi } from 'final-form'
// @ts-ignore
import createFocusDecorator from 'final-form-focus'
import * as Yup from 'yup'
import { validateWithYup } from 'components/FormManager/utils'
import { Flex } from 'components/primitives'
import Button from 'components/Button'
import Column from 'components/Column'
import Margin from 'components/Margin'
import { Paragraph } from 'components/primitives'
import { CancelDispatchSelect } from 'components/Select'
import Input from 'components/Input'
import { useCancelDispatch } from 'hooks/aaa'
import useDispatchRefetch from '../useDispatchRefetch'
import { cancelDialogContext } from '../DispatchStatus'

declare module 'types/form' {
  export interface Forms {
    cancelDispatch: FormApi
  }
}

const focusOnError = createFocusDecorator()

export function CancelDispatchForm({ callKey }: { callKey: string }) {
  const { t } = useTranslation()
  const cancelDispatch = useCancelDispatch()
  const refetchDispatch = useDispatchRefetch(callKey)
  const { setSubmitted, setDispatchError, setCancelled } = cancelDialogContext.useCancelDialogContext()
  const [loading, setLoading] = React.useState(false)
  const dialog = Ariakit.useDialogContext()
  const handleSubmit = async (values: any) => {
    if (!loading) {
      setLoading(true)

      const { cancelDispatchSelect, cancellationText } = values
      const cancelReasonCode = cancelDispatchSelect && cancelDispatchSelect.value

      try {
        const resp = await cancelDispatch({
          cancelReasonCode,
          cancellationText,
          callKey,
        })
        await refetchDispatch()
        if (resp.status !== 204) setDispatchError(true)

        setLoading(false)
        setSubmitted(true)
        setCancelled(true)
      } catch (e) {
        setLoading(false)
        setDispatchError(true)
        await refetchDispatch()
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} validate={validateWithYup(schema)} decorators={[focusOnError]}>
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Column>
            <Margin spacing="lg">
              <Paragraph>{t('cancelDispatch.areYouSure')}</Paragraph>
            </Margin>
            <CancelDispatchSelect name="cancelDispatchSelect" label={t('cancelDispatch.reason')} />
            <Input.Textarea name="cancellationText" label={`${t('cancelDispatch.cancellationNotes')}*`} />
            <Flex style={{ justifyContent: 'space-between', width: '100%' }}>
              <Button.Secondary
                onClick={() => {
                  dialog?.hide()
                  setSubmitted(false)
                  setDispatchError(false)
                }}
              >
                {t('back')}
              </Button.Secondary>
              <Button.Primary loading={loading} type="submit">
                {t('cancelDispatch.cancelService')}
              </Button.Primary>
            </Flex>
          </Column>
        </form>
      )}
    </Form>
  )
}

const schema = Yup.object().shape({
  cancelDispatchSelect: Yup.object()
    .shape({
      label: Yup.string().nullable(),
      value: Yup.string().nullable().required('Required'),
    })
    .nullable()
    .required('Required'),
  cancellationText: Yup.string().required('Required'),
})

export default CancelDispatchForm
