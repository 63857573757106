import * as React from 'react'
import styled from 'styled-components/macro'
import { Flex } from 'components/primitives'
import { useTranslation } from 'react-i18next'
import { useTransferStatus, TransferStatuses } from '../TransferStatus'

const StyledFlex = styled(Flex as any)`
  flex: 1;
  justify-content: center;
  align-items: center;
`

export const StatusDisplay = () => {
  const { t } = useTranslation()
  const [status] = useTransferStatus()

  if (status === TransferStatuses.DEFAULT) return <StyledFlex>{t('No Results')}</StyledFlex>
  if (status === TransferStatuses.SUCCESS) return <StyledFlex>{t('Success')}</StyledFlex>
  return null
}

export default StatusDisplay
