import * as React from 'react'
import styled from 'styled-components/macro'
import { Heading } from 'components/primitives'
import { palette } from 'styled-tools'
import BoxShadow from '../BoxShadow'
import SectionToolbar from './SectionToolbar'
import { StyleProps } from 'types/global'

export type SectionProps = {
  title?: React.ReactNode
  action?: React.ReactNode
  id?: string
  children?: React.ReactNode
} & StyleProps

const Background = styled.div`
  background: ${palette('white')};
`

export const Section: React.FC<SectionProps> = ({ children, title, action, style = {}, ...rest }) => (
  <Background as={BoxShadow} style={style} {...rest}>
    {title && (
      <SectionToolbar>
        {typeof title === 'string' ? <Heading as="h5">{title}</Heading> : title}
        {action && <div>{action}</div>}
      </SectionToolbar>
    )}
    {children}
  </Background>
)

export default Section
