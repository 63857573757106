import * as React from 'react'
import { Form as FinalForm, FormProps } from 'react-final-form'
// @ts-ignore
import createFocusDecorator from 'final-form-focus'
import pDebounce from 'p-debounce'
import * as Yup from 'yup'
import AutoSave from 'components/AutoSave'
import AutoSaveSync from 'components/AutoSave/AutoSaveSync'
import RegisterForm from './RegisterForm'
import { validateWithYup } from './utils'
import { FormName } from 'types/form'

type Props<T> = {
  name: FormName
  schema?: Yup.Schema<object>
  autoSave?: (values: T) => Promise<any>
  initialValues?: T | null
  autoSaveSync?: (values: T) => void
  autoSaveDebounce?: number
} & Omit<FormProps, 'onSubmit' | 'initialValues'>

const focusOnError = createFocusDecorator()

function Form<T>({
  name,
  schema = Yup.object({}),
  autoSave,
  autoSaveSync,
  autoSaveDebounce,
  children,
  mutators,
  ...rest
}: Props<T>) {
  // @ts-ignore
  const [initialValues, setInitialValues] = React.useState<T | null>(rest.initialValues)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validate = React.useCallback(pDebounce(validateWithYup(schema), 250), [schema])

  React.useEffect(() => {
    if (!initialValues && rest.initialValues) {
      setInitialValues(rest.initialValues)
    }
  }, [initialValues, rest.initialValues])

  return (
    <FinalForm
      onSubmit={() => {}}
      validate={validate}
      decorators={[focusOnError]}
      {...rest}
      initialValues={initialValues || {}}
      mutators={{
        displayFieldError: (args: any[], state) => {
          const [name, touched] = args
          const field = state.fields[name]

          if (field) {
            field.touched = !!touched
          }
        },
        ...mutators,
      }}
    >
      {(renderProps) => (
        <>
          <RegisterForm name={name} />
          {autoSave && <AutoSave save={autoSave} debounce={autoSaveDebounce} />}
          {autoSaveSync && <AutoSaveSync save={autoSaveSync} debounce={autoSaveDebounce} />}
          {typeof children === 'function' ? children(renderProps) : children}
        </>
      )}
    </FinalForm>
  )
}

export default Form
