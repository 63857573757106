import { TicketStatus } from 'types/ticket'
import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'

// Writable statuses provided in https://jira.ehi.com/browse/RDS-630
const writable = [TicketStatus.OPEN, TicketStatus.PREWRITE, TicketStatus.CLOSEPENDED]

/**
 * Returns boolean weather or not we can write to ticket services (notes, fees)
 */
export const useIsTicketWritable = () => {
  const { ticketStatus } = useSelector(selectTicket)
  return writable.includes(ticketStatus as TicketStatus)
}

export default useIsTicketWritable
