import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdChevronLeft } from 'react-icons/md'
import { Participant } from 'types/global'
import { Label } from 'components/primitives'
import { NetworkSelect } from 'components/Select'
import Margin from 'components/Margin'
import useFieldValue from 'hooks/utils/useFieldValue'
import { setSmartAssignmentAssignment, setSmartAssignmentParticipant } from 'redux/smartAssignment/smartAssignmentSlice'
import Button from 'components/Button'
import Results from './Results'
import Create from './Create'
import { LocationPayload } from 'types/location'
import { useDispatch, useSelector } from 'react-redux'
import { selectSmartAssignment } from 'redux/appStore'

type Props = {
  vehicleName?: string
  networkName?: string
  title?: string
  exitOnClick?: () => void
  onSetAssignment?: (participant: Participant | LocationPayload) => void
}

export const SmartAssignment: React.FC<Props> = ({
  vehicleName = 'vehicle',
  networkName = 'network',
  title = 'Search Network',
  exitOnClick,
  onSetAssignment,
}) => {
  const { t } = useTranslation()
  const { assignment } = useSelector(selectSmartAssignment)
  const dispatch = useDispatch()
  const vehicle = useFieldValue(vehicleName)
  const networkOption = useFieldValue(networkName)

  if (!assignment)
    return (
      <>
        {exitOnClick && (
          <Margin spacing="md">
            <Button iconBefore={MdChevronLeft} onClick={exitOnClick} style={{ padding: 0 }}>
              {t('network.previous')}
            </Button>
          </Margin>
        )}
        <Label>{t('network.destinationType')}</Label>
        <Margin spacing="md">
          <NetworkSelect name={networkName} />
        </Margin>
        <Create
          title={title}
          vehicle={vehicle}
          network={networkOption && networkOption.value}
          onSetAssignment={onSetAssignment}
        />
      </>
    )

  return (
    <>
      <Margin spacing="md">
        <Button
          iconBefore={MdChevronLeft}
          onClick={() => {
            dispatch(setSmartAssignmentAssignment(null))
            dispatch(setSmartAssignmentParticipant(null))
          }}
          style={{ padding: 0 }}
        >
          {t('network.previous')}
        </Button>
      </Margin>
      <Results network={networkOption.value} onSetAssignment={onSetAssignment} />
    </>
  )
}

export default SmartAssignment
