import { SelectOption } from 'types/global'

export type GroupType = {
  label?: string
  options: Array<SelectOption>
}

const states = [
  'AK - Alaska',
  'AL - Alabama',
  'AZ - Arizona',
  'AR - Arkansas',
  'CA - California',
  'CO - Colorado',
  'CT - Connecticut',
  'DE - Delaware',
  'FL - Florida',
  'GA - Georgia',
  'HI - Hawaii',
  'ID - Idaho',
  'IL - Illinois',
  'IN - Indiana',
  'IA - Iowa',
  'KS - Kansas',
  'KY - Kentucky',
  'LA - Louisiana',
  'ME - Maine',
  'MD - Maryland',
  'MA - Massachusetts',
  'MI - Michigan',
  'MN - Minnesota',
  'MS - Mississippi',
  'MO - Missouri',
  'MT - Montana',
  'NE - Nebraska',
  'NV - Nevada',
  'NH - New Hampshire',
  'NJ - New Jersey',
  'NM - New Mexico',
  'NY - New York',
  'NC - North Carolina',
  'ND - North Dakota',
  'OH - Ohio',
  'OK - Oklahoma',
  'OR - Oregon',
  'PA - Pennsylvania',
  'RI - Rhode Island',
  'SC - South Carolina',
  'SD - South Dakota',
  'TN - Tennessee',
  'TX - Texas',
  'UT - Utah',
  'VT - Vermont',
  'VA - Virginia',
  'WA - Washington',
  'WV - West Virginia',
  'WI - Wisconsin',
  'WY - Wyoming',
]

const provinces = [
  'AB - Alberta',
  'BC - British Columbia',
  'MB - Manitoba',
  'NB - New Brunswick',
  'NL - Newfoundland and Labrador',
  'NT - Northwest Territories',
  'NS - Nova Scotia',
  'NU - Nunavut',
  'ON - Ontario',
  'PE - Prince Edward Island',
  'QC - Quebec',
  'SK - Saskatchewan',
  'YT - Yukon',
]

const territories = ['PR - Puerto Rico', 'DC - District of Columbia']

export const stateOptions = states.map(state => ({ label: state, value: state.substring(0, 2) }))
export const provinceOptions = provinces.map(p => ({ label: p, value: p.substring(0, 2) }))
export const territoryOptions = territories.map(t => ({ label: t, value: t.substring(0, 2) }))

export const groupOptions: Array<GroupType> = [
  {
    label: 'United States',
    options: stateOptions,
  },
  {
    label: 'Canada',
    options: provinceOptions,
  },
  {
    label: 'Territories',
    options: territoryOptions,
  },
]
