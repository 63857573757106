import * as React from 'react'
import { FormApi } from 'final-form'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { ErrorMessage } from 'components/fields/Error'
import { useSelector } from 'react-redux'
import { selectAllEvents, selectFormsState } from 'redux/appStore'
import { isEmpty, isNil, merge } from 'lodash-es'
import CallTypes from 'types/callstore'
import { Forms } from 'types/form'
import { CallTypeToEventType } from 'utils/callstore'

const callTypesToIgnore = [CallTypes.FEATURE, CallTypes.DROP, CallTypes.TRANSFER, CallTypes.CALLBACK]

type Entry = [string, FormApi]

type SubmitFormsProps = {
  disabled?: boolean
  callType: CallTypes | undefined
  onSubmit: (values: object) => void
  children: React.ReactNode
}

export function scrollErrorIntoView(errorKey?: string | null, formError?: boolean) {
  let el: Element | null = null
  let errorKeyTop = [
    'selectedCaller',
    'confirmedDOB',
    'companyConfirmation',
    'phone',
    'callerName',
    'callerRelationship',
    'title',
    'badge',
    'email',
    'department',
    'cityAndCounty',
    'stateOrProvince',
    'drivers',
  ]

  if (errorKey && errorKeyTop.includes(errorKey)) {
    el = document.querySelector('#top')
  } else if (errorKey === 'callType') {
    el = document.querySelector('#callTypeTop')
  } else if (formError) {
    el = document.querySelector('#formEntry')
  }
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' })
  }
}

function formatKey(key: string) {
  let formattedKey = key
    .match(/([A-Z]?[^A-Z]*)/g)
    ?.slice(0, -1)
    ?.join(' ')

  formattedKey = formattedKey?.charAt(0).toUpperCase() + formattedKey!.slice(1)

  return formattedKey
}

export function formatErrors(errorKeys: string[]) {
  if (errorKeys.length > 1) {
    let requiredString = ''
    errorKeys.forEach((key, index) => {
      let formattedKey = formatKey(key)
      if (index === errorKeys.length - 1) requiredString += `${formattedKey}`
      else {
        requiredString += `${formattedKey} & `
      }
    })
    return `${requiredString} are required`
  } else {
    if (errorKeys[0]) {
      let formattedKey = formatKey(errorKeys[0])
      if (formattedKey === 'Notes') {
        return `${formattedKey} are required`
      } else {
        // Matching the common.json required error string
        if (errorKeys[0] === 'keysWhere') return `Location of Keys is required`
        if (errorKeys[0] === 'drivers') return `Phone number is required`
        return `${formattedKey} is required`
      }
    }
  }
  return null
}

const Submit: React.FC<SubmitFormsProps> = ({ onSubmit, callType, disabled, children }) => {
  const { t } = useTranslation()
  const forms = useSelector(selectFormsState)

  const eventType = callType ? CallTypeToEventType[callType] : null
  const allEvents = useSelector(selectAllEvents)
  const event = eventType ? allEvents[eventType] : undefined

  const [error, setError] = React.useState<string | null>(null)
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const [errorsArray, setErrorsArray] = React.useState<string[]>([])

  React.useEffect(() => {
    if (errorsArray.length) {
      scrollErrorIntoView(errorsArray[0])
    } else if (error) {
      const lastNotesSectionErrorString = 'Notes are required'
      scrollErrorIntoView(null, error === lastNotesSectionErrorString ? false : true)
    }
  }, [error, errorsArray])

  function scrollErrorIntoView(errorKey?: string | null, formError?: boolean) {
    let el: Element | null = null
    let errorKeyTop = [
      'selectedCaller',
      'confirmedDOB',
      'companyConfirmation',
      'phone',
      'callerName',
      'callerRelationship',
      'drivers',
      'title',
      'badge',
      'email',
      'department',
      'cityAndCounty',
      'stateOrProvince',
    ]

    if (errorKey && errorKeyTop.includes(errorKey)) {
      el = document.querySelector('#top')
    } else if (errorKey === 'callType') {
      el = document.querySelector('#callTypeTop')
    } else if (formError) {
      el = document.querySelector('#formEntry')
    }

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }

  React.useEffect(() => {
    setError(null)
  }, [callType])

  async function checkValidForms(forms: Forms) {
    let isValid = false
    const allFormsArray = Object.entries(forms) as Entry[]

    allFormsArray.forEach(async ([key, form]) => {
      if (!form || !form.submit) return

      if (typeof form === 'object' && !isEmpty(form)) {
        await form.submit()
        const formState = form.getState()
        isValid = formState.valid
      }
    })
    return isValid
  }

  function getFormValues(forms: Forms) {
    let newObject = {} as Record<string, any>
    const allFormsArray = Object.entries(forms) as Entry[]

    allFormsArray.forEach(async ([key, form]) => {
      if (!form || !form.getState) return { ...newObject }
      if (typeof form === 'object' && !isEmpty(form)) {
        const formState = form.getState()
        const { values } = formState
        newObject = merge({}, newObject, values)
      }
    })
    return newObject
  }

  return (
    <>
      <Button.Primary
        disabled={disabled}
        onClick={async () => {
          setErrorsArray([])
          if (!submitting) {
            setSubmitting(true)
            setError(null)
            try {
              let canSubmit = await checkValidForms(forms)
              const values = getFormValues(forms)

              // Handle Escalate Event Mid Form Flow
              if (callType && !callTypesToIgnore.includes(callType) && !event?.event) {
                // These are specific to escalateEvent and should auto-populate on form rendering
                const didEscalateFormRender = values.customer && values.customerContact && values.exchangeBranch
                if (callType === CallTypes.ESCALATE && !didEscalateFormRender) {
                  setError(t('error.incompleteEscalateForm') as string)
                } else {
                  setError(t('error.incompleteForm', { callType }) as string)
                }
                setSubmitting(false)
                return
              }

              if (!canSubmit) {
                let errors: string[] = []
                await Object.entries(forms).reduce(async (prevPromise: Promise<boolean>, [key, form]: Entry) => {
                  let currentSubmissionState = await prevPromise
                  if (!form || !form.submit) return currentSubmissionState
                  if (typeof form === 'object' && !isEmpty(form)) {
                    await form.submit()
                    const formState = form.getState()
                    let errorKeys = Object.keys(formState.errors as any)

                    if (errorKeys.includes('drivers')) {
                      if (formState.values.selectedCaller === 'account' || formState.values.selectedCaller === 'other') {
                        errorKeys = errorKeys.filter((element) => element !== 'drivers')
                      }
                    }
                    const callKeys = Object.entries(allEvents).reduce((acc: string[], [key, event]: any) => {
                      if (!isNil(event)) {
                        acc.push(key)
                      }
                      return acc
                    }, [])
                    if (errorKeys.length > 0 && callKeys.length > 0) {
                      errorKeys = errorKeys.filter((key) => !callKeys.includes(CallTypeToEventType[key]))
                    }
                    errors = [...errors, ...errorKeys]
                    setError(formatErrors(errorKeys))
                    currentSubmissionState = formState.valid
                  }

                  return currentSubmissionState
                }, Promise.resolve(true))
                setErrorsArray(errors)
                if (!errors.length) canSubmit = true
              }
              if (canSubmit) {
                onSubmit(values)
              }

              setSubmitting(false)
            } catch (e) {
              console.error(e)
              setError(t('error.submit') as string)
              setSubmitting(false)
            }
          }
        }}
      >
        {children}
      </Button.Primary>
      {error && <ErrorMessage style={{ display: 'block', marginTop: 8 }}>{error}</ErrorMessage>}
    </>
  )
}

export default Submit
