import styled from 'styled-components/macro'
import { palette, theme } from 'styled-tools'
import { pxToRem } from 'theme/utils'

export const CallerSeparator = styled.div`
  margin-left: ${pxToRem(11)};
  padding-left: ${theme('spaces.3')};
  border-left: 2px solid ${palette('calypso')};
`

export const UnselectedCallerSeparator = styled.div`
  margin-left: ${pxToRem(13)};
  padding-left: ${theme('spaces.3')};
`

export default CallerSeparator
