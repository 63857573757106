import { AxiosResponse } from 'axios'
import { useServiceProxy } from 'hooks/kong'
import { IncomingDynamoContacts } from 'types/api'
import { DynamoContactItems } from 'types/contacts'
import { deepMapSanitize } from 'utils/deepMap'

export function useContactsRefresh() {
  const serviceProxy = useServiceProxy()

  return async (eventId: string) => {
    return (await serviceProxy<IncomingDynamoContacts>(
      'get',
      `/serviceproxy/contacts-table/items?eventIdentifier=${eventId}`,
    ).then((response) => ({
      ...response,
      data: {
        Items: deepMapSanitize(response.data.Items),
      },
    }))) as AxiosResponse<DynamoContactItems>
  }
}

export default useContactsRefresh
