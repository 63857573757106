import * as React from 'react'
import { IconContext, IconType } from 'react-icons'
import { ListItem } from 'components/List'
import { Container, TitleText, infoIconContext, InfoIcon } from './DetailsStyles'
import { cardinal } from '../../../../../theme/colors'

type Props = {
  title: string
  icon?: IconType | React.FC<React.CSSProperties & unknown>
  onClick?: () => void
  rightIcon?: IconType | React.FC<React.CSSProperties & unknown>
  trucksWarning?: boolean
}

const DetailItem: React.FC<Props> = ({ title, icon, rightIcon, onClick, trucksWarning }) => (
  <Container onClick={onClick}>
    {icon ? (
      <ListItem.Icon icon={icon} />
    ) : (
      <span style={{ width: 24, height: 24, marginRight: '0.5rem' }} />
    )}
    <TitleText style={trucksWarning ? { color: cardinal, fontWeight: 'bold' } : {}}>
      {title}
    </TitleText>
    {onClick && !rightIcon && (
      <IconContext.Provider value={infoIconContext}>
        <InfoIcon />
      </IconContext.Provider>
    )}
    {rightIcon ? <ListItem.Icon icon={rightIcon} color="monza" /> : null}
  </Container>
)

export default DetailItem
