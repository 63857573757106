import * as React from 'react'
import { motion } from 'framer-motion'
import { default as SidebarComponent, SidebarProps } from 'components/Sidebar'
import { useSelector } from 'react-redux'
import { ReduxState, selectSidebarState } from 'redux/appStore'
import { SidebarPosition } from 'types/global'

type Custom = SidebarProps

const variants = {
  open: (custom: Custom) => ({
    width: custom.width || 'auto',
  }),
  closed: (custom: Custom) => ({
    width: custom.collapsedWidth || 0,
  }),
}

const Sidebar: React.FC<Custom & { side: SidebarPosition } & React.CSSProperties> = ({
  children,
  side,
  collapsedWidth,
  width,
  ...style
}) => {
  const isSidebarOpen = useSelector((state: ReduxState) => selectSidebarState(state)(side))

  return (
    <motion.div
      variants={variants}
      animate={isSidebarOpen ? 'open' : 'closed'}
      custom={{ width, collapsedWidth }}
      transition={{ type: 'tween' }}
    >
      <SidebarComponent style={style}>{children}</SidebarComponent>
    </motion.div>
  )
}

export default Sidebar
