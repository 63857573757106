import { useEffect, useState } from 'react'
import { globalConfig, useAxios } from '../useAxios'
import { config } from '../../config'

interface BranchHoursResponse {
  peopleSoftId: string
  data: { [key: string]: Datum }
}

interface Datum {
  STANDARD: Standard
}

interface Standard {
  hours: Hour[]
  open24Hours: boolean
  closed: boolean
}

interface Hour {
  open: string
  close: string
}

interface BranchHoursNormalized {
  day: string
  standard: Standard
}

export const useBranchHours = (
  peopleSoftId: string | null | undefined,
  startDateForBranchHours: string,
  endDateForBranchHours: string,
) => {
  const axios = useAxios()
  const [data, setData] = useState<null | BranchHoursNormalized[]>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<null | any>(null)
  const { solrBranchHours } = config
  const url = peopleSoftId ? `${solrBranchHours}/${peopleSoftId}?from=${startDateForBranchHours}&to=${endDateForBranchHours}` : ''

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get<BranchHoursResponse>(url, globalConfig)
        if (response.data.data) {
          const sevenDayDate = Object.keys(response.data.data).splice(0, 7)
          const sevenDayHourlyInfo = Object.values(response.data.data)
            .splice(0, 7)
            .map((day, index) => ({ day: sevenDayDate[index], standard: day.STANDARD }))

          setData(sevenDayHourlyInfo)
          setLoading(false)
        }
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    }

    fetchData()
  }, [url])

  return { data, loading, error }
}

export default useBranchHours
