import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { mergeWith, uniqBy } from 'lodash-es'
import { ConnectedCarStatusPayload, DynamicConnectedCarPayload, SearchState } from 'types/kong'

const initialState: SearchState = {
  lastVinSearched: null,
  connectedVehicles: [],
  connectedLocation: null,
  connectedMetrics: null,
  connectedError: null,
  connectedLoading: false,
}

const connectedSearchSlice = createSlice({
  name: 'connectedSearch',
  initialState,
  reducers: {
    setConnectedVehicleState: (state: SearchState, action: PayloadAction<ConnectedCarStatusPayload>) => {
      state.connectedVehicles = uniqBy([...state.connectedVehicles, action.payload], 'vin')
      return state
    },
    setConnectedState: (state: SearchState, action: PayloadAction<DynamicConnectedCarPayload>) => {
      state = mergeWith({}, state, action.payload)
      return state
    },
    setLastVinSearched: (state: SearchState, action: PayloadAction<string | null>) => {
      state.lastVinSearched = action.payload
    },
    resetConnectedSearchState: (state: SearchState) => {
      state = initialState
      return state
    },
  },
})

export const { setConnectedState, setConnectedVehicleState, setLastVinSearched, resetConnectedSearchState } =
  connectedSearchSlice.actions

export default connectedSearchSlice.reducer
