import { useSelector } from 'react-redux'
import { ReduxState, selectLocation } from 'redux/appStore'

export function useLocations() {
  const vehicleLocation = useVehicleLocation()
  const callerLocation = useCallerLocation()
  const vehicleDestinationLocation = useVehicleDestinationLocation()
  const callerDestinationLocation = useCallerDestinationLocation()
  const exchangeLocation = useExchangeLocation()

  return {
    vehicleLocation,
    callerLocation,
    vehicleDestinationLocation,
    callerDestinationLocation,
    exchangeLocation,
  }
}

export const useVehicleLocation = () => {
  return useSelector((state: ReduxState) => selectLocation(state)('vehicleLocation'))
}

export const useVehicleDestinationLocation = () => {
  return useSelector((state: ReduxState) => selectLocation(state)('vehicleDestinationLocation'))
}

export const useCallerDestinationLocation = () => {
  return useSelector((state: ReduxState) => selectLocation(state)('callerDestinationLocation'))
}

export const useCallerLocation = () => {
  return useSelector((state: ReduxState) => selectLocation(state)('callerLocation'))
}

export const useExchangeLocation = () => {
  return useSelector((state: ReduxState) => selectLocation(state)('exchangeLocation'))
}

export default useLocations
