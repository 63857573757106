import * as React from 'react'
import { FormSpy, FormSpyRenderProps } from 'react-final-form'
import { diff } from 'deep-object-diff'
import useMount from 'hooks/utils/useMount'

type Props<T> = {
  debounce?: number
  save: (input: T) => Promise<any>
}
type AutoSaveComponentProps<T> = Props<T> & FormSpyRenderProps

function AutoSaveComponent<T>({ debounce = 400, initialValues, values = {}, pristine, save }: AutoSaveComponentProps<T>) {
  const [promise, setPromise] = React.useState<Promise<any> | null>(null)
  const [state, setState] = React.useState<object>({})
  const [initValues, setInitValues] = React.useState<object>({})

  useMount(() => {
    setState(values)
  })

  React.useEffect(() => {
    if (initialValues && Object.keys(initialValues).length) {
      const difference = diff(initValues, initialValues)
      if (Object.keys(difference).length) {
        setState(initialValues)
        setInitValues(initialValues)
        setPromise(save(values as T))
      }
    }
  }, [initValues, initialValues, save, values])

  React.useEffect(() => {
    if (!pristine) {
      const performSave = async () => {
        if (promise) {
          await promise
        }
        const difference = diff(state, values)
        if (Object.keys(difference).length) {
          setState(values)
          setPromise(save(values as T))
        }
      }

      const handler = setTimeout(performSave, debounce)
      return () => {
        clearTimeout(handler)
      }
    }
  }, [values, debounce, promise, save, state, pristine])

  return null
}

function AutoSave<T>({ debounce, save, ...rest }: Props<T>) {
  return (
    <FormSpy
      {...rest}
      subscription={{ values: true, initialValues: true, pristine: true }}
      render={(formProps) => <AutoSaveComponent<T> debounce={debounce} save={save} {...formProps} />}
    />
  )
}

export default AutoSave
