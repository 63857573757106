import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { EventTypes } from 'types/events'
import { LDRClaimCreation, LDRConfirmation } from './Steps'
import LDREvent from './LDREvent'
import { ProgressBar, Step } from 'components/Step'
import { useSelector } from 'react-redux'
import { selectCurrentEvent } from 'redux/appStore'

export const LDR = () => {
  const stepStore = Ariakit.useCompositeStore({ defaultActiveId: '1' })

  const recentlyCreatedLDRevent = useSelector(selectCurrentEvent)(EventTypes.LDR)
  if (recentlyCreatedLDRevent) return <LDREvent {...(recentlyCreatedLDRevent.event as any)} />

  return (
    <Ariakit.CompositeProvider store={stepStore}>
      <Ariakit.Composite store={stepStore}>
        <ProgressBar />
        <Step step="1">
          <LDRClaimCreation />
        </Step>
        <Step step="2">
          <LDRConfirmation />
        </Step>
      </Ariakit.Composite>
    </Ariakit.CompositeProvider>
  )
}

export default LDR
