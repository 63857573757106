import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/Card'
import { Flex, Label, Paragraph } from 'components/primitives'
import Margin from 'components/Margin'
import { usePopupManager } from 'components/PopupManager'
import ScrollBox from 'components/ScrollBox'
import styled from 'styled-components/macro'
import { palette, theme } from 'styled-tools'
import { EventFeed } from 'types/events'
import NoHistory from './NoHistory'
import { MdClose, MdAssignmentReturned } from 'react-icons/md'
import { dustyGray, calypso } from 'theme/colors'
import { CSVLink } from 'react-csv'

const StyledCard = styled(Card as any)`
  display: flex;
  flex-direction: column;
  width: auto;
  max-height: 100%;
  margin-left: 24px;
  min-width: 600px;
  max-width: 700px;
`

const Header = styled(Flex as any)`
  justify-content: space-between;
  padding: 0 ${theme('spaces.2')} ${theme('spaces.1')};
  margin: 0 -${theme('spaces.2')};
  border-bottom: 1px solid ${palette('mercury')};
  font-size: 1em;
`
type Props = {
  limit?: number
  events: EventFeed['events']
  loadingHistory: boolean
  errorHistory: any
}
const CallHistoryPopupCard: React.FC<Props> = ({ events, loadingHistory, errorHistory }) => {
  const { t } = useTranslation()
  const { close } = usePopupManager()
  const getTableContent = () => {
    const iterateItem = () => {
      return events.map((event) => {
        const eventProvider = event.attributes?.provider === 'ULY' ? 'Urgent.ly' : 'AAA'
        const additionalTypeText = event.attributes?.unattended ? 'Unattended ' : ''
        const timestamp = event.audit?.[0]?.createTimestamp || ''
        const eventBranchNotes = event.attributes?.branchNotes || event.attributes?.attributes?.branchNotes || ''
        return (
          <tr key={event.eventIdentifier} style={{ borderBottom: '1px solid #000', width: '100%' }}>
            <td style={{ width: '25%' }}>{t('date.standardWithTime', { date: new Date(timestamp) })}</td>
            <td style={{ width: '10%' }}>
              {event.attributes?.dispatch || event.attributes?.serviceCase ? `${eventProvider}` : null}
            </td>
            <td style={{ width: '20%' }}>{additionalTypeText + event.eventType}</td>
            <td style={{ width: '10%' }}>{event.audit?.[0]?.eId || ''}</td>
            <td style={{ width: '35%' }}>{eventBranchNotes}</td>
          </tr>
        )
      })
    }
    return (
      <table key="Call History Table" style={{ fontSize: '0.75rem', borderCollapse: 'collapse' }}>
        <thead>
          <tr key="Call History Headings" style={{ borderBottom: '1px solid #000', textAlign: 'left' }}>
            <td style={{ width: '25%' }}>
              <b>{t('Date & Time')}</b>
            </td>
            <td style={{ width: '10%' }}>
              <b>{t('Provider')}</b>
            </td>
            <td style={{ width: '10%' }}>
              <b>{t('Type')}</b>
            </td>
            <td style={{ width: '10%' }}>
              <b>{t('Agent')}</b>
            </td>
            <td style={{ width: '45%' }}>
              <b>{t('Notes')}</b>
            </td>
          </tr>
        </thead>
        <tbody>{iterateItem()}</tbody>
      </table>
    )
  }
  const csvHeaders = [t('Date & Time'), t('Provider'), t('Type'), t('Agent'), t('Notes')]

  function csvData() {
    return events.map((event) => {
      const eventProvider = event.attributes?.provider === 'ULY' ? 'Urgent.ly' : 'AAA'
      const eventEID = event.audit?.[0]?.eId
      const eventBranchNotes = event.attributes?.branchNotes || event.attributes?.attributes?.branchNotes || ''
      const timestamp = event.audit?.[0]?.createTimestamp || ''

      return [
        t('date.standardWithTime', { date: new Date(timestamp) }),
        event.attributes?.dispatch || event.attributes?.serviceCase ? `${eventProvider}` : null,
        event.eventType,
        eventEID,
        eventBranchNotes,
      ]
    })
  }

  return (
    <React.Fragment>
      <StyledCard>
        <Header as="h5">
          <div style={{ display: 'flex' }}>
            {t('Call History')}
            <CSVLink data={csvData()} headers={csvHeaders} filename={'CallHistory.csv'}>
              <MdAssignmentReturned style={{ cursor: 'pointer', marginLeft: 5 }} color={calypso} />
            </CSVLink>
          </div>
          <MdClose style={{ cursor: 'pointer' }} color={dustyGray} onClick={close} />
        </Header>
        <ScrollBox>
          {(!events || events.length === 0) && !loadingHistory && <NoHistory />}
          {events && <div>{getTableContent()}</div>}
          {loadingHistory && <Paragraph style={{ fontStyle: 'italic', opacity: 0.5 }}>{t('Loading')}</Paragraph>}
          {errorHistory && (
            <Margin spacing="sm">
              <Label>{t('Error loading history')}</Label>
            </Margin>
          )}
        </ScrollBox>
      </StyledCard>
    </React.Fragment>
  )
}

export default CallHistoryPopupCard
