import * as React from 'react'
import { Branch } from 'types/global'
import { useFieldValue } from 'hooks/utils'
import ExchangeSearch from './ExchangeSearch'
import ExchangeBranch from './ExchangeBranch'
import { LocationPayload } from 'types/location'

type Props = {
  name: string
  searchLocation?: LocationPayload | undefined | null
  onSetBranch?: (branch: Branch) => void
  isTowExchange: boolean
}

export const Exchange: React.FC<Props> = ({ name, searchLocation, onSetBranch, isTowExchange }) => {
  const branch = useFieldValue(name)

  return (
    <>
      {branch ? (
        <ExchangeBranch name={name} />
      ) : (
        <ExchangeSearch
          name={name}
          searchLocation={searchLocation}
          onSetBranch={onSetBranch}
          isTowExchange={isTowExchange}
        />
      )}
    </>
  )
}

export default Exchange
