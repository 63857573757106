import * as React from 'react'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import useFieldValue from 'hooks/utils/useFieldValue'
import Margin from 'components/Margin'
import { Paragraph, Heading } from 'components/primitives'
import Input from 'components/Input'
import DisabledVehicleSelect from './DisabledVehicleSelect'
import { ExchangeBranchProps, getRecentTowEventInfo } from './utils'
import { isRequired } from 'utils/validators'
import { Condition } from 'components/fields'

export const DisabledVehicleSelection = ({ recentEvent }: ExchangeBranchProps) => {
  const { t } = useTranslation()
  const disabledVehicle = useFieldValue('disabledVehicleSelect')
  const towEvent = getRecentTowEventInfo({ recentEvent })
  const form = useForm()

  React.useEffect(() => {
    if (disabledVehicle.value === 'towed') {
      form.change('towLocation', towEvent ? towEvent : { name: 'N/A', address: '', cityState: '' })
    } else {
      form.change('towLocation', { name: 'N/A', address: '', cityState: '' })
    }
  }, [towEvent, form, disabledVehicle.value])

  return (
    <Margin spacing="sm">
      <DisabledVehicleSelect
        name="disabledVehicleSelect"
        label={`${t('labels.disabledVehicleSelect')}*`}
      />
      <Input.Hidden
        name="disabledVehicleSelect.value"
        validate={isRequired(t('required.disabledVehicle'))}
      />
      <Condition when="disabledVehicleSelect" is={v => v?.value !== undefined}>
        {(v: { label?: string }) => (
          <>
            <Input.Hidden
              name="disabledVehicle"
              defaultValue={v?.label ? (v.label as any) : undefined}
            />
          </>
        )}
      </Condition>
      {disabledVehicle.value === 'towed' && towEvent ? (
        <Margin>
          <Heading as="h5">Tow Destination</Heading>
          <Margin spacing="md">
            <Paragraph>{towEvent.name}</Paragraph>
          </Margin>
          <Margin spacing="md">
            <Paragraph>{towEvent.address}</Paragraph>
            <Paragraph>{towEvent.cityState}</Paragraph>
          </Margin>
        </Margin>
      ) : null}
    </Margin>
  )
}

export default DisabledVehicleSelection
