import styled, { css, FlattenInterpolation, ThemeProps } from 'styled-components/macro'
import { theme, switchProp, palette } from 'styled-tools'
import { pxToRem } from 'theme/utils'
import { Spaces } from 'types/global'

type SpacingProps = {
  spacing?: Spaces
  gutter?: Spaces
}

const applySpaces = (prop: string) =>
  switchProp(prop, {
    none: 0,
    sm: theme('spaces.1'),
    md: theme('spaces.2'),
    lg: theme('spaces.3'),
  })

function spacing({ spacing, gutter }: SpacingProps): FlattenInterpolation<ThemeProps<any>> {
  let styles: FlattenInterpolation<ThemeProps<any>> = css``
  if (spacing)
    styles = css`
      ${styles}
      margin-bottom: ${applySpaces('spacing')};
    `
  if (gutter)
    styles = css`
      ${styles}
      margin-right: ${applySpaces('gutter')};
    `
  return styles
}

export const Flex = styled.div<SpacingProps>`
  display: flex;
  ${spacing}
`

export const Heading = styled.h1<SpacingProps>`
  font-weight: 700;
  margin: 0;
  h1& {
    font-size: 1.5em;
    text-transform: uppercase;
  }
  h2& {
    font-size: 1.5em;
  }
  h3& {
    font-size: 1.5em;
  }
  h4& {
    font-size: 1.25em;
  }
  h5& {
    font-size: 1em;
    font-weight: 600;
  }
  h6& {
    font-size: 1em;
    font-weight: 400;
  }
  ${spacing}
`

export const Label = styled.label<SpacingProps>`
  display: block;
  font-size: ${pxToRem(12)};
  white-space: pre-wrap;

  input,
  textarea,
  > div {
    color: ${palette('black')};
    margin-top: 2px;
  }
  ${spacing}
`

export const Paragraph = styled.p<SpacingProps>`
  margin: 0;
  font-size: ${pxToRem(16)};
  ${spacing}
`

export const Link = styled.a`
  cursor: pointer;
  font-size: ${pxToRem(14)};
  display: flex;
  align-items: center;
  text-decoration: underline;
  height: 2rem;
  color: ${palette('denim')};
`

export const Field = styled.div<SpacingProps>`
  flex: 1;

  :not(:last-child) {
    margin-right: ${theme('spaces.3')};
  }
  ${spacing}
`

export const Input = styled.input<SpacingProps>`
  display: block;
  width: 100%;
  font-size: ${pxToRem(16)};
  padding: 0.5em 1em;
  height: ${pxToRem(38)};
  border: 2px solid ${palette('mercury')};
  font-family: inherit;

  &[type='checkbox'],
  &[type='radio'] {
    display: none;
  }

  &::placeholder {
    color: ${palette('silver')};
    text-transform: none;
  }

  textarea & {
    padding: 0.5em;
    height: 4em;
  }
  ${spacing}
`

export const Box = styled.div<SpacingProps>(spacing)
