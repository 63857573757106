import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Flex, Heading } from 'components/primitives'
import MdSpinner from 'react-md-spinner'
import Button from 'components/Button'
import Column from 'components/Column'
import { usePopupManager } from 'components/PopupManager'
import { Dialog } from 'components/Dialog'
import { calypso } from 'theme/colors'
import { pxToRem } from 'theme/utils'

export const PendingLDROverlay = ({ children }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { close } = usePopupManager()
  const dialog = Ariakit.useDialogContext()
  const [exiting, setExiting] = React.useState<boolean>(false)

  return (
    <>
      <Dialog {...dialog!} aria-label="Pending LDR">
        {exiting ? (
          <Column>
            <Heading as="h4">{t('Leaving call...')}</Heading>
            <MdSpinner singleColor={calypso} size={64} style={{ margin: `${pxToRem(32)} 0` }} />
          </Column>
        ) : (
          <Column>
            <Heading as="h4">{t('LDR Confirmed')}</Heading>
            <div
              style={{
                textAlign: 'center',
                margin: `${pxToRem(16)} 0 ${pxToRem(24)}`,
                width: pxToRem(312),
              }}
            >
              {t('The claim number has been saved.  Do you want to leave the call?')}
            </div>
            <Flex>
              <Button.Primary
                style={{ marginRight: pxToRem(24) }}
                onClick={async () => {
                  setExiting(true)
                  dialog?.hide()
                  navigate('/ticket')
                }}
              >
                {t('Leave call')}
              </Button.Primary>
              <Button.Regular
                onClick={() => {
                  dialog?.hide()
                  close()
                }}
              >
                {t('Return to call')}
              </Button.Regular>
            </Flex>
          </Column>
        )}
      </Dialog>
      {children}
    </>
  )
}

const OverlayWithContext: React.FC = (props) => {
  const dialogStore = Ariakit.useDialogStore()

  return (
    <Ariakit.DialogProvider store={dialogStore}>
      <PendingLDROverlay {...props} />
    </Ariakit.DialogProvider>
  )
}

export default OverlayWithContext
