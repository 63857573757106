import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useServiceProxy } from 'hooks/kong'
import useTimerState from 'hooks/useTimerState'
import { selectTicket } from 'redux/appStore'
import { updateTicket } from 'redux/ticket/ticketSlice'
import { fetchEvents, getFleetVehicleDetails, loadTicketByRA } from 'utils/ticket'
import { resetAppState } from 'redux/appSlice'
import { resetConnectedSearchState } from 'redux/connectedSearch/connectedSearchSlice'
import { resetExchangeSearchState } from 'redux/exchangeSearch/exchangeSearchSlice'
import { resetFleetVehicleState, setFleetVehicle, setFleetVehicleHistory } from 'redux/fleetVehicle/fleetVehicleSlice'
import { resetFormState } from 'redux/form/formSlice'
import { resetMapState } from 'redux/map/mapSlice'
import { resetTabState } from 'redux/tabs/tabsSlice'
import { resetCallHistoryEvents, updateTicketHistoryEvents } from 'redux/callHistory/callHistorySlice'
import { resetCurrentEvent } from 'redux/currentEvent/currentEventSlice'
import { resetSmartAssignmentState } from 'redux/smartAssignment/smartAssignmentSlice'
import { resetTempMarkerState } from 'redux/tempMarker/tempMarkerSlice'
import { updateLocation } from 'redux/location/locationSlice'
import { MarkerType } from 'types/googleMaps'
import { TransformedTicket, TransformedVehicle } from 'types/ticket'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isEmpty } from 'lodash'

export function useTicketUrlTracker(ticketNumber: string) {
  const [loadingTicket, setLoadingTicket] = useState<boolean>(false)
  const serviceProxy = useServiceProxy()
  const dispatch = useDispatch()
  const currentTicket = useSelector(selectTicket)
  const currentTicketNumber = currentTicket?.ticketNumber
  const timerState = useTimerState()
  const flags = useFlags()

  useEffect(() => {
    if (currentTicketNumber !== ticketNumber) {
      async function changeTicket() {
        if (!loadingTicket && !isEmpty(flags)) {
          setLoadingTicket(true)
          // Needing to reset the local App state
          dispatch(resetAppState()) // Sidebar State
          dispatch(resetMapState()) // Map State
          dispatch(resetExchangeSearchState()) // Exchange Search State
          dispatch(resetFormState()) // Form State
          dispatch(resetFleetVehicleState()) // Old Fleet Vehicle State
          dispatch(resetTabState()) // Tab State
          dispatch(resetConnectedSearchState()) // Any Connected Car Search State
          dispatch(resetTempMarkerState()) // Marker State
          dispatch(resetSmartAssignmentState()) // Any Entegral State
          dispatch(resetCurrentEvent()) // Any Created Event State
          dispatch(resetCallHistoryEvents()) // Event(s) state
          localStorage.removeItem('localFormStore')

          // Needing to populate the following...
          // 1. Ticket / RA Information
          const ticketResult = (await loadTicketByRA(ticketNumber, serviceProxy, flags)) as TransformedTicket
          dispatch(updateTicket({ ticket: ticketResult }))

          // 2. Fleet Vehicle Information
          if (ticketResult && ticketResult.vehicle && ticketResult.vehicle.unitNumber !== '') {
            const fleetVehicleResult = await getFleetVehicleDetails(ticketResult.vehicle.unitNumber, serviceProxy)

            if (fleetVehicleResult !== undefined) {
              dispatch(setFleetVehicle({ vehicle: fleetVehicleResult.fleetVehicle || {} }))
            }
          }
          // 2.5 Fleet Vehicle History Information
          if (ticketResult && ticketResult.vehicleHistory && ticketResult.vehicleHistory.length > 0) {
            ticketResult.vehicleHistory.forEach(async (vehicle) => {
              const fleetVehicleResult = await getFleetVehicleDetails((vehicle as TransformedVehicle).unitNumber, serviceProxy)
              if (fleetVehicleResult !== undefined) {
                dispatch(setFleetVehicleHistory({ vehicleHistory: [fleetVehicleResult.fleetVehicle] }))
              }
            })
          }

          // 3. DB Events - Call History, Recent Dispatches, & Service Locations
          const eventItems = await fetchEvents(ticketNumber, serviceProxy)
          if (eventItems) {
            dispatch(updateTicketHistoryEvents({ historyEvents: [...eventItems.Items] }))
            const recentEventWithLocations = eventItems.Items.find((event) => event.serviceLocations)
            if (recentEventWithLocations) {
              const serviceLocs = recentEventWithLocations.serviceLocations

              if (serviceLocs) {
                if (serviceLocs.callerLocation) {
                  let callerLoc = serviceLocs.callerLocation
                  dispatch(
                    updateLocation({
                      name: 'callerLocation',
                      location: {
                        latitude: callerLoc.latitude,
                        longitude: callerLoc.longitude,
                        address: callerLoc.address,
                        addressDescription: callerLoc.addressDescription,
                        serviceLocationType: MarkerType.CALLER,
                      },
                    }),
                  )
                }
                if (serviceLocs.vehicleLocation) {
                  let vehicleLoc = serviceLocs.vehicleLocation
                  dispatch(
                    updateLocation({
                      name: 'vehicleLocation',
                      location: {
                        latitude: vehicleLoc.latitude,
                        longitude: vehicleLoc.longitude,
                        address: vehicleLoc.address,
                        addressDescription: vehicleLoc.addressDescription,
                        serviceLocationType: MarkerType.VEHICLE,
                      },
                    }),
                  )
                }
                if (serviceLocs.exchangeLocation) {
                  let exchangeLoc = serviceLocs.exchangeLocation
                  dispatch(
                    updateLocation({
                      name: 'exchangeLocation',
                      location: {
                        latitude: exchangeLoc.latitude,
                        longitude: exchangeLoc.longitude,
                        address: exchangeLoc.address,
                        addressDescription: exchangeLoc.addressDescription,
                        serviceLocationType: MarkerType.EXCHANGE,
                      },
                    }),
                  )
                }
              }
            }
          }

          setLoadingTicket(false)
          timerState.set({
            ticketNumber: ticketNumber,
            createdAt: new Date().toISOString(),
          })
        }
      }
      changeTicket()
    }
  }, [dispatch, flags])

  return loadingTicket
}

export default useTicketUrlTracker
