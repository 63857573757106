import * as React from 'react'
import { useGoogleMaps } from 'hooks/map'
import { reverseGeocode } from 'components/useReverseGeocoder'
import { useServiceProxy } from 'hooks/kong'
import { GeocodeAddress } from 'types/googleMaps'
import { useAsync } from 'hooks/useAsync'

type ValueType = {
  event: google.maps.MapMouseEvent | null
  clear: () => void
  address: GeocodeAddress | null
  isLoading: boolean
}

export const TARGET_OFFSET = 26

export const useContextMenuEvent = (): (() => ValueType | null) => {
  const { map } = useGoogleMaps()
  const serviceProxy = useServiceProxy()
  const [address, setAddress] = React.useState<GeocodeAddress | null | 'ZERO_RESULTS'>(null)
  const [mapEvent, setMapEvent] = React.useState<google.maps.MapMouseEvent | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const clear = () => {
    setMapEvent(null)
    setAddress(null)
    setIsLoading(false)
  }

  React.useEffect(() => {
    if (map) {
      const contextListener = map.addListener('contextmenu', setMapEvent)
      const clickListener = map.addListener('click', clear)
      const moveListener = map.addListener('dragstart', clear)
      const mouseMoveListener = map.addListener('mousemove', clear)
      return () => {
        google.maps.event.removeListener(contextListener)
        google.maps.event.removeListener(clickListener)
        google.maps.event.removeListener(moveListener)
        google.maps.event.removeListener(mouseMoveListener)
      }
    }
  }, [map])

  const getGeocodeAddress = React.useCallback(async () => {
    setIsLoading(true)
    if (mapEvent && mapEvent.latLng) {
      const lat = mapEvent.latLng.lat()
      const lng = mapEvent.latLng.lng()
      if (lat && lng && !address && address !== 'ZERO_RESULTS') {
        return reverseGeocode(serviceProxy, lat, lng)
      }
    }
  }, [mapEvent, serviceProxy, address])

  function setGeocodeAddress(address: GeocodeAddress | null | 'ZERO_RESULTS') {
    if (address) {
      setAddress(address)
    }
    setIsLoading(false)
  }

  useAsync(getGeocodeAddress, setGeocodeAddress)

  const getContextMenu = React.useCallback(
    () => (mapEvent ? { address: address as GeocodeAddress | null, event: mapEvent, clear, isLoading } : null),
    [mapEvent, address, isLoading],
  )
  return getContextMenu
}

export default useContextMenuEvent
