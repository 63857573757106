import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { LocationState, Locations, LocationPayload } from 'types/location'
import { merge } from 'lodash-es'

export const initialState: LocationState = {
  vehicleLocation: undefined,
  vehicleDestinationLocation: undefined,
  callerLocation: undefined,
  callerDestinationLocation: undefined,
  exchangeLocation: undefined,
}

const locationSlice = createSlice({
  name: 'Location',
  initialState,
  reducers: {
    updateLocation: (state: LocationState, action: PayloadAction<{ name: Locations; location: LocationPayload | null }>) => {
      const { name, location } = action.payload
      if (location === null) {
        delete state[name]
        return state
      }
      return { ...state, [name]: merge({}, { ...state[name] }, { ...location }) }
    },
    resetLocations: (state: LocationState) => {
      state = { ...initialState }
      return state
    },
  },
})

export const { updateLocation, resetLocations } = locationSlice.actions

export default locationSlice.reducer
