import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ICurrentEventState, SetCurrentEventAction } from 'types/events'

const initialState: ICurrentEventState = {
  call: null,
  callback: null,
  dispatch: null,
  drop: null,
  exchange: null,
  exchangeTurndown: null,
  fuel: null,
  jumpstart: null,
  winch: null,
  recovery: null,
  law: null,
  escalate: null,
  ldr: null,
  lockout: null,
  tires: null,
  tiresChangeSpare: null,
  tow: null,
  transfer: null,
  accident: null,
  additional: null,
  feature: null,
}

const currentEventSlice = createSlice({
  name: 'CurrentEvent',
  initialState,
  reducers: {
    setCurrentEvent: (state: ICurrentEventState, action: PayloadAction<SetCurrentEventAction>) => {
      const newState = { ...state }
      const { newEvent, eventType } = action.payload
      newState[eventType] = { event: newEvent }
      return newState
    },
    resetCurrentEvent: (state: ICurrentEventState) => {
      const newState = { ...initialState }
      state = newState
      return state
    },
  },
})

export const { setCurrentEvent, resetCurrentEvent } = currentEventSlice.actions

export default currentEventSlice.reducer
