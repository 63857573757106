import * as React from 'react'
import * as Ariakit from '@ariakit/react'

function Step({ step, children }: { step: string; children: React.ReactNode | ((controls: Ariakit.CompositeStore) => void) }) {
  const controlsStore = Ariakit.useCompositeContext()
  const currentStepid = controlsStore?.useState('activeId')

  React.useEffect(
    () => {
      controlsStore?.registerItem({ id: step })
    },
    // eslint-disable-next-line
    [controlsStore?.registerItem, step],
  )

  const visible = step === currentStepid

  return (
    <Ariakit.CompositeItem
      id={step}
      render={<div />}
      store={controlsStore}
      style={{ backgroundColor: 'unset', border: 0, padding: 0 }}
    >
      {(visible ? (typeof children === 'function' ? children(controlsStore!) : children) : null) as React.ReactNode}
    </Ariakit.CompositeItem>
  )
}

export default Step
