import * as React from 'react'

export class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error(error, info)
    // TODO: some user error message
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
