import * as React from 'react'
import { Field as FinalFormField, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Field } from 'components/primitives'
import Margin from 'components/Margin'
import Input from 'components/Input'
import useCallerFormValues from 'hooks/events/caller/useCallerFormValues'
import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import { PhoneNumber } from 'components/Input/FinalFormInputs'
import { formatCaller } from 'components/fields/Submit'
import { isCheckboxRequired, isRequired } from 'utils/validators'
import { CountryCode, isSupportedCountry, getExampleNumber, parsePhoneNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'

export const ConfirmCallerContact = () => {
  const { t } = useTranslation()
  const form = useForm()
  const { drivers } = useSelector(selectTicket)
  const caller = useCallerFormValues(drivers as any)
  const callerValues = formatCaller(caller)

  const countryCode = callerValues.countryCode as unknown as string
  let defaultCountry = countryCode?.toUpperCase() as CountryCode
  if (!isSupportedCountry(defaultCountry)) defaultCountry = 'US'
  const exampleNumber = getExampleNumber(defaultCountry, examples)
  const defaultPhone = parsePhoneNumber(callerValues.number, defaultCountry)

  React.useEffect(() => {
    form.change('customer', callerValues.callerName)
    form.change('customerContact', callerValues.formatted)
  }, [callerValues.callerName, form, callerValues.formatted])

  return (
    <Margin spacing="sm">
      <Input.RegisteredCheckbox name="confirmedContact" validate={isCheckboxRequired(t('required.confirmContact'))} spacing="sm">
        {t('Confirmed')}
      </Input.RegisteredCheckbox>
      <div style={{ marginLeft: 34, marginRight: '35%' }}>
        <FinalFormField name="countryCode" subscription={{ value: true }}>
          {({ input: { value: option } }) => {
            let selectedCountry = (option.value ? option.value.toUpperCase() : option?.toUpperCase()) as CountryCode
            return (
              <Field>
                <PhoneNumber
                  label={t('Callback Number*')}
                  name="number"
                  exampleNumber={exampleNumber}
                  defaultPhone={defaultPhone}
                  selectedCountry={selectedCountry}
                  spacing="none"
                  validate={isRequired(t('required.confirmContact'))}
                  defaultValue={defaultPhone.formatNational() as any}
                />
              </Field>
            )
          }}
        </FinalFormField>
      </div>
    </Margin>
  )
}

export default ConfirmCallerContact
