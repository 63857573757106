import * as React from 'react'
import { MdPlace } from 'react-icons/md'
import { Heading, Paragraph } from 'components/primitives'
import { ListItem } from 'components/List'

type Props = {
  title: string
  lines: string[]
}

export const AddressTooltip: React.FC<Props> = ({ lines, title }) => (
  <div>
    <Heading as="h5">{title}</Heading>
    <ListItem>
      <ListItem.Icon icon={MdPlace} color="elm" />
      <ListItem.Text>
        {lines.map(line => (
          <Paragraph key={line}>{line}</Paragraph>
        ))}
      </ListItem.Text>
    </ListItem>
  </div>
)

export default AddressTooltip
