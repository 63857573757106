import * as React from 'react'
import Input, { InputProps, RadioInput } from './Input'
import {
  Date as DateComponent,
  PhoneNumber as PhoneNumberComponent,
  Radio as RadioComponent,
  Select as SelectComponent,
  Textarea as TextareaComponent,
  RegisteredCheckbox as CheckboxComponent,
} from 'components/fields'

export const Date: React.FC<InputProps> = props => <Input component={DateComponent} {...props} />
// Phone number without country code
export const PhoneNumber: React.FC<InputProps> = props => (
  <Input component={PhoneNumberComponent} {...props} />
)
export const Radio: React.FC<InputProps> = ({ children, ...props }) => (
  <RadioInput component={RadioComponent} type="radio" {...props}>
    {children}
  </RadioInput>
)
export const Select: React.FC<InputProps> = props => (
  <Input component={SelectComponent} {...props} />
)
export const Textarea: React.FC<InputProps> = props => (
  <Input component={TextareaComponent} {...props} />
)
export const Checkbox: React.FC<InputProps> = ({ children, ...props }) => (
  <Input type="checkbox" component={CheckboxComponent} {...props}>
    {children}
  </Input>
)
