import * as React from 'react'
import { Maybe } from 'types/global'
import { ConnectedCarFuel, ConnectedCarIgnitionStatus, ConnectedCarOdometer } from 'types/kong'
import { GeocodeAddress } from 'types/googleMaps'
import useCreateUpdateLocation from 'hooks/redux/useCreateUpdateLocation'
import { useVehicleLocation } from 'hooks/redux/useLocations'
import { MdPlace, MdLocalGasStation, MdTimeToLeave, MdSchedule, MdVpnKey } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { MarkerType } from 'types/googleMaps'
import { useCreateEhiTicketNote } from 'hooks/tickets'
import { Heading, Paragraph } from 'components/primitives'
import { ListItem } from 'components/List'
import Margin from 'components/Margin'
import { MdClose } from 'react-icons/md'
import { dustyGray } from 'theme/colors'
import { AaaAddress } from 'types/aaa'
import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'

type Props = {
  address: GeocodeAddress | null
  latitude: number
  longitude: number
  lastUpdatedAt: string
  title: string
  fuel?: Maybe<ConnectedCarFuel>
  ignitionStatus?: Maybe<ConnectedCarIgnitionStatus>
  odometer?: Maybe<ConnectedCarOdometer>
  vin: string
  handleClose: () => void
}

function ConnectedAddress({ address, latitude, longitude }: Pick<Props, 'address' | 'latitude' | 'longitude'>) {
  return (
    <ListItem>
      <ListItem.Icon icon={MdPlace} color="calypso" />
      <ListItem.Text>
        {latitude && longitude && <Paragraph>{`(${latitude}, ${longitude})`}</Paragraph>}
        {address && (
          <>
            {address.HouseNumber && address.Street && (
              <Paragraph>{`${address.HouseNumber} ${address.Street}`}</Paragraph>
            )}
            <Paragraph>{`${address.City}, ${address.State} ${address.PostalCode}, ${address.Country}`}</Paragraph>
          </>
        )}
      </ListItem.Text>
    </ListItem>
  )
}

function ConnectedFuel({ fuel }: Pick<Props, 'fuel'>) {
  const remainingFuel = (fuel?.capacityRemaining?.liters || 0) * 0.26417205235815
  return (
    <ListItem>
      <ListItem.Icon icon={MdLocalGasStation} color="calypso" />
      {
        <ListItem.Text>
          {fuel ? (
            <Paragraph>{`${remainingFuel.toFixed(1)} gallons`}</Paragraph>
          ) : (
            <Paragraph>No Fuel Metrics Found</Paragraph>
          )}
        </ListItem.Text>
      }
    </ListItem>
  )
}
function ConnectedOdometer({ odometer }: Pick<Props, 'odometer'>) {
  const mileage = odometer?.kilometers || 0

  function formatToMiles(odo: number) {
    return (odo * 0.621371)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const odometerInMiles = formatToMiles(mileage)

  return (
    <ListItem>
      <ListItem.Icon icon={MdTimeToLeave} color="calypso" />
      <ListItem.Text>
        {mileage !== 0 ? (
          <Paragraph>{`${odometerInMiles} Miles`}</Paragraph>
        ) : (
          <Paragraph>No Odometer Metrics found</Paragraph>
        )}
      </ListItem.Text>
    </ListItem>
  )
}

function ConnectedIgnitionStatus({ ignitionStatus }: Pick<Props, 'ignitionStatus'>) {
  const status = ignitionStatus ? (ignitionStatus.reading?.toLowerCase() === 'on' ? 'On' : 'Off') : 'Off'
  return (
    <ListItem>
      <ListItem.Icon icon={MdVpnKey} color="calypso" />
      <ListItem.Text>
        {ignitionStatus ? (
          <Paragraph>Status: {status}</Paragraph>
        ) : (
          <Paragraph>No Ignition Status Metrics Found</Paragraph>
        )}
      </ListItem.Text>
    </ListItem>
  )
}
function ConnectedUpdatedAt({ lastUpdatedAt }: Pick<Props, 'lastUpdatedAt'>) {
  const { t } = useTranslation()

  return (
    <ListItem>
      <ListItem.Icon icon={MdSchedule} color="calypso" />
      <ListItem.Text>
        {lastUpdatedAt && (
          <Paragraph>{`Location as of: ${t('date.standardWithTime', {
            date: new Date(lastUpdatedAt),
          })}`}</Paragraph>
        )}
      </ListItem.Text>
    </ListItem>
  )
}

function SetConnectedVehicle({
  address,
  latitude,
  longitude,
  vin,
}: Pick<Props, 'address' | 'latitude' | 'longitude' | 'vin'>) {
  const { t } = useTranslation()
  const ticket = useSelector(selectTicket)
  const createUpdateLocation = useCreateUpdateLocation()
  const createEhiTicketNote = useCreateEhiTicketNote()

  const isPrimaryVehicle = ticket.vehicle?.vin === vin

  const handleAddLocation = async () => {
    if (address) {
      const addr = address.Label && address.Label.replace(',', '<br/>')

      const vehicleLocationNotes = `Vehicle location updated using Connected Car services`

      await createEhiTicketNote(vehicleLocationNotes)

      createUpdateLocation({
        type: MarkerType.VEHICLE,
        latitude: latitude,
        longitude: longitude,
        address: addr || t('N/A'),
        extendedAddress: address as Partial<AaaAddress>,
      })
    }
  }

  if (!isPrimaryVehicle) return null

  return (
    <Margin style={{ margin: '12px 0' }}>
      <ListItem.Text
        as="span"
        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={handleAddLocation}
      >
        {t('Set As Vehicle Pin')}
      </ListItem.Text>
    </Margin>
  )
}

export const ConnectedCarTooltip: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const vehicleLocation = useVehicleLocation()
  const { title, address, latitude, longitude, fuel, odometer, ignitionStatus, lastUpdatedAt, vin, handleClose } = props
  const additionalTitleText =
    vehicleLocation?.latitude === latitude && vehicleLocation?.longitude === longitude
      ? t('(Current vehicle location)')
      : ''
  return (
    <div>
      <Heading as="h5">
        {`${title} ${additionalTitleText}`}
        <MdClose
          style={{ cursor: 'pointer', verticalAlign: 'middle', float: 'right' }}
          color={dustyGray}
          onClick={handleClose}
        />
      </Heading>
      <ConnectedAddress address={address} latitude={latitude} longitude={longitude} />
      <ConnectedFuel fuel={fuel} />
      <ConnectedOdometer odometer={odometer} />
      <ConnectedIgnitionStatus ignitionStatus={ignitionStatus} />

      {lastUpdatedAt && <ConnectedUpdatedAt lastUpdatedAt={lastUpdatedAt} />}
      <SetConnectedVehicle address={address} latitude={latitude} longitude={longitude} vin={vin} />
    </div>
  )
}

export default ConnectedCarTooltip
