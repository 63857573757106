import * as React from 'react'
import { MdLocalPhone, MdLocationOn } from 'react-icons/md'
import { Flex, Heading, Paragraph } from 'components/primitives'
import styled from 'styled-components/macro'
import { BranchInfoFragment } from 'types/global'
import Button from 'components/Button'
import Section from 'components/Section'
import { calypso, gallery } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import HoursTable from './HoursTable'

export type Day = {
  day: string
  hours: string
}

type BranchLocationProps = {
  hours?: Day[]
} & BranchInfoFragment

const StyledFlex = styled(Flex as any)`
  justify-content: flex-start;
  align-items: center;
`

export const BranchLocation: React.FC<BranchLocationProps> = ({
  defaultLocationName,
  formattedPhone,
  addressLines,
  formattedCityStateZip,
  hours,
}) => {
  return (
    <Section title={defaultLocationName}>
      <Section.Body style={{ borderBottom: `1px solid ${gallery}`, padding: `0 0 ${pxToRem(16)}` }}>
        <StyledFlex>
          <Button iconBefore={MdLocalPhone} iconColor={calypso} />
          <Paragraph>{formattedPhone}</Paragraph>
        </StyledFlex>
        <StyledFlex>
          <Button iconBefore={MdLocationOn} iconColor={calypso} />
          <Flex style={{ flexDirection: 'column', flex: 1 }}>
            <Paragraph style={{ whiteSpace: 'pre-line' }}>{addressLines[0]}</Paragraph>
            {addressLines[1] && <Paragraph style={{ whiteSpace: 'pre-line' }}>{addressLines[1]}</Paragraph>}
            <Paragraph style={{ whiteSpace: 'pre-line' }}>{formattedCityStateZip}</Paragraph>
          </Flex>
        </StyledFlex>
      </Section.Body>
      {hours && (
        <Section.Body style={{ padding: `${pxToRem(16)} 0` }}>
          <Flex
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: `0 ${pxToRem(16)} ${pxToRem(8)}`,
            }}
          >
            <Heading as="h6">{'Hours'}</Heading>
          </Flex>
          <HoursTable hours={hours} />
        </Section.Body>
      )}
    </Section>
  )
}

export default BranchLocation
