import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MarkerType } from 'types/googleMaps'
import useLocations from 'hooks/redux/useLocations'
import { LocationTypeToMarkerType } from 'redux/location/locationHelpers'
import ErrorBoundary from 'components/ErrorBoundary'
import { Tooltip } from 'components/Tooltip'
import IncidentLocationMenu from '../../IncidentLocationMenu'
import { GoogleHeroMarker } from '.'
import { MarkerTypeLabels } from '../utils'
import { Address } from '../Tooltips'
import { LocationPayload, LocationType } from 'types/location'

const formatAddress = (address: LocationPayload['address']) => {
  const { streetNumber, street, city, state, postalCode } = address
  const firstLine = [streetNumber, street].filter(Boolean).join(' ')
  const secondLine = [city, city && ', ', state, ' ', postalCode].filter(Boolean).join('')
  return `${firstLine}<br/>${secondLine}`
}

export const IncidentLocationsMarkers: React.FC = () => {
  const { t } = useTranslation()
  const locations = useLocations()
  const [activeMarker, setActiveMarker] = React.useState<string | null>(null)

  const hide = () => {
    setActiveMarker(null)
  }

  const locs = Object.values(locations).filter(Boolean) as LocationPayload[]

  return (
    <ErrorBoundary>
      {locs.map((location) => {
        const markerType = LocationTypeToMarkerType[LocationType[location.serviceLocationType]]
        const lat = location.latitude
        const lon = location.longitude
        const addr = location.address ? formatAddress(location.address) : location.addressDescription
        const lines = addr && addr.split('<br/>')
        lines && lines.push(`(${lat.toFixed(5)}, ${lon.toFixed(5)})`)

        return (
          <GoogleHeroMarker
            key={location.serviceLocationType}
            type={markerType || MarkerType.VEHICLEDESTINATION}
            latitude={lat}
            longitude={lon}
            onClick={() => {
              setActiveMarker(location.serviceLocationType)
            }}
          >
            <Tooltip>{lines && <Address title={t(MarkerTypeLabels[markerType] as string)} lines={lines} />}</Tooltip>

            {activeMarker === location.serviceLocationType && <IncidentLocationMenu location={location} hide={hide} />}
          </GoogleHeroMarker>
        )
      })}
    </ErrorBoundary>
  )
}

export default IncidentLocationsMarkers
