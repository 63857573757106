import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Link } from 'components/primitives'

export const TransferSheetLink = () => {
  const { t } = useTranslation()

  return (
    <Flex style={{ justifyContent: 'flex-start' }}>
      <Link
        target="_blank"
        href="https://enterpriseholdings-my.sharepoint.com/:o:/g/personal/daniel_v_olson_ehi_com/EutyYG9g485IveDekbGkQsEB1YN7zpa-_aXfxhj_c0m08g"
      >
        {t('Transfer Sheet')}
      </Link>
    </Flex>
  )
}

export default TransferSheetLink
