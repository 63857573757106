import * as React from 'react'
import LDRFields from './LDRFields'
import { DynamoEvents } from 'types/events'

export const LDREvent = (event: DynamoEvents) => {
  const { claimNumber, claim } = event?.attributes
  const attributes = {
    claimNumber,
    claim,
  }
  return <LDRFields {...attributes} callHistoryView />
}

export default LDREvent
