import * as Yup from 'yup'
import { isNil, set } from 'lodash-es'

type FormErrors = {
  [k: string]: string
}

function yupToFormErrors({ inner, path, message }: Yup.ValidationError): FormErrors {
  if (!isNil(path) && inner.length === 0) {
    return {
      [path]: message,
    }
  }

  return (
    inner?.reduce((errors: FormErrors, err) => {
      if (err.path && !errors[err.path]) {
        set(errors, err.path, err.message)
      }
      return errors
    }, {}) ?? {}
  )
}

type ValidateWithYupType = (s: Yup.Schema<object>) => (v: object) => Promise<object | undefined>

export const validateWithYup: ValidateWithYupType = (schema) => async (values) => {
  try {
    await schema.validate(values, { abortEarly: false })
    return
  } catch (err) {
    return yupToFormErrors(err as any)
  }
}

// Returns valid if passes any schema
// Is Syncronous for testing
type ValidateOneOfSchemasType = (s: Yup.Schema<object>[]) => (v: object) => object
export const validateOneOfSchemas: ValidateOneOfSchemasType = (schemas) => (values) => {
  const results = schemas.map((schema) => {
    try {
      schema.validateSync(values, { abortEarly: false })
      return undefined
    } catch (err) {
      return yupToFormErrors(err as any)
    }
  })

  return results.filter(Boolean).length < schemas.length
    ? {}
    : Object.assign(
        {},
        results.reduce(
          (obj, item) => ({
            ...obj,
            ...item,
          }),
          {},
        ),
      )
}

// https://gist.github.com/penguinboy/762197#gistcomment-2008512
export const flattenObjectWithDotNotation = (object: any) => {
  return Object.assign(
    {},
    // @ts-ignore
    ...(function _flatten(objectBit, path = '') {
      //spread the result into our return object
      return [].concat(
        //concat everything into one level
        ...Object.keys(objectBit).map(
          //iterate over object
          (key) => {
            const p = path !== '' ? `${path}.` : '' // check if path is empty
            return typeof objectBit[key] === 'object' //check if there is a nested object
              ? _flatten(objectBit[key], `${p}${key}`) //call itself if there is
              : { [`${p}${key}`]: objectBit[key] } //append object with it’s path as key
          },
        ),
      )
    })(object),
  )
}
