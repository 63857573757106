import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import { useServiceProxy } from 'hooks/kong'
import { CaseServiceCall, ULY_CLIENT_CODES } from 'types/urgently'

export function useUlyDispatchRefetch(caseId: string) {
  const serviceProxy = useServiceProxy()
  const { brandId } = useSelector(selectTicket)
  return async () => {
    try {
      await serviceProxy<CaseServiceCall>('get', `/serviceproxy/urgently/${ULY_CLIENT_CODES[brandId]}/cases/${caseId}`, {}, {})
    } catch (e) {
      console.error(e)
    }
  }
}

export default useUlyDispatchRefetch
