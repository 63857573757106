import { useServiceProxy } from 'hooks/kong'
import { StandardServiceCall } from 'types/aaa'

export function useDispatchRefetch(callKey: string) {
  const serviceProxy = useServiceProxy()
  return async () => {
    try {
      await serviceProxy<StandardServiceCall>('get', `/serviceproxy/aaa/dispatch/${callKey}`)
    } catch (e) {
      console.error(e)
    }
  }
}

export default useDispatchRefetch
