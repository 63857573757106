import React from 'react'
import { CallTypes } from 'types/callstore'
import { EventTypes } from 'types/events'
import { useTranslation } from 'react-i18next'
import Input from 'components/Input'
import styled from 'styled-components/macro'
import { Heading, Box } from 'components/primitives'
import { WorkflowAction } from 'components/Workflow'
import { isRequired } from 'utils/validators'
import Margin from 'components/Margin'
import Complete from 'components/Complete'
import ScheduledAt from 'components/ScheduledAt'
import Submit from 'components/fields/Submit'
import useCreateSoftService from 'hooks/aaa/useCreateSoftService'
import { useCreateUlySoftService, useUrgentlyProvider } from 'hooks/urgent.ly'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { BreakdownDetailsForm, BreakdownNote } from '../BreakdownDetails'
import { BreakdownOptions } from '../BreakdownDetails/BreakdownRadio'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const YY = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <WorkflowAction callType={CallTypes.RECOVERY} eventType={EventTypes.RECOVERY} label={t('recovery.dispatch')} required />
      <Box spacing="md" />
      <Heading as="h5" spacing="md">
        {t('winch.exchangeNeeded')}
      </Heading>
      <WorkflowAction callType={CallTypes.EXCHANGE} eventType={EventTypes.EXCHANGE} label={t('optionalVehicleExchange')} />
      <WorkflowAction callType={CallTypes.LDR} eventType={EventTypes.LDR} label={t('ldr.fileLDR')} />
    </Wrapper>
  )
}

export const WinchDispatch = () => {
  const { t } = useTranslation()
  const { isEnterpriseTicket } = useUrgentlyProvider()
  const { winch } = useSelector(selectFormsState) as any

  const createSoftService = useCreateSoftService(EventTypes.WINCH)
  const createUlySoftService = useCreateUlySoftService(EventTypes.WINCH)
  let safeToContinue =
    (winch?.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE && winch?.unsafe === 'no') ||
    (winch?.breakdownOption === BreakdownOptions.PARKINGGARAGE && winch?.garageAccessible === 'yes') ||
    (winch?.breakdownOption === BreakdownOptions.OTHER && winch?.accessible === 'yes')

  return (
    <>
      <BreakdownDetailsForm callType={CallTypes.WINCH} />
      {safeToContinue && (
        <>
          {winch?.serviceLocations?.vehicleLocation && (
            <Margin spacing="md">
              <ScheduledAt callType={CallTypes.WINCH} />
              <Input.Hidden name="scheduledAt" />
            </Margin>
          )}
          <BreakdownNote
            breakdownOption={winch?.breakdownOption}
            unsafe={winch?.unsafe}
            garageAccessible={winch?.garageAccessible}
            accessible={winch?.accessible}
          />
          <Wrapper>
            <Input.Textarea
              name="notes"
              label={`${t('notesToProvider.label')}*`}
              maxLength={240}
              validate={isRequired(t('required.notes'))}
            />
          </Wrapper>
          <Margin spacing="md">
            <Submit
              onSubmit={async (state, caller) => {
                const { secondaryProvider = false, scheduledAt } = state.values
                if (scheduledAt) {
                  return await createUlySoftService(state, caller)
                } else {
                  if (isEnterpriseTicket) {
                    // AAA
                    return secondaryProvider
                      ? await createUlySoftService(state, caller) //Secondary
                      : await createSoftService(state, caller) //Default
                  } else {
                    // Urgently
                    return secondaryProvider
                      ? await createSoftService(state, caller) //Secondary
                      : await createUlySoftService(state, caller) //Default
                  }
                }
              }}
            >
              {t('winch.dispatch')}
            </Submit>
          </Margin>
        </>
      )}
    </>
  )
}

export const NY = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <WorkflowAction callType={CallTypes.RECOVERY} eventType={EventTypes.RECOVERY} label={t('recovery.dispatch')} required />
      <Box spacing="md" />
      <Heading as="h5" spacing="md">
        {t('winch.exchangeNeeded')}
      </Heading>
      <WorkflowAction callType={CallTypes.EXCHANGE} eventType={EventTypes.EXCHANGE} label={t('optionalVehicleExchange')} />
      <WorkflowAction callType={CallTypes.LDR} eventType={EventTypes.LDR} label={t('ldr.fileLDR')} />
    </Wrapper>
  )
}

export const NN = () => {
  const { t } = useTranslation()

  return (
    <>
      <Wrapper>
        <WorkflowAction
          callType={CallTypes.TOW}
          eventType={EventTypes.TOW}
          additionalService={EventTypes.WINCH}
          additionalWorkflow={CallTypes.LDR}
          required
          label={t('tow.dispatch')}
        />
        <WorkflowAction callType={CallTypes.LDR} eventType={EventTypes.LDR} label={t('ldr.fileLDR')} required />
      </Wrapper>
      <Margin spacing="md">
        <Complete eventType={EventTypes.WINCH} />
      </Margin>
    </>
  )
}
