import { IconType } from 'react-icons'
import {
  MdLocalAirport,
  MdLocalAtm,
  MdAttachMoney,
  MdDomain,
  MdLocalShipping,
  MdCasino,
  MdTheaters,
  MdLocationCity,
  MdLocalCafe,
  MdLocalConvenienceStore,
  MdPlace,
  MdSchool,
  MdShoppingCart,
  MdLocalGasStation,
  MdLocalHospital,
  MdLocalHotel,
  MdLocalLibrary,
  MdLocalMall,
  MdLocalBar,
  MdLocalParking,
  MdLocalPharmacy,
  MdLocalPostOffice,
  MdDirectionsRun,
  MdRestaurant,
  MdFitnessCenter,
  MdDirectionsBus,
  MdStoreMallDirectory,
} from 'react-icons/md'

//Order is important! Lower on this list is lower in priority when multiple categories are present
const categories: { [key: string]: IconType } = {
  airport: MdLocalAirport,
  atm: MdLocalAtm,
  bank: MdAttachMoney,
  car_rental: MdStoreMallDirectory,
  moving_company: MdLocalShipping,
  casino: MdCasino,
  shopping_mall: MdLocalMall,
  movie_theater: MdTheaters,
  administrative_area_level_3: MdLocationCity,
  locality: MdLocationCity,
  cafe: MdLocalCafe,
  convenience_store: MdLocalConvenienceStore,
  store: MdShoppingCart,
  default: MdPlace,
  school: MdSchool,
  secondary_school: MdSchool,
  university: MdSchool,
  restaurant: MdRestaurant,
  gas_station: MdLocalGasStation,
  hospital: MdLocalHospital,
  lodging: MdLocalHotel,
  library: MdLocalLibrary,
  night_club: MdLocalBar,
  parking: MdLocalParking,
  pharmacy: MdLocalPharmacy,
  post_office: MdLocalPostOffice,
  recreation: MdDirectionsRun,
  gym: MdFitnessCenter,
  transit_station: MdDirectionsBus,
  establishment: MdDomain,
}

export function getIconByCategory(category: string[]): IconType {
  if (!category) return categories['default']
  const keys = Object.keys(categories)
  const iconName = keys.find(cat => category.includes(cat))
  if (!iconName) return categories['default']

  return categories[iconName]
}
