import * as React from 'react'
import ErrorBoundary from 'components/ErrorBoundary'
import { useGoogleMaps } from 'hooks/map'

const style: React.CSSProperties = {
  position: 'absolute',
  zIndex: 9999,
  display: 'inline-block',
  width: '300px',
  cursor: 'pointer',
}

type Props = {
  containerPoint: google.maps.Point
  children: (anchors: Array<string>) => React.ReactNode
  xOffset: number
  yOffset: number
  topMargin?: number
}

export const ContextMenu: React.FC<Props> = ({
  containerPoint,
  xOffset,
  yOffset,
  topMargin = 0,
  children,
}) => {
  const [boundingRect, setBoundingRect] = React.useState<DOMRect | null>(null)
  const anchors = useAnchors(containerPoint, boundingRect)

  const divRef = React.useCallback(node => {
    if (node !== null) {
      setBoundingRect(node.getBoundingClientRect())
    }
  }, [])

  const position = getAnchorPosition({
    anchors,
    boundingRect,
    containerPoint: containerPoint!,
    xOffset,
    yOffset,
    topMargin,
  })

  return (
    <ErrorBoundary>
      <div ref={divRef} style={{ ...style, ...position }}>
        {children(anchors)}
      </div>
    </ErrorBoundary>
  )
}

type Params = {
  anchors: Array<string>
  boundingRect?: DOMRect | null
  containerPoint: google.maps.Point
  xOffset: number
  yOffset: number
  topMargin: number
}
type PixelPosition = {
  top: number
  left: number
}
const getAnchorPosition = ({
  anchors,
  boundingRect,
  containerPoint: { x, y },
  xOffset,
  yOffset,
  topMargin,
}: Params): PixelPosition => {
  if (boundingRect) {
    const { width, height } = boundingRect

    if (anchors.includes('top')) {
      const top = y - height - topMargin + yOffset
      if (anchors.includes('left')) {
        // top left
        return {
          top,
          left: x - width + xOffset,
        }
      } else {
        // top right
        return {
          top,
          left: x - xOffset,
        }
      }
    } else if (anchors.includes('bottom')) {
      const top = y - yOffset
      if (anchors.includes('left')) {
        // bottom left
        return {
          top,
          left: x - width + xOffset,
        }
      } else {
        // bottom right
        return {
          top,
          left: x - xOffset,
        }
      }
    }
  }

  // default - must be rendered before bounding rect can be calculated
  return {
    top: y - yOffset,
    left: x - xOffset,
  }
}

const useAnchors = (
  containerPoint: google.maps.Point | null,
  domRect: DOMRect | null,
): Array<string> => {
  const { map } = useGoogleMaps()
  const anchors: Array<string> = []
  if (!map || !domRect || !containerPoint) return anchors
  const containerSize = map.getDiv()
  const { x, y } = containerPoint
  const { width, height } = domRect

  if (y + height < containerSize.offsetHeight) {
    anchors.push('bottom')
  } else {
    anchors.push('top')
  }

  if (x + width < containerSize.offsetWidth) {
    anchors.push('right')
  } else {
    anchors.push('left')
  }

  return anchors
}

export default ContextMenu
