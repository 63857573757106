import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'redux/appStore'
import { useLdProvider } from './LaunchDarklyService'

interface FeatureFlagProviderProps {
  children: React.ReactNode
}

const LaunchDarklyProvider = ({ children }: FeatureFlagProviderProps): JSX.Element => {
  const [LDProvider, setLDProvider] = useState<FunctionComponent<{ children: React.ReactNode }> | undefined>(undefined)
  const { getLdProvider } = useLdProvider()
  const currentUser = useSelector(selectCurrentUser)

  useEffect(() => {
    if (currentUser) {
      getLdProvider().then((provider: FunctionComponent<{ children: React.ReactNode }>) => setLDProvider(() => provider))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return LDProvider ? <LDProvider>{children}</LDProvider> : <>{children}</>
}

export default LaunchDarklyProvider
