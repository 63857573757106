import { Navigate, Routes, Route } from 'react-router-dom'
import * as React from 'react'
import AppRoutes from './routes'
import Timeout from './components/UserSession/Timeout'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import ErrorBoundary from 'components/ErrorBoundary'
import { TimerStateManager } from 'components/Timer'
import MainLayout from 'layouts/MainLayout'
import TicketSearch from 'pages/TicketSearch'
import Ticket from 'pages/Ticket'
import PendoHandler from './components/Pendo/PendoHandler'

type PreviousStateType = {
  wasPreviousSearch: boolean
  sortedTickets: any[]
}

export const previousInitialState = {
  wasPreviousSearch: false,
  sortedTickets: [],
}

const Login = React.lazy(() => import('./pages/Login'))
const VersionRoute = React.lazy(() => import('./routes/VersionRoute'))

function AuthenticatedView() {
  const [previousSearchObject, setPreviousSearchObject] = React.useState<PreviousStateType>(previousInitialState)
  return (
    <Routes>
      <Route path="/ticket">
        <Route
          index
          element={<TicketSearch previousSearchObject={previousSearchObject} setPreviousSearchObject={setPreviousSearchObject} />}
        />
        <Route path=":ticketNumber/*" element={<Ticket />} />
      </Route>
      <Route path="/version" element={<VersionRoute />} />

      <Route path="*" element={<Navigate to="/ticket" />} />
    </Routes>
  )
}

function UnAuthenticatedView() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Navigate to={AppRoutes.LOGIN} />} />
    </Routes>
  )
}

export const App: React.FC = () => (
  <React.Suspense fallback={null}>
    <Timeout />
    <AuthenticatedTemplate>
      <ErrorBoundary>
        <MainLayout>
          <TimerStateManager />
          <AuthenticatedView />
          <PendoHandler />
        </MainLayout>
      </ErrorBoundary>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <UnAuthenticatedView />
    </UnauthenticatedTemplate>
  </React.Suspense>
)

export default App
