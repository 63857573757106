import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AaaCenter } from 'types/aaa'
import { Label, Paragraph } from 'components/primitives'

type Props = {
  center?: AaaCenter | null
  rap?: string
}
export function DispatchCenter({ center, rap }: Props) {
  const { t } = useTranslation()

  if (!center) return null
  const { name, contact } = center
  return (
    <>
      <Label>{t('labels.dispatchCenter')}</Label>
      <Paragraph spacing="sm">{name}</Paragraph>
      <Label>{t('labels.RAPNumber')}</Label>
      <Paragraph spacing="sm">{rap}</Paragraph>
      {contact && contact.phones && contact.phones.length > 0 && (
        <>
          <Label>{t('labels.dispatchContact')}</Label>
          <Paragraph spacing="sm">{contact.phones[0].phoneNumber}</Paragraph>
        </>
      )}
    </>
  )
}

export default DispatchCenter
