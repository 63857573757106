import { MarkerType } from 'types/googleMaps'

export const MarkerSize: { [key: string]: [number, number] } = {
  SMALL: [16, 38.5],
  LARGE: [22, 50.5],
}

export const RoundMarkerSize: { [key: string]: [number, number] } = {
  SMALL: [16, 19.25],
  LARGE: [22, 25.25],
}

export const MarkerTypeLabels: { [key: string]: String } = {
  // Values are translated where used
  [MarkerType.RENTALRETURN]: 'tooltip.rental',
  [MarkerType.RENTAL]: 'tooltip.rental',
  [MarkerType.RETURN]: 'tooltip.return',
  [MarkerType.CALLER]: 'tooltip.caller',
  [MarkerType.CONNECTEDCAR]: 'tooltip.connectedCar',
  [MarkerType.VEHICLE]: 'tooltip.vehicle',
  [MarkerType.VEHICLEDESTINATION]: 'tooltip.vehicleDestination',
  [MarkerType.CALLERDESTINATION]: 'tooltip.callerDestination',
  [MarkerType.PLACE]: 'tooltip.place',
  [MarkerType.EXCHANGE]: 'tooltip.exchange',
}
