import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReduxState } from 'redux/appStore'
import { getInitialTabState } from './tabsHelper'
import { TabsState } from 'types/global'

const initialState: TabsState = {
  tabs: getInitialTabState(),
}

const tabsSlice = createSlice({
  name: 'Tab',
  initialState,
  reducers: {
    updateTabsState: (state: TabsState, action: PayloadAction<TabsState>) => {
      if (action.payload.tabs.length === state.tabs.length) return action.payload
    },
    resetTabState: (state: TabsState) => {
      state = initialState
      return state
    },
  },
})

export const selectActiveTabs = (state: ReduxState) => state.tabsSlice.tabs.filter((tab) => tab.enabled)

export const { updateTabsState, resetTabState } = tabsSlice.actions

export default tabsSlice.reducer
