import styled from 'styled-components/macro'
import { theme } from 'styled-tools'
import { palette } from 'styled-tools'

export const ListItem = styled.div`
  width: 100%;
  background: white;
  padding: ${theme('spaces.1')};
  cursor: pointer;
  &:not(first-child) {
    border-top: 1px solid ${palette('gallery')};
  }
`
