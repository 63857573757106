import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading } from 'components/primitives'
import useTimer, { millisecondsToTime } from 'hooks/utils/useTimer'
import useTimerState from 'hooks/useTimerState'

export const CallerFormTimer: React.FC = () => {
  const { t } = useTranslation()
  const { createdAt } = useTimerState()
  const startTime: number = createdAt ? Date.now() - new Date(createdAt).getTime() : 0
  const { time } = useTimer(startTime)
  const timeElapsed = millisecondsToTime(time)

  return (
    <Heading as="h5">
      {t('New Call')} ({timeElapsed})
    </Heading>
  )
}
