import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { Flex } from 'components/primitives'

const TableRow = styled(Flex as any)`
  justify-content: space-between;
  padding: 2px 16px;

  &:nth-of-type(odd) {
    background-color: ${palette('gallery')};
  }
`
export const TableRowWeekdays = styled(Flex as any)`
  justify-content: space-between;
  padding: 2px 16px;

  &:nth-of-type(even) {
    background-color: ${palette('gallery')};
  }

  &:nth-of-type(1) {
    color: ${palette('elm')};
    font-weight: bold;
  }
`

export default TableRow
