import { CancelReasonCode } from 'types/aaa'
import { useServiceProxy } from 'hooks/kong'

type Props = {
  cancelReasonCode: CancelReasonCode
  cancellationText: string
  callKey: string
}

export const useCancelDispatch = () => {
  const serviceProxy = useServiceProxy()
  async function cancelDispatch({ cancelReasonCode, cancellationText, callKey }: Props) {
    //Perform fetch
    const resp = await serviceProxy(
      'DELETE',
      `/serviceproxy/aaa/dispatch/${callKey}`,
      {
        'Content-Type': 'application/json',
      },
      { cancelReasonCode, cancellationText },
    )
    return resp
  }

  return cancelDispatch
}

export default useCancelDispatch
