import { useCreateUpdateLocation } from 'hooks/redux/useCreateUpdateLocation'
import { useQueryParams } from 'components/LocationProvider'
import * as React from 'react'
import { MdDirectionsCar, MdPerson } from 'react-icons/md'
import { calypso, black } from 'theme/colors'
import { useGoogleMaps } from 'hooks/map'
import { Marker, MarkerType } from 'types/googleMaps'
import { DEFAULT_VIEWPORT } from './utils'

export const GoogleMapsDisplay = (props) => {
  const { map, setMap } = useGoogleMaps()

  const {
    query: { lat, lng, z },
  } = useQueryParams()
  const createUpdateLocation = useCreateUpdateLocation()
  let [placeMarkers, setPlaceMarkers] = React.useState<google.maps.Marker[]>([])

  const mapRef = React.useCallback(
    (node) => {
      const defaultOptions: google.maps.MapOptions = { disableDefaultUI: true }
      if (node !== null && !map) {
        const initialViewport = {
          center:
            lat && lng ? new google.maps.LatLng(parseFloat(lat as string), parseFloat(lng as string)) : DEFAULT_VIEWPORT.center,
          zoom: z ? parseInt(z as string) : DEFAULT_VIEWPORT.zoom,
        }
        const map = new google.maps.Map(node, { ...initialViewport, ...defaultOptions })

        if (map) {
          map.addListener('click', handleClick)
          setMap(map)
        }

        function isIconMouseEvent(e: any) {
          return 'placeId' in e
        }

        function removeMarker(markers: google.maps.Marker[]) {
          markers[0].setMap(null)
          placeMarkers.pop()
          setPlaceMarkers(placeMarkers)
        }

        function createSvgIcon(marker: Marker, markerType: MarkerType, svgString: string) {
          const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
          svg.style.width = '25px'
          svg.style.height = '25px'
          svg.style.position = 'relative'
          svg.style.cursor = 'pointer'
          svg.onmouseover = () => {
            svg.style.fill = calypso
          }
          svg.onmouseleave = () => {
            svg.style.fill = black
          }
          svg.onclick = () => {
            marker.type = markerType
            createUpdateLocation(marker)
          }
          const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
          path.setAttribute('d', svgString)
          svg.appendChild(path)

          return svg
        }

        function handleClick(event: any) {
          if (isIconMouseEvent(event)) {
            event.stop()
            if (event.placeId) {
              if (placeMarkers.length) {
                removeMarker(placeMarkers)
              }
              const request = {
                placeId: event.placeId,
                fields: ['name', 'place_id', 'geometry', 'formatted_address', 'vicinity'],
              }
              const infoWindow = new google.maps.InfoWindow()
              let service = new google.maps.places.PlacesService(map)
              service.getDetails(request, (place, status) => {
                if (
                  status === google.maps.places.PlacesServiceStatus.OK &&
                  place &&
                  place.geometry &&
                  place.geometry.location &&
                  place.name &&
                  place.vicinity
                ) {
                  const defaultPlaceMarker = new google.maps.Marker({
                    map,
                    position: place.geometry.location,
                  })
                  placeMarkers.push(defaultPlaceMarker)
                  setPlaceMarkers(placeMarkers)

                  const content = document.createElement('div')

                  const nameElement = document.createElement('h2')
                  nameElement.textContent = place.name!
                  content.appendChild(nameElement)

                  const placeAddressElement = document.createElement('p')
                  placeAddressElement.textContent = place.formatted_address!
                  placeAddressElement.style.fontSize = '14px'
                  content.appendChild(placeAddressElement)

                  const coords = document.createElement('p')
                  coords.textContent =
                    'Coordinates: ' + place.geometry.location.lat().toFixed(5) + ', ' + place.geometry.location.lng().toFixed(5)
                  coords.style.fontSize = '14px'
                  content.appendChild(coords)

                  const marker: Marker = {
                    type: MarkerType.VEHICLE,
                    address: place.formatted_address!,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                    href: place.place_id,
                    place_id: place.place_id,
                  }

                  // @ts-ignore
                  const personPathString = MdPerson().props.children[1].props.d
                  // @ts-ignore
                  const vehiclePathString = MdDirectionsCar().props.children[1].props.d

                  const pinDiv = document.createElement('div')
                  pinDiv.style.display = 'flex'
                  pinDiv.style.alignItems = 'center'
                  pinDiv.textContent = 'Set Marker as:'
                  pinDiv.style.fontSize = '14px'

                  const personSvg = createSvgIcon(marker, MarkerType.CALLER, personPathString)
                  pinDiv.appendChild(personSvg)

                  const divider = document.createElement('div')
                  divider.setAttribute('width', '15px')
                  divider.style.width = '10px'
                  divider.textContent = '|'
                  divider.style.fontSize = '21px'
                  divider.style.fontWeight = 'bold'
                  divider.style.textAlign = 'center'
                  pinDiv.appendChild(divider)

                  const vehicleSvg = createSvgIcon(marker, MarkerType.VEHICLE, vehiclePathString)
                  pinDiv.appendChild(vehicleSvg)
                  content.appendChild(pinDiv)

                  infoWindow.setContent(content)
                  infoWindow.open(map, defaultPlaceMarker)
                  infoWindow.addListener('closeclick', () => {
                    removeMarker(placeMarkers)
                  })
                }
              })
            }
          }
        }
      }
    },
    [lat, lng, map, z, createUpdateLocation, placeMarkers, setMap],
  )

  return (
    <>
      <div id="googleMap" ref={mapRef} style={{ display: 'flex', flex: 1 }} />
      {/* Children are rendered outside of map div so that map events (clicks)
      don't interfere with events from other components (context menu) */}
      {map && props.children}
    </>
  )
}
