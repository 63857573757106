import { useTranslation } from 'react-i18next'
import { FormState } from 'final-form'
import { EventTypes } from 'types/events'
import { SoftService } from 'types/callstore'
import { FormattedCaller } from 'components/fields/Submit'
import useCreateServiceEvent from 'hooks/events/useCreateServiceEvent'
import useUpdateTicketCharges from 'hooks/tickets/useUpdateTicketCharges'
import { pruneCaseData } from 'hooks/urgent.ly/useCreateUlyTowService'
import useULYStandardServices from './useULYStandardservices'
import { useServiceProxy } from 'hooks/kong'
import { CaseServiceCall } from 'types/urgently'
import { useDispatch } from 'react-redux'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

export const useCreateULYSoftService = (eventType: EventTypes) => {
  const { t } = useTranslation()
  const createServiceRequest = useULYStandardServices([eventType])
  const createServiceEvent = useCreateServiceEvent<SoftService & { provider: string; customerOptIn: boolean }>({
    eventType,
  })
  const updateTicketCharges = useUpdateTicketCharges()
  const serviceProxy = useServiceProxy()
  const dispatch = useDispatch()

  return async function (state: FormState<SoftService>, caller: FormattedCaller) {
    try {
      const service = state.values
      const caseRequest = createServiceRequest(service, caller)

      // Create ULY Dispatch
      const { data: dispatchData } = await serviceProxy<CaseServiceCall>(
        'post',
        '/serviceproxy/urgently/cases',
        {},
        caseRequest,
      )

      // Create Ronin Event
      if (dispatchData && dispatchData.case) {
        // Ticket charges
        await updateTicketCharges(eventType)

        const dispatchCase = pruneCaseData(dispatchData)

        if (dispatchCase) {
          const event = await createServiceEvent({
            ...service,
            serviceCase: dispatchCase,
            provider: 'ULY',
            customerOptIn: caller.optIn || Boolean(dispatchData.contactInfo.textOptIn) || false,
          })
          dispatch(setCurrentEvent({ eventType, newEvent: event.data }))
        }
      }
    } catch (e) {
      console.error(e)
      return t('error.uly')
    }
  }
}

export default useCreateULYSoftService
