import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialSmartAssignmentState } from './smartAssignmentHelpers'
import { SmartAssignment, SmartAssignmentState } from 'types/entegral'
import { Participant } from 'types/global'
import { LocationPayload } from 'types/location'

const initialState: SmartAssignmentState = {
  ...initialSmartAssignmentState(),
}

const smartAssignmentSlice = createSlice({
  name: 'SmartAssignment',
  initialState,
  reducers: {
    setSmartAssignmentAssignment: (state: SmartAssignmentState, action: PayloadAction<SmartAssignment | null>) => {
      state.assignment = action.payload
    },
    setSmartAssignmentParticipant: (state: SmartAssignmentState, action: PayloadAction<Participant | null>) => {
      state.participant = action.payload
    },
    setSmartAssignmentCustomDropoffDestination: (
      state: SmartAssignmentState,
      action: PayloadAction<LocationPayload | null>,
    ) => {
      state.customDropoffDestination = action.payload
    },
    resetSmartAssignmentState: (state: SmartAssignmentState) => {
      state = initialState
      return state
    },
  },
})

export const {
  setSmartAssignmentAssignment,
  setSmartAssignmentParticipant,
  setSmartAssignmentCustomDropoffDestination,
  resetSmartAssignmentState,
} = smartAssignmentSlice.actions

export default smartAssignmentSlice.reducer
