import useLocalStorage from 'react-use-localstorage'

type TimerState = {
  createdAt?: string
  ticketNumber?: string
}

export type TimerStateFn = TimerState & {
  set: (state: TimerState) => void
  reset: () => void
}

const initialState = JSON.stringify({})

const parseItem = (item: string): TimerState | string => {
  try {
    return JSON.parse(item) as TimerState
  } catch (e) {
    return initialState
  }
}

export const useTimerState = (): TimerStateFn => {
  const [item, setItem] = useLocalStorage('TIMER', initialState)
  const timerState = parseItem(item)
  const setTimerState = (value: TimerState) => setItem(JSON.stringify(value))
  const resetTimerState = () => setItem(initialState)

  return Object.assign(timerState, {
    set: setTimerState,
    reset: resetTimerState,
  })
}

export default useTimerState
