import SmartAssignment from './SmartAssignment'
import Create from './Create'
import Results from './Results'
import Participant from './Participant'

export default Object.assign(SmartAssignment, {
  Results,
  Create,
  Participant,
})
