import * as React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { EventTypes } from 'types/events'
import Button from 'components/Button'
import { CallTypes, TowForm, Exchange } from 'types/callstore'
import Input from 'components/Input'
import { useCallEvent, useReduxCallStoreActions } from 'hooks/events'
import { isRequired } from 'utils/validators'
import CallTypeIcon from 'forms/CallForm/CallTypeSelection/CallTypeIcon'
import { pxToRem } from 'theme/utils'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

const Span = styled.span`
  text-transform: none;
  font-size: ${pxToRem(14)};
  font-weight: 400;
`

type Props = {
  callType: CallTypes
  additionalWorkflow?: CallTypes
  eventType: EventTypes
  label: string
  disabled?: boolean
  onClick?: () => void
  required?: boolean
  hasError?: boolean
  additionalService?: EventTypes
  towFormValues?: TowForm
  exchangeValues?: Partial<Exchange>
  nonMultiFlow?: boolean
}

export const WorkflowAction = ({
  eventType,
  callType,
  additionalWorkflow,
  required,
  onClick,
  label,
  additionalService,
  towFormValues,
  exchangeValues,
  nonMultiFlow = false,
  ...props
}: Props) => {
  const { t } = useTranslation()
  const event = useCallEvent(eventType)
  const { update } = useReduxCallStoreActions()
  const { workflow, towForm, exchange } = useSelector(selectFormsState) as any
  const formManager = useSelector(selectFormsState)
  const openCallTypeForm = React.useCallback(() => {
    const state = formManager.CallForm.getState()
    const current = state.values.callType

    update({
      workflow: nonMultiFlow
        ? [callType]
        : [...new Set([...(workflow || []), current, callType, additionalWorkflow].filter(Boolean))],
      additionalService,
      towForm: {
        ...towForm,
        ...towFormValues,
      },
      // @ts-ignore
      exchange: {
        ...exchange,
        ...exchangeValues,
      },
    })
    formManager.CallForm.change('callType', callType)
  }, [
    formManager.CallForm,
    update,
    workflow,
    callType,
    additionalWorkflow,
    additionalService,
    towForm,
    towFormValues,
    exchange,
    exchangeValues,
    nonMultiFlow,
  ])
  const callTypeIcon = React.useCallback(
    () => <CallTypeIcon callType={callType} gutter="sm" completed={!!event} />,
    [callType, event],
  )

  return (
    <Field name={eventType} validate={required ? isRequired() : undefined}>
      {({ input, meta: { touched, error } }) => {
        const hasError = Boolean(touched && error)
        return (
          <>
            <Button.Workflow
              iconBefore={callTypeIcon}
              onClick={onClick || openCallTypeForm}
              hasError={hasError}
              {...props}
            >
              {label}
              {!required && <Span> ({t('labels.optional')})</Span>}
            </Button.Workflow>
            <Input.Hidden {...input} defaultValue={event ? (true as any) : undefined} />
          </>
        )
      }}
    </Field>
  )
}

export default WorkflowAction
