import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { EventTypes } from 'types/events'
import { CallTypes } from 'types/callstore'
import { Heading } from 'components/primitives'
import { Condition } from 'components/fields'
import Input, { YesNoRadio, YesNoTriggers } from 'components/Input'
import Margin from 'components/Margin'
import BreakdownRadio, { BreakdownOptions } from './BreakdownRadio'
import { WorkflowAction } from '../../../components/Workflow'
import Complete from 'components/Complete'
import { useForm } from 'react-final-form'
import { CallTypeToEventType } from 'utils/callstore'

type Props = {
  callType: CallTypes
}

const BreakdownDetailsForm: React.FC<Props> = ({ callType }) => {
  const { t } = useTranslation()
  const isTowCallType = callType === 'Accident' || callType === 'Tow' || callType === 'Winch'
  const eventType = CallTypeToEventType[callType]
  const form = useForm()

  // Reset the form on calltype change
  React.useEffect(() => {
    form.change('breakdownOption', undefined)
    form.change('unsafe', undefined)

    form.change('garageAccessible', YesNoTriggers.YES)

    form.change('accessible', YesNoTriggers.YES)
  }, [form, eventType])

  return (
    <>
      <BreakdownRadio name="breakdownOption" />
      <Condition when="breakdownOption" is={(v) => v === BreakdownOptions.ROADWAYINTERSTATE}>
        <Input.Hidden name="breakdownOption" value={BreakdownOptions.ROADWAYINTERSTATE} />
        <YesNoRadio name="unsafe" label={t('Is the vehicle blocking traffic or unsafe for provider to perform service?')} />
        <Condition when="unsafe" is={(v) => v === YesNoTriggers.YES}>
          <Heading as="h5" spacing="md">
            {t('Advise the customer to hang up and dial 911')}
          </Heading>
          <Margin spacing="md">
            <WorkflowAction
              callType={CallTypes.RECOVERY}
              eventType={EventTypes.RECOVERY}
              label="Dispatch Vehicle Recovery"
              required
              nonMultiFlow
            />
          </Margin>
          <Complete eventType={eventType} />
        </Condition>
      </Condition>

      <Condition when="breakdownOption" is={(v) => v === BreakdownOptions.PARKINGGARAGE}>
        <Input.Hidden name="breakdownOption" value={BreakdownOptions.PARKINGGARAGE} />
        {isTowCallType ? (
          <YesNoRadio name="garageAccessible" label={t('Is the garage clearance over 7 feet and the vehicle accessible?')} />
        ) : (
          <Input.Hidden name="garageAccessible" defaultValue={YesNoTriggers.YES as any} />
        )}
        <Condition when="garageAccessible" is={(v) => v === YesNoTriggers.NO}>
          <Heading as="h5" spacing="md">
            {t('We are unable to tow, please send a recovery.')}
          </Heading>
          <Margin spacing="md">
            <WorkflowAction
              callType={CallTypes.RECOVERY}
              eventType={EventTypes.RECOVERY}
              label="Dispatch Vehicle Recovery"
              required
              nonMultiFlow
            />
          </Margin>
          <Complete eventType={eventType} />
        </Condition>
      </Condition>

      <Condition when="breakdownOption" is={(v) => v === BreakdownOptions.OTHER}>
        <Input.Hidden name="breakdownOption" value={BreakdownOptions.OTHER} />
        {isTowCallType ? (
          <YesNoRadio name="accessible" label={t('Is the vehicle accessible?')} />
        ) : (
          <Input.Hidden name="accessible" defaultValue={YesNoTriggers.YES as any} />
        )}
        <Condition when="accessible" is={(v) => v === YesNoTriggers.NO}>
          <Heading as="h5" spacing="md">
            {t('We are unable to tow, please send a recovery.')}
          </Heading>
          <Margin spacing="md">
            <WorkflowAction
              callType={CallTypes.RECOVERY}
              eventType={EventTypes.RECOVERY}
              label="Dispatch Vehicle Recovery"
              nonMultiFlow
              required
            />
          </Margin>
          <Complete eventType={eventType} />
        </Condition>
      </Condition>
    </>
  )
}

export default BreakdownDetailsForm
