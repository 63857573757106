import { FormApi } from 'final-form'
import * as React from 'react'
import * as Yup from 'yup'
import { EventTypes } from 'types/events'
import { TowExchange, TowType, CallTypes } from 'types/callstore'
import { Form } from 'components/FormManager'
import { useCallEvent, useReduxCallStoreActions } from 'hooks/events'
import Margin from 'components/Margin'
import { BackNavigation } from 'components/Step'
import ExchangeEvent from 'forms/CallForm/ExchangeForm/ExchangeEvent'
import TowExchangeInfo from './TowExchangeInfo'
import Divider from 'components/Divider'
import TowExchangeSteps from './TowExchangeSteps'
import { NetworkNames } from 'components/Select/NetworkSelect'
import { AssignmentNetworks } from 'types/global'
import { BreakdownDetailsForm } from 'forms/CallForm/BreakdownDetails'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

declare module 'types/form' {
  export interface Forms {
    [TowType.EXCHANGE]: FormApi
  }
}

const TowExchangeForm: React.FC = () => {
  const { update } = useReduxCallStoreActions()
  const { towExchange, workflow } = useSelector(selectFormsState) as any
  const towExchangeEvent = useCallEvent(EventTypes.TOW)
  const forms = useSelector(selectFormsState)

  const accidentTowInitialValues =
    workflow?.length && workflow[0] === CallTypes.ACCIDENT
      ? ({
          network: {
            label: NetworkNames[AssignmentNetworks.BODY],
            value: AssignmentNetworks.BODY,
          },
          ...towExchange,
        } as TowExchange)
      : null

  if (towExchangeEvent && towExchangeEvent.event)
    return (
      <>
        <BackNavigation onClick={() => forms.Tow.change('towType', null)} />
        <ExchangeEvent {...towExchangeEvent.event} />
      </>
    )

  return (
    <Form
      name={TowType.EXCHANGE}
      schema={exchangeSchema}
      initialValues={{ ...accidentTowInitialValues, exchange: true } as TowExchange}
      autoSaveSync={(towExchange) => update({ towExchange })}
    >
      <BreakdownDetailsForm callType={CallTypes.TOW} />
      <TowExchangeInfo />
      <Margin use={Divider} spacing="md" />
      <TowExchangeSteps />
    </Form>
  )
}

const exchangeSchema = Yup.object({})

export default TowExchangeForm
