import styled from 'styled-components/macro'
import { Flex } from 'components/primitives'

export const Column = styled(Flex as any)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default Column
