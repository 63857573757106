import * as React from 'react'
import { pxToRem } from 'theme/utils'
import { elm } from 'theme/colors'
import IconBase from './IconBase'

const ReturnIconMarker = () => (
  <IconBase
    width={pxToRem(24)}
    height={pxToRem(24)}
    fill={elm}
    style={{ marginRight: 8 }}
    path="M76 36 60 36 60 12 36 12 36 36 20 36 48 64 76 36ZM20 72 20 80 76 80 76 72 20 72Z"
  />
)

export default ReturnIconMarker
