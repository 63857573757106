import colors, { Colors } from './colors'

export type Palette = { [K in Colors]: string }

export default {
  ...colors,
  shadow: [
    'rgba(0, 0, 0, 0.9)',
    'rgba(0, 0, 0, 0.7)',
    'rgba(0, 0, 0, 0.5)',
    'rgba(0, 0, 0, 0.3)',
    'rgba(0, 0, 0, 0.15)',
    'rgba(0, 0, 0, 0.075)',
  ],
} as Palette
