import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Label, Paragraph } from 'components/primitives'
import SoftServiceEvent from '../SoftServiceEvent'
import { DynamoEvents } from 'types/events'
import { createCancelDialogContext } from 'components/Dispatch/Cancel/useCancelServiceDialog'

export const cancelDialogContext = createCancelDialogContext()

function WinchEvent(event: DynamoEvents) {
  const { t } = useTranslation()
  const { recovery, exchange, ldr, tow } = event?.attributes

  // For workflow actions that aren't winch service
  // TODO: refactor these into SoftServiceEvent
  const hasDispatched = recovery || exchange || ldr || tow

  return (
    <cancelDialogContext.Provider>
      <SoftServiceEvent title={t('winch.heading')} {...event}>
        {hasDispatched && (
          <>
            <Label>{t('labels.dispatchServices')}</Label>
            <Paragraph spacing="sm">
              {exchange && t('labels.exchange')}
              {recovery && (
                <>
                  <br />
                  {t('labels.recovery')}
                </>
              )}
              {tow && (
                <>
                  <br />
                  {t('labels.tow')}
                </>
              )}
              {ldr && (
                <>
                  <br />
                  {t('labels.ldr')}
                </>
              )}
            </Paragraph>
          </>
        )}
      </SoftServiceEvent>
    </cancelDialogContext.Provider>
  )
}

export default WinchEvent
