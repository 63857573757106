import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Label, Paragraph } from 'components/primitives'
import { LocationAddress } from 'components/IncidentLocation'
import Margin from 'components/Margin'
import { DynamoEvents } from '../../../types/events'

const ParagraphWithLabel: React.FC<{ label: string }> = ({ label, children }) => (
  <>
    <Label>{label}</Label>
    <Paragraph spacing="sm">{children}</Paragraph>
  </>
)

export function RecoveryEvent(event: DynamoEvents) {
  const attributes = event?.attributes
  const locationBusiness = attributes?.locationBusiness
  const vehicleLocation = attributes?.vehicleLocation
  const recoveryGroup = attributes?.recoveryGroup
  const keysWhere = attributes?.keysWhere
  const reason = attributes?.reason
  const description = attributes?.description
  const vehicle = attributes?.vehicle
  const policeReportNumber = attributes?.policeReportNumber
  const destinationLocation = attributes?.destinationLocation
  const destinationName = attributes?.destinationName
  const isDestinationDifferent = attributes?.isDestinationDifferent
  const { t } = useTranslation()

  return (
    <>
      <Heading as="h6" spacing="md">
        {t('recovery.heading')}
      </Heading>
      <ParagraphWithLabel label={t('recovery.vehicle')}>{vehicle?.label}</ParagraphWithLabel>
      <Margin spacing="sm">
        <Label>{t('labels.vehicleLocation')}</Label>
        {locationBusiness}
        <LocationAddress location={vehicleLocation} />
      </Margin>
      {isDestinationDifferent && (
        <Margin spacing="sm">
          <Label>{t('recovery.destination')}</Label>
          {destinationName}
          <LocationAddress location={destinationLocation} />
        </Margin>
      )}
      <ParagraphWithLabel label={t('recovery.group')}>{recoveryGroup}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('recovery.keysWhere')}>{keysWhere}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('recovery.reason')}>{reason}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('recovery.description')}>{description}</ParagraphWithLabel>
      {policeReportNumber && <ParagraphWithLabel label={t('recovery.policeReportNumber')}>{policeReportNumber}</ParagraphWithLabel>}
    </>
  )
}

export default RecoveryEvent
