import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdChevronRight, MdExpandMore } from 'react-icons/md'
import { isArray } from 'lodash-es'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { Flex, Label } from 'components/primitives'
import CallTypeIcon from 'forms/CallForm/CallTypeSelection/CallTypeIcon'
import { CallTypes } from 'types/callstore'
import { AaaStatusCodeLabels } from 'components/Dispatch/DispatchStatus'
import Divider from 'components/Divider'
import { callEvents } from 'pages/Ticket/routes/Default/Details/CallHistory/CallEvent'
import { useAAADispatchQuery } from 'hooks/aaa/useAAADispatch'
import { monza } from 'theme/colors'
import useDispatchEta from 'components/Dispatch/useDispatchEta'
import { AaaStatus, StandardServiceCall } from 'types/aaa'
import { DynamoEvents } from 'types/events'
import { useFlags } from 'launchdarkly-react-client-sdk'

const GridWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .grid {
    display: grid;
    grid-template-columns: 50px auto 20px;
    grid-gap: 8px;
  }
`

export const RetryLink = styled(Label as any)`
  cursor: pointer;
  color: ${palette('calypso')};
`

enum otherCallTypes {
  LOCKOUT = 'Keys',
  TIRESCHANGESPARE = 'Tires',
}

export const UnionCallTypes = { ...CallTypes, ...otherCallTypes }

export function formatDispatchTimestamp(timestamp: any) {
  let date = new Date(timestamp)
  let time = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  })
  if (time.toString().charAt(0) === '0') {
    time = time.toString().substr(1)
  }

  return (
    'Transmitted: (' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '/' +
    ('0' + date.getDate()).slice(-2) +
    '/' +
    date.getFullYear() +
    ' - ' +
    time +
    ')'
  )
}

export const DispatchAAAEventProxy: React.FC<{ event: DynamoEvents }> = ({ event }) => {
  const { eventType, attributes } = event
  const Component = callEvents[eventType as keyof typeof callEvents]
  const dispatches = (isArray(attributes?.dispatch) ? attributes?.dispatch : [attributes?.dispatch]) as StandardServiceCall[]

  return (
    <>
      {dispatches.map((dispatch, i) => (
        <DispatchEventDetails
          key={i}
          dispatch={dispatch}
          eventType={eventType}
          timestamp={formatDispatchTimestamp(event.audit?.[0]?.createTimestamp)}
        >
          {Component && <Component {...(event as DynamoEvents)} />}
        </DispatchEventDetails>
      ))}
    </>
  )
}

type DetailProps = {
  dispatch: StandardServiceCall
  eventType?: string | null
  timestamp?: string
}
export const DispatchEventDetails: React.FC<DetailProps> = ({ dispatch, eventType, timestamp, children }) => {
  const { t } = useTranslation()
  const { roadsideIsAaaHookActive } = useFlags()
  const [edit, setEdit] = React.useState<boolean>(false)
  const callEventType = UnionCallTypes[eventType?.toUpperCase() as keyof typeof CallTypes]
  const { callKey, service } = dispatch || {}
  const RAP = callKey?.split('-').pop() || t('N/A')
  const { data, loading, error, refetch } = useAAADispatchQuery(callKey)
  const [status, setStatus] = React.useState<AaaStatus | null>(service?.status || null)
  const { dispatchEta } = useDispatchEta(dispatch.service, roadsideIsAaaHookActive)

  const isUnattended = data?.vehicle.unattended ? ' - Unattended' : ''

  React.useEffect(() => {
    if (data?.service?.status) {
      if (roadsideIsAaaHookActive) {
        setStatus(service?.status)
      } else {
        setStatus(data.service.status)
      }
    }
  }, [data, service, roadsideIsAaaHookActive])

  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={() => setEdit(!edit)}>
        <GridWrapper>
          <div className="grid">
            <div>{eventType && <CallTypeIcon callType={callEventType} gutter="sm" />}</div>
            <Flex style={{ flexDirection: 'column' }}>
              <Flex>
                <Label style={{ fontWeight: 'bold', cursor: 'pointer', marginRight: 20, width: '100%' }}>
                  {`# ${RAP} ${callEventType}` + isUnattended}
                </Label>
              </Flex>
              <Flex>
                <Label style={{ alignSelf: 'center' }}>{`${t('labels.eta')} : ${dispatchEta ? dispatchEta : t('N/A')}`}</Label>
              </Flex>
              {loading && <Label style={{ fontStyle: 'italic' }}>{t('Loading')}</Label>}
              {!loading && !error && status?.status && (
                <Label style={{ whiteSpace: 'nowrap' }}> {t(AaaStatusCodeLabels[status.status])} </Label>
              )}
              {!loading && !error && (
                <Flex>
                  <Label style={{ whiteSpace: 'nowrap' }}> {timestamp} </Label>
                </Flex>
              )}
              {!loading && error && (
                <>
                  <Label style={{ color: monza }}>{t('Unable to retrieve status from AAA')}</Label>
                  <RetryLink
                    onClick={async (event) => {
                      const shouldFireQuery = true
                      event.preventDefault()
                      event.stopPropagation()
                      await refetch(shouldFireQuery)
                    }}
                  >
                    {t('Retry')}
                  </RetryLink>
                </>
              )}
            </Flex>
            <div>{edit ? <MdExpandMore /> : <MdChevronRight />}</div>
          </div>
        </GridWrapper>
      </div>
      {edit && (
        <>
          <Divider />
          {children}
        </>
      )}
    </>
  )
}

export default DispatchAAAEventProxy
