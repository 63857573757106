import * as React from 'react'
import { MdAssignment, MdCallEnd, MdDone, MdImportExport, MdBrightness1, MdAdd } from 'react-icons/md'
import styled from 'styled-components/macro'
import { Flex } from 'components/primitives'
import { CallTypes } from 'types/callstore'
import { mountainMeadow, white } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import { ifNotProp, ifProp, palette, switchProp } from 'styled-tools'
import {
  TransferIcon,
  AccidentIcon,
  PoliceBadgeIcon,
  EscalateIcon,
  IconBase,
  TireIcon,
  StartIcon,
  KeysIcon,
  WinchIcon,
  FuelIcon,
  TowIcon,
  RecoveryIcon,
  CallbackIcon,
} from 'icons'

type IconCallTypes = CallTypes

const CallTypeIconType: React.FC<{ callType: IconCallTypes }> = ({ callType }) => {
  switch (callType) {
    case CallTypes.FEATURE:
      return <MdDone />
    case CallTypes.DROP:
      return <MdCallEnd />
    case CallTypes.TRANSFER:
      return <IconBase icon={TransferIcon} />
    case CallTypes.CALLBACK:
      return <IconBase icon={CallbackIcon} />
    case CallTypes.EXCHANGE:
      return <MdImportExport style={{ transform: 'rotate(90deg)' }} />
    case CallTypes.RECOVERY:
      return <IconBase icon={RecoveryIcon} />
    case CallTypes.ACCIDENT:
      return <IconBase icon={AccidentIcon} />
    case CallTypes.TOW:
      return <IconBase icon={TowIcon} />
    case CallTypes.LAW:
      return <IconBase icon={PoliceBadgeIcon} />
    case CallTypes.ESCALATE:
      return <IconBase icon={EscalateIcon} />
    case CallTypes.LDR:
      return <MdAssignment />
    case CallTypes.TIRES:
      return <IconBase icon={TireIcon} />
    case CallTypes.SPARE_TIRE_CHANGE:
      return <IconBase icon={TireIcon} />
    case CallTypes.JUMPSTART:
      return <IconBase icon={StartIcon} />
    case CallTypes.KEYS:
      return <IconBase icon={KeysIcon} />
    case CallTypes.WINCH:
      return <IconBase icon={WinchIcon} />
    case CallTypes.FUEL:
      return <IconBase icon={FuelIcon} />
    case CallTypes.ADDITIONAL:
      return <IconBase icon={MdAdd} />
    default:
      return null
  }
}

type CallTypeIconProps = {
  callType: IconCallTypes
  children?: React.ReactNode
  checked?: boolean
  disabled?: boolean
  completed?: boolean
}

const colorConfig = {
  [CallTypes.FEATURE]: palette('camelot'),
  [CallTypes.DROP]: palette('camelot'),
  [CallTypes.TRANSFER]: palette('camelot'),
  [CallTypes.EXCHANGE]: palette('orangeRoughy'),
  [CallTypes.RECOVERY]: palette('cardinal'),
  [CallTypes.ACCIDENT]: palette('cardinal'),
  [CallTypes.TOW]: palette('cardinal'),
  [CallTypes.LAW]: palette('buttercup'),
  [CallTypes.ESCALATE]: palette('cardinal'),
  [CallTypes.LDR]: palette('vividViolet'),
  [CallTypes.TIRES]: palette('calypso'),
  [CallTypes.JUMPSTART]: palette('calypso'),
  [CallTypes.KEYS]: palette('calypso'),
  [CallTypes.WINCH]: palette('calypso'),
  [CallTypes.FUEL]: palette('calypso'),
  [CallTypes.ADDITIONAL]: palette('calypso'),
  [CallTypes.CALLBACK]: palette('camelot'),
}

const CallTypeIcon = styled(Flex as any).attrs<CallTypeIconProps>(({ callType, completed }) => ({
  children: (
    <>
      <CallTypeIconType callType={callType} />
      {completed && (
        <MdBrightness1
          size={14}
          color={mountainMeadow}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            boxShadow: `inset 0 0 0 2px ${white}, 0 0 0 1px ${white}`,
            borderRadius: '50%',
          }}
        />
      )}
    </>
  ),
}))<CallTypeIconProps>`
  && {
    background: ${ifProp('checked', palette('white'), switchProp('callType', colorConfig, palette('calypso')))};
    color: ${ifNotProp('checked', palette('white'), switchProp('callType', colorConfig))};
    border: 1px solid;
    border-color: ${ifProp('checked', switchProp('callType', colorConfig, palette('calypso')), 'none')};
    height: ${pxToRem(44)};
    width: ${pxToRem(44)};
    opacity: ${ifProp('disabled', '0.5', '1')};
    cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
    align-items: center;
    border-radius: 50%;
    padding: 0;
    justify-content: center;
  }

  position: relative;
`

export default CallTypeIcon
