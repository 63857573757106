import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { QuickSearchState, QuickSearchActions } from 'types/global'
import { initialQuickSearchState } from './quickSearchHelpers'

const initialState: QuickSearchState = {
  ...initialQuickSearchState(),
}

const quickSearchSlice = createSlice({
  name: 'QuickSearch',
  initialState,
  reducers: {
    setQuickSearchView: (state: QuickSearchState, action: PayloadAction<QuickSearchActions>) => {
      state.currentView = action.payload.view
    },
  },
})

export const { setQuickSearchView } = quickSearchSlice.actions

export default quickSearchSlice.reducer
