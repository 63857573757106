import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { stringify } from 'querystring'
import { useQueryParams } from 'components/LocationProvider'
import { useDispatch } from 'react-redux'
import { DEFAULT_VIEWPORT } from './utils'
import { setViewport } from 'redux/map/mapSlice'
import { useGoogleMaps } from 'hooks/map'

export const useViewport = () => {
  const { map } = useGoogleMaps()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    query: { lat, lng, z, ...rest },
    location,
  } = useQueryParams()

  React.useEffect(() => {
    if (map) {
      const updateUrlParams = () => {
        const center = map && map.getCenter() ? map.getCenter()! : DEFAULT_VIEWPORT.center
        const zoom = map && map.getZoom() ? map.getZoom()! : DEFAULT_VIEWPORT.zoom

        if (center) {
          const queryParams = {
            ...rest,
            lat: center.lat().toPrecision(8),
            lng: center.lng().toPrecision(9),
            z: zoom,
          }
          navigate(`${location.pathname}?${stringify(queryParams)}`, { replace: true })
          dispatch(setViewport({ center, zoom }))
        }
      }
      const listener = map.addListener('dragend', updateUrlParams)
      const zoomListener = map.addListener('zoom_changed', updateUrlParams)

      return () => {
        google.maps.event.removeListener(listener)
        google.maps.event.removeListener(zoomListener)
      }
    }
  }, [location.pathname, map, rest, dispatch])
}

export default useViewport
