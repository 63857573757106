import { AaaAddress } from 'types/aaa'

export type GMapsGeocodeResponse = {
  results: GMapsGeocodeResults[]
  status: string
}

export type GMapsTimezoneResponse = { status: string; timeZoneId: string }

export type GMapsAutocompleteResponse = {
  predictions: GMapsPredictions[]
  status: string
  errorMessage: string
}

export type GMapsGeocodeResults = {
  address_components?: GMapsAddressComponents[]
  formatted_address?: string
  name?: string
  place_id?: string
  types?: any
  geometry: GMapsGeometry
  vicinity: string
  status: string
}

export type GMapsPredictions = {
  description?: string
  place_id?: string
  matched_substrings: GMapsOffsetAndLength[]
  structured_formatting?: StructuredFormatting
  terms?: GMapsOffsetAndValue[]
  label?: string
  value?: string
  address?: GeocodeAddress
}

export type GMapsPlaceResult = {
  address_components?: GMapsAddressComponents
  formatted_address?: string
  name?: string
  place_id?: string
  types?: any
  geometry: GMapsGeometry
  vicinity?: string
}

export type GMapsAddressComponents = {
  long_name?: string
  short_name?: string
  types?: any
}

export type GMapsGeometry = {
  location: GMapsGeometryLocation
  location_type: string
}

export type GMapsGeometryLocation = {
  lat?: number
  lng?: number
}

export type GMapsOffsetAndLength = {
  offset?: number
  length?: string
}

export type GMapsOffsetAndValue = {
  offset?: number
  value?: string
}

interface geometry {
  location: {
    lat: number
    lng: number
  }
  viewport: {
    northeast: {
      lat: number
      lng: number
    }
    southwest: {
      lat: number
      lng: number
    }
  }
}

export type GMapsHotelListItem = {
  business_status: string
  name: string
  geometry: geometry
  place_id: string
  vicinity: string
}

export type GMapsHotelListResponse = {
  results: GMapsHotelListItem[]
}

export type GMapsHotelDetails = {
  formatted_address: string
  formatted_phone_number: string
  geometry: geometry
  international_phone_number: string
  name: string
  place_id: string
  vicinity: string
  website: string
}

export type GMapsHotelDetailsResponse = {
  result: GMapsHotelDetails
}

export type StructuredFormatting = {
  main_text?: string
  main_text_matched_substrings?: GMapsOffsetAndLength[]
  secondary_text?: string
}

export type SuggestedPlace = {
  readonly id: string
  readonly title: string
  readonly highlightedTitle: string
  readonly vicinity: string
  readonly highlightedVicinity: string
  readonly position: number[]
  readonly href: string
  readonly category: string
  /** Custom Attributes for Select Option */
  readonly label?: string
  readonly value?: number[]
  readonly address?: GeocodeAddress | null
}

export type GeocodeAddress = {
  readonly HouseNumber?: string
  readonly Street?: string
  readonly Label?: string
  readonly City?: string
  readonly CountryShortCode?: string
  readonly Country?: string
  readonly County?: string
  readonly PostalCode?: string
  readonly State?: string
}

export interface MapState {
  mapType: keyof typeof MapTypes
  viewport: Viewport
}

export type Viewport = {
  center: google.maps.LatLng
  zoom: number
  shouldFitBounds: boolean
}

export type ViewportActions = {
  center?: google.maps.LatLng
  zoom?: number
  shouldFitBounds?: boolean
}

export type MapTypeActions = {
  mapType: keyof typeof MapTypes
}

export enum MapTypes {
  'hybrid' = 'hybrid',
  'roadmap' = 'roadmap',
  'satellite' = 'satellite',
  'terrain' = 'terrain',
}

export enum MarkerType {
  CONNECTEDCAR = 'CONNECTEDCAR',
  CALLER = 'CALLER',
  VEHICLE = 'VEHICLE',
  VEHICLEDESTINATION = 'VEHICLEDESTINATION',
  BRANCH = 'BRANCH',
  BODYSHOP = 'BODYSHOP',
  REPAIRSHOP = 'REPAIRSHOP',
  TIRESHOP = 'TIRESHOP',
  RENTALRETURN = 'RENTALRETURN',
  RENTAL = 'RENTAL',
  RETURN = 'RETURN',
  PLACE = 'PLACE',
  BRANCHES = 'BRANCHES',
  EXCHANGE = 'EXCHANGE',
  CALLERDESTINATION = 'CALLERDESTINATION',
  HOTEL = 'HOTEL',
  HOTELS = 'HOTELS',
}

export type Marker = {
  type?: MarkerType
  latitude: number
  longitude: number
  address: string
  href?: string
  place_id?: string | null
  extendedAddress?: Partial<AaaAddress>
  place?: any
}

export interface ITempMarkerState {
  tempMarker: Marker | null | undefined
  singlePinView: boolean
}
