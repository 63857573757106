const HAWAII_ZIPS = [
  96704,
  96710,
  96719,
  96720,
  96725,
  96726,
  96727,
  96728,
  96737,
  96738,
  96740,
  96743,
  96749,
  96750,
  96755,
  96760,
  96764,
  96771,
  96772,
  96773,
  96774,
  96776,
  96777,
  96778,
  96780,
  96781,
  96783,
  96785,
]

const LANAI_ZIPS = [96763]

const MAUI_ZIPS = [96708, 96713, 96732, 96753, 96761, 96768, 96779, 96790, 96793]

const KAUAI_ZIPS = [
  96703,
  96705,
  96714,
  96716,
  96722,
  96741,
  96746,
  96747,
  96751,
  96752,
  96754,
  96756,
  96765,
  96766,
  96769,
  96796,
]

const MOLOKAI_ZIPS = [96729, 96742, 96748, 96757, 96770]

const OAHU_ZIPS = [
  96701,
  96706,
  96707,
  96712,
  96717,
  96730,
  96731,
  96734,
  96744,
  96759,
  96762,
  96782,
  96786,
  96789,
  96791,
  96792,
  96795,
  96797,
  96813,
  96814,
  96815,
  96816,
  96817,
  96818,
  96819,
  96821,
  96822,
  96825,
  96826,
  96850,
  96853,
  96857,
  96859,
  96860,
  96863,
]

export function getIslandZipsFromZip(inZip: number) {
  switch (true) {
    case HAWAII_ZIPS.includes(inZip):
      return HAWAII_ZIPS

    case LANAI_ZIPS.includes(inZip):
      return LANAI_ZIPS

    case MAUI_ZIPS.includes(inZip):
      return MAUI_ZIPS

    case KAUAI_ZIPS.includes(inZip):
      return KAUAI_ZIPS

    case MOLOKAI_ZIPS.includes(inZip):
      return KAUAI_ZIPS

    case OAHU_ZIPS.includes(inZip):
      return OAHU_ZIPS

    default:
      return null
  }
}

export function areZipsFromSameIsland(vehicleZip: string | undefined, destinationZip: string) {
  //fail forward if our vehicle zip is undefined
  if (vehicleZip === undefined) return true

  if (vehicleZip === destinationZip) return true

  let vehicleIsland = getIslandZipsFromZip(Number(vehicleZip))

  if (vehicleIsland === null) return false

  if (vehicleIsland?.includes(Number(destinationZip))) return true
  else return false
}
