import { FormState } from 'final-form'
import { useServiceProxy } from 'hooks/kong'
import { SmartAssignment } from 'types/entegral'
import { useSelector } from 'react-redux'
import { selectSmartAssignment } from 'redux/appStore'

export const useUpdateAssignment = () => {
  const serviceProxy = useServiceProxy()
  const { assignment: smartAssignment, participant } = useSelector(selectSmartAssignment)

  return async (values: FormState<any>['values']) => {
    try {
      if (smartAssignment && participant && (!participant.isHerePlace || !participant.isGooglePlace)) {
        const { network } = values
        const { assignment: assignmentObj } = smartAssignment
        if (!assignmentObj || !network.value) throw new Error()

        const assignment = {
          ...assignmentObj,
          assignmentDetails: {
            ...assignmentObj.assignmentDetails,
            assignee: {
              assignedEntityId: participant.assigneeEntityId,
            },
            vehicle: {
              ...assignmentObj.assignmentDetails.vehicle,
              vehicleLoss: {
                lossDescription: values.vehicleCondition,
              },
            },
            searchAddress: {
              ...assignmentObj.assignmentDetails.searchAddress,
            },
          },
        }
        // Update Assignment
        await serviceProxy<SmartAssignment>(
          'put',
          `/serviceproxy/entegral/assignments/${assignmentObj.assignmentId}?network=${network.value}`,
          {},
          { assignment },
        )

        // Dispatch Assignment - entegral sends an email to service provider
        await serviceProxy<SmartAssignment>(
          'post',
          `/serviceproxy/entegral/assignments/${assignmentObj.assignmentId}/dispatches?network=${network.value}`,
          {},
          { assignment },
        )
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default useUpdateAssignment
