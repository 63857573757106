import * as React from 'react'
import { Heading } from 'components/primitives'

export const FormHeading: React.FC = ({ children }) => (
  <Heading as="h5" spacing="md">
    {children}
  </Heading>
)

export default FormHeading
