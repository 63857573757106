import { TowExchange } from 'types/callstore'
import { transformExtendedAddress } from './aaa'
import { BranchAddress } from 'types/branch'
import { AaaAddress } from 'types/aaa'
import { isNil } from 'lodash-es'

/**
 * Only transforms the exchange branch information -- still requires the following 
    rentalBrand
    rentalGPBR
    rentalStationId
    ticketNumber
    vinLast8
    agentEID
 */
export function createExchangeFromTowEvent(towEvent: TowExchange) {
  const exchangeBranch = towEvent.serviceLocations.exchangeLocation

  // Geocode payload
  const addressObject = exchangeBranch?.address?.streetNumber
    ? (transformExtendedAddress(exchangeBranch?.address) as Partial<AaaAddress>)
    : undefined

  // SOLR payload
  const addressLines = exchangeBranch?.address?.street_addresses?.[0]
    ? (exchangeBranch?.address?.street_addresses[0] as string)
    : undefined

  const address =
    !isNil(addressObject) && addressLines // SOLR
      ? addressLines
      : !isNil(addressLines) && !!addressObject // Geocode
        ? `${addressObject.streetNumber} ${addressObject.street} ${addressObject.city} ${addressObject.state} ${addressObject.postalCode}${addressObject.country}`
        : ''

  return {
    exchangeBrand: exchangeBranch?.brand,
    exchangeGPBR: exchangeBranch?.additional_data.group_branch_number,
    peopleSoftId: exchangeBranch?.id,
    formattedPhone: exchangeBranch?.additional_data.formatted_phone,
    addressLines: address,
    formattedCityStateZip: exchangeBranch?.additional_data.formatted_city_state_zip,
    locationNameTranslation: exchangeBranch?.name,
    notes: towEvent.exchangeNotes,
    vehicleAccommodation: towEvent.vehicleAccommodation,
    accommodationNotes: towEvent.accommodationNotes,
    disabledVehicle: towEvent.disabledVehicle,
    bypassReason: towEvent.bypassReason,
    stationId: exchangeBranch?.station_id,
    branchId: exchangeBranch?.id,
  }
}
