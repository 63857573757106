import React from 'react'
import * as Ariakit from '@ariakit/react'
import { pxToRem } from 'theme/utils'
import { MdChevronLeft } from 'react-icons/md'
import { CompositeStore } from '@ariakit/react/composite'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'

const BackButton = styled(Button.Secondary as any)`
  margin-bottom: ${pxToRem(16)};
  border: 0;
  padding: 0;
  min-width: 1em;
  margin-left: -6px;
  & span {
    display: inline-flex;
    align-items: center;
  }
`

const BackNavigation = ({ onClick }: { onClick?: (controls: CompositeStore) => void }) => {
  const controlsContext = Ariakit.useCompositeContext()
  const currentStepid = controlsContext?.useState('activeId')
  const controlsItems = controlsContext?.useState('items') || []

  const isFirst = !onClick && controlsItems?.findIndex(({ id }) => id === currentStepid) === 0
  const { t } = useTranslation()
  return (
    <>
      <BackButton
        onClick={() =>
          typeof onClick === 'function'
            ? onClick(controlsContext!)
            : !isFirst && controlsContext?.move(controlsContext?.previous())
        }
      >
        <MdChevronLeft />
        {t('Previous')}
      </BackButton>
    </>
  )
}

export default BackNavigation
