import React, { ReactNode } from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import Column from 'components/Column'
import Margin from 'components/Margin'
import { Heading } from 'components/primitives'
import Button from 'components/Button'
import { createCancelDialogContext } from './useCancelServiceDialog'
import CancelDispatchForm from './CancelDispatchForm'

export const cancelDialogContext = createCancelDialogContext()

function Wrapper({ children }: { children: ReactNode }) {
  const { t } = useTranslation()
  const { setSubmitted, setDispatchError } = cancelDialogContext.useCancelDialogContext()
  const dialog = Ariakit.useDialogContext()
  return (
    <Column>
      <Margin spacing="lg">{children}</Margin>
      <Button.Secondary
        onClick={() => {
          dialog?.hide()
          setSubmitted(false)
          setDispatchError(false)
        }}
      >
        {t('back')}
      </Button.Secondary>
    </Column>
  )
}

export function CancelDispatchState({ callKey }: { callKey: string }) {
  const { t } = useTranslation()
  const { submitted, dispatchError } = cancelDialogContext.useCancelDialogContext()
  return (
    <Column>
      <Margin spacing="lg">
        <Heading as="h3" spacing="sm">
          {t('cancelDispatch.cancelService')}
        </Heading>
        {dispatchError ? (
          <Wrapper>{t('cancelDispatch.error')}</Wrapper>
        ) : submitted ? (
          <Wrapper>{t('cancelDispatch.success')}</Wrapper>
        ) : (
          <CancelDispatchForm callKey={callKey} />
        )}
      </Margin>
    </Column>
  )
}

export default CancelDispatchState
