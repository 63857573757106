import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Sidebar, SidebarAction } from 'types/global'
import { initialSidebar } from './appHelper'

export interface AppState {
  sidebar: Sidebar
}

const initialState: AppState = {
  sidebar: initialSidebar(),
}

const appSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    toggleSidebar: (state: AppState, action: PayloadAction<SidebarAction>) => {
      const side = action.payload.side
      const toggleOpen = action.payload.toggle === 'OPEN'
      const toggleClose = action.payload.toggle === 'CLOSE'

      // If the side is closed and we call open sidebar
      if (state.sidebar[side] === false && toggleOpen) {
        state.sidebar[side] = true
      }
      // If the side is open and we call close sidebar
      if (state.sidebar[side] === true && toggleClose) {
        state.sidebar[side] = false
      }
    },
    resetAppState: (state: AppState) => {
      state = initialState
      return state
    },
  },
})

export const { toggleSidebar, resetAppState } = appSlice.actions

export default appSlice.reducer
