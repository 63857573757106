import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Label } from 'components/primitives'
import Margin from 'components/Margin'
import { LocationPayload } from 'types/location'
import { SelectOption } from 'types/global'
import { LocationLabels } from 'components/Input/Location'
import { isLocation, isParticipant } from 'utils/typeChecks'
import IncidentLocation from 'components/IncidentLocation'
import SmartAssignment from 'components/SmartAssignment'
import { isNil } from 'lodash'

type DisplayProps = {
  location?: LocationPayload | null
  network?: SelectOption
}
export const ServiceLocationDisplay: React.FC<DisplayProps> = ({ location, network }) => {
  const { t } = useTranslation()

  if (isNil(location)) return null

  if (isLocation(location)) {
    const label = LocationLabels[location.serviceLocationType]
    return <IncidentLocation label={t(label)} location={location} spacing="md" />
  }

  if (isParticipant(location)) {
    return (
      <Margin spacing={'md'}>
        <Label>{t('labels.disabledVehicleDropoff')}</Label>
        <SmartAssignment.Participant participant={location} network={network?.value} showTooltip={false} />
      </Margin>
    )
  }

  return null
}

export default ServiceLocationDisplay
