import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ITempMarkerState, Marker } from 'types/googleMaps'

const initialState: ITempMarkerState = {
  tempMarker: null,
  singlePinView: false,
}

const tempMarkerSlice = createSlice({
  name: 'tempMarker',
  initialState,
  reducers: {
    updateTempMarker: (state: ITempMarkerState, action: PayloadAction<Marker | null | undefined>) => {
      state.tempMarker = action.payload
    },
    resetTempMarkerState: (state: ITempMarkerState) => {
      state = initialState
      return state
    },
    updateSinglePinView: (state: ITempMarkerState, action: PayloadAction<boolean>) => {
      state.singlePinView = action.payload
    },
  },
})

export const { updateTempMarker, resetTempMarkerState, updateSinglePinView } = tempMarkerSlice.actions

export default tempMarkerSlice.reducer
