import * as React from 'react'
import Margin from 'components/Margin'
import { usePopupManager } from 'components/PopupManager'
import Section from 'components/Section'
import LineItem from './LineItem'
import ConfirmLDR from './ConfirmLDR'
import PendingLDROverlay from './PendingLDROverlay'
import { LDREvent } from 'types/ldrEvent'
import { useSelector } from 'react-redux'
import { selectTicketHistoryEvents } from 'redux/appStore'

export const PendingLDR = () => {
  const [ldrEvents, setLdrEvents] = React.useState<LDREvent[]>([])
  const historyEvents = useSelector(selectTicketHistoryEvents)

  React.useEffect(() => {
    const fetchEvents = async () => {
      try {
        const ldrEvents = historyEvents!.filter((event) => event.eventType === 'ldr') as LDREvent[]
        setLdrEvents(ldrEvents)
      } catch (e) {
        console.error(e)
      }
    }
    if (historyEvents) {
      fetchEvents()
    }
  }, [historyEvents])

  if (!ldrEvents || ldrEvents.filter((event) => !event.attributes.claimNumber).length === 0) return null
  return (
    <Margin spacing="md">
      <Section title="Pending LDR">
        <Section.Body>
          {ldrEvents.map((e) => (e.attributes.claimNumber ? null : <PendingLDREvent key={e.eventIdentifier} {...e} />))}
        </Section.Body>
      </Section>
    </Margin>
  )
}

export const PendingLDREvent: React.FC<LDREvent> = (event) => {
  const { open } = usePopupManager()
  const [isOpen, setIsOpen] = React.useState<boolean>(true)
  return (
    <LineItem
      title={event?.attributes?.claim?.date}
      isOpen={isOpen}
      onClick={() => {
        open(
          <PendingLDROverlay>
            <ConfirmLDR event={event} onSubmit={() => setIsOpen(false)} />
          </PendingLDROverlay>,
        )
      }}
    />
  )
}

export default PendingLDR
