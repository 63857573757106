import { useEffect, useState } from 'react'
import { useServiceProxy } from 'hooks/kong'
import { isNil } from 'lodash-es'
import { stringify } from 'querystring'

import { GMapsHotelListItem, GMapsHotelListResponse } from 'types/googleMaps'
import { IncomingHotelListResponse } from 'types/api'
import { deepMapSanitize } from 'utils/deepMap'

export const useGoogleMapsNearbySearch = (latitude: number, longitude: number, radius: number, type: string) => {
  const [data, setData] = useState<null | GMapsHotelListItem[]>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<null | any>(null)
  const proxy = useServiceProxy()

  useEffect(() => {
    if (!isNil(latitude) && !isNil(longitude) && !isNil(radius)) {
      const fetchData = async () => {
        setLoading(true)
        try {
          const params = {
            location: `${latitude},${longitude}`,

            // Note: fields cannot be filtered in a nearbysearch google api call
            radius,
            type,
          }

          // TODO: this is being called twice for the same coordinates. reduce down to 1 call to reduce costs

          const response = await proxy<IncomingHotelListResponse>(
            'get',
            `/serviceproxy/googleMaps/place/nearbysearch/json?${stringify(params)}`,
          ).then((response) => ({
            ...response,
            data: deepMapSanitize(response.data) as GMapsHotelListResponse,
          }))
          const { results } = response.data
          setData(results)
          setLoading(false)
        } catch (err) {
          setError(err)
          setLoading(false)
        }
      }
      fetchData()
    }
  }, [latitude, longitude, radius, type])

  return { data, loading, error }
}
