import axios from 'axios'
import { useEffect, useState } from 'react'
import { BranchInfoFragment } from 'types/branch'
import { config } from '../../config'

export const useLocatorSearchByBranch = (branchId?: string) => {
  const [branch, setBranch] = useState<BranchInfoFragment | null>(null)
  const { solrLocator } = config

  useEffect(() => {
    async function getBranchData() {
      if (branchId === undefined) return

      if (branchId) {
        const { data } = await axios.get<{ branches: BranchInfoFragment[] }>(`${solrLocator}/branch:${branchId}`)

        if (data && data.branches.length > 0) {
          const branchIndex = data.branches.findIndex((b) => b.groupBranchNumber === branchId)
          setBranch(data.branches[branchIndex])
        }
      }
    }

    getBranchData()
  }, [branchId])

  return branch
}

export default useLocatorSearchByBranch
