import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'components/primitives'
import CircleIcon, { CircleIconSize } from '../CircleIcon'
import { CircleIconProps } from '../CircleIcon/CircleIcon'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { calypso, wildSand } from '../../theme/colors'

export type CircleActionProps = {
  label?: string
  onClick?: () => void
  isOpen?: boolean
} & CircleIconProps

const StyledTooltip = styled.div`
  position: absolute;
  display: inline-block;
`

const StyledTooltipText: any = styled.div`
  position: absolute;
  background-color: ${calypso};
  color: ${wildSand};
  text-align: center;
  border-radius: 6px;
  padding: 5px 2px;
  top: 8px;
  right: 20px;
  width: 100px;
  z-index: 9999;
`

function CircleAction({
  icon: Icon,
  color,
  circleSize = CircleIconSize.LARGE,
  iconSize,
  label,
  onClick,
  ghost,
  iconColor,
  style = {},
  isOpen,
}: CircleActionProps) {
  const { t } = useTranslation()
  const [hovering, setHovering] = useState(false)
  return (
    <div onMouseOver={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <Flex
        style={{
          cursor: 'pointer',
          flexDirection: 'column',
          ...style,
        }}
        onClick={onClick!}
      >
        <CircleIcon icon={Icon} circleSize={circleSize!} ghost={ghost} color={color} iconColor={iconColor} iconSize={iconSize} />
        {hovering && !isOpen && (
          <StyledTooltip>
            <StyledTooltipText>{t(label as string)}</StyledTooltipText>
          </StyledTooltip>
        )}
      </Flex>
    </div>
  )
}

export default CircleAction
