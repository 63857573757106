import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Label, Paragraph } from 'components/primitives'
import Divider from 'components/Divider'
import Margin from 'components/Margin'
import { PopupCard } from 'components/PopupManager'
import { pxToRem } from 'theme/utils'
import useTicketNotes from 'hooks/tickets/useTicketNotes'
import { MdAssignmentReturn } from 'react-icons/md'
import NotesPopupCard from '../Notes/NotesPopupCard'
import { TicketNote } from 'types/ticket'

const Notes = () => {
  const { t } = useTranslation()
  const { notes } = useTicketNotes() as any
  const [notesPopupCard, openNotesPopupCard] = React.useState(false)

  return (
    <React.Fragment>
      <PopupCard
        heading={t('Notes')}
        icon={MdAssignmentReturn}
        onClick={() => {
          openNotesPopupCard(true)
        }}
      >
        {!notes && <Paragraph style={{ fontStyle: 'italic', opacity: 0.5 }}>{t('No Notes')}</Paragraph>}
        {notes &&
          (notes as TicketNote[]).map(({ createDateTime, enteredByUser, userId, text }, i) => {
            const isLast = i === notes.length - 1
            return (
              <React.Fragment key={i}>
                <Margin spacing={!isLast ? 'sm' : undefined}>
                  <Label>{t('date.standardWithTime', { date: new Date(createDateTime) })}</Label>
                  <Paragraph spacing="sm">{userId || enteredByUser}</Paragraph>
                  <Paragraph style={{ fontSize: pxToRem(14) }}>{text}</Paragraph>
                </Margin>
                {!isLast && <Divider />}
              </React.Fragment>
            )
          })}
      </PopupCard>
      {notesPopupCard && <NotesPopupCard />}
    </React.Fragment>
  )
}

export default Notes
