import styled from 'styled-components/macro'
import { white } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import BoxShadow from '../BoxShadow'

const Card = styled(BoxShadow as any)`
  background-color: ${white};
  border-radius: ${pxToRem(8)};
  padding: ${pxToRem(16)};
`

export default Card
