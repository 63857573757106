import * as React from 'react'
import Divider from 'components/Divider'
import { Heading, Paragraph } from 'components/primitives'
import { Brands } from 'types/global'

type Props = {
  brand: Brands
  name: String
  calculatedDistance: String
  formattedPhone: String
  street: String
  formattedCityStateZip: String
}

export const ExchangeTooltip: React.FC<Props> = ({
  brand,
  name,
  calculatedDistance,
  formattedPhone,
  street,
  formattedCityStateZip,
}) => (
  <div>
    <Heading as="h5">{brand}</Heading>
    <Heading as="h6">{name}</Heading>
    <Paragraph>{calculatedDistance}</Paragraph>
    <Divider />
    <div>
      <Paragraph>{formattedPhone}</Paragraph>
      <Paragraph>{street}</Paragraph>
      <Paragraph>{formattedCityStateZip}</Paragraph>
    </div>
  </div>
)

export default ExchangeTooltip
