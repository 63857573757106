import { LogLevel, BrowserCacheLocation } from '@azure/msal-browser'
import { config } from 'config'
export interface AppConfig {
  readonly clientId: string
  readonly authority: string
  readonly scopes: string[]
}

const { msalClientId, msalAuthority, msalScopes } = config

export const msalConfig = {
  clientId: msalClientId,
  authority: msalAuthority,
  scopes: msalScopes,
}

export const getAzureConfig = () => ({
  auth: {
    authority: msalConfig.authority,
    clientId: msalConfig.clientId,
    redirectUri: `${window.location.href}`,
    postLogoutRedirectUri: `${window.origin}/login`,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          // case LogLevel.Info:
          //   console.info(message)
          //   return
          // case LogLevel.Verbose:
          //   console.debug(message)
          //   return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
      piiLoggingEnabled: false,
    },
    allowRedirectInIframe: true,
  },
})
