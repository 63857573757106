import React, { createContext, useContext } from 'react'

type CancelDialogProps = {
  submitted: boolean
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>
  dispatchError: boolean
  setDispatchError: React.Dispatch<React.SetStateAction<boolean>>
  cancelled: boolean
  setCancelled: React.Dispatch<React.SetStateAction<boolean>>
}

const initialDialogState: CancelDialogProps = {
  submitted: false,
  dispatchError: false,
  cancelled: false,
  setSubmitted: () => false,
  setDispatchError: () => false,
  setCancelled: () => false,
}

export const createCancelDialogContext = () => {
  const cancelDialogContext = createContext(initialDialogState)

  const Provider = ({ children }) => {
    const [submitted, setSubmitted] = React.useState(false)
    const [dispatchError, setDispatchError] = React.useState(false)
    const [cancelled, setCancelled] = React.useState(false)

    const props = {
      submitted,
      dispatchError,
      cancelled,
      setSubmitted,
      setDispatchError,
      setCancelled,
    }
    return <cancelDialogContext.Provider value={props}>{children}</cancelDialogContext.Provider>
  }

  const useCancelDialogContext = () => useContext(cancelDialogContext)

  return { Provider, useCancelDialogContext }
}

export default createCancelDialogContext
