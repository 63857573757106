import { TransformedVehicle } from 'types/ticket'
import { Branch, Participant } from 'types/global'
import { callEvents } from 'pages/Ticket/routes/Default/Details/CallHistory/CallEvent'
import { DynamoEvents, EventTypes } from 'types/events'
import { TowExchange } from 'types/callstore'
import { isNil } from 'lodash-es'
import { isParticipant, isLocation, isNumeric } from 'utils/typeChecks'
import { AnyObject } from 'immer/dist/internal'
import { LocationPayload, LocationState } from 'types/location'

export type ExchangeBranchProps = {
  recentEvent: {
    eventType: string
    attributes: any
  } | null
}
export type BranchInfo = {
  brand: string
  gpbr: string
  peoplesoftId: string
  phone: string
  address: string
  formattedCityStateZip: string
} | null

const filterByServiceEvent = ([eventType]: [string, unknown]) => callEvents[eventType as keyof typeof callEvents]
const callTypesToIgnore = [EventTypes.FEATURE, EventTypes.DROP, EventTypes.TRANSFER, EventTypes.CALLBACK, EventTypes.LDR]

/**
 *
 * @param events  Previous Call Events from ticket to check against any possible escalations ( Any event with an exchange associated with it )
 * If MOST recent event requires escalation, list out additional details from said event
 * If MOST recent event is an escalation event, find the next recent event that requires escalation, list out additional details from said event
 */
function getRecentEvent(events: DynamoEvents[]): AnyObject | null | undefined {
  if (events.length === 0) return null
  const recentEvents = events.filter((_, index) => index < 20) // Only check the last 20 events

  const newEvent = recentEvents.map((mostRecentEvent, index) => {
    if (mostRecentEvent.eventType === EventTypes.TOW) {
      // Check for multi event with exchange
      // Tow with an Exchange Event
      if (mostRecentEvent.attributes.exchange) {
        return { tow: mostRecentEvent } as AnyObject
      }
    }

    if (mostRecentEvent.eventType === EventTypes.ACCIDENT) {
      // Accident with a Tow + Exchange Event
      if (mostRecentEvent.attributes.tow) {
        const towEvent = recentEvents.filter((event) => event.eventType === EventTypes.TOW)[0]
        if (towEvent) {
          return { tow: towEvent } as AnyObject
        }
      }
      // Accident with an Exchange Event
      else if (mostRecentEvent.attributes.accident?.attributes.exchange) {
        return { exchange: mostRecentEvent.attributes.exchange } as AnyObject
      }
    }

    // Check for Escalate Event
    if (mostRecentEvent.eventType === EventTypes.ESCALATE) {
      // Get Second most recent event
      const refIndex = index
      const newEvents = events.filter((_, index) => index > refIndex) // Filter out the most recent event which is escalate
      const nonEscalateEvent = getRecentEvent(newEvents) // Get new set of events for escalation / recurssive check to remove previous escalate workflow
      return nonEscalateEvent as AnyObject
    }

    //Check for exchange multi-event
    if (mostRecentEvent.eventType === EventTypes.EXCHANGE) {
      return { exchange: mostRecentEvent }
    }

    return undefined
  })

  return newEvent.filter(Boolean)[0] as DynamoEvents | undefined
}

/**
 * Get most recent tow event
 * @param events Call History event list
 */
function getRecentTowEvent(events: DynamoEvents[]) {
  if (events.length === 0) return null
  const recentEvents = events.filter((_, index) => index < 20) // Only check the last 20 events

  const newEvent = recentEvents.map((mostRecentEvent, index) => {
    if (mostRecentEvent.eventType === EventTypes.TOW) {
      return { tow: mostRecentEvent }
    }

    return undefined
  })

  return newEvent.filter(Boolean)[0] as AnyObject | undefined
}

export function getTowEventInfo(events: DynamoEvents[]): AnyObject | null {
  if (events.length === 0) return null
  const recentEvent = getRecentTowEvent(events)
  if (isNil(recentEvent)) return null

  const eventName = recentEvent.eventType

  // Filter out Null entries
  const eventEntries = Object.entries(recentEvent).filter(([_, event]) => !!event)
  // Isolate all of the service events
  const serviceEventEntries = eventEntries.filter(filterByServiceEvent)
  // Check to see if event should be ignored
  const ignoredEvent = callTypesToIgnore.some((callType) => callType === eventName)

  // Use case: No service event found
  if (serviceEventEntries.length === 0) return null
  // Use case: No service event found And Ignored Event
  if (serviceEventEntries === undefined && ignoredEvent) return null

  // Use case: Multi event
  if (serviceEventEntries.length > 1) {
    // Look for tow event
    if (serviceEventEntries.some(([eventType]) => eventType === EventTypes.TOW)) {
      const towEvent = serviceEventEntries.filter(([eventType]) => eventType === EventTypes.TOW)[0]
      const towEventType = towEvent[0]
      const towAttributes = (towEvent[1] as { attributes: any }).attributes

      return { eventType: towEventType, attributes: towAttributes }
    }
  }

  // Use case: Single event
  const eventType = serviceEventEntries[0][0] as EventTypes
  const attributes = (serviceEventEntries[0][1] as { attributes: any }).attributes

  return { eventType, attributes }
}

export function getEventInfo(events: DynamoEvents[]) {
  if (events.length === 0) return null
  const recentEvent = getRecentEvent(events)
  if (isNil(recentEvent)) return null

  const eventName = recentEvent.eventType

  // Filter out Null entries
  const eventEntries = Object.entries(recentEvent).filter(([_, event]) => !!event)
  // Isolate all of the service events
  const serviceEventEntries = eventEntries.filter(filterByServiceEvent)
  // Check to see if event should be ignored
  const ignoredEvent = callTypesToIgnore.some((callType) => callType === eventName)

  // Use case: No service event found
  if (serviceEventEntries.length === 0) return null
  // Use case: No service event found && Ignored Event
  if (serviceEventEntries === undefined && ignoredEvent) return null

  // Use case: Multi event
  if (serviceEventEntries.length > 1) {
    // Look for Accident event
    if (serviceEventEntries.some(([eventType]) => eventType === EventTypes.ACCIDENT)) {
      const accidentEvent = serviceEventEntries.filter(([eventType]) => eventType === EventTypes.ACCIDENT)[0]
      const accidentEventType = accidentEvent[0]
      const accidentAttributes = (accidentEvent[1] as { attributes: any }).attributes

      return { eventType: accidentEventType, attributes: accidentAttributes }
    }

    // Look for tow event
    if (serviceEventEntries.some(([eventType]) => eventType === EventTypes.TOW)) {
      const towEvent = serviceEventEntries.filter(([eventType]) => eventType === EventTypes.TOW)[0]
      const towEventType = towEvent[0]
      const towAttributes = (towEvent[1] as { attributes: any }).attributes

      return { eventType: towEventType, attributes: towAttributes }
    }

    // Look for Exchange event
    if (serviceEventEntries.some(([eventType]) => eventType === EventTypes.EXCHANGE)) {
      const exchangeEvent = serviceEventEntries.filter(([eventType]) => eventType === EventTypes.EXCHANGE)[0]
      const exchangeEventType = exchangeEvent[0]
      const exchangeAttributes = (exchangeEvent[1] as { attributes: any }).attributes

      return { eventType: exchangeEventType, attributes: exchangeAttributes }
    }

    // Grab most recent non-LDR event
    const multiEvent = serviceEventEntries.filter(([eventType]) => eventType !== EventTypes.LDR)
    const multiEventEventType = multiEvent[0][0]
    const multiEventAttributes = (multiEvent[0][1] as { attributes: any }).attributes

    return { eventType: multiEventEventType, attributes: multiEventAttributes }
  }

  // Use case: Single event
  const eventType = serviceEventEntries[0][0] as EventTypes
  const attributes = (serviceEventEntries[0][1] as { attributes: AnyObject }).attributes

  return { eventType, attributes }
}

export function getExchangeBranch({ recentEvent }: ExchangeBranchProps) {
  // No recent service event found
  if (isNil(recentEvent)) return null

  // Exchange Event
  if (recentEvent.eventType === EventTypes.EXCHANGE) {
    const { attributes } = recentEvent
    return {
      brand: attributes.exchangeBrand,
      gpbr: attributes.exchangeGPBR,
      peoplesoftId: attributes.peopleSoftId,
      phone: attributes.formattedPhone,
      address: attributes.addressLines,
      formattedCityStateZip: attributes.formattedCityStateZip,
    }
  }

  // Tow Event
  if (recentEvent.eventType === EventTypes.TOW) {
    const { attributes } = recentEvent as { attributes: TowExchange }

    // If the Tow Event doesn't have an associated Exchange - return null
    if (!attributes.exchange) return null

    // Tow Vehicle Delivery Event
    if (
      !attributes.branch &&
      attributes.serviceLocations.exchangeLocation &&
      attributes.serviceLocations.vehicleDestinationLocation
    ) {
      const exchangeBranch = attributes.serviceLocations.exchangeLocation as LocationPayload & Branch

      return {
        brand: exchangeBranch.brand,
        gpbr: exchangeBranch.additional_data.group_branch_number,
        peoplesoftId: exchangeBranch.id,
        phone: exchangeBranch.additional_data.formatted_phone,
        address: exchangeBranch.address.street_addresses[0],
        formattedCityStateZip: exchangeBranch.additional_data.formatted_city_state_zip,
      }
    }

    if (!attributes.branch) return null

    return {
      brand: attributes.branch.brand,
      gpbr: attributes.branch.additional_data.group_branch_number,
      peoplesoftId: attributes.branch.id,
      phone: attributes.branch.additional_data.formatted_phone,
      address: attributes.branch.address.street_addresses[0],
      formattedCityStateZip: attributes.branch.additional_data.formatted_city_state_zip,
    }
  }

  return null
}

export function getRecentTowEventInfo({ recentEvent }: ExchangeBranchProps) {
  if (!recentEvent) return null

  const { serviceLocations } = recentEvent.attributes
  if (!serviceLocations) return null

  const towLocation = (serviceLocations as LocationState).vehicleDestinationLocation

  if (!towLocation) return null

  if (isParticipant(towLocation)) {
    const towLoc = towLocation as unknown as Participant
    const { addressLine1, city, administrativeArea, postalCode } = towLoc.entity.address!

    const towLocationName = towLoc.entity.name
    const towLocationAddress = `${addressLine1}`
    const towLocationCityState = `${city} ${administrativeArea} ${postalCode}`

    return { name: towLocationName, address: towLocationAddress, cityState: towLocationCityState }
  }

  if (isLocation(towLocation)) {
    const towLoc = towLocation as unknown as LocationPayload

    if (towLoc.addressDescription) {
      const splitLoc = towLoc.addressDescription.split('<br/>')
      const isLocationNamed = splitLoc.filter(Boolean).length === 3
      const towLocationName = isLocationNamed ? splitLoc[0] : ''
      const towLocationAddress = isLocationNamed ? splitLoc[1] : splitLoc[0]
      const towLocationCityState = isLocationNamed ? splitLoc[2] : splitLoc[1]

      return { name: towLocationName, address: towLocationAddress, cityState: towLocationCityState }
    }

    const { street = '', city, state, streetNumber = '', postalCode, country } = towLoc.address

    let towStreet: string = `${streetNumber} ${street}`
    const [towStreetNumber, ...rest] = towStreet && towStreet.split(' ')
    if (isNumeric(towStreetNumber)) {
      towStreet = `${towStreetNumber} ${rest.join(' ')}`
    } else {
      towStreet = `${towStreet} ${city} ${state}, ${country} ${postalCode}`
    }

    const towLocationName = ''
    const towLocationAddress = `${towStreet}`
    const towLocationCityState = `${city}, ${state} ${postalCode}`

    return { name: towLocationName, address: towLocationAddress, cityState: towLocationCityState }
  }
}

export function transformVehicle(vehicle: TransformedVehicle & { label: string }) {
  return `${vehicle.color} - ${vehicle.label}`
}

export function transformTowLocation(towLocation: Record<'name' | 'address' | 'cityState', string>) {
  let full: string = ''

  if (towLocation.name) full += towLocation.name
  if (towLocation.address) full += ` - ${towLocation.address}`
  if (towLocation.cityState) full += `, ${towLocation.cityState}`

  return full || 'N/A'
}

export function transformExchangeBranch(exchangeBranch: BranchInfo) {
  let full: string = ''

  if (exchangeBranch) {
    if (exchangeBranch.brand && exchangeBranch.gpbr) full += `${exchangeBranch.brand} - ${exchangeBranch.gpbr}`
    if (exchangeBranch.address) full += ` - ${exchangeBranch.address}`
    if (exchangeBranch.formattedCityStateZip) full += `, ${exchangeBranch.formattedCityStateZip}`
    if (exchangeBranch.phone) full += ` - ${exchangeBranch.phone}`
  }

  return full || 'N/A'
}
