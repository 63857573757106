import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import { useCreateTowService } from 'hooks/aaa'
import { useCreateUlyTowService, useUrgentlyProvider } from 'hooks/urgent.ly'
import useFieldValue from 'hooks/utils/useFieldValue'
import Input from 'components/Input'
import { PassengerSelect, LuggageSelect } from 'components/Select'
import { BackNavigation } from 'components/Step'
import { CallTypes, Tow } from 'types/callstore'
import { Submit } from 'components/fields'
import Margin from 'components/Margin'
import ScheduledAt from 'components/ScheduledAt'
import { UnattendedTow } from '.'
import { BreakdownOptions } from 'forms/CallForm/BreakdownDetails/BreakdownRadio'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { BreakdownDetailsForm, BreakdownNote } from 'forms/CallForm/BreakdownDetails'

export const DispatchTow = () => {
  const { t } = useTranslation()
  const createAAATowService = useCreateTowService()
  const createULYTowService = useCreateUlyTowService()
  const rideAlong = useFieldValue('rideAlong')
  const { shouldUseUrgently, isThirdParty } = useUrgentlyProvider()
  const { tow } = useSelector(selectFormsState) as any

  const form = useForm()
  const controlsContext = Ariakit.useCompositeContext()

  let safeToContinue =
    (tow?.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE && tow?.unsafe === 'no') ||
    (tow?.breakdownOption === BreakdownOptions.PARKINGGARAGE && tow?.garageAccessible === 'yes') ||
    (tow?.breakdownOption === BreakdownOptions.OTHER && tow?.accessible === 'yes')

  return (
    <>
      <>
        <BackNavigation
          onClick={() => {
            const { rideAlong, customerSupplied } = form.getState().values as Tow

            if (customerSupplied) {
              form.change('customerSupplied', null)
              controlsContext?.move('2')
            } else if (rideAlong) {
              form.change('rideAlongLocationSet', null)
              controlsContext?.move(controlsContext?.previous())
            } else {
              form.change('unattended', null)
              form.change('rideAlong', null)
              controlsContext?.move(controlsContext?.previous())
            }
          }}
        />
        <>
          <BreakdownDetailsForm callType={CallTypes.TOW} />
          {safeToContinue && (
            <>
              {!rideAlong && <UnattendedTow />}
              <Margin spacing="md">
                <ScheduledAt callType={CallTypes.TOW} />
                <Input.Hidden name="scheduledAt" />
              </Margin>
              {shouldUseUrgently && (
                <>
                  {isThirdParty && (
                    <>
                      <Margin spacing="md">
                        <PassengerSelect name="passengerCount" label={t('labels.passengerCount')} />
                      </Margin>
                      <Margin spacing="md">
                        <LuggageSelect name="luggageCount" label={t('labels.luggageCount')} />
                      </Margin>
                    </>
                  )}
                </>
              )}
              <BreakdownNote
                breakdownOption={tow?.breakdownOption}
                unsafe={tow?.unsafe}
                garageAccessible={tow?.garageAccessible}
                accessible={tow?.accessible}
              />
              <Input.Textarea name="notes" label={t('notesToProvider.label')} maxLength={240} />
              <Margin spacing="md">
                <Submit
                  onSubmit={async (state, caller) => {
                    const { secondaryProvider = false, scheduledAt } = state.values
                    if (scheduledAt) {
                      return await createULYTowService(state, caller)
                    } else {
                      if (shouldUseUrgently) {
                        // Urgently
                        return secondaryProvider
                          ? await createAAATowService(state, caller) //Secondary
                          : await createULYTowService(state, caller) //Default
                      } else {
                        // AAA
                        return secondaryProvider
                          ? await createULYTowService(state, caller) //Secondary
                          : await createAAATowService(state, caller) //Default
                      }
                    }
                  }}
                >
                  {t('tow.dispatch')}
                </Submit>
              </Margin>
            </>
          )}
        </>
      </>
    </>
  )
}

export default DispatchTow
