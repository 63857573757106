import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

export const StyledBox = styled.div`
  margin: -${theme('spaces.2')};
  margin-top: 0;
  padding: ${theme('spaces.2')};
  height: 100%;
`

export const ScrollBox = styled(StyledBox as any)`
  overflow-y: scroll;
`

export default ScrollBox
