import { useServiceProxy } from 'hooks/kong'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import { selectCurrentUser, selectFormsState } from '../../redux/appStore'
import { Callers, Officer } from 'types/callstore'
import { splitName } from 'utils/splitName'

export const useCreateContact = () => {
  const serviceProxy = useServiceProxy()
  const { caller, officer } = useSelector(selectFormsState)
  const currentUser = useSelector(selectCurrentUser)

  return async (eventIdentifier: string) => {
    const { callerName, number, countryCode, callerRelationship, drivers, selectedCaller } = caller as any as Callers
    const callerformValues = caller.getState().values as Callers
    const { badge, cityAndCounty, department, deskPhone, deskPhoneExt, email, stateOrProvince, title, isVerified } =
      officer as any as Officer
    const attributes = {
      badge,
      cityAndCounty,
      department,
      deskPhone,
      deskPhoneExt,
      email,
      stateOrProvince,
      title,
      isVerified,
    }

    const isDriverSelected = selectedCaller && selectedCaller.includes('caller-')
    const driverIndex = isDriverSelected ? parseInt(selectedCaller.split('caller-')[1]) : 0
    const selectedDriver = (callerformValues.drivers || drivers || [])[driverIndex]
    const selectedDriverName = isDriverSelected ? selectedDriver.callerName : null
    const selectedDriversPhoneNumber = selectedDriver.phone?.[0]?.number ?? null
    const driverName = selectedDriverName || ''
    const driverPhone = selectedDriversPhoneNumber || ''

    const phoneNumber = number !== null ? { number, countryCode } : driverPhone
    const cn = callerName !== null ? callerName : driverName
    const { firstName, lastName } = splitName(cn)

    const now = new Date()
    const audit = [
      {
        eId: currentUser?.nameID || '',
        createTimestamp: now.toISOString(),
      },
    ]
    const body = {
      audit,
      contactIdentifier: uuidv4(),
      firstName,
      lastName,
      phone: phoneNumber,
      type: callerRelationship?.value || 'driver',
      eventIdentifier: eventIdentifier,
      attributes: callerRelationship?.value === 'police' ? attributes : {},
    }
    return await serviceProxy<{ message: string }>('put', '/serviceproxy/contacts-table/items', {}, body)
  }
}

export default useCreateContact
