import { MapTypes } from 'types/googleMaps'

export function initialMapState() {
  return {
    mapType: MapTypes.hybrid,
    viewport: initialViewport(),
  }
}

function initialViewport() {
  return {
    center: { lat: 38.6233822, lng: -90.4902562 } as unknown as google.maps.LatLng,
    zoom: 10,
    shouldFitBounds: false,
  }
}
