import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import { Heading, Flex, Paragraph } from 'components/primitives'
import Margin from 'components/Margin'
import RouteLink from 'components/RouteLink'
import ScrollBox from 'components/ScrollBox'
import { pxToRem } from 'theme/utils'
import { ListItem } from '../PlaceList/ListItem'
import { useSelector } from 'react-redux'
import { selectMapViewport, selectTicket } from 'redux/appStore'
import { vehicleExchangeRadiusInKM } from 'components/Exchange/utils'
import { useGoogleMapsNearbySearch } from 'hooks/map/useGoogleMapsNearbySearch'

export const HotelList = () => {
  const viewport = useSelector(selectMapViewport)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { ticketNumber } = useSelector(selectTicket)

  const lat = (typeof viewport.center.lat === 'number' ? viewport.center.lat : viewport.center.lat()) as unknown as number
  const lng = (typeof viewport.center.lng === 'number' ? viewport.center.lng : viewport.center.lng()) as unknown as number
  const radius = vehicleExchangeRadiusInKM * 1000

  const { data, loading, error } = useGoogleMapsNearbySearch(lat, lng, radius, 'lodging')

  if (!data || loading || error) return null

  return (
    <>
      <Margin use={RouteLink} to="../../" spacing="md">
        <MdClose />
        Hotels
      </Margin>
      <ScrollBox style={{ paddingTop: 0 }}>
        {data
          .filter((hotel) => hotel.business_status === 'OPERATIONAL')
          .map((hotel) => {
            const { place_id, name, vicinity } = hotel

            return (
              <ListItem
                key={place_id!}
                onClick={() => {
                  navigate(`/ticket/${ticketNumber}/place/HOTELS/${place_id}`)
                }}
              >
                <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Heading as="h5" style={{ fontSize: pxToRem(18) }}>
                    {name}
                  </Heading>
                </Flex>
                <Paragraph
                  style={{ fontStyle: 'italic', marginTop: '4px', fontSize: pxToRem(16) }}
                  dangerouslySetInnerHTML={{ __html: vicinity || t('N/A') }}
                />
              </ListItem>
            )
          })}
      </ScrollBox>
    </>
  )
}

export default HotelList
