import React from 'react'
import { YesNoTriggers } from 'components/Input'
import Margin from 'components/Margin'
import Location from 'components/Input/Location'
import { Label, Paragraph } from 'components/primitives'
import SmartAssignment from 'components/SmartAssignment'
import useFieldValue from 'hooks/utils/useFieldValue'
import { useTranslation } from 'react-i18next'
import { useCallerDestinationLocation } from 'hooks/redux/useLocations'
import { Condition } from 'components/fields'
import IncidentLocation from 'components/IncidentLocation'
import { LocationPayload } from 'types/location'
import { useSelector } from 'react-redux'
import { selectSmartAssignment } from 'redux/appStore'

export const NoExchangeInfo = () => {
  const { t } = useTranslation()
  const { participant, customDropoffDestination } = useSelector(selectSmartAssignment)
  const network = useFieldValue('network.value')
  const rideAlongLocationSet = useFieldValue('rideAlongLocationSet')
  const rideAlongSameAsDropoff = useFieldValue('rideAlongSameAsDropoff')
  const callerDestinationLocation = useCallerDestinationLocation()
  const callerLocation: LocationPayload = useFieldValue('callerLocation')
  const customerWithVehicle = useFieldValue('customerWithVehicle')
  const callerLocationSet = useFieldValue('callerLocationSet')

  const caller = customerWithVehicle === YesNoTriggers.YES || (callerLocationSet && callerLocation)

  return (
    <>
      <Margin spacing="md">
        <Location.Vehicle name="serviceLocations.vehicleLocation" />
      </Margin>
      {participant ? (
        <Margin spacing="md">
          <Label>{t('labels.disabledVehicleDropoff')}</Label>
          <SmartAssignment.Participant participant={participant} network={network} showTooltip={false} />
        </Margin>
      ) : (
        customDropoffDestination && (
          <Margin spacing="md">
            <Label>{t('labels.disabledVehicleDropoff')}</Label>
            <IncidentLocation location={customDropoffDestination} />
          </Margin>
        )
      )}
      <Condition when="customerSupplied" is={(v) => v === true}>
        <>
          <Label>{t('labels.customersTransportationMethod')}</Label>
          <Paragraph spacing="md">{t('tow.exchange.customerSupplied')}</Paragraph>
        </>
      </Condition>
      <Condition when="rideAlong" is={(v) => v === true || v === false}>
        {(value: boolean) => (
          <>
            <Label>{t('labels.customersTransportationMethod')}</Label>
            <Paragraph spacing="md">{value ? t('Ride Along') : t('Third Party')}</Paragraph>
            {caller && (
              <IncidentLocation.Caller location={callerLocation} spacing="md">
                {!callerLocation && <Paragraph>{t('Customer is with disabled vehicle')}</Paragraph>}
              </IncidentLocation.Caller>
            )}
            {rideAlongLocationSet && (
              <Margin spacing="md">
                <Label>{t('Customer Dropoff Location')}</Label>
                {rideAlongSameAsDropoff ? (
                  <Paragraph>{t('Same as vehicle dropoff destination')}</Paragraph>
                ) : (
                  <IncidentLocation location={callerDestinationLocation} />
                )}
              </Margin>
            )}
          </>
        )}
      </Condition>
    </>
  )
}

export default NoExchangeInfo
