import { useTranslation } from 'react-i18next'
import { FormState } from 'final-form'
import { EventTypes } from 'types/events'
import { Tow, TowExchange } from 'types/callstore'
import { useTowExchangeEmail } from 'hooks/aaa'
import { useCreateServiceEvent } from 'hooks/events'
import { FormattedCaller } from 'components/fields/Submit'
import useUpdateAssignment from 'hooks/assignment/useUpdateAssignment'
import useUpdateTicketCharges from 'hooks/tickets/useUpdateTicketCharges'
import useULYStandardServices from './useULYStandardservices'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser, selectFormsState, selectTicket } from 'redux/appStore'
import { useServiceProxy } from 'hooks/kong'
import { CaseServiceCall } from 'types/urgently'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'
import { createExchangeFromTowEvent } from 'utils/exchange'

export const useCreateUlyTowService = (eventType?: EventTypes) => {
  const { t } = useTranslation()
  const ticket = useSelector(selectTicket)
  const currentUser = useSelector(selectCurrentUser)
  const { additionalService, ...forms } = useSelector(selectFormsState) as any
  const updateAssignment = useUpdateAssignment()
  const events = [EventTypes.TOW, additionalService].filter(Boolean) as ReadonlyArray<EventTypes>
  const createServiceRequests = useULYStandardServices(events)
  const serviceProxy = useServiceProxy()
  const dispatch = useDispatch()
  const createServiceEvent = useCreateServiceEvent({
    eventType: EventTypes.TOW,
  })
  const createExchangeServiceEvent = useCreateServiceEvent({
    eventType: EventTypes.EXCHANGE,
  })

  const sendTowExchangeEmail = useTowExchangeEmail()
  const updateTicketCharges = useUpdateTicketCharges()

  return async (state: FormState<Tow | TowExchange>, caller: FormattedCaller) => {
    const service = state.values as typeof eventType extends undefined ? Tow : TowExchange
    const additionalValues = additionalService === 'lockout' || additionalService === 'winch' ? forms[additionalService] : {}

    // TODO: Update these strings
    const lockoutNotes = 'Please perform Lockout service with Tow. ' + service.notes
    const winchNotes = 'Please perform Winch service with Tow. ' + service.notes
    const additionalNotes = additionalService === 'lockout' ? lockoutNotes : additionalService === 'winch' ? winchNotes : ''

    const unattendedNotes = service.unattended ? ' Vehicle is unattended and locked.' : ''

    const serviceWithAdditionalNotes = {
      ...service,
      notes: additionalNotes + service.notes + unattendedNotes,
    }

    try {
      const caseRequest = createServiceRequests({ ...additionalValues, ...serviceWithAdditionalNotes }, caller)
      // Fire Tow Dispatch
      const { data: towData } = await serviceProxy<CaseServiceCall>('post', '/serviceproxy/urgently/cases', {}, caseRequest)

      if (towData && towData.case) {
        if (additionalService) {
          await updateTicketCharges(additionalService)
        }

        await updateAssignment(state.values)

        if (eventType === EventTypes.EXCHANGE) {
          const towExchangeValues = state.values as TowExchange
          const exchangeEvent = await createExchangeServiceEvent({
            ...createExchangeFromTowEvent(towExchangeValues),
            rentalBrand: ticket.brandId,
            rentalGPBR: ticket.pickupInfo.groupBranchId,
            rentalStationId: ticket.pickupInfo.branch.stationId,
            ticketNumber: ticket.ticketNumber,
            vinLast8: ticket.vehicle!.vinLast8,
            agentEID: currentUser?.nameID,
          })

          await sendTowExchangeEmail(state.values as TowExchange, caller)
          dispatch(
            setCurrentEvent({
              eventType: EventTypes.EXCHANGE,
              newEvent: exchangeEvent.data,
            }),
          )
        }

        const dispatchCase = pruneCaseData(towData)

        const event = await createServiceEvent({
          ...serviceWithAdditionalNotes,
          serviceCase: dispatchCase,
          provider: 'ULY',
          customerOptIn: caller.optIn || Boolean(towData.contactInfo.textOptIn) || false,
        })
        dispatch(setCurrentEvent({ eventType: EventTypes.TOW, newEvent: event.data }))
      }
    } catch (e) {
      console.error(e)
      return t('error.uly')
    }
  }
}

// ULY dispatch data to save in ronin event
export function pruneCaseData(caseDispatch?: CaseServiceCall) {
  if (!caseDispatch) return null

  return {
    caseId: caseDispatch.case.id,
    serviceId: caseDispatch.services[0].ulyServiceId,
  }
}

export default useCreateUlyTowService
