import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdChevronRight, MdErrorOutline } from 'react-icons/md'
import styled from 'styled-components/macro'
import { Flex, Label, Heading } from 'components/primitives'
import { EventTypes } from 'types/events'
import { calypso, white, dustyGray, black, monza } from 'theme/colors'
import { EventFeed } from 'types/events'
import { EventTypeToCallType } from 'utils/callstore'
import { useContactsRefresh } from 'hooks/tickets'
import { DynamoContacts } from 'types/contacts'
import { isNil } from 'lodash-es'

export type CallHistoryEventProps = {
  event: EventFeed['events'][0]
  onClick: (event: EventFeed['events'][0] | null) => void
  isActive?: boolean
}

type EventProps = {
  isActive?: boolean
  isAlertColor?: boolean
  wasSelected?: boolean
}

const AlertEventTypes = [EventTypes.ESCALATE, EventTypes.RECOVERY]

const StyledFlex = styled(Flex as any)<EventProps>(
  ({ wasSelected, isActive }) => `
  flex: 1;
  align-items: center;
  text-decoration: none;
  color: ${!isActive && wasSelected ? dustyGray : black}

  * {
    cursor: pointer;
  }
`,
)

const ChevronWrapper = styled(Flex as any)<EventProps>(
  ({ isActive, isAlertColor }) => `
  border-radius: 1rem
  background-color: ${isActive ? (isAlertColor ? monza : calypso) : white}
  color: ${isActive ? white : isAlertColor ? monza : calypso}
  `,
)

const CallEventSummary: React.FC<CallHistoryEventProps> = ({ event, onClick, isActive }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState(false)
  const [caller, setCaller] = React.useState<DynamoContacts | null>(null)
  const contactsRefresh = useContactsRefresh()

  const handleSelected = React.useCallback(() => {
    setSelected(true)
  }, [])
  const { branchNotes } = event.attributes
  const { eventType } = event
  const timestamp = event.audit?.[0]?.createTimestamp || ''
  const NOTE_LIMIT = 250
  const eventProvider = event.attributes?.provider === 'ULY' ? 'Urgent.ly' : 'AAA'
  const agentEID = event.audit?.[0]?.eId ? event.audit?.[0]?.eId + ' | ' : ''
  let isUnattended = event?.attributes?.unattended ? ' - Unattended' : ''
  const isAlertEventType = AlertEventTypes.includes(eventType)

  function shortenString(input: String) {
    if (input.length <= NOTE_LIMIT) return input
    return input.substr(0, input.lastIndexOf(' ', NOTE_LIMIT)) + '...'
  }

  const noteText = branchNotes ? shortenString(branchNotes) : t('notFound.notes')

  React.useEffect(() => {
    if (isNil(caller)) {
      contactsRefresh(event.eventIdentifier).then((response) => {
        setCaller(response.data.Items[0])
      })
    }
  }, [event, contactsRefresh, caller])

  return (
    <StyledFlex
      onClick={() => {
        onClick(event)
        handleSelected()
      }}
      isActive={isActive}
      wasSelected={selected}
    >
      <div style={{ flex: 1 }}>
        <Label>{t('date.standardWithTime', { date: new Date(timestamp) })}</Label>
        <Heading
          as="h6"
          spacing="sm"
          style={{
            fontWeight: 'bold',
            color: isAlertEventType ? monza : undefined,
          }}
        >
          {EventTypeToCallType[eventType] + isUnattended}
          {event?.attributes?.unattended ? (
            <MdErrorOutline style={{ color: monza, position: 'relative', top: '5px', left: '5px' }} />
          ) : null}
        </Heading>
        {event.attributes?.dispatch || event.attributes?.serviceCase ? (
          <Label spacing="sm">{eventProvider ? `Provider: ${eventProvider}` : null}</Label>
        ) : null}
        <Label spacing="sm">{agentEID + noteText}</Label>

        {!caller?.firstName && !caller?.lastName && !caller?.phone.number ? (
          <Label>{t('REDACTED')}</Label>
        ) : (
          <Label>
            <div style={{ fontWeight: 'bold', display: 'inline' }}>{`${caller?.firstName} ${caller?.lastName} `}</div>
            {!caller?.phone ? (
              <Label>{t('REDACTED')}</Label>
            ) : (
              <div>{`${caller?.phone?.countryCode?.label} ${caller?.phone?.number}`}</div>
            )}
          </Label>
        )}
      </div>
      <ChevronWrapper isActive={isActive} isAlertColor={isAlertEventType}>
        <MdChevronRight />
      </ChevronWrapper>
    </StyledFlex>
  )
}

export default CallEventSummary
