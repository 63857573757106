import * as React from 'react'
import { pxToRem } from 'theme/utils'
import { calypso } from 'theme/colors'
import IconBase from './IconBase'

const ConnectedCarMarkerIcon = () => (
  <IconBase
    width={pxToRem(24)}
    height={pxToRem(24)}
    fill={calypso}
    style={{ marginRight: 8 }}
    path="M55-155.5C43.4-154.1 30.6-148.5 24-141.9L21.5-139.5 24.7-136.4 27.8-133.2 33.9-137.3C44-144 49.9-145.9 61.5-145.9 72.8-145.9 79.1-144 88.1-137.9 90.9-136 93.7-134.5 94.4-134.5 95.1-134.5 96.7-135.9 98-137.7L100.3-140.9 96.7-144C87-152.4 69.3-157.2 55-155.5ZM52-134.1C47.1-132.7 37-126.9 37-125.5 37-124.3 41.9-119.5 43.1-119.5 43.6-119.5 45.9-120.7 48.2-122.3 51.7-124.6 53.6-125.1 60.4-125.3 67.9-125.6 69-125.4 74.4-122.6L80.3-119.4 82.7-121.7C86.5-125.3 85.4-127.6 77.8-131.3 69.9-135.2 59.6-136.3 52-134.1ZM56.1-114.9C53.4-111.4 53.5-109 56.5-106 60.7-101.7 67-103.6 68.4-109.6 69.9-116.4 60.4-120.4 56.1-114.9ZM36.5-96C29.8-92.8 28.5-90.7 25-76.1L22.4-65.2 18.1-64.1C10.9-62.3 9.6-59.6 9.2-45.5L8.8-33.6 12.2-33.3C15.4-33 15.5-32.8 15.8-28.1 16.2-22.1 18.2-18.9 22.5-17.5 25.2-16.6 26.5-16.7 28.9-17.9 32.8-20 33.4-21 34-27.5L34.5-33 61-33 87.5-33 87.8-29C88.2-22.2 88.9-20.7 92.5-18.5 96.4-16.1 99.3-16.3 103.1-19.4 104.9-20.9 105.6-22.6 106-27.1 106.5-32.9 106.5-33 109.8-33.3L113.2-33.6 112.8-46.2 112.5-58.7 109.2-61.6C107.3-63.4 104.9-64.5 103.1-64.5 100.5-64.5 100.2-64.9 98.5-71.3 91.6-98 92.3-97.5 60.7-97.5 44.6-97.4 38.8-97.1 36.5-96ZM81.6-84.7C83-83.9 88.1-65.9 87.2-65.1 86.9-64.8 75-64.5 60.8-64.5 37.9-64.5 35-64.7 35-66.1 35-69.3 39.2-84 40.3-84.7 41.8-85.7 80.1-85.7 81.6-84.7ZM31.4-54.4C34.7-50.5 34.7-47.5 31.4-43.6 26.3-37.5 16.6-41.1 16.6-49 16.6-56.9 26.3-60.5 31.4-54.4ZM103.4-54.4C106.2-51.1 106.6-48.5 104.8-45.2 100.9-37.8 90-39.7 88.4-48.1 88-50.5 88.4-51.8 90.5-54.3 94.3-58.8 99.7-58.8 103.4-54.4Z"
  />
)

export default ConnectedCarMarkerIcon
