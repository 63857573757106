import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'

export const roadsideProtection = ['RAP', 'RSP']

export function useProtectionWaiver() {
  const { protection } = useSelector(selectTicket)
  const isProtected = protection && protection.some(({ type }) => roadsideProtection.includes(type))

  return isProtected
}

export default useProtectionWaiver
