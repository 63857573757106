import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Label } from 'components/primitives'
import OrDivider from 'components/OrDivider'
import Input from 'components/Input'
import { getExampleNumber, parsePhoneNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'

export const CustomerForm: React.FC = () => {
  const { t } = useTranslation()
  const exampleNumber = getExampleNumber('US', examples)
  const defaultPhone = parsePhoneNumber(exampleNumber?.formatNational() ?? '', 'US')

  return (
    <>
      <Label spacing="md">{t('customerSearch')}</Label>
      <Flex style={{ marginBottom: '16px' }}>
        <Input.Checkbox label={t('Search Closed Tickets')} name="searchClosed" />
      </Flex>
      <Flex>
        <Input label={t('First Name*')} name="firstName" gutter="md" />
        <Input label={t('Last Name*')} name="lastName" />
      </Flex>
      <Flex>
        <Input.PhoneNumber
          label={t('Phone Number')}
          name="phoneNumber"
          gutter="md"
          exampleNumber={exampleNumber}
          defaultPhone={defaultPhone}
        />
        <Input.Date label={t('Date of Birth')} name="dateOfBirth" />
      </Flex>
      <OrDivider />
      <Flex>
        <Input label={t('Loyalty Number')} name="membershipNumber" />
      </Flex>
    </>
  )
}

export default CustomerForm
