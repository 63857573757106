import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FormApi } from 'final-form'
import { Paragraph } from 'components/primitives'
import Divider from 'components/Divider'
import * as Yup from 'yup'
import { PopupCard } from 'components/PopupManager'
import Input from 'components/Input'
import { Form } from 'components/FormManager'
import Submit from './Submit'
import { LDREvent } from 'types/ldrEvent'

declare module 'types/form' {
  export interface Forms {
    PendingLDR: FormApi
  }
}

type Props = {
  event: LDREvent
  onSubmit: () => void
}

export const ConfirmLDR: React.FC<Props> = ({ event, onSubmit }) => {
  const { t } = useTranslation()
  const { date, state, facts, vehicle } = event.attributes.claim
  return (
    <PopupCard heading={t('Pending LDR')}>
      <Paragraph spacing="md">
        {vehicle.year} {vehicle.make} {vehicle.model} ({vehicle.licensePlate})
      </Paragraph>
      <Paragraph spacing="md">{date}</Paragraph>
      <Paragraph spacing="md">{state.label}</Paragraph>
      <Paragraph spacing="md">{facts}</Paragraph>
      <Divider />
      <Form<any> name="PendingLDR" schema={pendingLDRSchema}>
        <Input label={t('Claim Number')} name="claimNumber" />
        <Submit event={event} onSubmit={onSubmit} />
      </Form>
    </PopupCard>
  )
}

const pendingLDRSchema = Yup.object().shape({
  claimNumber: Yup.string().required('Claim number is required'),
})

export default ConfirmLDR
