import * as React from 'react'
import { useLoadScript } from '@react-google-maps/api'
import { libraries } from 'pages/Ticket/HeroMap/googleConfig'
import { GoogleMapsProvider } from 'hooks/map'
import { config } from '../../config'

const GoogleMapProviders = ({ children }) => {
  const { googleMapKey } = config
  const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: googleMapKey, libraries })

  if (loadError) {
    console.error('Google Maps LoadError', loadError)
    return null
  }

  return isLoaded ? <GoogleMapsProvider>{children}</GoogleMapsProvider> : null
}

export default GoogleMapProviders
