import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Label, Paragraph } from 'components/primitives'
import { monza } from 'theme/colors'
import { AnyObject } from 'types/location'

const ParagraphWithLabel: React.FC<{ label: string }> = ({ label, children }) => (
  <>
    <Label>{label}</Label>
    <Paragraph spacing="sm">{children}</Paragraph>
  </>
)

export const EscalateEvent: React.FC<{ attributes: AnyObject }> = ({ attributes }) => {
  const { t } = useTranslation()
  const {
    accommodationNotes,
    vehicle,
    vehicleAccommodation,
    disabledVehicle,
    vehicleCondition,
    exchangeBranch,
    towLocation,
  } = attributes

  return (
    <>
      <Heading as="h5" spacing="md" style={{ color: monza, textTransform: 'uppercase' }}>
        {t('Exchange Escalation')}
      </Heading>

      <ParagraphWithLabel label={t('Vehicle')}>{`${vehicle.color} - ${vehicle.label}`}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('Vehicle Condition')}>{vehicleCondition}</ParagraphWithLabel>
      <ParagraphWithLabel
        label={t('Exchange Branch')}
      >{`${exchangeBranch.brand} - ${exchangeBranch.gpbr}`}</ParagraphWithLabel>
      <Paragraph>{exchangeBranch.address}</Paragraph>
      <Paragraph>{exchangeBranch.formattedCityStateZip}</Paragraph>
      <Paragraph spacing="sm">{exchangeBranch.phone}</Paragraph>
      <ParagraphWithLabel label={t('Vehicle Accommodation')}>{vehicleAccommodation}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('Accommodation Notes')}>{accommodationNotes}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('labels.disabledVehicleSelect')}>{disabledVehicle}</ParagraphWithLabel>
      {towLocation.address && (
        <>
          <ParagraphWithLabel label={t('Tow Destination')}>{towLocation.name}</ParagraphWithLabel>
          <Paragraph>{towLocation.address}</Paragraph>
          <Paragraph spacing="sm">{towLocation.cityState}</Paragraph>
        </>
      )}
    </>
  )
}

export default EscalateEvent
