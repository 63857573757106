import { Location as RouterLocation, BrowserRouter, useLocation } from 'react-router-dom'
import { parse, ParsedUrlQuery } from 'querystring'
import { isEmpty } from 'lodash-es'
import * as React from 'react'

export const LocationProvider = ({ children }) => <BrowserRouter>{children}</BrowserRouter>

type UseQueryParams = {
  query: ParsedUrlQuery
  location: RouterLocation
}

export function useQueryParams(): UseQueryParams {
  const location = useLocation()
  let query = {}
  if (!isEmpty(location.search)) {
    try {
      query = parse(location.search.substring(1)) // remove leading ? to parse
    } catch (e) {
      console.error(e)
    }
  }
  return { query, location }
}

export default LocationProvider
