import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { GoogleHeroMarker, HeroMarkerProps } from './GoogleHeroMarker'

type Props = Omit<HeroMarkerProps, 'onClick'> & { id: string; onClick: (s: boolean) => void }

export const SelectableHeroMarker: React.FC<Props> = ({ id, onClick, ...rest }) => {
  const location = useLocation()
  const selected = location.pathname.includes(id)

  return (
    <GoogleHeroMarker
      {...rest}
      selected={selected}
      onClick={() => {
        onClick(selected)
      }}
    />
  )
}

export default SelectableHeroMarker
