import * as React from 'react'
import Divider from 'components/Divider'
import BranchDetails from './BranchDetails'
import { useTransferStatus, TransferStatuses } from '../TransferStatus'
import useMount from 'hooks/utils/useMount'
import useSpatialSearch from 'hooks/solr/useSpatialSearch'
import { Brands } from 'types/global'

type Props = {
  lat: string
  lon: string
  brand: Brands
}

const BranchSearch: React.FC<Props> = ({ lat, lon, brand }) => {
  const [, setTransferStatus] = useTransferStatus()
  const { data, loading, error } = useSpatialSearch(lat, lon, brand)

  useMount(() => {
    setTransferStatus(TransferStatuses.DEFAULT)
  })

  if (!data || !data.locationsResult || loading || error) return null

  const { locationsResult } = data

  return (
    <div style={{ height: '100%', overflowY: 'scroll' }}>
      {locationsResult.map(
        (branch, i) =>
          branch && (
            <div key={i}>
              {i !== 0 && <Divider />}
              <BranchDetails branch={branch} />
            </div>
          ),
      )}
    </div>
  )
}

export default BranchSearch
