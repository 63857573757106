import * as React from 'react'
import styled, { css } from 'styled-components/macro'
import { theme, ifProp } from 'styled-tools'
import useFieldValue from 'hooks/utils/useFieldValue'
import { calypso, silver } from 'theme/colors'
import { Radio } from './FinalFormInputs'
import { Box, Heading } from 'components/primitives'
import { InputProps } from 'components/Input'

const StyledRadioInput = styled(Radio as any).attrs({
  hideIndicator: true,
})`
  padding: ${theme('spaces.1')};
  justify-content: center;
  box-shadow: inset 0 0 0 ${ifProp('checked', `2px ${calypso}`, `1px ${silver}`)};
  border-radius: 2px;
`

enum Orientation {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

type GridProps = {
  numberOfChoices: number
  orientation: keyof typeof Orientation
}

function applyLayout({ orientation, numberOfChoices }: GridProps) {
  if (orientation === Orientation.horizontal)
    return css`
      grid-template-rows: 1fr;
      grid-template-columns: repeat(${numberOfChoices}, 1fr);
    `

  return css`
    grid-template-columns: 1fr;
    grid-template-rows: repeat(${numberOfChoices}, 1fr);
  `
}

const MultipleChoiceGrid = styled(Box as any).attrs({ spacing: 'sm' })<GridProps>`
  display: grid;
  grid-gap: 8px;
  ${applyLayout}
`

type Props = {
  /** input name */
  name: string
  /** key-value pair that maps to value-label */
  choices: Record<string, React.ReactNode>
  /**
   * sets orientation
   * @default "horizontal"
   * */
  orientation?: keyof typeof Orientation
} & InputProps

export default function MultipleChoice({
  name,
  choices,
  orientation = 'horizontal',
  required,
  label,
  ...rest
}: Props) {
  const options = Object.entries(choices)
  const fieldValue: string = useFieldValue(name)

  return (
    <>
      {label && (
        <Heading as="h5" spacing="md">
          {label}
        </Heading>
      )}
      <MultipleChoiceGrid numberOfChoices={options.length} orientation={orientation}>
        {options.map(([value, label]) => (
          <StyledRadioInput
            key={value}
            checked={fieldValue === value}
            name={name}
            value={value}
            {...rest}
          >
            {label}
          </StyledRadioInput>
        ))}
      </MultipleChoiceGrid>
    </>
  )
}
