import { BranchResponse } from 'types/branch'

export function filterSolrDataByCountry(
  branchResponse: BranchResponse,
  country: string,
): BranchResponse {
  const responseCopy = { ...branchResponse }
  if (responseCopy.locationsResult && responseCopy.locationsResult.length > 0) {
    responseCopy.locationsResult = responseCopy.locationsResult.filter(
      (branch) => branch.countryCode === country,
    )
  }

  if (responseCopy.result && responseCopy.result.length > 0) {
    responseCopy.result = responseCopy.result.filter(
      (branch) => branch.address.country_code === country,
    )
  }
  return responseCopy
}
