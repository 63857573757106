import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'react-final-form'
import {
  useCallerLocation,
  useVehicleLocation,
  useVehicleDestinationLocation,
  useCallerDestinationLocation,
} from 'hooks/redux/useLocations'
import { Label } from 'components/primitives'
import { ErrorMessage } from 'components/fields/Error'
import Places from 'components/Places'
import Margin from 'components/Margin'
import Input from 'components/Input'
import { LocationAddress } from 'components/IncidentLocation'
import { isRequired } from 'utils/validators'
import { LocationPayload, LocationType, ServiceLocationType } from 'types/location'
import { LocationTypeToMarkerType } from 'redux/location/locationHelpers'

export enum FormValueType {
  Location,
  Address,
}

type Props = {
  name: string
  label?: string
  required?: boolean
  type: LocationType
  valueType?: FormValueType
  serviceLocationType?: ServiceLocationType
  edit?: boolean
  removeLabel?: boolean
  serviceLocation?: LocationPayload | null
}

type ServiceLocation = {
  serviceLocationType: ServiceLocationType
} & LocationPayload

export const LocationLabels: { [key: string]: string } = {
  [ServiceLocationType.CALLER]: 'labels.callerLocation',
  [ServiceLocationType.VEHICLE]: 'labels.vehicleLocation',
  [ServiceLocationType.VEHICLEDESTINATION]: 'labels.vehicleDestinationLocation',
  [ServiceLocationType.CALLERDESTINATION]: 'labels.callerDestinationLocation',
}

const ErrorMessages: { [key: string]: string } = {
  [LocationType.CALLER]: 'error.callerLocation',
  [LocationType.VEHICLE]: 'error.vehicleLocation',
  [LocationType.VEHICLEDESTINATION]: 'error.vehicleDestinationLocation',
  [LocationType.CALLERDESTINATION]: 'error.callerDestinationLocation',
}

const Location: React.FC<Props> = ({
  name,
  label,
  removeLabel,
  required,
  type,
  valueType = FormValueType.Location,
  serviceLocationType = ServiceLocationType.VEHICLEDESTINATION,
  edit = false,
  serviceLocation,
}) => {
  const { t } = useTranslation()

  // Save either the LocationFragment or Address string as the form value
  let value: ServiceLocation | string | null = null
  if (serviceLocation) {
    if (valueType === FormValueType.Location) value = { ...serviceLocation, serviceLocationType }
    else value = (serviceLocation && serviceLocation.addressDescription) || null
  }

  if (value) {
    return (
      <Margin spacing="sm">
        {!removeLabel && <Label>{label ? label : t(LocationLabels[serviceLocationType])}</Label>}
        <Margin spacing="sm">
          <LocationAddress location={serviceLocation} />
        </Margin>
        <Input.Hidden name={name} initialValue={value as any} />
        {edit && (
          <Margin spacing="sm">
            <Label>{t(`${LocationLabels[serviceLocationType]}-edit`)}</Label>
            <Places markerType={LocationTypeToMarkerType[type]} />
          </Margin>
        )}
      </Margin>
    )
  }

  return (
    <Field name={name} validate={required ? isRequired() : undefined} initialValue={value}>
      {({ meta: { touched, error } }) => {
        const hasError = Boolean(touched && error)
        return (
          <>
            {!removeLabel && <Label>{label ? label : t(LocationLabels[type])}</Label>}
            <Margin spacing="sm">
              <Places markerType={LocationTypeToMarkerType[type]} />
            </Margin>
            {hasError && <ErrorMessage>{`* ${t(ErrorMessages[type])}`}</ErrorMessage>}
          </>
        )
      }}
    </Field>
  )
}

const Caller: React.FC<Omit<Props, 'type' | 'serviceLocation'>> = (props) => {
  const callerLocation = useCallerLocation()
  return (
    <Location
      type={LocationType.CALLER}
      serviceLocationType={ServiceLocationType.CALLER}
      serviceLocation={callerLocation}
      {...props}
    />
  )
}
const Vehicle: React.FC<Omit<Props, 'type' | 'serviceLocation'>> = (props) => {
  const vehicleLocation = useVehicleLocation()

  return (
    <Location
      type={LocationType.VEHICLE}
      serviceLocationType={ServiceLocationType.VEHICLE}
      serviceLocation={vehicleLocation}
      required
      {...props}
    />
  )
}
const VehicleDestination: React.FC<Omit<Props, 'type' | 'serviceLocation'>> = (props) => {
  const vehicleDestinationLocation = useVehicleDestinationLocation()
  return (
    <Location
      type={LocationType.VEHICLEDESTINATION}
      serviceLocationType={ServiceLocationType.VEHICLEDESTINATION}
      serviceLocation={vehicleDestinationLocation}
      {...props}
    />
  )
}

const CallerDestination: React.FC<Omit<Props, 'type' | 'serviceLocation'>> = (props) => {
  const callerDestinationLocation = useCallerDestinationLocation()

  return (
    <Location
      type={LocationType.CALLERDESTINATION}
      serviceLocationType={ServiceLocationType.CALLERDESTINATION}
      serviceLocation={callerDestinationLocation}
      {...props}
    />
  )
}

export default Object.assign(Location, {
  Caller,
  Vehicle,
  VehicleDestination,
  CallerDestination,
})
