import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Ariakit from '@ariakit/react'
import Margin from 'components/Margin'
import { Flex, Paragraph, Label } from 'components/primitives'
import Button from 'components/Button'
import { useCreateEhiTicketNote } from 'hooks/tickets'
import { MdClose } from 'react-icons/md'
import { YesNoCancel, YesNo } from 'types/global'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'redux/appStore'

type ConsentProps = { handleOnValidate: () => void; vin: string }

function generateConsentNotes(answer: YesNoCancel) {
  const consent = `Did Customer consent to Connected Car Services? ${answer}`
  return consent
}

export const CustomerConsent = ({ handleOnValidate, vin }: ConsentProps) => {
  const [isInRestRequest, setIsInRestRequest] = React.useState(false)
  const consentDialog = Ariakit.useDialogContext()
  const currentUser = useSelector(selectCurrentUser)
  const { t } = useTranslation()
  const createEhiTicketNote = useCreateEhiTicketNote()

  React.useEffect(() => {
    return () => {
      consentDialog?.hide()
      setIsInRestRequest(false)
    }
  }, [])

  async function handleNoClick() {
    const consentNotes = generateConsentNotes(YesNo.NO)
    const noNotesWithEid = `${currentUser?.nameID.toUpperCase()}: ${consentNotes}`
    consentDialog?.hide()

    await createEhiTicketNote(noNotesWithEid)
  }

  async function handleYesClick() {
    setIsInRestRequest(true)
    const consentNotes = generateConsentNotes(YesNo.YES)
    const yesNotesWithEid = `${currentUser?.nameID.toUpperCase()}: ${consentNotes}`
    await createEhiTicketNote(yesNotesWithEid)
    handleOnValidate()
  }

  async function handleCancelClick() {
    const cancel = 'CANCEL'
    const consentNotes = generateConsentNotes(cancel)
    const cancelNotesWithEid = `${currentUser?.nameID.toUpperCase()}: ${consentNotes}`
    consentDialog?.hide()
    await createEhiTicketNote(cancelNotesWithEid)
  }

  return (
    <Margin style={{ width: 500 }}>
      <Margin spacing="md">
        <MdClose
          style={{
            cursor: 'pointer',
            float: 'right',
          }}
          onClick={handleCancelClick}
        />
        <Paragraph>{t('connectedCar.customerConsent')}</Paragraph>
      </Margin>
      <Margin spacing="md" style={{ width: 130 }}>
        <Label
          style={{ color: 'blue', fontSize: 14, textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            window.open('https://1drv.ms/b/s!Ans0HO6zqYeMgQw6EmlT3horLUfR')
          }}
        >
          {t('Connected Car FAQ')}
        </Label>
      </Margin>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Button.Secondary disabled={isInRestRequest} onClick={handleNoClick}>
          {t('No')}
        </Button.Secondary>
        <Button.Primary disabled={isInRestRequest} onClick={handleYesClick}>
          {t('Yes')}
        </Button.Primary>
      </Flex>
    </Margin>
  )
}

export default CustomerConsent
