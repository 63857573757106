import { callerDefault, LdrClaim, officerDefault } from 'types/callstore'
import isArray from 'lodash-es/isArray'
import { FormStore } from 'redux/form/formTypes'

export const defaultState: FormStore = {
  caller: callerDefault,
  notes: null,
  callType: null,
  ldr: {} as LdrClaim,
  workflow: [],
  officer: officerDefault,
}

export const handleArrayCheck = (a: any, b: any) => (isArray(b) ? b : undefined)
