import { cloneDeep } from 'lodash-es'
import { useEffect } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { registerForm, unregisterForm } from 'redux/form/formSlice'
import { FormName } from 'types/form'

type Props = {
  name: FormName
}

export const RegisterForm: React.FC<Props> = ({ name }) => {
  const form = useForm()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(registerForm({ name, form: cloneDeep(form) }))
    return () => {
      if (name !== 'caller' && name !== 'CallForm') dispatch(unregisterForm({ name }))
    }
    // eslint-disable-next-line
  }, [])

  return null
}

export default RegisterForm
