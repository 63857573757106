import { useLocation, useMatch, useParams } from 'react-router-dom'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdChevronLeft } from 'react-icons/md'
import Margin from 'components/Margin'
import Places from 'components/Places'
import RouteLink from 'components/RouteLink'
import { StyledBox } from 'components/ScrollBox'
import BranchLocation from './BranchLocation'
import { useLocatorSearchByBranch } from 'hooks/solr/useLocatorSearchByBranch'
import { BranchInfoFragment } from 'types/branch'

export const Branch = () => {
  const { t } = useTranslation()
  const { branchId, ticketNumber } = useParams()
  const branch = useLocatorSearchByBranch(branchId)
  let path = `/ticket/${ticketNumber}/branch/${branchId}`
  let match = useMatch(path)
  let location = useLocation()
  let isExact = match && location.pathname === path
  let to = isExact ? '../..' : '..'

  if (!branch || !branchId) return null

  return (
    <>
      <Margin use={RouteLink} relative="path" to={to} spacing="md">
        <MdChevronLeft />
        {t('Back')}
      </Margin>
      <Places />

      <StyledBox>
        <BranchLocation {...(branch as BranchInfoFragment)} />
      </StyledBox>
    </>
  )
}

export default Branch
