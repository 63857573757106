import * as React from 'react'
import { MdSearch } from 'react-icons/md'
import { components, DropdownIndicatorProps } from 'react-select'
import { SelectOption } from 'types/global'

export const DropdownIndicator: React.FC<DropdownIndicatorProps<SelectOption>> = (props) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <MdSearch size={20} />
    </components.DropdownIndicator>
  )

export default DropdownIndicator
