import React from 'react'
import { useTranslation } from 'react-i18next'
import Input, { InputProps } from 'components/Input'

export enum VehicleConditions {
  ACCIDENT = 'accident',
  BRAKE_FAILURE = 'brakeFailure',
  BROKEN_GLASS = 'brokenGlass',
  OUT_OF_CHARGE = 'outOfCharge',
  OVERHEATED = 'overheated',
  RUNS_POORLY = 'runsPoorly',
  FLAT_NO_SPARE = 'flatTireNoSpare',
  FLAT_TIRES = 'flatTires',
  STUCK = 'stuck',
  KEYS_LOCKED = 'keysLocked',
  LEAKING_FLUIDS = 'leakingFluids',
  LOST_KEY = 'lostKey',
  MAINTENANCE_LIGHT = 'maintenanceLight',
  NO_START = 'noStart',
  SUSPENSION_FAILURE = 'suspensionFailure',
  TRANSMISSION_FAILURE = 'transmissionFailure',
  FIRE = 'fire',
  MIS_FUELED = 'misFueled',
  OTHER = 'other',
}

export function VehicleConditionSelect(props: InputProps) {
  const { t } = useTranslation()
  const options = Object.values(VehicleConditions)
    .filter(condition => {
      if (condition === VehicleConditions.OTHER) return false
      return true
    })
    .map(condition => ({
      label: t(`vehicleCondition.${condition}`),
      value: condition,
    }))
  return <Input.Select placeholder="--" options={options} isClearable {...props} />
}

export default VehicleConditionSelect
