import React from 'react'
import { IndicatorSeparatorProps } from 'react-select'

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  marginBottom: 8,
  marginTop: 8,
  width: 1,
}

export const IndicatorSeparator = ({ innerProps }: IndicatorSeparatorProps<any, boolean>) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />
}
