import { useServiceProxy } from 'hooks/kong/useServiceProxy'
import { v4 as uuid } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser, selectTicket } from 'redux/appStore'
import { useLocations } from 'hooks/redux/useLocations'
import { DynamoEvents, EventTypes } from 'types/events'
import { TransformedVehicle } from 'types/ticket'
import { useCreateContact } from 'hooks/tickets'
import { config } from '../../config'
import { addTicketHistoryEvent } from 'redux/callHistory/callHistorySlice'
import { isEmpty, isNil } from 'lodash-es'

type UseServiceSubmitParams = {
  /** the type of the event to be created */
  eventType: EventTypes
}

const softServices = [
  EventTypes.FUEL,
  EventTypes.TIRES,
  EventTypes.TIRES_CHANGE_SPARE,
  EventTypes.WINCH,
  EventTypes.LOCKOUT,
  EventTypes.JUMPSTART,
  EventTypes.TOW,
]

/**
 * Returns the PUT event serviceProxy response. This hook creates
 * an event that's referenced to the call.
 */
export default function useCreateServiceEvent<T>({ eventType }: UseServiceSubmitParams) {
  const serviceProxy = useServiceProxy()
  const createContact = useCreateContact()
  const dispatch = useDispatch()
  const { ticketNumber, vehicle, vehicleHistory, pickupInfo } = useSelector(selectTicket)
  const serviceLocations = useLocations()
  const currentUser = useSelector(selectCurrentUser)
  const { rentalAgreementUrn, vehicleUrn } = config
  // passing an optional vehicle allows for the calling form to pass a vehicle from form values
  async function createServiceEvent(values: T, eventId?: string, selectedVehicle?: TransformedVehicle) {
    const secondaryVehicle = vehicleHistory.find((vehicle) => !isNil(vehicle) && !isEmpty(vehicle)) as TransformedVehicle
    const serviceId = values?.serviceCase?.serviceId
    const eventIdentifier = eventId ?? serviceId ?? uuid()
    const days = 30
    const now = new Date()
    const rentalContext = rentalAgreementUrn + ticketNumber
    const vehicleContext = selectedVehicle
      ? vehicleUrn + selectedVehicle!.unitNumber
      : // If the primary vehicle is null ( find the first vehicle in vehicle history which should be the primary vehicle )
        isNil(vehicle)
        ? vehicleUrn + secondaryVehicle.unitNumber
        : vehicleUrn + vehicle!.unitNumber
    const audit = [
      {
        eId: currentUser?.nameID || '',
        createTimestamp: now.toISOString(),
      },
    ]

    const provider = values?.provider?.toLowerCase() ?? (softServices.includes(eventType) ? 'aaa' : undefined)
    const body = {
      audit,
      eventIdentifier,
      eventType,
      rentalContext,
      vehicleContext,
      serviceLocations,
      provider,
      attributes: {
        ...values,
        // This is used for RSA reporting in Splunk - events created by group branch
        rentalGroupBranchId: pickupInfo.groupBranchId,
      },
    }

    const event = await serviceProxy<DynamoEvents>('PUT', '/serviceproxy/events-table/items', {}, body)
    await createContact(eventIdentifier)

    dispatch(addTicketHistoryEvent({ event: event.data }))

    return event
  }

  return createServiceEvent
}
