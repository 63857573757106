import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { alpha3ToAlpha2 } from 'i18n-iso-countries'
import { Paragraph } from 'components/primitives'
import { TransformedDriver } from 'types/ticket'
import { PopupCard } from 'components/PopupManager'
import { formatPhone } from 'utils/formatPhone'
import formatBirthdate from 'utils/formatBirthdate'
import { getCountryOption } from 'hooks/events/caller/useCallerFormValues'

const DriverDetails: React.FC<TransformedDriver> = ({ firstName, lastName, dayOfBirth, monthOfBirth, phone }) => {
  const { t } = useTranslation()

  return (
    <PopupCard heading={t('Driver Details')}>
      <Paragraph spacing="md">
        {firstName} {lastName}
      </Paragraph>
      <DriverPhone phone={phone} />
      <Paragraph spacing="md">{formatBirthdate(monthOfBirth, dayOfBirth)}</Paragraph>
    </PopupCard>
  )
}
const DriverPhone: React.FC<{ phone: TransformedDriver['phone'] }> = ({ phone }) => {
  const { t } = useTranslation()
  const phn = phone && phone[0]
  if (phn) {
    const countryCode = alpha3ToAlpha2(phn.countryCode)
    return (
      <Paragraph spacing="md">
        {countryCode} {formatPhone(phn.number, getCountryOption(phn.countryCode))}
      </Paragraph>
    )
  }

  return <Paragraph>{t('No Phone')}</Paragraph>
}

export default DriverDetails
