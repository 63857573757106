import * as React from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import Button from 'components/Button'
import { BaseButtonProps } from 'components/Button/Button'
import { isRequired } from 'utils/validators'
import { pxToRem } from 'theme/utils'

const Span = styled.span`
  text-transform: none;
  font-size: ${pxToRem(14)};
  font-weight: 400;
`

type Props = {
  label: string
  required?: boolean
  name: string
} & BaseButtonProps

export const ButtonField = ({ required, label, name, ...props }: Props) => {
  const { t } = useTranslation()

  return (
    <Field name={name} validate={required ? isRequired() : undefined}>
      {({ meta: { touched, error } }) => {
        const hasError = Boolean(touched && error)
        return (
          <>
            <Button.Workflow hasError={hasError} {...props}>
              {label}
              {!required && <Span> ({t('labels.optional')})</Span>}
            </Button.Workflow>
          </>
        )
      }}
    </Field>
  )
}

export default ButtonField
