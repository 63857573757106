import { isLocation, isBranch, isParticipant } from './typeChecks'
import { LocationPayload } from 'types/location'
import { Branch } from 'types/branch'
import { Participant } from 'types/global'

export const getIncidentLocationCoordinates = (location?: LocationPayload | null) => ({
  lat: location && location.latitude,
  lon: location && location.longitude,
})

export function getIncidentLocationComparator(location: LocationPayload) {
  return [location.serviceLocationType, location.latitude, location.longitude].filter(Boolean).join('')
}

export function isSameLocation(
  oldServiceLocation: LocationPayload | Branch | Participant,
  newServiceLocation: LocationPayload | Branch | Participant,
) {
  if (isLocation(oldServiceLocation) && isLocation(newServiceLocation))
    return getIncidentLocationComparator(oldServiceLocation) === getIncidentLocationComparator(newServiceLocation)
  else if (isBranch(oldServiceLocation) && isBranch(newServiceLocation))
    return (
      oldServiceLocation.additional_data.group_branch_number === newServiceLocation.additional_data.group_branch_number
    )
  else if (isParticipant(oldServiceLocation) && isParticipant(newServiceLocation))
    return (oldServiceLocation as Participant).assigneeEntityId === (newServiceLocation as Participant).assigneeEntityId
  else return false
}
