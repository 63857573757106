import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  UlyServices,
  UlyExchangeLocation,
  UlyDropoffLocation,
  UlyPickupLocation,
  UlyServiceLocation,
} from 'types/urgently'
import { DateTime } from 'luxon'
import Margin from 'components/Margin'
import { Label, Paragraph } from 'components/primitives'

export const DispatchUlyServiceInfo = ({ service }: { service: UlyServices }) => {
  const { serviceLocation, pickupLocation, dropoffLocation, exchangeLocation } = service
  const timezone = serviceLocation?.timeZone || pickupLocation?.timeZone

  return (
    <>
      <Margin spacing="sm">
        <Paragraph as="b" spacing="sm">
          {service.name}
        </Paragraph>
      </Margin>

      {service.scheduledAt && <ScheduledAt zone={timezone!} scheduledTime={service.scheduledAt} />}
      {serviceLocation && <ServiceLocation serviceLocation={serviceLocation} />}
      {pickupLocation && <PickupLocation pickupLocation={pickupLocation} />}
      {dropoffLocation && <DropoffLocation dropoffLocation={dropoffLocation} />}
      {exchangeLocation && <ExchangeLocation exchangeLocation={exchangeLocation} />}
    </>
  )
}

const ScheduledAt = ({ scheduledTime, zone }: { scheduledTime: string; zone: string }) => {
  const { t } = useTranslation()
  const timeZone = new Date(`${scheduledTime} UTC`)
    .toLocaleTimeString('en-us', { timeZoneName: 'short', timeZone: zone })
    .split(' ')[2]
  const isoString = new Date(`${scheduledTime} UTC`).toISOString()
  const utcString = DateTime.fromISO(isoString, { zone }) as any

  const formattedDateTime = DateTime.fromISO(utcString).setZone(zone).toFormat('LL-dd-yyyy hh:mma')

  return (
    <Margin spacing="sm">
      <Label>{t('case.scheduledAt')}</Label>
      <Paragraph spacing="sm">{`${formattedDateTime} (${timeZone})`}</Paragraph>
    </Margin>
  )
}

const ServiceLocation = ({ serviceLocation }: { serviceLocation: UlyServiceLocation }) => {
  const { t } = useTranslation()
  return (
    <Margin spacing="sm">
      <Label>{t('case.serviceLocation')}</Label>
      {serviceLocation.locationName && (
        <>
          <Label>{t('case.locationName')}</Label>
          <Paragraph spacing="sm">{serviceLocation.locationName}</Paragraph>
        </>
      )}
      <Paragraph spacing="sm">{serviceLocation.address}</Paragraph>
      <Label>{t('case.coordinates')}</Label>
      <Paragraph spacing="sm">{`${serviceLocation.latitude}, ${serviceLocation.longitude}`}</Paragraph>
    </Margin>
  )
}

const PickupLocation = ({ pickupLocation }: { pickupLocation: UlyPickupLocation }) => {
  const { t } = useTranslation()
  return (
    <Margin spacing="sm">
      <Label>{t('case.pickupLocation')}</Label>
      <Paragraph spacing="sm">{pickupLocation.address}</Paragraph>
      {pickupLocation.locationName && (
        <>
          <Label>{t('case.pickupLocationName')}</Label>
          <Paragraph spacing="sm">{pickupLocation.locationName}</Paragraph>
        </>
      )}
      <Label>{t('case.coordinates')}</Label>
      <Paragraph spacing="sm">{`${pickupLocation.latitude}, ${pickupLocation.longitude}`}</Paragraph>
      {pickupLocation.impoundFacility && (
        <Margin spacing="sm">
          <Label>{t('case.impoundFacility')}</Label>
          <Paragraph spacing="sm">{pickupLocation.impoundFacility.businessName}</Paragraph>
          <Label>{t('case.phone')}</Label>
          <Paragraph spacing="sm">{pickupLocation.impoundFacility.phone}</Paragraph>
          <Label>{t('case.fax')}</Label>
          <Paragraph spacing="sm">{pickupLocation.impoundFacility.fax}</Paragraph>
        </Margin>
      )}
    </Margin>
  )
}
const DropoffLocation = ({ dropoffLocation }: { dropoffLocation: UlyDropoffLocation }) => {
  const { t } = useTranslation()
  return (
    <Margin spacing="sm">
      <Label>{t('case.dropoffLocation')}</Label>
      <Paragraph spacing="sm">{dropoffLocation.address}</Paragraph>
      {dropoffLocation.locationName && (
        <>
          <Label>{t('case.dropoffLocationName')}</Label>
          <Paragraph spacing="sm">{dropoffLocation.locationName}</Paragraph>
        </>
      )}
    </Margin>
  )
}

const ExchangeLocation = ({ exchangeLocation }: { exchangeLocation: UlyExchangeLocation }) => {
  const { t } = useTranslation()
  return (
    <Margin spacing="sm">
      <Label>{t('case.exchangeLocation')}</Label>
      <Paragraph spacing="sm">{exchangeLocation.address}</Paragraph>
      {exchangeLocation.locationName && (
        <>
          <Label>{t('case.exchangeLocationName')}</Label>
          <Paragraph spacing="sm">{exchangeLocation.locationName}</Paragraph>
        </>
      )}
    </Margin>
  )
}

export default DispatchUlyServiceInfo
