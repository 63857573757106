export function initialQuickNotesState() {
  return {
    callerName: null,
    number: null,
    countryCode: {
      label: 'United States +1',
      value: 'us',
    },
    notes: null,
  }
}
