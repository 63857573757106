import { Branch, Participant } from 'types/global'
import { TowExchange } from 'types/callstore'
import useExchangeEmail, { ExchangeEmailValues } from 'hooks/tickets/useExchangeEmail'
import { FormattedCaller, formatCaller } from 'components/fields/Submit'
import { transformTowLocation } from 'forms/CallForm/EscalateForm/utils'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { LocationPayload, LocationState } from 'types/location'
import { EventTypes } from 'types/events'
import { useCallEvent } from 'hooks/events'

function getDisabledVehicleLocation(serviceLocations: LocationState) {
  const vehicleLocation = serviceLocations.vehicleLocation
  const vehicleDropoffLocation = serviceLocations.vehicleDestinationLocation as Participant & LocationPayload

  let disabledVehicleLocation = vehicleLocation && vehicleLocation.addressDescription!
  if (vehicleDropoffLocation) {
    const { name } = vehicleDropoffLocation.entity
    const { addressLine1, city, administrativeArea, postalCode } = vehicleDropoffLocation.entity.address!
    disabledVehicleLocation = `${name}<br/>${addressLine1}<br />${city}, ${administrativeArea} ${postalCode}`
  }

  return disabledVehicleLocation
}

export const useTowExchangeEmail = () => {
  const sendEmail = useExchangeEmail()
  return async (towExchange: TowExchange & Pick<ExchangeEmailValues, 'modified'>, caller: FormattedCaller) => {
    try {
      const branch = towExchange.serviceLocations.exchangeLocation || towExchange.branch
      const disabledVehicleLocation = getDisabledVehicleLocation(towExchange.serviceLocations)

      const { towDirection } = towExchange
      const exchange = {
        ...towExchange,
        disabledVehicleLocation,
        methodOfDelivery: towDirection === 'branch' ? 'Tow' : 'Ride-Along',
        branch,
      }

      await sendEmail(
        {
          ...exchange,
          towLocation: transformTowLocation(exchange.towLocation!),
        } as unknown as ExchangeEmailValues,
        caller,
      )
    } catch (e) {
      console.error(e)
    }
  }
}

export const useTowExchangeUpdateEmail = () => {
  const sendTowExchangeEmail = useTowExchangeEmail()
  const forms = useSelector(selectFormsState) as any

  const event = useCallEvent(EventTypes.EXCHANGE)
  const { serviceLocations, branch } = event?.event?.attributes || {}
  return async (values: TowExchange) => {
    const caller = formatCaller(forms.caller.getState().values)

    const { branch: branchFormVal, serviceLocations: serviceLocationsFormVal } = values
    const oldBranch = branch || (serviceLocations.exchangeLocation as LocationPayload | Branch)
    const newBranch = branchFormVal || serviceLocationsFormVal.exchangeLocation

    // If there is no branch, then this is not an exchange
    if (!newBranch || !oldBranch) return

    const oldDisabledVehicleLocation = getDisabledVehicleLocation(serviceLocations)
    const newDisabledVehicleLocation = getDisabledVehicleLocation(serviceLocationsFormVal)

    // Send new exchange email if branch changed
    if (
      (oldBranch as Branch).additional_data.group_branch_number !==
      (newBranch as Branch).additional_data.group_branch_number
    )
      await sendTowExchangeEmail(values, caller)
    // Send modified exchange email to original branch if certain properties changed
    else if (oldDisabledVehicleLocation !== newDisabledVehicleLocation)
      await sendTowExchangeEmail({ ...values, modified: true }, caller)
  }
}

export default useTowExchangeEmail
