import * as React from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import styled from 'styled-components/macro'
import { Checked, Unchecked } from './Checkbox'
import { Input } from 'components/primitives'

const StyledLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`

type InputProps = React.ComponentProps<typeof Input> &
  FieldRenderProps<HTMLInputElement> & { label?: string }

const RadioCheckboxComponent: React.FC<InputProps> = ({ input, label }) => (
  <StyledLabel>
    {input.checked ? <Checked /> : <Unchecked />}
    {label}
    <Input type="radio" {...(input as any)} />
  </StyledLabel>
)

type RadioCheckboxProps = {
  name: string
  label?: string
  value: string | boolean
}

export const RadioCheckbox: React.FC<RadioCheckboxProps> = props => (
  <Field type="radio" component={RadioCheckboxComponent as any} {...props} />
)

export default RadioCheckbox
