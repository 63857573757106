import * as React from 'react'
import { GoogleFeatureGroup } from 'components/GoogleMaps'
import { useHeroLayers } from '../Layers/HeroLayersProvider'
import BranchesLayer from './BranchesLayer'
import HotelsLayer from './HotelsLayer'
import GoogleLocationsLayer from './GoogleLocationsLayer'

export const InteractiveLayers: React.FC = () => {
  const { layers } = useHeroLayers()
  return (
    <>
      <GoogleFeatureGroup>
        <GoogleLocationsLayer />
      </GoogleFeatureGroup>
      {layers
        .filter(({ visible }) => visible)
        .map((place) => {
          if (place.markerType === 'BRANCHES') {
            return <BranchesLayer key={place.id} {...place} />
          } else if (place.markerType === 'HOTELS') {
            return <HotelsLayer key={place.id} {...place} />
          }
        })}
    </>
  )
}

export default InteractiveLayers
