import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FormApi } from 'final-form'
import * as Yup from 'yup'
import styled from 'styled-components/macro'
import { palette, theme } from 'styled-tools'
import { Flex, Heading } from 'components/primitives'
import Button from 'components/Button'
import { Form } from 'components/FormManager'
import Margin from 'components/Margin'
import { useReduxCallStoreActions } from 'hooks/events'
import { calypso } from 'theme/colors'
import WorkflowItem from './WorkflowItem'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

declare module 'types/form' {
  export interface Forms {
    Workflow: FormApi
  }
}

const Background = styled.div`
  background: ${palette('white')};
  padding: ${theme('spaces.1')} ${theme('spaces.2')};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.13);
  z-index: 2;
`

export function Workflow() {
  const { t } = useTranslation()
  const { workflow, additionalService } = useSelector(selectFormsState) as any
  const { update } = useReduxCallStoreActions()
  const [edit, setEdit] = React.useState<boolean>(false)

  // Remove additionalService if workflow is deleted
  React.useEffect(() => {
    if ((!workflow || workflow.length === 0) && additionalService) update({ additionalService: undefined })
  }, [additionalService, update, workflow])

  if (!workflow || workflow.length === 0) return null

  return (
    <Form name="Workflow" schema={schema}>
      <Background>
        <Margin spacing="sm">
          <Flex style={{ justifyContent: 'space-between' }}>
            <Heading as="h5">{t('workflow.heading')}</Heading>
            <Button.Link style={{ padding: 0, color: calypso }} onClick={() => setEdit(!edit)}>
              {edit ? t('workflow.close') : t('workflow.edit')}
            </Button.Link>
          </Flex>
        </Margin>
        <Flex>
          {workflow.map((type) => (
            <WorkflowItem key={type} callType={type} edit={edit} setEdit={setEdit} />
          ))}
        </Flex>
      </Background>
    </Form>
  )
}

const schema = Yup.object()

export default Workflow
