import * as React from 'react'
import Input, { InputProps } from 'components/Input'

export const DisabledVehicleSelect: React.FC<InputProps> = props => (
  <Input.Select placeholder="--" options={disabledOptions} isClearable {...props} />
)
export default DisabledVehicleSelect

const disabledOptions = [
  { label: 'Towed', value: 'towed' },
  { label: 'With Customer', value: 'withCustomer' },
  { label: 'Group Vehicle Recovery', value: 'groupVehicleRecovery' },
]
