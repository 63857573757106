import * as React from 'react'
import Input, { InputProps } from 'components/Input'
import { useTranslation } from 'react-i18next'

export enum KeyLocation {
  WITH_CUSTOMER = 'customer',
  OTHER = 'other',
}

export function KeySelect(props: InputProps) {
  const { t } = useTranslation()
  const options = Object.values(KeyLocation).map(location => ({
    label: t(`carKeySelect.${location}`),
    value: location,
  }))
  return <Input.Select placeholder="--" options={options} isClearable {...props} />
}

export default KeySelect
