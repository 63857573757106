import * as React from 'react'
import { Field as FinalFormField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { CountryCode, isSupportedCountry } from 'libphonenumber-js'
import { getExampleNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import { Flex, Field } from 'components/primitives'
import Margin from 'components/Margin'
import { PhoneNumber } from './FinalFormInputs'
import CountryCodeSelect from 'components/Select/CountryCodeSelect'
import { Maybe, SelectOption } from 'types/global'
import { parsePhoneNumber } from 'redux/ticket/ticketHelpers'
import { isRequired } from 'utils/validators'

type Props = {
  label: string
  smallLabel?: string
  countryCodeName?: string
  numberName?: string
  defaultPhoneNumber?: string
  defaultPhoneCountryCode?: Maybe<SelectOption<string>>
}

export function Phone({
  countryCodeName = 'countryCode',
  numberName = 'number',
  label,
  smallLabel,
  defaultPhoneNumber = '',
  defaultPhoneCountryCode,
}: Props) {
  const { t } = useTranslation()

  let defaultCountry = defaultPhoneCountryCode?.value.toUpperCase() as CountryCode
  if (!isSupportedCountry(defaultCountry)) defaultCountry = 'US'
  const exampleNumber = getExampleNumber(defaultCountry, examples)
  const defaultPhone = parsePhoneNumber(defaultPhoneNumber, defaultCountry)

  return (
    <Margin use={Flex} spacing="md" style={{ flex: 1 }}>
      <Field style={{ flex: 4 }}>
        <CountryCodeSelect
          label={t('Country')}
          name={countryCodeName}
          spacing="none"
          defaultValue={defaultPhoneCountryCode as any}
        />
      </Field>
      <FinalFormField name={countryCodeName} subscription={{ value: true }}>
        {({ input: { value: option } }) => {
          let selectedCountry = (option.value ? option.value.toUpperCase() : option?.toUpperCase()) as CountryCode

          return (
            <Field style={{ flex: 5 }}>
              <div style={{ fontSize: '0.72rem' }}>{smallLabel}</div>
              <PhoneNumber
                label={label}
                name={numberName}
                spacing="none"
                exampleNumber={exampleNumber}
                defaultPhone={defaultPhone}
                selectedCountry={isSupportedCountry(selectedCountry) ? selectedCountry : 'US'}
                validate={isRequired(t('Contact number required'))}
              />
            </Field>
          )
        }}
      </FinalFormField>
    </Margin>
  )
}

export default Phone
