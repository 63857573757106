import * as React from 'react'
import Card from 'components/Card'
import { useMarkerContainerPoint, useMarker } from 'components/GoogleMaps/Marker'
import { useSelector } from 'react-redux'
import { ReduxState, selectSidebarState } from 'redux/appStore'
import { useGoogleMaps } from 'hooks/map'

export const Tooltip: React.FC<{ xOffset?: number; sticky?: boolean; id?: string; children: React.ReactNode }> = ({
  children,
  xOffset = 0,
  sticky,
  id,
}) => {
  const { map } = useGoogleMaps()
  const marker = useMarker()
  const isLeftSidebarOpen = useSelector((state: ReduxState) => selectSidebarState(state)('left'))

  const containerPoint = useMarkerContainerPoint()
  const [hoverMarker, setHoverMarker] = React.useState<boolean>(false)
  const [hoverTooltip, setHoverTooltip] = React.useState<boolean>(false)
  const [markerTimeout, setMarkerTimeout] = React.useState<number | null>(null)
  const [animating, setAnimating] = React.useState<boolean>(false)
  const toolTipElement = document.getElementById('connectedTooltip')

  // update cardWidth variable on hover for tooltip flipping when tooltip touches right sidebar
  const [cardWidth, setCardWidth] = React.useState<number>(0)
  const cardRef = React.useCallback(
    (node) => {
      if (node !== null) {
        const width = node.getBoundingClientRect().width

        if (width > 0) {
          setCardWidth(width)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoverMarker, hoverTooltip],
  )

  React.useEffect(() => {
    if (map) {
      const movestart = () => setAnimating(true)
      const moveend = () => setAnimating(false)
      const moveStartListener = map.addListener('dragstart', movestart)
      const moveStopListener = map.addListener('dragend', moveend)

      return () => {
        google.maps.event.removeListener(moveStartListener)
        google.maps.event.removeListener(moveStopListener)
      }
    }
  }, [map])

  React.useEffect(() => {
    const hide = () => {
      setHoverMarker(false)
    }
    const onMarkerMouseOut = () => {
      setMarkerTimeout(window.setTimeout(hide, 250))
    }
    const onMarkerMouseOver = () => {
      if (markerTimeout) window.clearTimeout(markerTimeout)
      if (!hoverMarker) setHoverMarker(true)
    }

    let mouseOverListener: google.maps.MapsEventListener | undefined = undefined
    let mouseOutListener: google.maps.MapsEventListener | undefined = undefined
    let clickListener: google.maps.MapsEventListener | undefined = undefined
    if (marker) {
      mouseOverListener = marker.addListener('mouseover', onMarkerMouseOver)
      mouseOutListener = marker.addListener('mouseout', onMarkerMouseOut)
      clickListener = marker.addListener('click', hide)
    }
    return () => {
      if (marker) {
        mouseOverListener && google.maps.event.removeListener(mouseOverListener)
        mouseOutListener && google.maps.event.removeListener(mouseOutListener)
        clickListener && google.maps.event.removeListener(clickListener)
      }
    }
  }, [hoverMarker, marker, markerTimeout])

  // Pseudo Effect of having a "sticky" tooltip but have it being hidden
  React.useEffect(() => {
    if (toolTipElement) {
      const { x: elementX } = toolTipElement.getBoundingClientRect()

      // Is Left sidebar open -- Hide if it overlaps
      if (isLeftSidebarOpen) {
        if (toolTipElement.style.display !== 'none' && elementX < 412) {
          toolTipElement.style.display = 'none'
        } else {
          toolTipElement.style.display = 'inherit'
        }
      } else {
        // If Leftsidebar is not open -- Show
        toolTipElement.style.display = 'inherit'
      }
    }
  }, [isLeftSidebarOpen, toolTipElement])

  if (!containerPoint || animating) return null

  const { x, y } = containerPoint

  const mapContainer = map?.getDiv()
  const mapContainerWidth = mapContainer?.clientWidth !== undefined ? mapContainer?.clientWidth : 0

  const getCardX = (xOffset: number, x: number, mapContainerWidth: number) => {
    let cardX = !!id ? x + xOffset + 65 : x + xOffset + 42
    // if card touches or overlaps right sidebar, display on other side of marker
    if (cardX + cardWidth > mapContainerWidth + xOffset) {
      cardX -= cardWidth + 85
    }

    return cardX
  }

  const cardX = getCardX(xOffset, x, mapContainerWidth)
  const cardY = !!id ? y - 25 : y - 105

  return (
    <Card
      id={id}
      ref={cardRef}
      style={{
        display: id !== 'connectedTooltip' ? (sticky || hoverMarker || hoverTooltip ? 'inherit' : 'none') : 'none',
        position: 'absolute',
        top: cardY,
        left: cardX,
        zIndex: 9999,
      }}
      onMouseOver={() => {
        setHoverTooltip(true)
      }}
      onMouseOut={() => {
        setHoverTooltip(false)
      }}
    >
      {children}
    </Card>
  )
}

export default Tooltip
