import { EventTypes } from 'types/events'
import Button from 'components/Button'
import { Flex, Heading, Paragraph } from 'components/primitives'
import { TransferIcon } from 'icons'
import * as React from 'react'
import { FormSpy } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { calypso } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import { TransferStatuses, useTransferStatus } from '../TransferStatus'
import { BranchInfoFragment } from 'types/branch'

type Props = {
  branch: BranchInfoFragment
}

export const BranchDetails: React.FC<Props> = ({ branch }) => {
  const { t } = useTranslation()
  const [, setTransferStatus] = useTransferStatus()
  const { locationNameTranslation, addressLines, formattedCityStateZip, formattedPhone, groupBranchNumber } = branch

  return (
    <div>
      <Heading as="h5">{`${locationNameTranslation} - ${groupBranchNumber}`}</Heading>
      {addressLines && addressLines.map((line, i) => <Paragraph key={i}>{line}</Paragraph>)}
      <Paragraph>{formattedCityStateZip}</Paragraph>
      <Paragraph style={{ color: calypso, marginTop: pxToRem(8) }}>{formattedPhone}</Paragraph>
      <FormSpy>
        {({ form: { reset } }) => (
          <Flex>
            <Button.Link
              style={{
                justifyContent: 'start',
                padding: 0,
                color: calypso,
                marginTop: pxToRem(8),
              }}
              iconAfter={TransferIcon}
              iconColor={calypso}
              color={calypso}
              onClick={() => {
                setTransferStatus(TransferStatuses.SUCCESS)
                reset()
              }}
            >
              {t('transfer')}
            </Button.Link>
          </Flex>
        )}
      </FormSpy>
    </div>
  )
}

export default BranchDetails
