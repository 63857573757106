import * as React from 'react'
import { AaaCommentType } from 'types/aaa'
import { SoftService, Tow, TowExchange } from 'types/callstore'
import { addRideAlong, locationToAAALocation } from 'utils/aaa'
import { StandardServiceCall } from 'types/aaa'
import { useServiceProxy } from 'hooks/kong'
import { useSelector } from 'react-redux'
import { selectAllEvents } from 'redux/appStore'
import { isEmpty } from 'lodash-es'
import { IncomingStandardServiceCall } from 'types/api'
import { deepMapSanitize } from 'utils/deepMap'
import { AxiosResponse } from 'axios'

export function useAAADispatchQuery(callKey?: string | null) {
  const serviceProxy = useServiceProxy()
  const allEvents = useSelector(selectAllEvents)
  const [error, setError] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<StandardServiceCall | null>(null)

  const query = React.useCallback(
    async (shouldFireQuery: boolean) => {
      if (shouldFireQuery) {
        try {
          setLoading(true)
          setError(null)
          const url = `/serviceproxy/aaa/dispatch/${callKey}`
          const inc = await serviceProxy<IncomingStandardServiceCall>('get', url)
          const response = { ...inc, data: deepMapSanitize(inc.data) } as AxiosResponse<StandardServiceCall>

          if (!response) {
            setLoading(false)
            throw new Error('No Response found')
          }

          if (!response.status || response.status !== 200) {
            setLoading(false)
            throw new Error(`Failed call ${response.status}`)
          }

          if (response?.data) {
            setData(response.data)
            setLoading(false)
          }
        } catch (e) {
          console.error(e)
          setLoading(false)
          setError('Unable to lookup AAA dispatch')
        }
      }
    },
    [callKey, serviceProxy],
  )

  React.useEffect(() => {
    let shouldFireQuery = true

    async function fetchAAAData() {
      const reduxCallKeyEvent = Object.entries(allEvents).filter(([key, value]) => {
        const event = value?.event
        if (event && event.attributes && event.attributes.dispatch) {
          const dispatch = event.attributes.dispatch[0] as StandardServiceCall
          if (dispatch.callKey === callKey) return { ...dispatch }
        }
        return null
      })[0]

      if (error || loading) return

      if (!isEmpty(reduxCallKeyEvent)) {
        const reduxEvent = reduxCallKeyEvent[1]
        reduxEvent && setData(reduxEvent?.event?.attributes.dispatch[0])
        shouldFireQuery = false
        return
      }

      if (callKey && data === null) {
        await query(shouldFireQuery)
        shouldFireQuery = false
      }
    }

    shouldFireQuery && fetchAAAData()
  }, [callKey, data, query, error, loading, allEvents])

  return { data, loading, error, refetch: query }
}

function useAAADispatch(callKey?: string | null) {
  const { data: standardServiceCall, loading, refetch, error } = useAAADispatchQuery(callKey)
  const serviceProxy = useServiceProxy()

  const handleUpdate = async (values: SoftService | Tow | TowExchange) => {
    if (!standardServiceCall || !callKey) throw new Error()
    const { serviceLocations } = values
    let service = {
      ...standardServiceCall,
      service: {
        ...standardServiceCall.service,
        serviceLocations: Object.values(serviceLocations).map(locationToAAALocation),
      },
      ...(values.notes && {
        comments: [
          {
            commentType: AaaCommentType.NORMAL,
            text: values.notes,
          },
        ],
      }),
    }

    // @ts-ignore
    service = addRideAlong(service, values)

    try {
      return await serviceProxy<StandardServiceCall>('post', `/serviceproxy/aaa/dispatch/${callKey}`, {}, { callKey, ...service })
    } catch (e) {
      throw new Error('Dispatch failed to update')
    }
  }

  return {
    dispatch: standardServiceCall,
    handleUpdate,
    loading,
    refetch,
    error,
  }
}

export default useAAADispatch
