import * as React from 'react'

type PopupManagerContextType = {
  popup: React.ReactNode | null
  open: (popup: React.ReactNode) => void
  close: () => void
}

const PopupManagerContext = React.createContext<PopupManagerContextType | null>(null)

const PopupManager = ({ children }) => {
  const [popup, setPopup] = React.useState<React.ReactNode | null>(null)

  const open = (o: React.ReactNode) => setPopup(o)
  const close = () => setPopup(null)

  const popupManager = {
    open,
    close,
    popup,
  }

  return <PopupManagerContext.Provider value={popupManager}>{children}</PopupManagerContext.Provider>
}

export function usePopupManager() {
  const popupManager = React.useContext(PopupManagerContext)

  if (!popupManager) {
    throw new Error('Missing popup manager context')
  }

  return popupManager
}

export default PopupManager
