import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import Margin from 'components/Margin'
import { Step } from 'components/Step'
import { ConfirmedVehicleLocation } from 'components/Dispatch/ConfirmedLocations'
import Location from 'components/Input/Location'
import { ServiceLocationType } from 'types/location'

export default function SoftServiceUpdateSteps() {
  const { t } = useTranslation()
  const controlsContext = Ariakit.useCompositeContext()

  return (
    <>
      <>
        <Step step="1">
          <Location.Vehicle name="serviceLocations.vehicleLocation" serviceLocationType={ServiceLocationType.VEHICLE} edit />
          <Margin spacing="sm">
            <Button.Primary onClick={() => controlsContext?.move(controlsContext?.next())}>
              {t('Confirm New Vehicle Disablement Location')}
            </Button.Primary>
          </Margin>
        </Step>
        <Step step="2">
          <ConfirmedVehicleLocation />
        </Step>
      </>
    </>
  )
}
