import { Branch } from './branch'
import { Participant } from './global'

export type AnyObject = { [key: string]: any }

/* Server currently only accepts integers for locType on Location */
export enum LocationType {
  CALLER,
  VEHICLE,
  VEHICLEDESTINATION,
  BRANCH,
  EXCHANGE,
  CALLERDESTINATION,
}

export enum ServiceLocationType {
  RENTAL_PICKUP = 'RENTAL_PICKUP',
  BREAKDOWN = 'BREAKDOWN',
  TOW_DESTINATION = 'TOW_DESTINATION',
  CALLER = 'CALLER',
  VEHICLE = 'VEHICLE',
  VEHICLEDESTINATION = 'VEHICLEDESTINATION',
  BRANCH = 'BRANCH',
  EXCHANGE = 'EXCHANGE',
  CALLERDESTINATION = 'CALLERDESTINATION',
}

export type LocationState = {
  vehicleLocation?: LocationPayload | null
  vehicleDestinationLocation?: (LocationPayload & Participant) | null
  callerLocation?: LocationPayload | null
  callerDestinationLocation?: LocationPayload | null
  exchangeLocation?: (LocationPayload & Branch) | null
}

export type LocationPayload = {
  address: LocationAddress
  addressDescription?: string | null
  latitude: number
  longitude: number
  serviceLocationType: string
}

export type LocationAddress = {
  city: string
  country: string
  countryShortCode: string
  postalCode: string
  state: string
  street: string
  streetNumber: string
}

export type Locations = keyof LocationState
