import * as React from 'react'
import { Brands } from 'types/global'
import { camelCase, mapKeys, sortBy } from 'lodash-es'
import { useLocalTimeZone } from 'hooks/utils'
import { getIncidentLocationCoordinates } from 'utils/location'
import useSpatialSearch from 'hooks/solr/useSpatialSearch'
import { getBrandBranches, generateTimestamp, emptyCarClasses, ResultState, defaultState } from './utils'
import { LocationPayload } from 'types/location'
import { useServiceProxy } from 'hooks/kong'
import { ExchangeSearchQuery } from './ExchangeTypes'

const useExchangeSearch = (searchLocation?: LocationPayload) => {
  // @ts-ignore
  const [result, setResult] = React.useState<ResultState>(defaultState)
  const { lat, lon } = getIncidentLocationCoordinates(searchLocation)
  const { timezone: localTimeZone } = useLocalTimeZone({ lat, lon })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const car_classes = [] as any
  const { data } = useSpatialSearch(lat, lon, 'TRIBRAND')
  const serviceProxy = useServiceProxy()

  const fetch = React.useCallback(async () => {
    setResult({ ...defaultState, loading: true } as any)
    if (!data || !data.result || data.result?.length === 0) {
      setResult({ ...defaultState, error: 'no locations', loading: false } as any)
      return { ...result, fetch, reset }
    }
    const alamoBranches = getBrandBranches(data.result, Brands.ALAMO)
    const nationalBranches = getBrandBranches(data.result, Brands.NATIONAL)
    const enterpriseBranches = getBrandBranches(data.result, Brands.ENTERPRISE)
    const pickupTimestamp = generateTimestamp(localTimeZone, 'pickup')
    const returnTimestamp = generateTimestamp(localTimeZone, 'return')
    const brandQueriesToCall: any[] = []

    /**
     * Check to see if there are any branches from Spatial Search
     * If Yes: get car class availabilities per brand
     */
    if (alamoBranches.length > 0) {
      brandQueriesToCall.push(getCarClassAvail(alamoBranches))
    }
    if (nationalBranches.length > 0) {
      brandQueriesToCall.push(getCarClassAvail(nationalBranches))
    }
    if (enterpriseBranches.length > 0) {
      brandQueriesToCall.push(getCarClassAvail(enterpriseBranches))
    }
    async function getCarClassAvail(branches: string | any[]) {
      let { data } = await serviceProxy<ExchangeSearchQuery>(
        'post',
        `/serviceproxy/availability`,
        {
          'Content-Type': 'application/json',
        },
        {
          location_pairs: branches.slice(0, 40), // max 40 location pairs
          car_classes,
          pickup_time: pickupTimestamp,
          return_time: returnTimestamp,
        },
      )
      const { car_class_availability } = data
      const carAvailData = {
        exchangeSearch: {
          carClassAvailability: car_class_availability.map((cca) => {
            const location = mapKeys(cca, (v, k) => camelCase(k))
            const branch = (branches as any).find((branch) => branch.pickup_location_id === location.pickupLocationId)
            return {
              ...location,
              ...branch,
              // @ts-ignore
              carClasses: location.carClasses.map((cc) => mapKeys(cc, (v, k) => camelCase(k))),
            }
          }),
        },
      }
      return carAvailData
    }

    const results = await Promise.all(brandQueriesToCall.map((p) => p.catch((e) => e)))
    const [alamo = emptyCarClasses, national = emptyCarClasses, enterprise = emptyCarClasses] = results.filter(
      (result) => !(result instanceof Error),
    )

    const unsortedCarClassAvailability = [
      ...alamo?.exchangeSearch?.carClassAvailability,
      ...national?.exchangeSearch?.carClassAvailability,
      ...enterprise?.exchangeSearch?.carClassAvailability,
    ]
    const sortedBranchesByDistance = sortBy(data.result, (branch) => branch.additional_data.calculated_distance).map(
      (branch) => branch.id,
    )

    const carClassAvailability = sortBy(unsortedCarClassAvailability, (branch) =>
      sortedBranchesByDistance.indexOf((branch as any).pickupLocationId),
    ).slice(0, 40)

    const triBrandData = { exchangeSearch: { carClassAvailability } }

    if (carClassAvailability.length > 0) {
      setResult({ ...defaultState, data: triBrandData, loading: false } as any)
    } else {
      setResult({ ...defaultState, error: 'no data', loading: false } as any)
    }
  }, [car_classes, serviceProxy, localTimeZone, data])

  const reset = () => setResult(defaultState as any)

  return { ...result, fetch, reset }
}

export default useExchangeSearch
