import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import * as Ariakit from '@ariakit/react'
import { Label } from 'components/primitives'
import Button from 'components/Button'
import Margin from 'components/Margin'
import { Step } from 'components/Step'
import Input from 'components/Input'
import ServiceLocationDisplay from 'components/Dispatch/ServiceLocationDisplay'
import { ConfirmedVehicleLocation, ConfirmedParticipantLocation } from 'components/Dispatch/ConfirmedLocations'
import Location from 'components/Input/Location'
import SmartAssignment from 'components/SmartAssignment'

import { DynamoEvents, EventTypes } from 'types/events'
import { ICurrentEvent } from 'types/events'
import { useCallEvent } from 'hooks/events'
import { ServiceLocationType } from 'types/location'

type Props = {
  dispatchEvent?: DynamoEvents
}

export default function NoExchangeTowSteps({ dispatchEvent }: Props) {
  const { t } = useTranslation()
  const controlsContext = Ariakit.useCompositeContext()
  const form = useForm()

  const towEvent = useCallEvent(EventTypes.TOW) as ICurrentEvent
  const event = dispatchEvent || towEvent.event

  if (!event || !event.attributes) return null

  const { serviceLocations, vehicle, network } = event?.attributes

  return (
    <>
      <>
        <Step step="1">
          <Location.Vehicle name="serviceLocations.vehicleLocation" serviceLocationType={ServiceLocationType.VEHICLE} edit />
          <Margin spacing="sm">
            <Button.Primary onClick={() => controlsContext?.move(controlsContext?.next())}>
              {t('Confirm New Vehicle Disablement Location')}
            </Button.Primary>
          </Margin>
          <ServiceLocationDisplay location={serviceLocations.vehicleDestinationLocation} network={network} />
        </Step>
        <Step step="2">
          <Margin spacing={'md'}>
            <ConfirmedVehicleLocation />
            <Input.Hidden name="vehicle" defaultValue={vehicle as any} />
            <Label>{t('labels.disabledVehicleDropoff')}</Label>
            <SmartAssignment
              onSetAssignment={(participant) => {
                form.change('serviceLocations.vehicleDestinationLocation', {
                  ...participant,
                  serviceLocationType: ServiceLocationType.VEHICLEDESTINATION,
                })
                controlsContext?.move(controlsContext?.next())
              }}
            />
          </Margin>
        </Step>
        <Step step="3">
          <ConfirmedVehicleLocation />
          <ConfirmedParticipantLocation />
        </Step>
      </>
    </>
  )
}
