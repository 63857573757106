import * as React from 'react'
import { Exchange, Tow, TowExchange } from 'types/callstore'
import { createCancelDialogContext } from 'components/Dispatch/Cancel/useCancelServiceDialog'
import { NoExchangeEvent } from './NoExchange'
import { TowExchangeEvent } from './TowExchange'
import ReverseTowExchangeEvent from './TowEvents/ReverseTowExchangeEvent'
import TowDisabledVehicleEvent from './TowEvents/TowDisabledVehicleEvent'
import { DynamoEvents } from 'types/events'
import { isEmpty, isNil } from 'lodash'

export type TowFlow = (Tow | TowExchange) & { exchange: boolean }

export const cancelDialogContext = createCancelDialogContext()

export const TowEvent = (event: DynamoEvents) => {
  const tow = event.attributes as TowFlow

  // NoExchangeEvent needs the entire event passed through the prop
  let content = <NoExchangeEvent {...(event as DynamoEvents)} />

  // isTwoWayTow for legacy events
  if (isTwoWayTow(tow)) {
    content = (
      <>
        <ReverseTowExchangeEvent {...(event as DynamoEvents)} />
        <TowDisabledVehicleEvent {...(event as DynamoEvents)} />
      </>
    )
  }
  if (tow.exchange) content = <TowExchangeEvent {...(event as DynamoEvents)} />

  return <cancelDialogContext.Provider>{content}</cancelDialogContext.Provider>
}

const isTwoWayTow = (tow: Tow | (Exchange & Tow)) =>
  isNil(tow) || isNil(tow?.serviceLocations)
    ? false
    : !isEmpty(tow?.serviceLocations?.exchangeLocation) && !isEmpty(tow?.serviceLocations?.vehicleDestinationLocation)

export default TowEvent
