import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Label, Paragraph } from 'components/primitives'
import { LawEnforcementLog } from 'types/events'

const ParagraphWithLabel: React.FC<{ label: string }> = ({ label, children }) => (
  <>
    <Label>{label}</Label>
    <Paragraph spacing="sm">{children}</Paragraph>
  </>
)

export const LawEvent: React.FC<LawEnforcementLog> = ({
  isActionable,
  name,
  title,
  badge,
  mobilePhone,
  deskPhone,
  deskPhoneExt,
  email,
  department,
  cityAndCounty,
  stateOrProvince,
  notes,
  vehicle,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Heading as="h5" spacing="md">
        {t('Law Enforcement Call')}
        {isActionable && ' - ACTIONABLE'}
      </Heading>
      <ParagraphWithLabel label={t('Officer Name')}>{name}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('Officer Title')}>{title}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('Badge Number')}>{badge}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('Officer Mobile Phone')}>{mobilePhone}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('Officer Desk Phone')}>
        {deskPhone || t('N/A')} {deskPhoneExt && <>Ext. {deskPhoneExt}</>}
      </ParagraphWithLabel>
      <ParagraphWithLabel label={t('Officer Email')}>{email}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('Department / Agency Name')}>{department}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('City and Country of Agency')}>{cityAndCounty}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('State or Province')}>{stateOrProvince}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('Vehicle')}>{vehicle.label}</ParagraphWithLabel>
      <ParagraphWithLabel label={t('Law Notes')}>{notes}</ParagraphWithLabel>
    </>
  )
}

export default LawEvent
