import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import styled from 'styled-components/macro'
import { Label, Input } from 'components/primitives'
import CallTypeIcon from './CallTypeIcon'
import { CallTypes } from 'types/callstore'

const CallTypeLabel = styled(Label as any)`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-transform: none;
  padding: 0;

  & > * {
    cursor: pointer;
  }
`

const CallTypeRadio: React.FC<
  FieldRenderProps<HTMLInputElement> & {
    disabled?: boolean
    onMouseEnter?: () => void
  }
> = ({ input, onMouseEnter, ...rest }) => (
  <CallTypeLabel onMouseEnter={onMouseEnter}>
    <CallTypeIcon callType={input.value as unknown as CallTypes} checked={input.checked} disabled={rest.disabled} />
    <Input type="radio" {...(input as any)} {...rest} />
    {input.value}
  </CallTypeLabel>
)

export default CallTypeRadio
