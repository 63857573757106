import * as React from 'react'
import Divider from 'components/Divider'
import { Heading, Paragraph } from 'components/primitives'
import { Participant, AssignmentNetworks } from 'types/global'
import { ParticipantTooltipHours } from './ParticipantTooltipHours'

type Props = {
  participant: Participant
  network: AssignmentNetworks
}

export const NetworkNames: { [key: string]: string } = {
  [AssignmentNetworks.SERVICE]: 'Service Center',
  [AssignmentNetworks.TIRE]: 'Tire Shop',
  [AssignmentNetworks.BODY]: 'Body Shop',
}

export const ParticpantTooltip: React.FC<Props> = ({
  participant: { distanceStats, entity, businessHours },
  network,
}) => {
  const { address, contactInfo, name } = entity
  return (
    <div>
      <Heading as="h5">{NetworkNames[network]}</Heading>
      <Heading as="h6">{name}</Heading>
      {distanceStats && distanceStats.distance && (
        <Paragraph>{`${distanceStats.distance.toFixed(2)} ${distanceStats.units}`}</Paragraph>
      )}
      <Divider />
      <div>
        <Paragraph>{contactInfo && contactInfo.primaryPhone}</Paragraph>
        {address && (
          <>
            <Paragraph>{address.addressLine1}</Paragraph>
            <Paragraph>{`${address.city}, ${address.administrativeArea} ${address.postalCode}`}</Paragraph>
          </>
        )}
      </div>
      {businessHours && (
        <>
          <Divider />
          <ParticipantTooltipHours businessHours={businessHours} />
        </>
      )}
    </div>
  )
}

export default ParticpantTooltip
