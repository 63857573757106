import * as React from 'react'
import { useTranslation } from 'react-i18next'
import SoftServiceEvent from '../SoftServiceEvent'
import { createCancelDialogContext } from 'components/Dispatch/Cancel/useCancelServiceDialog'
import { DynamoEvents } from 'types/events'

export const cancelDialogContext = createCancelDialogContext()
function FuelEvent(event: DynamoEvents) {
  const { t } = useTranslation()

  return (
    <cancelDialogContext.Provider>
      <SoftServiceEvent title={t('fuel.heading')} {...event} />
    </cancelDialogContext.Provider>
  )
}

export default FuelEvent
