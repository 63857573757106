import * as React from 'react'

type ContextType = {
  map: google.maps.Map | null
  setMap: (map: google.maps.Map | null) => void
} | null
const GoogleMapsContext = React.createContext<ContextType>(null)

export const GoogleMapsProvider = ({ children }) => {
  const [map, setMap] = React.useState<google.maps.Map | null>(null)

  return <GoogleMapsContext.Provider value={{ map, setMap }}>{children}</GoogleMapsContext.Provider>
}

export const useGoogleMaps = () => {
  const context = React.useContext<ContextType>(GoogleMapsContext)

  if (!context) {
    throw new Error('No GoogleMapsContext found... Is the Provider set?')
  }

  return { ...context }
}

export default useGoogleMaps
