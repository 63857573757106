import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Divider from 'components/Divider'
import { FormApi } from 'final-form'
import * as Yup from 'yup'
import { Form } from 'components/FormManager'
import Input, { YesNoRadio } from 'components/Input'
import { VehicleSelect, StateSelect } from 'components/Select'
import { StepNavigation } from 'components/Step'
import { useReduxCallStoreActions } from 'hooks/events'
import { isRequired, isValidDate } from 'utils/validators'
import FormHeading from './FormHeading'
import DriversRadio from './DriversRadio'
import { Condition } from 'components/fields'
import { WorkflowAction } from 'components/Workflow'
import { CallTypes } from 'types/callstore'
import { EventTypes } from 'types/events'
import { useSelector } from 'react-redux'
import { selectFormsState, selectTicket } from 'redux/appStore'

declare module 'types/form' {
  export interface Forms {
    LDRClaimCreation: FormApi
  }
}

export const LDRClaimCreation = () => {
  const { drivers } = useSelector(selectTicket)
  const { update } = useReduxCallStoreActions()
  const { ldr } = useSelector(selectFormsState) as any
  const { claim, ...restValues } = ldr
  const { t } = useTranslation()

  return (
    <Form
      name="LDRClaimCreation"
      schema={LDRClaimCreationSchema}
      initialValues={claim}
      autoSaveDebounce={200}
      autoSaveSync={(updatedClaim) => {
        let values = {
          ...restValues,
          claim: updatedClaim,
        }
        update({ ldr: { ...values } })
      }}
    >
      <FormHeading>{t('ldr.claim.heading')}</FormHeading>
      <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
      <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} />
      <Input.Date label={t('ldr.claim.dateOfLoss')} name="date" validate={isValidDate()} />
      <Input.Textarea label={`${t('ldr.claim.factsOfLoss')}*`} name="facts" />
      <StateSelect label={t('ldr.claim.state')} name="state" />
      <Divider />
      <YesNoRadio name="policeReport" label={t('ldr.claim.policeReport')}>
        <Input label={t('ldr.claim.policeReportNumber')} name="policeReportNumber" />
        <Input label={t('ldr.claim.precinct')} name="precinctName" />
        <Input label={t('ldr.claim.department')} name="policeDepartmentName" />
      </YesNoRadio>
      <Divider />
      <YesNoRadio name="roadsideTow" label={t('ldr.claim.otherTow')}></YesNoRadio>
      <Divider />
      <FormHeading>{t('ldr.claim.driver')}</FormHeading>
      <DriversRadio drivers={drivers as any} />
      <Divider />
      <Condition when="roadsideTow" is="yes">
        <WorkflowAction
          callType={CallTypes.RECOVERY}
          eventType={EventTypes.RECOVERY}
          additionalService={EventTypes.LDR}
          required
          label={t('recovery.dispatch')}
        />
      </Condition>
      <div style={{ height: 1, width: '100%', marginTop: 10 }} />
      <StepNavigation />
    </Form>
  )
}

export default LDRClaimCreation

const LDRClaimCreationSchema = Yup.object().shape({
  vehicle: Yup.object()
    .shape({
      label: Yup.string().nullable(true),
      value: Yup.string().nullable(true).required('Vehicle is required'),
    })
    .nullable(true)
    .required('Vehicle is required'),
  date: Yup.string().nullable(true).required('Date of Loss is required'),
  facts: Yup.string().nullable(true).required('Facts of Loss is required'),
  state: Yup.object().shape({
    label: Yup.string().nullable(true),
    value: Yup.string().nullable(true).required('State/Province is required'),
  }),
  policeReport: Yup.string().nullable(true).required('Required'),
  policeReportNumber: Yup.string().nullable(true),
  precinctName: Yup.string().nullable(true),
  policeDepartmentName: Yup.string().nullable(true),
  roadsideTow: Yup.string().nullable(true).required('Required'),
  towWho: Yup.string().nullable(true),
  towWhere: Yup.string().nullable(true),
  renterDriver: Yup.string().required('Driver is required'),
  driverFirstName: Yup.string().nullable(true),
  driverLastName: Yup.string().nullable(true),
  driverCallbackNumber: Yup.string().nullable(true),
})
