import * as React from 'react'
import { IconType } from 'react-icons'
import { MdStoreMallDirectory, MdLocalHotel } from 'react-icons/md'
import { MarkerType } from 'types/googleMaps'
import { useLocation } from 'react-router-dom'
import { elm } from 'theme/colors'
import { CustomIconType } from 'icons'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/appStore'

type PlaceLayer = {
  id: number
  markerType: MarkerType
  visible: boolean
  icon: IconType | CustomIconType
  color: string
  label: string
}

type HeroLayersContextType = {
  layers: PlaceLayer[]
  setLayers: React.Dispatch<React.SetStateAction<PlaceLayer[]>>
}

// https://developer.here.com/documentation/places/topics/category-terms-en.html
export enum PlaceQueries {
  BODYSHOP = 'car_dealer', // mabye? 'auto parts and accessories',
  REPAIRSHOP = 'car_repair',
  TIRESHOP = 'car_repair',
}

// TODO: translate
export enum LayerLabels {
  BODYSHOP = 'Body Shop',
  REPAIRSHOP = 'Repair Shop',
  TIRESHOP = 'Tire Shop',
  BRANCHES = 'Branches',
  TRAFFIC = 'Traffic',
  HOTELS = 'Hotels',
}

export const MarkerTypeToLayerLabel: { [key: string]: LayerLabels } = {
  [MarkerType.BODYSHOP]: LayerLabels.BODYSHOP,
  [MarkerType.REPAIRSHOP]: LayerLabels.REPAIRSHOP,
  [MarkerType.TIRESHOP]: LayerLabels.TIRESHOP,
  [MarkerType.BRANCHES]: LayerLabels.BRANCHES,
  [MarkerType.HOTELS]: LayerLabels.HOTELS,
}

const initialPlaceLinkLayers = [
  {
    id: 4,
    markerType: MarkerType.BRANCHES,
    visible: false,
    icon: MdStoreMallDirectory,
    color: elm,
    label: LayerLabels.BRANCHES,
  },
  {
    id: 5,
    markerType: MarkerType.HOTELS,
    visible: false,
    icon: MdLocalHotel,
    color: elm,
    label: LayerLabels.HOTELS,
  },
]

const HeroLayersContext = React.createContext<HeroLayersContextType | null>(null)

export const HeroLayersProvider = ({ children }) => {
  const { roadsideHotelsEnabled, roadsideHotelsEnabledAdminOnly } = useFlags()
  const currentUser = useSelector(selectCurrentUser)
  // roadside-hotels-enabled LD flag is for all users. roadside-hotels-enabled-admin-only LD flag is for admins only
  let isHotelFunctionalityOn =
    !roadsideHotelsEnabled && currentUser?.roles.includes('usr.grp-admin')
      ? roadsideHotelsEnabledAdminOnly
      : roadsideHotelsEnabled

  const [layers, setLayers] = React.useState<PlaceLayer[]>(
    isHotelFunctionalityOn
      ? initialPlaceLinkLayers
      : initialPlaceLinkLayers.filter((layer) => layer.label !== LayerLabels.HOTELS),
  )
  return <HeroLayersContext.Provider value={{ layers, setLayers }}>{children}</HeroLayersContext.Provider>
}

export function useHeroLayers(): HeroLayersContextType {
  const location = useLocation()
  const layersCtx = React.useContext(HeroLayersContext)

  if (!layersCtx) throw new Error('No Hero Layer context provided')
  const { layers, setLayers } = layersCtx

  return {
    layers: layers.map((layer) => {
      if (location.pathname.includes(layer.markerType)) {
        return {
          ...layer,
          visible: true,
        }
      }
      return layer
    }),
    setLayers,
  }
}
