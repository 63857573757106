import * as React from 'react'
import { HourInfo, Hours, Standard } from 'types/ticket'
import TableRow from 'components/TableRow'
import { Label } from 'components/primitives'
import { useTranslation } from 'react-i18next'
import { parseInt } from 'lodash-es'
import { DateTimeFormatOptions } from 'luxon'

type Props = {
  hours: readonly HourInfo[]
  statusColor?: string
  localHour?: number
  localMinutes?: number
}

function formatOpenCloseHours(t: any, day: string, hourInfo: Standard, opHours: Hours) {
  if (hourInfo.closed) return t('date.closed')
  if (hourInfo.open24Hours) return t('date.24hours')

  const openDate = new Date(`${day}, ${opHours.open}`)
  const closedDate = new Date(`${day}, ${opHours.close}`)
  const options = { hour: 'numeric', minute: 'numeric', hour12: true } as DateTimeFormatOptions
  const formattedOpenDate = openDate.toLocaleString('en-us', options)
  const formattedClosedDate = closedDate.toLocaleString('en-us', options)

  return `${formattedOpenDate} - ${formattedClosedDate}`
}

function formatLabelsForMultipleOpenCloses(t: any, h: HourInfo, statusColor?: string | undefined) {
  return (
    // eslint-disable-next-line array-callback-return
    h.standard.hours.map((opHours: Hours, index: number) => {
      if (index !== 0) {
        return (
          <div key={index} style={{ display: 'flex', flexFlow: 'row wrap', width: '100%' }}>
            <Label style={{ flexBasis: '50%' }} />
            {statusColor ? (
              <Label
                style={{
                  fontWeight: 'bold',
                  color: statusColor,
                  flexBasis: '50%',
                  textAlign: 'right',
                }}
              >
                {formatOpenCloseHours(t, h.day, h.standard, opHours)}
              </Label>
            ) : (
              <Label style={{ flexBasis: '50%', textAlign: 'right' }}>
                {formatOpenCloseHours(t, h.day, h.standard, opHours)}
              </Label>
            )}
          </div>
        )
      }
    })
  )
}

export const BranchHoursTable: React.FC<Props> = ({ hours, statusColor, localHour, localMinutes }) => {
  const { t } = useTranslation()

  return (
    <>
      {hours.map((h, i) => {
        const date = new Date(h.day)
        // @ts-ignore
        let hours: Hours[] = h.standard.hours
        let closeHours: string[] = h.standard.hours[0] ? h.standard.hours[0].close.split(':') : []
        let firstCloseHour: number = parseInt(closeHours[0])
        let firstCloseMins: number = parseInt(closeHours[1])
        if (
          !i &&
          h.standard.hours.length > 1 &&
          localHour &&
          localMinutes &&
          (localHour > firstCloseHour || (localHour === firstCloseHour && localMinutes > firstCloseMins))
        ) {
          hours.shift()
        }
        return (
          <TableRow key={i}>
            {!i && statusColor ? (
              <>
                <div style={{ display: 'flex', flexFlow: 'row wrap', width: '100%' }}>
                  <Label
                    style={{
                      fontWeight: 'bold',
                      color: statusColor,
                      flexBasis: '50%',
                      fontSize: '14px',
                    }}
                  >
                    {new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date.setDate(date.getDate() + 1))}
                  </Label>
                  <Label
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      color: statusColor,
                      flexBasis: '50%',
                      textAlign: 'right',
                    }}
                  >
                    {formatOpenCloseHours(t, h.day, h.standard, h.standard.hours[0])}
                  </Label>
                  {!h.standard.closed && !h.standard.open24Hours && h.standard.hours.length > 1
                    ? formatLabelsForMultipleOpenCloses(t, h, statusColor)
                    : null}
                </div>
              </>
            ) : (
              <>
                <div style={{ display: 'flex', flexFlow: 'row wrap', width: '100%' }}>
                  <Label style={{ flexBasis: '50%' }}>
                    {new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date.setDate(date.getDate() + 1))}
                  </Label>
                  <Label style={{ flexBasis: '50%', textAlign: 'right' }}>
                    {formatOpenCloseHours(t, h.day, h.standard, h.standard.hours[0])}
                  </Label>
                  {!h.standard.closed && !h.standard.open24Hours && h.standard.hours.length > 1
                    ? formatLabelsForMultipleOpenCloses(t, h)
                    : null}
                </div>
              </>
            )}
          </TableRow>
        )
      })}
    </>
  )
}

export default BranchHoursTable
