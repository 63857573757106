import * as React from 'react'
import { useField } from 'react-final-form'
import { BranchInfoFragment, CarClassAvailability } from 'types/global'
import useCarousel from 'components/Carousel'
import ExchangeCarouselItem from './ExchangeCarouselItem'
import { BackNavigation } from '../../Step'
import useExchangeSearch from '../useExchangeSearch'
import { useVehicleLocation } from 'hooks/redux/useLocations'
import { areZipsFromSameIsland } from 'utils/HawaiiUtils'

type CarouselProps = {
  name: string
  items: ReadonlyArray<CarClassAvailability>
  isTowExchange: boolean
  onSetBranch?: (branch: BranchInfoFragment) => void
  setConfirmLocation: (value: boolean) => void
}

const ExchangeCarousel: React.FC<CarouselProps> = ({ name, items, onSetBranch, setConfirmLocation, isTowExchange }) => {
  const { input } = useField('acceptedCarClass', {})
  const onCycle = () => {
    if (input) input.onChange('')
  }
  const vehicleLocation = useVehicleLocation()
  const vehicleAddress = vehicleLocation?.address
  const isOnSameIsland = (value: any) => {
    return areZipsFromSameIsland(
      vehicleAddress?.postalCode?.substring(0, 5),
      value.branchData.address.postal.substring(0, 5),
    )
  }
  const { reset } = useExchangeSearch()
  if (vehicleAddress && vehicleAddress?.state === 'HI') {
    let backupItems = items
    items = items.filter(isOnSameIsland)

    if (items.length === 0) {
      items = backupItems
    }
  }
  const { item, ...rest } = useCarousel(items, onCycle)

  return (
    <>
      <BackNavigation
        onClick={() => {
          setConfirmLocation(false)
          return reset
        }}
      />
      <ExchangeCarouselItem
        name={name}
        onSetBranch={onSetBranch}
        availability={item}
        isTowExchange={isTowExchange}
        {...rest}
      />
    </>
  )
}

export default ExchangeCarousel
