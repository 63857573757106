import * as React from 'react'
import { AsYouType, PhoneNumber as LibPhoneNumber } from 'libphonenumber-js'
// @ts-expect-error
import { PatternFormat } from 'react-number-format'
import Input from './Input'

export type PhoneNumberProps = {
  input: { value: string }
  selectedCountry: string
  defaultPhone: LibPhoneNumber
  exampleNumber: LibPhoneNumber
}

export const PhoneNumber = ({ input, selectedCountry, defaultPhone, exampleNumber, ...rest }: PhoneNumberProps) => {
  const formatter = new AsYouType({
    defaultCountry: exampleNumber?.country,
    defaultCallingCode: exampleNumber?.countryCallingCode,
  })
  formatter.input(exampleNumber?.formatNational() || '')
  const template = formatter.getTemplate()
  formatter.reset()
  formatter.input(input.value)

  return (
    <PatternFormat
      {...input}
      {...rest}
      type="tel"
      initialValue={defaultPhone?.formatNational()}
      value={formatter.isInternational() ? formatter.getNationalNumber() : input.value}
      format={template}
      placeholder={template}
      patternChar="x"
      mask={'_'}
      customInput={Input}
    />
  )
}

export default PhoneNumber
