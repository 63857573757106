import { Checkbox, RadioCheckbox } from 'components/fields'
import Input, { Hidden } from './Input'
import {
  Date,
  Radio,
  Textarea,
  Select,
  PhoneNumber,
  Checkbox as RegisteredCheckbox,
} from './FinalFormInputs'
import { Phone } from './Phone'
import Button from './Button'

export * from './Input'

export default Object.assign(Input, {
  Button,
  Checkbox,
  RegisteredCheckbox,
  Date,
  Hidden,
  Phone,
  PhoneNumber, // without country code
  Radio,
  RadioCheckbox,
  Textarea,
  Select,
})

export { default as YesNoRadio, YesNoTriggers } from './YesNoRadio'
