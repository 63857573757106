import * as React from 'react'
import ErrorBoundary from 'components/ErrorBoundary'
import useTempMarker from 'hooks/map/useTempMarker'
import { GoogleFeatureGroup } from 'components/GoogleMaps'
import { GoogleHeroMarker } from '../Markers/GoogleMapMarkers'
import { Tooltip } from 'components/Tooltip'
import { MarkerType } from 'types/googleMaps'
import { Address } from '../Markers/Tooltips'
import { MarkerTypeLabels } from '../Markers/utils'
import { useTranslation } from 'react-i18next'

export const TempMarkersLayer: React.FC = () => {
  const { marker } = useTempMarker()
  const { t } = useTranslation()
  if (!marker) return null
  const { type, address, latitude, longitude } = marker
  const markerType = type ? type : MarkerType.PLACE
  const lines = address.split('<br/>')
  lines && lines.push(`(${latitude.toFixed(5)}, ${longitude.toFixed(5)})`)

  return (
    <ErrorBoundary>
      <GoogleFeatureGroup>
        <GoogleHeroMarker type={markerType} latitude={latitude} longitude={longitude}>
          <Tooltip>{<Address title={t(MarkerTypeLabels[markerType] as string)} lines={lines} />}</Tooltip>
        </GoogleHeroMarker>
      </GoogleFeatureGroup>
    </ErrorBoundary>
  )
}

export default TempMarkersLayer
