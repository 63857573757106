import { PathMatch, useMatch } from 'react-router-dom'
import * as React from 'react'
import Routes from 'routes'
import useTimerState from 'hooks/useTimerState'

type Props = {
  match: PathMatch<string>
}

const ResetState: React.FC<Props> = ({ match }) => {
  const { reset: resetLocalStorage } = useTimerState()

  React.useEffect(() => {
    // User has transitioned out of ticket detail - reset call store
    if (!match) {
      resetLocalStorage()
    }
  }, [match, resetLocalStorage])

  return null
}

const TimerStateManager: React.FC = () => {
  const versionMatch = useMatch(Routes.VERSION)
  const ticketMatch = useMatch(`/${Routes.TICKET}/${Routes.TICKET_DETAIL}`)

  if (versionMatch) return <ResetState match={versionMatch} />
  if (ticketMatch) return <ResetState match={ticketMatch} />

  return null
}

export default TimerStateManager
