import { FormApi } from 'final-form'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { TicketCharges } from 'types/ticket'
import { EventTypes } from 'types/events'
import { CallTypes } from 'types/callstore'
import { Heading, Label } from 'components/primitives'
import { Form } from 'components/FormManager'
import Location from 'components/Input/Location'
import Input from 'components/Input'
import Waivers from 'components/Waivers'
import { useCallEvent, useReduxCallStoreActions } from 'hooks/events'
import useCreateSoftService from 'hooks/aaa/useCreateSoftService'
import useCreateUlySoftService from 'hooks/urgent.ly/useCreateUlySoftService'
import Margin from 'components/Margin'
import Submit from 'components/fields/Submit'
import { VehicleSelect } from 'components/Select'
import ScheduledAt from 'components/ScheduledAt'
import { isRequired } from 'utils/validators'
import useUrgentlyProvider from 'hooks/urgent.ly/useUrgentlyProvider'
import FuelEvent from './FuelEvent'
import { BreakdownDetailsForm, BreakdownNote } from '../BreakdownDetails'
import { BreakdownOptions } from '../BreakdownDetails/BreakdownRadio'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

declare module 'types/form' {
  export interface Forms {
    [CallTypes.FUEL]: FormApi
  }
}

const FuelService: React.FC = () => {
  const { t } = useTranslation()
  const fuelEvent = useCallEvent(EventTypes.FUEL)
  const { update } = useReduxCallStoreActions()
  const { fuel } = useSelector(selectFormsState) as any
  const { isEnterpriseTicket } = useUrgentlyProvider()
  const createSoftService = useCreateSoftService(EventTypes.FUEL)
  const createUlySoftService = useCreateUlySoftService(EventTypes.FUEL)

  let safeToContinue =
    (fuel?.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE && fuel?.unsafe === 'no') ||
    (fuel?.breakdownOption === BreakdownOptions.PARKINGGARAGE && fuel?.garageAccessible === 'yes') ||
    (fuel?.breakdownOption === BreakdownOptions.OTHER && fuel?.accessible === 'yes')

  if (fuelEvent && fuelEvent.event) return <FuelEvent {...fuelEvent.event} />

  return (
    <Form name={CallTypes.FUEL} schema={fuelSchema} initialValues={fuel} autoSaveSync={(fuel) => update({ fuel })}>
      <Heading as="h5" spacing="md">
        {t('fuel.heading')}
      </Heading>
      <Margin spacing="md">
        <Label>{t('labels.fees')}</Label>
        <Waivers.Protection charge={TicketCharges.FUEL_DELIVERY_CHARGE} />
      </Margin>
      <BreakdownDetailsForm callType={CallTypes.FUEL} />
      {safeToContinue && (
        <>
          <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
          <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} />
          <Margin spacing="md">
            <Location.Vehicle name="serviceLocations.vehicleLocation" />
            <Input.Hidden
              name="serviceLocations.vehicleLocation"
              validate={isRequired('Vehicle location is required')}
            />
          </Margin>
          <Margin spacing="md">
            <ScheduledAt callType={CallTypes.FUEL} />
            <Input.Hidden name="scheduledAt" />
          </Margin>
          <BreakdownNote
            breakdownOption={fuel?.breakdownOption}
            unsafe={fuel?.unsafe}
            garageAccessible={fuel?.garageAccessible}
            accessible={fuel?.accessible}
          />
          <Margin spacing="md">
            <Input.Textarea label={`${t('notesToProvider.label')}*`} name="notes" maxLength={240} />
          </Margin>
          <Margin spacing="md">
            <Submit
              onSubmit={async (state, caller) => {
                const { secondaryProvider = false, scheduledAt } = state.values

                if (scheduledAt) {
                  return await createUlySoftService(state, caller)
                } else {
                  if (isEnterpriseTicket) {
                    // AAA
                    return secondaryProvider
                      ? await createUlySoftService(state, caller) //Secondary
                      : await createSoftService(state, caller) //Default
                  } else {
                    // Urgently
                    return secondaryProvider
                      ? await createSoftService(state, caller) //Secondary
                      : await createUlySoftService(state, caller) //Default
                  }
                }
              }}
            >
              {t('fuel.save')}
            </Submit>
          </Margin>
        </>
      )}
    </Form>
  )
}

export default FuelService

const fuelSchema = Yup.object({
  notes: Yup.string().nullable(true).required('Dispatch notes are required'),
})
