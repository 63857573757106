import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading } from 'components/primitives'

function NoHistory() {
  const { t } = useTranslation()
  return (
    <Heading as="h6" style={{ fontStyle: 'italic', opacity: 0.5 }}>
      {t('No History')}
    </Heading>
  )
}

export default NoHistory
