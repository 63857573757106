import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Label, Paragraph } from 'components/primitives'
import DispatchService from 'components/Dispatch/DispatchService'
import TowServiceLocations from './TowServiceLocations'
import { DynamoEvents, EventTypes } from 'types/events'
import { useCallEvent } from 'hooks/events'

export default function ReverseTowExchangeEvent(event: DynamoEvents) {
  const { t } = useTranslation()
  const exchangeEvent = useCallEvent(EventTypes.EXCHANGE)
  // <TowExchange>
  const { dispatch, dispatchRentalNotes } = exchangeEvent?.event?.attributes || event.attributes

  return (
    <>
      <Heading as="h6" spacing="md">
        {t('Reverse Tow Exchange')}
      </Heading>

      <TowServiceLocations {...event} />

      {dispatchRentalNotes && (
        <>
          <Label>{t('Rental Pickup Notes')}</Label>
          <Paragraph spacing="md">{dispatchRentalNotes}</Paragraph>
        </>
      )}
      {dispatch && <DispatchService dispatch={dispatch} />}
    </>
  )
}
