import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading } from 'components/primitives'
import { BreakdownOptions } from './BreakdownRadio'
import { YesNoTriggers } from 'components/Input'

type Props = {
  breakdownOption: string | undefined
  unsafe: YesNoTriggers | undefined
  garageAccessible: YesNoTriggers | undefined
  accessible: YesNoTriggers | undefined
}

const BreakdownNote: React.FC<Props> = ({
  breakdownOption,
  unsafe,
  garageAccessible,
  accessible,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {unsafe && breakdownOption === BreakdownOptions.ROADWAYINTERSTATE && (
        <Heading as="h5" spacing="md">
          {t(
            'Enter the closest exit, mile marker or landmark along with the direction the customer is travelling.',
          )}
        </Heading>
      )}
      {garageAccessible && breakdownOption === BreakdownOptions.PARKINGGARAGE && (
        <Heading as="h5" spacing="md">
          {t(
            'Enter the garage clearance, floor number or name, and other details to locate the vehicle.',
          )}
        </Heading>
      )}
      {accessible && breakdownOption === BreakdownOptions.OTHER && (
        <Heading as="h5" spacing="md">
          {t(
            'Enter important location details available-businesses nearby, landmarks, or descriptors.',
          )}
        </Heading>
      )}
    </>
  )
}

export default BreakdownNote
