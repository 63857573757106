import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdKeyboardArrowUp } from 'react-icons/md'
import { Flex, Link } from 'components/primitives'
import { TabList, TabPanel, Tab } from 'components/Tabs'
import VehicleFormFields from './VehicleFormFields'
import * as Ariakit from '@ariakit/react'
import CustomerForm from './CustomerForm'
import { motion, useAnimation } from 'framer-motion'
import { useFormState } from 'react-final-form'
import { FormState } from 'final-form'
import styled from 'styled-components/macro'

const StyledLink = styled(Link as any)`
  &:focus {
    outline: none;
  }
`

const AdvancedSearch = () => {
  const { t } = useTranslation()
  const controls = useAnimation()
  const vehicleRef = React.useRef<HTMLDivElement>(null)
  const customerRef = React.useRef<HTMLDivElement>(null)
  const { touched, invalid } = useFormState({
    subscription: {
      invalid: true,
      touched: true,
    },
  }) as FormState<any>
  const tabsContextStore = Ariakit.useTabContext()
  const activeTabId = tabsContextStore?.useState('selectedId')

  const advancedSearchStore = Ariakit.useDisclosureContext()
  const advancedSearchStoreIsOpen = advancedSearchStore?.useState('open') || false

  React.useEffect(() => {
    if (advancedSearchStoreIsOpen) {
      const vehicleHeight = vehicleRef.current?.offsetHeight || 0
      const customerHeight = customerRef.current?.offsetHeight || 0
      controls.start({
        opacity: 1,
        height: activeTabId === 'tab-1' ? vehicleHeight : customerHeight,
      })
    } else {
      controls.start({ opacity: 0, height: 0 })
    }
  }, [activeTabId, advancedSearchStoreIsOpen, controls, touched, invalid])

  return (
    <>
      <Flex style={{ width: '100%' }}>
        <Ariakit.DisclosureContent store={advancedSearchStore}>
          <TabList store={tabsContextStore} aria-label="Advanced Search">
            <Tab id="tab-1">{t('Vehicle')}</Tab>
            <Tab id="tab-2">{t('Customer')}</Tab>
          </TabList>
        </Ariakit.DisclosureContent>
        <Ariakit.Disclosure
          store={advancedSearchStore}
          style={{ backgroundColor: 'unset', border: 0, padding: 0, display: 'flex', margin: '0 0 0 auto', color: 'inherit' }}
        >
          <StyledLink style={{ color: 'inherit' }} focusable={false}>
            {t('Advanced')}
            <motion.div animate={{ rotate: advancedSearchStoreIsOpen ? 0 : 180 }} initial={false}>
              <MdKeyboardArrowUp size={24} />
            </motion.div>
          </StyledLink>
        </Ariakit.Disclosure>
      </Flex>

      <motion.div
        animate={controls}
        transition={{ type: 'tween' }}
        style={{ visibility: advancedSearchStoreIsOpen ? 'inherit' : 'hidden', height: 'auto' }}
      >
        {advancedSearchStoreIsOpen ? (
          <div style={{ margin: '20px 0' }}>
            <TabPanel store={tabsContextStore} unmountOnHide tabId="tab-1" ref={vehicleRef}>
              <VehicleFormFields />
            </TabPanel>
            <TabPanel store={tabsContextStore} unmountOnHide tabId="tab-2" ref={customerRef}>
              <CustomerForm />
            </TabPanel>
          </div>
        ) : null}
      </motion.div>
    </>
  )
}

export default AdvancedSearch
