import * as React from 'react'
import styled from 'styled-components/macro'
import { Flex } from 'components/primitives'
import { usePopupManager } from './PopupManager'

type PopupDisplayProps = {
  marginLeft?: number | string
  maxHeight?: number
}

const StyledFlex = styled(Flex as any)`
  flex: 1;
  position: absolute;
  z-index: 9999;
  max-height: 100%;
`

export const PopupDisplay: React.FC<PopupDisplayProps> = ({ marginLeft, maxHeight }) => {
  const { popup } = usePopupManager()

  if (!popup) return null

  return <StyledFlex style={{ marginLeft, maxHeight }}>{popup}</StyledFlex>
}

export default PopupDisplay
