import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Card from 'components/Card'
import { Flex, Paragraph } from 'components/primitives'
import { usePopupManager } from 'components/PopupManager'
import ScrollBox from 'components/ScrollBox'
import styled from 'styled-components/macro'
import { palette, theme } from 'styled-tools'
import { MdAssignmentReturned, MdClose } from 'react-icons/md'
import { calypso, dustyGray } from 'theme/colors'
import useTicketNotes from 'hooks/tickets/useTicketNotes'
import { isEmpty } from 'lodash-es'
import { CSVLink } from 'react-csv'
import { TicketNote } from 'types/ticket'

const StyledCard = styled(Card as any)`
  display: flex;
  flex-direction: column;
  width: auto;
  max-height: 100%;
  margin-left: 24px;
  min-width: 600px;
  max-width: 700px;
`

const Header = styled(Flex as any)`
  justify-content: space-between;
  padding: 0 ${theme('spaces.2')} ${theme('spaces.1')};
  margin: 0 -${theme('spaces.2')};
  border-bottom: 1px solid ${palette('mercury')};
  font-size: 1em;
`

const NotesPopupCard: React.FC = () => {
  const { t } = useTranslation()
  const { close } = usePopupManager()
  const { notes, loading, error } = useTicketNotes()

  const getTableContent = () => {
    const iterateItem = () => {
      return (notes as TicketNote[])?.map(({ createDateTime, userId, text }, i) => {
        const userEID = text.startsWith('E') ? text.substring(0, text.indexOf(':')) : userId
        const noteText = text.startsWith('E') ? text.substring(text.indexOf(' ') + 1) : text

        return (
          <tr key={i} style={{ borderBottom: '1px solid #000' }}>
            <td style={{ width: '25%' }}>{t('date.standardWithTime', { date: new Date(createDateTime) })}</td>
            <td style={{ width: '15%' }}>{userEID}</td>
            <td style={{ width: '60%' }}>{noteText}</td>
          </tr>
        )
      })
    }
    return (
      <table key="Notes Table" style={{ fontSize: '0.75rem', borderCollapse: 'collapse' }}>
        <thead>
          <tr key="Notes Headings" style={{ borderBottom: '1px solid #000' }}>
            <td style={{ width: '25%' }}>
              <b>{t('Date & Time')}</b>
            </td>
            <td style={{ width: '15%' }}>
              <b>{t('User')}</b>
            </td>
            <td style={{ width: '60%' }}>
              <b>{t('Notes')}</b>
            </td>
          </tr>
        </thead>
        <tbody>{iterateItem()}</tbody>
      </table>
    )
  }
  const csvHeaders = [t('Date & Time'), t('User'), t('Notes')]

  function csvNotesData() {
    if (notes) {
      return (notes as TicketNote[]).map(({ createDateTime, userId, text }) => {
        const userEID = text.startsWith('E') ? text.substring(0, text.indexOf(':')) : userId
        const noteText = text.startsWith('E') ? text.substring(text.indexOf(' ') + 1) : text
        return [t('date.standardWithTime', { date: new Date(createDateTime) }), userEID, noteText]
      })
    } else {
      return ''
    }
  }

  return (
    <React.Fragment>
      <StyledCard>
        <Header as="h5">
          <div style={{ display: 'flex' }}>
            {t('Ticket Notes')}
            <CSVLink data={csvNotesData()} headers={csvHeaders} filename={'Notes.csv'}>
              <MdAssignmentReturned style={{ cursor: 'pointer', marginLeft: 5 }} color={calypso} />
            </CSVLink>
          </div>
          <MdClose style={{ cursor: 'pointer' }} color={dustyGray} onClick={close} />
        </Header>
        <ScrollBox>
          {!loading && error && (
            <Paragraph style={{ fontStyle: 'italic', opacity: 0.5 }}>{t('Error loading notes')}</Paragraph>
          )}
          {isEmpty(notes) && !loading && (
            <Paragraph style={{ fontStyle: 'italic', opacity: 0.5 }}>{t('No Notes')}</Paragraph>
          )}
          {notes && !isEmpty(notes) && <div>{getTableContent()}</div>}
          {loading && <Paragraph style={{ fontStyle: 'italic', opacity: 0.5 }}>{t('Loading')}</Paragraph>}
        </ScrollBox>
      </StyledCard>
    </React.Fragment>
  )
}

export default NotesPopupCard
