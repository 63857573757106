import { useIsTicketWritable } from 'hooks/tickets'
import { selectCurrentUser, selectTicket } from 'redux/appStore'
import { useSelector } from 'react-redux'
import { useServiceProxy } from '../kong'

export default function useCreateEhiTicketNote() {
  const { ticketNumber, pickupInfo } = useSelector(selectTicket)
  const currentUser = useSelector(selectCurrentUser)

  const isWritable = useIsTicketWritable()
  const serviceProxy = useServiceProxy()

  return async function createNote(text: string) {
    try {
      if (isWritable) {
        const notesWithoutNewlines = text.replace(/\r?\n|\r/g, ' ')
        const body = JSON.stringify({
          createdUserId: currentUser!.nameID,
          text: notesWithoutNewlines,
        })
        // pass the brand as a header for RSA reporting in Splunk - mainly for Connected Car notes
        await serviceProxy(
          'POST',
          `/serviceproxy/ticket/${ticketNumber}/note`,
          {
            'Ehi-brand': pickupInfo.branch.brand,
          },
          body,
        )
      }
    } catch (error) {
      console.error(error)
    }
  }
}
