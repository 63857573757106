import { CallTypes } from 'types/callstore'
import { EventTypes } from 'types/events'

export const CallTypeToEventType: { [key: string]: EventTypes } = {
  [CallTypes.ACCIDENT]: EventTypes.ACCIDENT,
  [CallTypes.ESCALATE]: EventTypes.ESCALATE,
  [CallTypes.EXCHANGE]: EventTypes.EXCHANGE,
  [CallTypes.FUEL]: EventTypes.FUEL,
  [CallTypes.JUMPSTART]: EventTypes.JUMPSTART,
  [CallTypes.KEYS]: EventTypes.LOCKOUT,
  [CallTypes.LAW]: EventTypes.LAW,
  [CallTypes.LDR]: EventTypes.LDR,
  [CallTypes.RECOVERY]: EventTypes.RECOVERY,
  [CallTypes.TIRES]: EventTypes.TIRES,
  [CallTypes.TOW]: EventTypes.TOW,
  [CallTypes.WINCH]: EventTypes.WINCH,
  [CallTypes.FEATURE]: EventTypes.FEATURE,
  [CallTypes.CALLBACK]: EventTypes.CALLBACK,
  [CallTypes.DROP]: EventTypes.DROP,
  [CallTypes.TRANSFER]: EventTypes.TRANSFER,
}

export const EventTypeToCallType: { [key: string]: CallTypes } = {
  [EventTypes.ACCIDENT]: CallTypes.ACCIDENT,
  [EventTypes.ESCALATE]: CallTypes.ESCALATE,
  [EventTypes.EXCHANGE]: CallTypes.EXCHANGE,
  [EventTypes.FUEL]: CallTypes.FUEL,
  [EventTypes.JUMPSTART]: CallTypes.JUMPSTART,
  [EventTypes.LAW]: CallTypes.LAW,
  [EventTypes.LDR]: CallTypes.LDR,
  [EventTypes.LOCKOUT]: CallTypes.KEYS,
  [EventTypes.RECOVERY]: CallTypes.RECOVERY,
  [EventTypes.TIRES]: CallTypes.TIRES,
  [EventTypes.TOW]: CallTypes.TOW,
  [EventTypes.WINCH]: CallTypes.WINCH,
  [EventTypes.FEATURE]: CallTypes.FEATURE,
  [EventTypes.CALLBACK]: CallTypes.CALLBACK,
  [EventTypes.DROP]: CallTypes.DROP,
  [EventTypes.TRANSFER]: CallTypes.TRANSFER,
  [EventTypes.TIRES_CHANGE_SPARE]: CallTypes.SPARE_TIRE_CHANGE,
}
