import * as React from 'react'
import { AaaServiceLocation } from 'types/aaa'
import { Paragraph } from 'components/primitives'
import Margin, { MarginProps } from 'components/Margin'

type Props = {
  serviceLocation?: AaaServiceLocation | null
} & MarginProps

function DispatchServiceLocation({ serviceLocation, ...rest }: Props) {
  if (!serviceLocation) return null

  const address = serviceLocation.foi?.location?.address
  const { streetNumber, street, city, state, postalCode } = address || {}
  const firstLine = [streetNumber, street].filter(Boolean).join(' ')
  const secondLine = [city, city && ', ', state, ' ', postalCode].filter(Boolean).join('')

  return (
    <Margin {...rest}>
      {address && (
        <>
          <Paragraph>{firstLine}</Paragraph>
          <Paragraph>{secondLine}</Paragraph>
        </>
      )}
    </Margin>
  )
}

export default DispatchServiceLocation
