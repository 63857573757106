import React from 'react'
import { Step } from 'components/Step'
import {
  TransportationMethod,
  VehicleDropOffDestination,
  DispatchTow,
  CustomerDropOffDestination,
} from './Steps'

export const NoExchangeSteps: React.FC = () => {
  return (
    <>
      <Step step="1">
        <VehicleDropOffDestination />
      </Step>
      <Step step="2">
        <TransportationMethod />
      </Step>
      <Step step="3">
        <CustomerDropOffDestination />
      </Step>
      <Step step="4">
        <DispatchTow />
      </Step>
    </>
  )
}

export default NoExchangeSteps
