import { MarkerType } from 'types/googleMaps'
import { LocationState, LocationType } from 'types/location'

export const LocationTypeToReduxName: { [key: number]: keyof LocationState } = {
  [LocationType.CALLER]: 'callerLocation',
  [LocationType.CALLERDESTINATION]: 'callerDestinationLocation',
  [LocationType.VEHICLE]: 'vehicleLocation',
  [LocationType.VEHICLEDESTINATION]: 'vehicleDestinationLocation',
  [LocationType.EXCHANGE]: 'exchangeLocation',
}

export const LocationTypeToMarkerType: { [key: number]: MarkerType } = {
  [LocationType.CALLER]: MarkerType.CALLER,
  [LocationType.VEHICLE]: MarkerType.VEHICLE,
  [LocationType.VEHICLEDESTINATION]: MarkerType.VEHICLEDESTINATION,
  [LocationType.BRANCH]: MarkerType.BRANCH,
  [LocationType.EXCHANGE]: MarkerType.EXCHANGE,
  [LocationType.CALLERDESTINATION]: MarkerType.CALLERDESTINATION,
}

export const MarkerTypeToLocationType: { [key: string]: LocationType } = {
  [MarkerType.CALLER]: LocationType.CALLER,
  [MarkerType.VEHICLE]: LocationType.VEHICLE,
  [MarkerType.VEHICLEDESTINATION]: LocationType.VEHICLEDESTINATION,
  [MarkerType.BRANCH]: LocationType.BRANCH,
  [MarkerType.EXCHANGE]: LocationType.EXCHANGE,
  [MarkerType.CALLERDESTINATION]: LocationType.CALLERDESTINATION,
}
