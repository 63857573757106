import { useDispatch, useSelector } from 'react-redux'
import { selectTempMarker } from 'redux/appStore'
import { Marker } from 'types/googleMaps'
import { updateTempMarker } from 'redux/tempMarker/tempMarkerSlice'

const useTempMarker = () => {
  const marker = useSelector(selectTempMarker)
  const dispatch = useDispatch()
  const setMarker = (marker: Marker | null) => dispatch(updateTempMarker(marker))

  return {
    marker,
    setMarker,
  }
}

export default useTempMarker
