import React from 'react'
import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import { useServiceProxy } from 'hooks/kong'
import { CaseServiceCall, ULY_CLIENT_CODES } from 'types/urgently'
import { IncomingCaseServiceCall } from 'types/api'
import { deepMapSanitize } from 'utils/deepMap'

export function useULYDispatchQuery(caseId?: string | null) {
  const { brandId } = useSelector(selectTicket)
  const [error, setError] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<CaseServiceCall | null>(null)
  const serviceProxy = useServiceProxy()

  const query = React.useCallback(
    async (shouldFireQuery: boolean) => {
      if (shouldFireQuery) {
        try {
          setLoading(true)
          setError(null)
          const { data } = await serviceProxy<IncomingCaseServiceCall>(
            'get',
            `/serviceproxy/urgently/${ULY_CLIENT_CODES[brandId]}/cases/${caseId}`,
          )
          const dispatchData = deepMapSanitize(data) as CaseServiceCall
          setData(dispatchData)
          setLoading(false)
        } catch (e) {
          console.error(e)
          setLoading(false)
          setError('Unable to lookup ULY dispatch')
        }
      }
    },
    [caseId, serviceProxy, brandId],
  )

  React.useEffect(() => {
    let shouldFireQuery = true

    async function fetchUrgentlyData() {
      if (error || loading) return

      if (caseId && data === null) {
        setLoading(true)
        await query(shouldFireQuery)
        shouldFireQuery = false
      }
    }

    shouldFireQuery && fetchUrgentlyData()
  }, [caseId, data, error, loading, query])

  return { data, loading, error, refetch: query }
}

function useULYDispatch(caseId?: string | null) {
  const { data: caseServiceCall, loading, refetch, error } = useULYDispatchQuery(caseId)

  return {
    dispatch: caseServiceCall,
    loading,
    error,
    refetch,
  }
}

export default useULYDispatch
