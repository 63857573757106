enum MonthNames {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export function formatBirthdate(monthOfBirth: string, dayOfBirth: string) {
  const month = MonthNames[parseInt(monthOfBirth)]
  return `DOB: ${month} ${dayOfBirth}`
}

export default formatBirthdate
