import { isEmpty } from 'lodash-es'
import * as React from 'react'
import { MdOpenInNew } from 'react-icons/md'
import DetailItem from '../DetailsItem'
import { allEquipmentInfo, EquipmentInfoDescriptions } from './EquipmentInfoDescriptions'
import { useTranslation } from 'react-i18next'
import { EquipmentInfo } from 'types/global'

type Props = {
  equipmentInfo: ReadonlyArray<EquipmentInfo>
}
export type FeaturesMap = { [feature in EquipmentInfoDescriptions]: string }

const EquipmentInfoList: React.FC<Props> = ({ equipmentInfo }) => {
  const { t } = useTranslation()

  return (
    <div>
      {!isEmpty(equipmentInfo) &&
        equipmentInfo!
          .filter((info) => allEquipmentInfo.includes(info.localizedFullDescription as EquipmentInfoDescriptions))
          .map((info, i) => <DetailItem key={i} title={info.localizedFullDescription} icon={MdOpenInNew} />)}
      <DetailItem
        title={t('vehicleTips')}
        icon={MdOpenInNew}
        onClick={() => {
          window.open('https://1drv.ms/x/s!Ans0HO6zqYeMc89FzADpBhAqcJs')
        }}
      />
    </div>
  )
}

export default EquipmentInfoList
