import * as React from 'react'
import styled from 'styled-components/macro'
import { GroupType, groupOptions } from './GroupOptions'
import Input, { InputProps } from 'components/Input'

const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const formatGroupLabel = (data: GroupType) => (
  <Group>
    <span>{data.label}</span>
  </Group>
)

export const StateSelect: React.FC<InputProps> = props => (
  <Input.Select
    placeholder="--"
    options={groupOptions}
    isClearable
    formatGroupLabel={formatGroupLabel}
    {...props}
  />
)
export default StateSelect
