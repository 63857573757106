import { FormApi } from 'final-form'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { DynamoEvents, EventTypes } from 'types/events'
import { CallTypes } from 'types/callstore'
import Complete from 'components/Complete'
import { Heading, Label } from 'components/primitives'
import { Condition } from 'components/fields'
import { Form } from 'components/FormManager'
import Location from 'components/Input/Location'
import Input, { YesNoRadio, YesNoTriggers } from 'components/Input'
import Waivers from 'components/Waivers'
import { useCallEvent } from 'hooks/events/call/useCallEvents'
import { useReduxCallStoreActions } from 'hooks/events'
import Margin from 'components/Margin'
import { WorkflowAction } from 'components/Workflow'
import { VehicleSelect } from 'components/Select'
import AccidentEvent from './AccidentEvent'
import { isRequired } from 'utils/validators'
import useCreateServiceEvent from '../../../hooks/events/useCreateServiceEvent'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

declare module 'types/form' {
  export interface Forms {
    [CallTypes.ACCIDENT]: FormApi
  }
}

const AccidentForm: React.FC = () => {
  const { t } = useTranslation()
  const accidentEvent = useCallEvent(EventTypes.ACCIDENT)
  const { update } = useReduxCallStoreActions()
  const { accident } = useSelector(selectFormsState) as any
  const recoveryEvent = useCallEvent(EventTypes.RECOVERY)
  const towEvent = useCallEvent(EventTypes.TOW)
  const ldrEvent = useCallEvent(EventTypes.LDR)
  const createServiceEvent = useCreateServiceEvent({ eventType: EventTypes.ACCIDENT } || null)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const submitAccident = async () => {
      const event = await createServiceEvent({
        ...accident,
      })
      dispatch(setCurrentEvent({ eventType: EventTypes.ACCIDENT, newEvent: event.data }))
    }
    if ((towEvent && ldrEvent && !accidentEvent) || recoveryEvent) {
      submitAccident()
    }
  }, [towEvent, ldrEvent])

  if (accidentEvent) {
    const allEventAttrs = {
      ...accidentEvent.event,
      attributes: {
        ...(accidentEvent && { ...accidentEvent.event?.attributes }),
        ...(towEvent && { ...towEvent.event?.attributes, tow: towEvent.event }),
        ...(ldrEvent && { ...ldrEvent.event?.attributes, ldr: ldrEvent.event }),
        ...(recoveryEvent && { ...recoveryEvent.event?.attributes, recovery: recoveryEvent.event }),
      },
    }

    return <AccidentEvent {...(allEventAttrs as DynamoEvents)} />
  }

  return (
    <Form
      name={CallTypes.ACCIDENT}
      schema={accidentSchema}
      initialValues={accident}
      autoSaveSync={(accident) => update({ accident })}
    >
      <Heading as="h5" spacing="md">
        {t('accident.heading')}
      </Heading>
      <Margin spacing="md">
        <Label>{t('labels.protections')}</Label>
        <Waivers />
      </Margin>
      <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
      <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} />
      <Margin spacing="md">
        <Location.Vehicle name="serviceLocations.vehicleLocation" />
        <Input.Hidden name="serviceLocations.vehicleLocation" validate={isRequired('Vehicle location is required')} />
      </Margin>
      <YesNoRadio name="drivable" label={t('Is the vehicle drivable and safe to drive?')} />
      <Condition when="drivable" is={YesNoTriggers.YES}>
        <Heading as="h5" spacing="md">
          {t('Is an exchange needed? If not, file LDR')}
        </Heading>
        <Margin spacing="md">
          <WorkflowAction
            callType={CallTypes.EXCHANGE}
            eventType={EventTypes.EXCHANGE}
            label={t('tires.vehicleExchange')}
          />
        </Margin>
        <Margin spacing="md">
          <WorkflowAction callType={CallTypes.LDR} eventType={EventTypes.LDR} label={t('ldr.fileLDR')} required />
        </Margin>
        <Complete eventType={EventTypes.ACCIDENT} />
      </Condition>
      <Condition when="drivable" is={YesNoTriggers.NO}>
        <YesNoRadio name="alreadyTowed" label={t('Has the unit already been towed?')} />
        <Condition when="alreadyTowed" is={YesNoTriggers.YES}>
          <Margin spacing="md">
            <WorkflowAction
              callType={CallTypes.EXCHANGE}
              eventType={EventTypes.EXCHANGE}
              label={t('tires.vehicleExchange')}
            />
          </Margin>
          <Margin spacing="md">
            <WorkflowAction
              callType={CallTypes.RECOVERY}
              eventType={EventTypes.RECOVERY}
              label="Dispatch Vehicle Recovery"
              required
            />
          </Margin>
          <Margin spacing="md">
            <WorkflowAction callType={CallTypes.LDR} eventType={EventTypes.LDR} label={t('ldr.fileLDR')} required />
          </Margin>
        </Condition>
        <Condition when="alreadyTowed" is={YesNoTriggers.NO}>
          <Margin spacing="md">
            <WorkflowAction
              callType={CallTypes.TOW}
              eventType={EventTypes.TOW}
              additionalService={EventTypes.ACCIDENT}
              additionalWorkflow={CallTypes.LDR}
              required
              label={t('tow.dispatch')}
            />
          </Margin>
          <Margin spacing="md">
            <WorkflowAction callType={CallTypes.LDR} eventType={EventTypes.LDR} label={t('ldr.fileLDR')} required />
          </Margin>
        </Condition>
        <Complete eventType={EventTypes.ACCIDENT} />
      </Condition>
    </Form>
  )
}

export default AccidentForm

const accidentSchema = Yup.object({
  drivable: Yup.string().nullable(false).required('Vehicle Drivable field required'),
  alreadyTowed: Yup.string().nullable(false),
})
