import * as React from 'react'
import { MarkerType } from 'types/googleMaps'
import ErrorBoundary from 'components/ErrorBoundary'
import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import GoogleBranchMarker from './GoogleBranchMarker'

export const RentalReturnMarkers: React.FC = () => {
  const { pickupInfo, returnInfo } = useSelector(selectTicket)

  if (!pickupInfo || !returnInfo) return null

  const { dateTime: pickupTime, branch: pickupBranch } = pickupInfo
  const { dateTime: returnTime, branch: returnBranch } = returnInfo

  return (
    <ErrorBoundary>
      {pickupBranch && returnBranch && pickupBranch.groupBranchNumber === returnBranch.groupBranchNumber ? (
        <GoogleBranchMarker
          markerType={MarkerType.RENTALRETURN}
          {...pickupBranch}
          pickupTime={pickupTime}
          returnTime={returnTime}
        />
      ) : (
        <>
          {pickupBranch && (
            <GoogleBranchMarker markerType={MarkerType.RENTAL} {...pickupBranch} pickupTime={pickupTime} />
          )}
          {returnBranch && (
            <GoogleBranchMarker markerType={MarkerType.RETURN} {...returnBranch} returnTime={returnTime} />
          )}
        </>
      )}
    </ErrorBoundary>
  )
}

export default RentalReturnMarkers
