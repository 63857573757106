import { useField } from 'react-final-form'

export function useFieldValue<T = any, E extends keyof T = any>(name: E): T[E] {
  const {
    input: { value },
  } = useField(name as string, { subscription: { value: true } })

  return value
}

export default useFieldValue
