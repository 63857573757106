import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdCheckCircle, MdHighlightOff } from 'react-icons/md'
import { cardinal, mountainMeadow } from 'theme/colors'
import useDamageWaiver from 'hooks/tickets/useDamageWaiver'
import { IconIndicator } from 'components/IconIndicator'

function DamageWaiver() {
  const { t } = useTranslation()
  const isDamageWaived = useDamageWaiver()

  const props = isDamageWaived
    ? {
        icon: MdCheckCircle,
        iconColor: mountainMeadow,
      }
    : {
        icon: MdHighlightOff,
        iconColor: cardinal,
      }

  return <IconIndicator {...props} label={t('waivers.damage')} />
}

export default DamageWaiver
