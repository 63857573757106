import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AuthPayload, AuthState } from 'types/auth'
import { initialAuthState } from './authHelpers'

const initialState: AuthState = {
  ...initialAuthState(),
}

const authSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setCurrentUser: (state: AuthState, action: PayloadAction<AuthPayload>) => {
      state.user = action.payload.user
    },
  },
})

export const { setCurrentUser } = authSlice.actions

export default authSlice.reducer
