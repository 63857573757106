import useCreateEhiTicketNote from 'hooks/tickets/useCreateEhiTicketNote'
import {
  searchByCurrentConnectedCarLocation,
  searchByHistoricalConnectedCarLocation,
  searchByCurrentConnectedCarMetrics,
  searchByHistoricalConnectedCarMetrics,
} from './utils'
import { ConnectedErrors } from './ConnectedError'
import { getFleetVehicle, getFleetVehicleHistory, useServiceProxy } from 'hooks/kong'
import { useGoogleMaps } from 'hooks/map'
import { useDispatch, useSelector } from 'react-redux'
import { setConnectedState, setLastVinSearched } from 'redux/connectedSearch/connectedSearchSlice'
import { ReduxState, selectCurrentUser, selectFleetVehicle, selectFleetVehicleHistory } from 'redux/appStore'
import { ConnectedCarLocation, ConnectedCarMetrics } from 'types/kong'
import { deepMapSanitize } from 'utils/deepMap'
import { AxiosResponse } from 'axios'

export const useConnectedSearch = () => {
  const { connectedError, connectedLoading, connectedVehicles, connectedLocation, connectedMetrics, lastVinSearched } =
    useSelector((state: ReduxState) => state.connectedSearchSlice)
  const dispatch = useDispatch()
  const createEhiTicketNote = useCreateEhiTicketNote()
  const fleetVehicle = useSelector(selectFleetVehicle)
  const fleetVehicleHistory = useSelector(selectFleetVehicleHistory)
  const serviceproxy = useServiceProxy()
  const currentUser = useSelector(selectCurrentUser)
  const { map } = useGoogleMaps()

  function reLocateVehicle(connectedLocation: ConnectedCarLocation) {
    map?.panTo(new google.maps.LatLng(connectedLocation.geoLocation!.latitude, connectedLocation.geoLocation!.longitude))
    map?.setZoom(12)
  }

  async function handleConnectedServices(vin: string, historical: boolean) {
    if (lastVinSearched === vin) {
      let results: null | (AxiosResponse<ConnectedCarLocation> | AxiosResponse<ConnectedCarMetrics>)[]
      if (historical) {
        results = await Promise.all(
          [
            searchByHistoricalConnectedCarLocation(vin, serviceproxy, currentUser?.nameID),
            searchByHistoricalConnectedCarMetrics(vin, serviceproxy, currentUser?.nameID),
          ].map((p) => p.catch((e) => e)),
        )
      } else {
        results = await Promise.all(
          [
            searchByCurrentConnectedCarLocation(vin, serviceproxy, currentUser?.nameID),
            searchByCurrentConnectedCarMetrics(vin, serviceproxy, currentUser?.nameID),
          ].map((p) => p.then((response: any) => ({ ...response, data: deepMapSanitize(response.data) })).catch((e) => e)),
        )
      }

      const [location, metrics] = results.filter(
        (result: AxiosResponse<ConnectedCarLocation> | AxiosResponse<ConnectedCarMetrics> | Error) => !(result instanceof Error),
      )

      // Check if both location and metrics calls failed - Exit early
      if (location?.status !== 200 && metrics?.status !== 200) {
        dispatch(setConnectedState({ connectedError: ConnectedErrors.MULTICALL, connectedLoading: false }))
        return
      }
      // Check if location calls failed - Exit early
      if (location?.status !== 200) {
        dispatch(setConnectedState({ connectedError: ConnectedErrors.LOCATION, connectedLoading: false }))
        return
      }

      // Assuming we have a valid location object from here on out
      const { isMasked, isTamperingSuspected } = location?.data as ConnectedCarLocation
      //   Check for vehicle mask and tampering status
      if (isMasked || isTamperingSuspected) {
        let error = ''

        if (isMasked) error = ConnectedErrors.MASKED
        if (isTamperingSuspected) error = ConnectedErrors.TAMPERED

        dispatch(setConnectedState({ connectedError: error, connectedLoading: false }))
      } else {
        // Check for vehicle metric errors
        if (metrics?.status !== 200) {
          dispatch(setConnectedState({ connectedError: ConnectedErrors.METRICS, connectedLoading: false }))
        }

        const locationData = location?.data as ConnectedCarLocation
        const metricData = metrics?.data as ConnectedCarMetrics
        dispatch(
          setConnectedState({ connectedLocation: locationData, connectedMetrics: metricData || null, connectedLoading: false }),
        )
        // TODO: zoom and move the focus to the connected car lat long??
      }
    }
  }

  async function searchForPrimaryConnectedCar(vin: string, unit: string) {
    if (lastVinSearched === vin) {
      if (connectedLocation) {
        reLocateVehicle({ ...connectedLocation })
        return
      }

      try {
        dispatch(setConnectedState({ connectedLoading: true }))
        const vehicle = getFleetVehicle(fleetVehicle, unit)
        if (vehicle) {
          let rentalStatus = vehicle.rentalStatus

          if (rentalStatus && rentalStatus === 'ON_RENT') {
            await handleConnectedServices(vin, false)
          } else if (rentalStatus) {
            dispatch(setConnectedState({ connectedError: ConnectedErrors.NOT_RENT, connectedLoading: false }))
          } else {
            dispatch(setConnectedState({ connectedError: ConnectedErrors.CANTCONNECT4xxRESPONSE, connectedLoading: false }))
          }
        }
        dispatch(setConnectedState({ connectedLoading: false }))
      } catch (e) {
        dispatch(
          setConnectedState({
            connectedLoading: false,
            connectedError: ConnectedErrors.CANTCONNECT4xxRESPONSE,
            connectedLocation: null,
            connectedMetrics: null,
          }),
        )
        dispatch(setLastVinSearched(null))
        console.error(e)
      }
    }
  }

  async function searchForHistoryConnectedCar(vin: string, unit: string) {
    if (lastVinSearched === vin) {
      if (connectedLocation) {
        reLocateVehicle({ ...connectedLocation })
        return
      }

      try {
        dispatch(setConnectedState({ connectedLoading: true }))
        const fleetVehicle = getFleetVehicleHistory(fleetVehicleHistory, unit)
        let rentalStatus = fleetVehicle?.rentalStatus
        let isInMovement = fleetVehicle?.physicalLocation?.parking?.bay === 'IT' || false

        if (!rentalStatus || (rentalStatus === 'ON_RENT' && !isInMovement)) {
          dispatch(setConnectedState({ connectedError: ConnectedErrors.RENT }))
        } else if (rentalStatus && isInMovement) {
          await handleConnectedServices(vin, true)
          const historicalNotes = 'Historical vehicle location found using connected car services'
          await createEhiTicketNote(historicalNotes)
        } else {
          dispatch(setConnectedState({ connectedError: ConnectedErrors.CANTCONNECT4xxRESPONSE }))
        }
        dispatch(setConnectedState({ connectedLoading: false }))
      } catch (e) {
        dispatch(
          setConnectedState({
            connectedError: ConnectedErrors.CANTCONNECT4xxRESPONSE,
            connectedLocation: null,
            connectedMetrics: null,
            connectedLoading: false,
          }),
        )
        dispatch(setLastVinSearched(null))
        console.error(e)
      }
    }
  }

  return {
    // Data
    connectedLocation,
    connectedMetrics,
    lastVinSearched,
    // Status
    connectedError,
    connectedLoading,
    connectedVehicles,
    // Functions
    searchForPrimaryConnectedCar,
    searchForHistoryConnectedCar,
  }
}

export default useConnectedSearch
