import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MapState, MapTypeActions, ViewportActions } from 'types/googleMaps'
import { initialMapState } from './mapHelpers'

const initialState: MapState = {
  ...initialMapState(),
}

const mapSlice = createSlice({
  name: 'Map',
  initialState,
  reducers: {
    setViewport: (state: MapState, action: PayloadAction<ViewportActions>) => {
      state.viewport = { ...state.viewport, ...action.payload }
    },
    setMapType: (state: MapState, action: PayloadAction<MapTypeActions>) => {
      state.mapType = action.payload.mapType
    },
    resetMapState: (state: MapState) => {
      state = initialState
      return state
    },
  },
})

export const { setViewport, setMapType, resetMapState } = mapSlice.actions

export default mapSlice.reducer
