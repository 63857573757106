import React from 'react'
import * as Ariakit from '@ariakit/react'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import Margin from 'components/Margin'
import { Label, Paragraph } from 'components/primitives'
import Input, { YesNoTriggers, YesNoRadio } from 'components/Input'
import { Checkbox, Condition } from 'components/fields'
import Location, { FormValueType } from 'components/Input/Location'
import Button from 'components/Button'
import { BackNavigation } from 'components/Step'
import { Tow } from 'types/callstore'
import IncidentLocation from 'components/IncidentLocation'
import { reverseGeocode } from 'components/useReverseGeocoder'
import { transformExtendedAddress } from 'utils/aaa'
import { LocationPayload } from 'types/location'
import { useServiceProxy } from 'hooks/kong'
import { useDispatch, useSelector } from 'react-redux'
import { selectSmartAssignment } from 'redux/appStore'
import useLocations from 'hooks/redux/useLocations'
import { updateLocation } from 'redux/location/locationSlice'

const ParticipantDropoffLocation = () => {
  const { participant } = useSelector(selectSmartAssignment)
  const { name, address } = participant!.entity
  const { addressLine1, city, administrativeArea, postalCode } = address!

  return (
    <>
      <Paragraph>{name}</Paragraph>
      <Paragraph>{addressLine1}</Paragraph>
      <Paragraph spacing="md">{`${city}, ${administrativeArea} ${postalCode}`}</Paragraph>
      <Input.Hidden
        name="rideAlongLocation"
        initialValue={`${name}, ${addressLine1}, ${city}, ${administrativeArea} ${postalCode}` as any}
      />
    </>
  )
}

export const CustomerDropoffDestination = () => {
  const { t } = useTranslation()
  const proxy = useServiceProxy()
  const { participant, customDropoffDestination } = useSelector(selectSmartAssignment)
  const form = useForm()
  const controlsContext = Ariakit.useCompositeContext()

  const rideAlongValue = form.getState().values.rideAlong
  const customerSuppliedValue = form.getState().values.customerSupplied
  const locations = useLocations()
  const dispatch = useDispatch()
  const customerWithVehicle = form.getState().values.customerWithVehicle
  const rideAlongLocation = form.getState().values.rideAlongLocation
  const disabled = !customerWithVehicle || !rideAlongLocation

  return (
    <>
      <>
        <BackNavigation
          onClick={() => {
            form.change('unattended', false)
            form.change('rideAlong', undefined)
            form.change('branch', null)
            controlsContext?.move(controlsContext?.previous())
          }}
        />
        <Margin spacing="md">
          <YesNoRadio name="customerWithVehicle" trigger={YesNoTriggers.NO} label={t('tow.exchange.withDisabledVehicle')}>
            <Margin spacing="md">
              <Location.Caller name="callerLocation" required />
            </Margin>
          </YesNoRadio>
        </Margin>
        {customerSuppliedValue && (
          <Button.Primary
            style={{ width: '100%' }}
            onClick={() => {
              form.change('serviceLocations.callerLocation', {
                ...locations.vehicleLocation!,
                serviceLocationType: 'CALLER',
              })
              dispatch(
                updateLocation({
                  name: 'callerLocation',
                  location: { ...locations.vehicleLocation!, serviceLocationType: 'CALLER' },
                }),
              )
              controlsContext?.move(controlsContext?.next())
            }}
          >
            {t('Set Customer Location')}
          </Button.Primary>
        )}

        {!customerSuppliedValue && (
          <>
            <Condition when="rideAlongSameAsDropoff" is={true}>
              <Label>{t('Customer Dropoff Location')}</Label>
              {participant ? (
                <ParticipantDropoffLocation />
              ) : (
                customDropoffDestination && (
                  <>
                    <IncidentLocation location={customDropoffDestination} spacing="md" />
                    <Input.Hidden name="rideAlongLocation" defaultValue={customDropoffDestination.address as any} />
                  </>
                )
              )}
            </Condition>
            <Condition when="rideAlongSameAsDropoff" is={(v) => !v}>
              <Margin spacing="md">
                <Location.CallerDestination
                  name="rideAlongLocation"
                  label={t('Customer Dropoff Location')}
                  valueType={rideAlongValue === false ? undefined : FormValueType.Address}
                  required
                />
              </Margin>
            </Condition>
            <Margin spacing="md">
              <Checkbox name="rideAlongSameAsDropoff" label="Same as vehicle dropoff destination?" />
            </Margin>
            <Button.Primary
              style={{ width: '100%' }}
              disabled={disabled}
              onClick={async () => {
                const { rideAlongLocation } = form.getState().values as Tow
                if (typeof rideAlongLocation === 'object' && rideAlongValue === false) {
                  const customLocation = rideAlongLocation as LocationPayload
                  const lat = customLocation.latitude
                  const lon = customLocation.longitude
                  const geocodeAddress = await reverseGeocode(proxy, lat, lon)
                  form.change('thirdPartyLocation', {
                    ...customLocation,
                    ...(geocodeAddress &&
                      geocodeAddress !== 'ZERO_RESULTS' && {
                        extendedPayload: {
                          address: transformExtendedAddress(geocodeAddress),
                        },
                      }),
                  })
                }

                if (rideAlongLocation) {
                  form.change('rideAlongLocationSet', true)
                  controlsContext?.move(controlsContext?.next())
                }
              }}
            >
              {t('Set Dropoff Location')}
            </Button.Primary>
          </>
        )}
      </>
    </>
  )
}

export default CustomerDropoffDestination
