import { useEffect, useState } from 'react'
import axios from 'axios'
import { stringify } from 'querystring'
import { BranchResponse } from 'types/branch'
import { Brands } from 'types/global'
import { vehicleExchangeRadiusInKM } from 'components/Exchange/utils'
import { isNil } from 'lodash'
import { useCallerLocation, useVehicleLocation } from 'hooks/redux/useLocations'
import { config } from '../../config'
import { filterSolrDataByCountry } from './filterSolrDataByCountry'

export const useSpatialSearch = (
  latitude?: string | number | null,
  longitude?: string | number | null,
  brand?: string,
) => {
  const callerLocation = useCallerLocation()
  const vehicleLocation = useVehicleLocation()
  const location = vehicleLocation || callerLocation
  const countryShortCode = location?.address.countryShortCode

  const [data, setData] = useState<null | BranchResponse>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<null | any>(null)
  const { solrSpatial } = config

  const params = {
    latitude,
    longitude,
  }

  const baseURL = solrSpatial
  let url

  if (brand === Brands.ALAMO) {
    url = `${baseURL}/alamo/web/spatial?${stringify(params)}`
  } else if (brand === Brands.NATIONAL) {
    url = `${baseURL}/national/web/spatial?${stringify(params)}`
  } else if (brand === 'TRIBRAND') {
    url = `${baseURL}/alamo/web/spatial?${stringify(params)}&radius=${vehicleExchangeRadiusInKM}`
  } else {
    url = `${baseURL}/enterprise/web/spatial/${latitude}/${longitude}`
  }

  useEffect(() => {
    if (!isNil(latitude) && !isNil(longitude)) {
      const fetchData = async () => {
        try {
          const response = await axios.get<BranchResponse>(url)

          if (countryShortCode) {
            const filteredResponse = filterSolrDataByCountry(response.data, countryShortCode)
            setData(filteredResponse)
          } else {
            setData(response.data)
          }
          setLoading(false)
        } catch (err) {
          setError(err)
          setLoading(false)
        }
      }
      fetchData()
    }
  }, [url, latitude, longitude, countryShortCode])

  return { data, loading, error }
}

export default useSpatialSearch
