import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Label, Paragraph } from 'components/primitives'
import SoftServiceEvent from '../SoftServiceEvent'
import { DynamoEvents } from 'types/events'
import { createCancelDialogContext } from 'components/Dispatch/Cancel/useCancelServiceDialog'

export const cancelDialogContext = createCancelDialogContext()

function JumpstartEvent(event: DynamoEvents) {
  const { t } = useTranslation()
  const service = event?.attributes
  const { vehicleStatus, noise, outOfFuel, fuel } = service

  return (
    <cancelDialogContext.Provider>
      <SoftServiceEvent title={t('jumpstart.heading')} {...event}>
        <Label>{t('jumpstart.vehicleStatus.heading')}</Label>
        <Paragraph spacing="sm">{t(`jumpstart.vehicleStatus.${vehicleStatus}.label`)}</Paragraph>
        {noise && (
          <>
            <Label>{t('jumpstart.vehicleStatus.workingLightsAndRadio.heading')}</Label>
            <Paragraph spacing="sm">{t(`jumpstart.vehicleStatus.workingLightsAndRadio.noise.${noise}`)}</Paragraph>
          </>
        )}
        {outOfFuel && (
          <>
            <Label>{t('jumpstart.outOfFuel')}</Label>
            <Paragraph spacing="sm">{outOfFuel}</Paragraph>
          </>
        )}
        {fuel && (
          <>
            <Label>{t('labels.dispatchServices')}</Label>
            <Paragraph spacing="sm">{t('labels.fuel')}</Paragraph>
          </>
        )}
      </SoftServiceEvent>
    </cancelDialogContext.Provider>
  )
}

export default JumpstartEvent
