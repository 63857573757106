import * as React from 'react'
import { Participant, AssignmentNetworks } from 'types/global'
import { MarkerType } from 'types/googleMaps'
import { useCallerLocation, useVehicleLocation } from 'hooks/redux/useLocations'
import { SelectableGoogleHeroMarker } from 'pages/Ticket/HeroMap/Markers/GoogleMapMarkers'
import { useGoogleMaps } from 'hooks/map'
import { Tooltip } from 'components/Tooltip'
import { LEFT_SIDEBAR_WIDTH } from 'pages/Ticket/LeftSidebar'
import ParticipantTooltip from './ParticipantTooltip'
import { LocationPayload } from 'types/location'

export const NetworkMarkerMapping: { [key: string]: MarkerType } = {
  [AssignmentNetworks.SERVICE]: MarkerType.REPAIRSHOP,
  [AssignmentNetworks.TIRE]: MarkerType.TIRESHOP,
  [AssignmentNetworks.BODY]: MarkerType.BODYSHOP,
}

type Props = {
  participant: Participant
  network: AssignmentNetworks
  showTooltip?: boolean
}

const ParticipantMarker: React.FC<Props> = ({ participant, network, showTooltip = true }) => {
  const { assigneeEntityId, location } = participant
  const { map } = useGoogleMaps()
  const vehicleLocation = useVehicleLocation()
  const callerLocation = useCallerLocation()
  const eitherLocation = vehicleLocation || callerLocation
  const mapRef = React.useRef<google.maps.Map | null>(map)
  const vehicleOrCallerRef = React.useRef<LocationPayload | undefined | null>(eitherLocation)

  React.useEffect(() => {
    const eitherLocation = vehicleLocation || callerLocation

    mapRef.current = map
    vehicleOrCallerRef.current = eitherLocation || null
  })

  React.useEffect(() => {
    if (mapRef.current && location?.latitude && location?.longitude && vehicleOrCallerRef.current) {
      if (vehicleOrCallerRef.current) {
        const branchCoords = new google.maps.LatLng(location.latitude, location.longitude)
        const vehicleCoords = new google.maps.LatLng(
          vehicleOrCallerRef.current.latitude,
          vehicleOrCallerRef.current.longitude,
        )

        const bounds = new google.maps.LatLngBounds()
        bounds.extend(branchCoords)
        bounds.extend(vehicleCoords)

        mapRef.current.fitBounds(bounds, {
          top: 30,
          left: 30,
          bottom: 104,
          right: 104,
        })

        if (mapRef.current.getZoom()! > 13) mapRef.current.setZoom(13)
      }
    }
    // Only fit bounds when participant marker lat/lon changes
  }, [location])

  if (!location || !location.latitude || !location.longitude) return null
  const { latitude, longitude } = location

  return (
    <SelectableGoogleHeroMarker
      id={assigneeEntityId.toString()}
      type={NetworkMarkerMapping[network]}
      latitude={latitude}
      longitude={longitude}
      onClick={() => {}}
    >
      <Tooltip sticky={showTooltip} xOffset={LEFT_SIDEBAR_WIDTH} id={assigneeEntityId.toString()}>
        <ParticipantTooltip participant={participant} network={network} />
      </Tooltip>
    </SelectableGoogleHeroMarker>
  )
}

export default ParticipantMarker
