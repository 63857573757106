import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { isArray, mergeWith } from 'lodash-es'
// @ts-ignore
import createFocusDecorator from 'final-form-focus'
import { Form } from 'react-final-form'
import { Label, Paragraph } from 'components/primitives'
import Button from 'components/Button'
import { TowExchange } from 'types/callstore'
import DispatchService from 'components/Dispatch/DispatchService'
import { ErrorMessage } from 'components/fields/Error'
import Input from 'components/Input'
import { useUpdateAssignment } from 'hooks/assignment'
import { useAAADispatch, useTowExchangeUpdateEmail } from 'hooks/aaa'
import { useCallEvent, useUpdateEvent } from 'hooks/events'
import { isRequired } from 'utils/validators'
import { applyTimestamp } from './utils'
import EventVehicle from 'components/EventVehicle'
import { BreakdownNote } from 'forms/CallForm/BreakdownDetails'
import { useSelector } from 'react-redux'
import { selectFormsState, selectSmartAssignment } from 'redux/appStore'
import { DynamoEvents, EventTypes } from 'types/events'
import { Branch, EitherOrBoth } from 'types/global'
import { LocationPayload } from 'types/location'
import { formatErrors } from 'components/FormManager/Submit'

const focusOnError = createFocusDecorator()

type Props = {
  dispatchEvent?: DynamoEvents
  onUpdated: () => void
  children: React.ReactNode
}

const UpdateTowFormProviders: React.FC<Props> = (props) => {
  const stepStore = Ariakit.useCompositeStore({ defaultActiveId: '1' })
  return (
    <Ariakit.CompositeProvider store={stepStore}>
      <Ariakit.Composite store={stepStore}>
        <UpdateTowForm {...props} />
      </Ariakit.Composite>
    </Ariakit.CompositeProvider>
  )
}

const UpdateTowForm: React.FC<Props> = ({ dispatchEvent, onUpdated, children }) => {
  const { t } = useTranslation()
  const forms = useSelector(selectFormsState)
  const towEvent = useCallEvent(EventTypes.TOW)
  const exchangeEvent = useCallEvent(EventTypes.EXCHANGE)
  const event = dispatchEvent || towEvent?.event || exchangeEvent?.event
  const updateEvent = useUpdateEvent()

  const {
    dispatch,
    notes,
    serviceLocations: oldServiceLocations,
    breakdownOption,
    unsafe,
    garageAccessible,
    accessible,
  } = event?.attributes || {}
  const callKey = isArray(dispatch) ? dispatch?.[0]?.callKey : null
  const { handleUpdate } = useAAADispatch(callKey)

  const sendTowExchangeUpdateEmail = useTowExchangeUpdateEmail()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)
  const controlsContext = Ariakit.useCompositeContext()
  const currentStepid = controlsContext?.useState('activeId')
  const controlsItems = controlsContext?.useState('items') || []
  const isLast = Number(currentStepid) === controlsItems.length
  const { participant: newParticipant } = useSelector(selectSmartAssignment)
  const updateAssignment = useUpdateAssignment()
  const timezone = DateTime.local().offsetNameLong?.split(' ')[0] || '(Timezone not found)'

  return (
    <>
      <Form
        onSubmit={async (values) => {
          if (!loading) {
            setError(null)
            setLoading(true)
            const oldBranch = (event?.attributes as TowExchange).branch as EitherOrBoth<LocationPayload, Branch>
            const newBranch = (values as TowExchange).branch as EitherOrBoth<LocationPayload, Branch>
            const updatedBranchValue = newBranch && oldBranch && applyTimestamp(oldBranch, newBranch, timezone)

            try {
              await forms.caller.submit() // trigger any errors in caller form
              const callerState = mergeWith({}, forms.caller.getState(), forms.caller.getState().values)
              if (!callerState.valid) {
                let errorKeys = Object.keys(callerState.errors as any)
                if (errorKeys.includes('drivers')) {
                  if (callerState.values.selectedCaller === 'account' || callerState.values.selectedCaller === 'other') {
                    errorKeys = errorKeys.filter((element) => element !== 'drivers')
                  }
                }
                if (errorKeys.length) {
                  setError(formatErrors(errorKeys))
                  setLoading(false)
                  return
                }
              }

              const { data } = await handleUpdate(values as TowExchange)
              try {
                // Update and Dispatch a new assignment if the `participant` has changed
                const oldParticipant = oldServiceLocations.vehicleDestinationLocation

                if (newParticipant && oldParticipant?.assigneeEntityId !== newParticipant.assigneeEntityId) {
                  await updateAssignment(values)
                }
                await sendTowExchangeUpdateEmail(values as TowExchange)
              } catch (e) {
                console.error(e)
              }

              if (event)
                await updateEvent({
                  ...event,
                  serviceLocations: values.serviceLocations,
                  attributes: {
                    ...event.attributes,
                    ...values,
                    ...(updatedBranchValue && { branch: updatedBranchValue }),
                    serviceLocations: values.serviceLocations,
                    dispatch: [data],
                  },
                })
              setLoading(false)
              onUpdated()
            } catch (e) {
              console.error(e)
              setError(t('error.submit') as string)
              setLoading(false)
            }
          }
        }}
        decorators={[focusOnError]}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <EventVehicle
              vehicleInDispatch={event?.attributes?.vehicle}
              vehicleConditionFromEvent={event?.attributes?.vehicleCondition}
            />
            {children}
            {isLast ? (
              <>
                <BreakdownNote
                  breakdownOption={breakdownOption}
                  unsafe={unsafe}
                  garageAccessible={garageAccessible}
                  accessible={accessible}
                />
                <Input.Textarea
                  name="notes"
                  label={`${t('notesToProvider.label')}*`}
                  defaultValue={notes as unknown as HTMLInputElement}
                  maxLength={240}
                  validate={isRequired(t('required.notes'))}
                />
              </>
            ) : (
              <>
                <Label>{t('notesToProvider.label')}</Label>
                <Paragraph spacing="md">{notes}</Paragraph>
              </>
            )}
            <DispatchService dispatch={dispatch} disableCancel />
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Button.Primary type="submit" loading={loading} disabled={!isLast} style={{ width: '100%' }}>
              {t('confirmChanges')}
            </Button.Primary>
          </form>
        )}
      </Form>
    </>
  )
}

export default UpdateTowFormProviders
