import _ from 'lodash'
import { config } from '../../config'

const { envName, exchangeEmailsCsv, ldrEmailsCsv, recoveryEmailsCsv, zendeskEmailsCsv, testEmailsCsv } = config

type EmailRecipients = Record<'EXCHANGE' | 'LDR' | 'RECOVERY' | 'ESCALATE', string>

const Emails: EmailRecipients = {
  EXCHANGE: exchangeEmailsCsv || '',
  LDR: ldrEmailsCsv || '',
  RECOVERY: recoveryEmailsCsv || '',
  ESCALATE: zendeskEmailsCsv || '',
}

const EmailTemplates = {
  EXCHANGE: '#@ehi.com',
  EXCHANGE_GPBR: 'ERAC#@ehi.com',
  RECOVERY: '#_Roadside_Contacts@em.com',
}

const prod = envName === 'prod'

export const getEmailRecipients = (recipientsList) => {
  const recipients = recipientsList.split(',')
  return recipients.map((recipient) => ({ address: recipient }))
}

export const Recipients: {
  [key: string]: (arg?: any) => string | string[]
} = {
  EXCHANGE: ({ exchangeGPBR, peopleSoftId }) => {
    if (prod) {
      const peopleSoftIdEmails = EmailTemplates.EXCHANGE.replace('#', peopleSoftId)
      const gpbrEmails = EmailTemplates.EXCHANGE_GPBR.replace('#', exchangeGPBR)
      const emails = peopleSoftIdEmails.concat(',', gpbrEmails)
      return emails.concat(',', Emails.EXCHANGE)
    }
    return fallback()
  },
  LDR: () => {
    if (prod) {
      return Emails.LDR
    }

    return fallback()
  },
  ESCALATE: () => {
    if (prod) {
      return Emails.ESCALATE
    }

    return fallback()
  },
  RECOVERY: ({ rentalGroup, recoveryGroup }) => {
    if (prod) {
      const groups = _.uniq([rentalGroup, recoveryGroup])
        .map((group) => EmailTemplates.RECOVERY.replace('#', group))
        .join(',')
      return groups.concat(',', Emails.RECOVERY)
    }

    return fallback()
  },
}

const fallback = () => testEmailsCsv || ''

export default Recipients
