import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ListItem from 'components/List/ListItem'

export enum ConnectedErrors {
  RENT = 'RENT',
  NOT_RENT = 'NOT_RENT',
  MASKED = 'MASKED',
  TAMPERED = 'TAMPERED',
  METRICS = 'METRICS',
  LOCATION = 'LOCATION',
  MULTICALL = 'MULTICALL',
  CANTCONNECT4xxRESPONSE = 'CANT_CONNECT4xxResponse',
}

export const ConnectedError = ({ error }: { error: string | null }) => {
  const { t } = useTranslation()

  if (error) {
    if (error.includes(ConnectedErrors.RENT))
      return (
        <ListItem.Text style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
          {t('connectedCar.vehicleOnRent')}
        </ListItem.Text>
      )
    else if (error.includes(ConnectedErrors.NOT_RENT))
      return (
        <ListItem.Text style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
          {t('connectedCar.vehicleNotOnRent')}
        </ListItem.Text>
      )
    else if (error.includes(ConnectedErrors.MASKED))
      return (
        <ListItem.Text style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
          {t('connectedCar.vehicleMasked')}
        </ListItem.Text>
      )
    else if (error.includes(ConnectedErrors.TAMPERED))
      return (
        <ListItem.Text style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
          {t('connectedCar.vehicleTampered')}
        </ListItem.Text>
      )
    else if (error.includes(ConnectedErrors.METRICS))
      return (
        <ListItem.Text style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
          {t('connectedCar.vehicleMetrics')}
        </ListItem.Text>
      )
    else if (error.includes(ConnectedErrors.LOCATION))
      return (
        <ListItem.Text style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
          {t('connectedCar.vehicleLocation')}
        </ListItem.Text>
      )
    else if (error.includes(ConnectedErrors.MULTICALL))
      return (
        <ListItem.Text style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
          {t('connectedCar.vehicleMultiCall')}
        </ListItem.Text>
      )
    else if (error.includes(ConnectedErrors.CANTCONNECT4xxRESPONSE))
      return (
        <ListItem.Text style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
          {t('connectedCar.cantConnect4xxResponse')}
        </ListItem.Text>
      )
  }
  return null
}

export default ConnectedError
