export const isRequired =
  (errorMessage: string = 'Required') =>
  (value: any) =>
    value ? undefined : errorMessage

export const isCheckboxRequired =
  (errorMessage: string = 'Required') =>
  (value: any) =>
    value === true ? undefined : errorMessage

export const isScheduledAtRequired = (errorMessage: string = 'Required', scheduledAt: any) =>
  scheduledAt !== null ? undefined : errorMessage

export const isValidDate =
  (errorMessage: string = 'Valid date required') =>
  (value: any) => {
    const dateRegex = /\d{2}\/\d{2}\/\d{4}/
    if (!value || !value.match(dateRegex)) return errorMessage
    const InputDate = new Date(value)
    const currentDate = new Date()
    const isValid = currentDate >= InputDate
    return isValid ? undefined : errorMessage
  }
