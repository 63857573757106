import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FormState } from 'final-form'
import { Field as FieldValue, useForm } from 'react-final-form'
import { useCallerLocation, useVehicleLocation } from 'hooks/redux/useLocations'
import { EventTypes } from 'types/events'
import useReduxExchange from 'hooks/redux/useReduxExchange'
import { Exchange as TExchange } from 'types/callstore'
import Exchange from 'components/Exchange'
import { Submit } from 'components/fields'
import { FormattedCaller } from 'components/fields/Submit'
import Margin from 'components/Margin'
import { Flex, Label, Paragraph } from 'components/primitives'
import Location from 'components/Input/Location'
import useFieldValue from 'hooks/utils/useFieldValue'
import { useExchangeEmail } from 'hooks/tickets'
import { ExchangeEmailValues } from 'hooks/tickets/useExchangeEmail'
import { BackNavigation } from 'components/Step'
import Divider from 'components/Divider'
import useCreateServiceEvent from 'hooks/events/useCreateServiceEvent'
import { transformTowLocation } from '../EscalateForm/utils'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser, selectTicket } from 'redux/appStore'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

const DriveIn: React.FC = () => {
  const { t } = useTranslation()
  const form = useForm()
  const branch = useFieldValue('branch')
  const callerLocation = useCallerLocation()
  const vehicleLocation = useVehicleLocation()
  const bypassReason = useFieldValue('bypassReasonSelect')
  React.useEffect(() => {
    form.change('type', 'driveExchange')
  }, [form])

  return (
    <>
      <Margin spacing="md">
        <Margin spacing="md">
          {callerLocation ? <Location.Caller name="callerLocation" /> : <Location.Vehicle name="vehicleLocation" />}
        </Margin>
      </Margin>
      <Margin spacing="md">
        <Divider />
      </Margin>
      {branch && (
        <BackNavigation
          onClick={() => {
            form.change('branch', null)
          }}
        />
      )}
      <Exchange name="branch" searchLocation={callerLocation || vehicleLocation} isTowExchange={false} />

      {branch && (
        <>
          <Label>{t('labels.vehicleAccommodationSelect')}</Label>
          <FieldValue name="vehicleAccommodation" subscription={{ value: true }}>
            {({ input: { value } }) => <Paragraph spacing="md">{value}</Paragraph>}
          </FieldValue>
          <Label>{t('labels.accommodationNotes')}</Label>
          <FieldValue name="accommodationNotes" subscription={{ value: true }}>
            {({ input: { value } }) => <Paragraph spacing="md">{value}</Paragraph>}
          </FieldValue>
          <Label>{t('labels.disabledVehicleSelect')}</Label>
          <FieldValue name="disabledVehicle" subscription={{ value: true }}>
            {({ input: { value } }) => <Paragraph spacing="md">{value}</Paragraph>}
          </FieldValue>
          <Label>{t('labels.describeIssue')}</Label>
          <FieldValue name="exchangeNotes" subscription={{ value: true }}>
            {({ input: { value } }) => <Paragraph spacing="md">{value}</Paragraph>}
          </FieldValue>
          {bypassReason ? (
            <>
              <Label>{t('labels.bypassReasonSelect')}</Label>
              <FieldValue name="bypassReason" subscription={{ value: true }}>
                {({ input: { value } }) => <Paragraph spacing="md">{value}</Paragraph>}
              </FieldValue>
            </>
          ) : null}
          <SubmitDriveIn />
        </>
      )}
    </>
  )
}

const SubmitDriveIn: React.FC = () => {
  const { t } = useTranslation()
  const currentUser = useSelector(selectCurrentUser)
  const agentEID = currentUser!.nameID
  const updateExchangeLocation = useReduxExchange()
  const { ticketNumber, brandId, pickupInfo, vehicle } = useSelector(selectTicket)
  const sendEmail = useExchangeEmail()
  const createServiceEvent = useCreateServiceEvent({ eventType: EventTypes.EXCHANGE })
  const dispatch = useDispatch()

  async function handleSubmit(state: FormState<any>, caller: FormattedCaller) {
    const { exchangeNotes, vehicleAccommodation, accommodationNotes, bypassReason, disabledVehicle, branch } =
      state.values as TExchange
    const groupBranchNumber = branch?.additional_data?.group_branch_number
    const stationId = branch?.station_id
    const latitude = branch?.gps?.latitude
    const longitude = branch?.gps?.longitude
    const peopleSoftId = branch?.id
    const locationNameTranslation = branch?.name
    const brand = branch?.brand
    const formattedPhone = branch?.additional_data?.formatted_phone
    const formattedCityStateZip = branch?.additional_data?.formatted_city_state_zip
    const addressLines = branch?.address?.street_addresses[0]

    if (latitude && longitude) {
      updateExchangeLocation({
        address: `${addressLines}<br/>${formattedCityStateZip}` || '',
        latitude: latitude,
        longitude: longitude,
      })
    }

    try {
      const event = await createServiceEvent({
        exchangeBrand: brand,
        exchangeGPBR: groupBranchNumber,
        peopleSoftId,
        formattedPhone,
        addressLines,
        formattedCityStateZip,
        locationNameTranslation,
        notes: exchangeNotes,
        vehicleAccommodation: vehicleAccommodation,
        accommodationNotes: accommodationNotes,
        disabledVehicle: disabledVehicle,
        bypassReason: bypassReason,
        stationId,
        rentalBrand: brandId,
        rentalGPBR: pickupInfo.groupBranchId,
        rentalStationId: pickupInfo.branch.stationId,
        ticketNumber: ticketNumber,
        vinLast8: vehicle!.vinLast8,
        agentEID,
        branchId: peopleSoftId,
      })
      await sendEmail(
        {
          ...state.values,
          towLocation: transformTowLocation(state.values.towLocation),
          methodOfDelivery: state.values.methodOfDelivery,
          vehicleCondition: state.values.vehicleCondition || 'Other - No Previous Tow Found',
        } as ExchangeEmailValues,
        caller,
      )
      dispatch(setCurrentEvent({ eventType: EventTypes.EXCHANGE, newEvent: event.data }))
    } catch (e) {
      console.error(e)
      return t('error.driveInExchange')
    }
  }

  return (
    <Flex>
      <Submit onSubmit={handleSubmit}>{t('submit')}</Submit>
    </Flex>
  )
}

export default DriveIn
