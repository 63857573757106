import * as React from 'react'
import { MdSubject } from 'react-icons/md'
import * as Ariakit from '@ariakit/react'
import Float from 'components/Float'
import CircleAction from 'components/CircleAction'
import { pxToRem } from 'theme/utils'
import { calypso } from 'theme/colors'
import { TransferIcon } from 'icons'
import Transfer from './Transfer'
import QuickNotes from './QuickNotes'

const Actions: React.FC = () => {
  const quickNotesStore = Ariakit.useDisclosureStore()
  const transferStore = Ariakit.useDisclosureStore()

  const quickNotesStoreIsOpen = quickNotesStore.useState('open')
  const transferStoreIsOpen = transferStore.useState('open')

  return (
    <>
      <Ariakit.DisclosureProvider store={quickNotesStore}>
        <Ariakit.DisclosureProvider store={transferStore}>
          <Float
            placement="bottomRight"
            style={{
              marginBottom: pxToRem(24),
              marginRight: pxToRem(24),
            }}
          >
            {!transferStoreIsOpen && !quickNotesStoreIsOpen && (
              <>
                <Ariakit.Disclosure
                  store={quickNotesStore}
                  style={{ backgroundColor: 'unset', border: 0, padding: 0, marginRight: pxToRem(24) }}
                >
                  <CircleAction icon={MdSubject} label="Quicknotes" color={calypso} />
                </Ariakit.Disclosure>
                <Ariakit.Disclosure store={transferStore} style={{ backgroundColor: 'unset', border: 0, padding: 0 }}>
                  <CircleAction label="Transfer" icon={TransferIcon} color={calypso} />
                </Ariakit.Disclosure>
              </>
            )}
          </Float>
          <Ariakit.DisclosureContent
            store={transferStore}
            style={{ position: 'absolute', height: '100%', padding: 24, bottom: 0, right: 0 }}
          >
            <Transfer />
          </Ariakit.DisclosureContent>
          <Ariakit.DisclosureContent store={quickNotesStore} style={{ position: 'fixed', margin: 30, bottom: 0, right: 0 }}>
            <QuickNotes />
          </Ariakit.DisclosureContent>
        </Ariakit.DisclosureProvider>
      </Ariakit.DisclosureProvider>
    </>
  )
}
export default Actions
