import * as React from 'react'
import { Label } from 'components/primitives'
import TableRow from 'components/TableRow'
import { Day } from './BranchLocation'

type Props = {
  hours: Day[]
}

export const HoursTable: React.FC<Props> = ({ hours }) => {
  return (
    <>
      {hours.map((h, i) => (
        <TableRow key={i}>
          <Label>{h.day}</Label>
          <Label>{h.hours}</Label>
        </TableRow>
      ))}
    </>
  )
}

export default HoursTable
