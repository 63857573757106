import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useTranslation } from 'react-i18next'
//@ts-expect-error
import { PatternFormat, PatternFormatProps } from 'react-number-format'
import { Label } from 'components/primitives'
import Input from './Input'

export type Props = PatternFormatProps & FieldRenderProps<HTMLInputElement> & Omit<React.ComponentProps<typeof Input>, 'ref'>

export const Date: React.FC<Props> = ({ input, meta, ...rest }) => {
  const { t } = useTranslation()
  const type = input && input.type ? (input.type as PatternFormatProps['type']) : rest.type

  return (
    <>
      <PatternFormat
        {...(input as any)}
        {...rest}
        type={type}
        format={t('##/##/####')}
        placeholder={t('  /  /    ')}
        customInput={Input}
      />
      <Label>{t('MM/DD/YYYY')}</Label>
    </>
  )
}

export default Date
