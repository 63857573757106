import * as React from 'react'
import Input, { InputProps } from 'components/Input'

export const BypassReasonSelect: React.FC<InputProps> = props => {
  return <Input.Select placeholder="--" options={bypassReasons} isClearable {...props} />
}
export default BypassReasonSelect

const bypassReasons = [
  { label: 'Branch is closed', value: 'closed' },
  { label: 'Customer Convenience', value: 'convenience' },
]
