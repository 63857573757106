import * as React from 'react'
import * as Yup from 'yup'
import { FormApi } from 'final-form'
import { useTranslation } from 'react-i18next'
import { Form } from 'components/FormManager'
import { Flex, Label } from 'components/primitives'
import Margin from 'components/Margin'
import { StateSelect } from 'components/Select'
import Input from 'components/Input'
import { useReduxCallStoreActions } from 'hooks/events'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

declare module 'types/form' {
  export interface Forms {
    officer: FormApi
  }
}

export const OfficerForm: React.FC = () => {
  const { update } = useReduxCallStoreActions()
  const { officer } = useSelector(selectFormsState) as any
  const { t } = useTranslation()

  return (
    <Form name="officer" schema={officerSchema} initialValues={officer} autoSaveSync={(officer) => update({ officer })}>
      <Input label={`${t('labels.officer.email')}*`} name="email" />
      <Margin spacing="md" style={{ display: 'inline-flex' }}>
        <Input.Checkbox name="isVerified" label={t('labels.officer.verified')} />
      </Margin>
      <Input label={`${t('labels.officer.title')}*`} name="title" />
      <Input label={`${t('labels.officer.badge')}*`} name="badge" />
      <Margin use={Flex} spacing="md">
        <Label style={{ flex: 5 }} gutter="md">
          <Input.PhoneNumber label={`${t('labels.officer.deskPhone')}`} name="deskPhone" />
        </Label>
        <Label style={{ flex: 4 }}>
          <Input label={`${t('labels.officer.deskPhoneExt')}`} name="deskPhoneExt" />
        </Label>
      </Margin>
      <Input label={`${t('labels.officer.department')}*`} name="department" />
      <Input label={`${t('labels.officer.cityAndCounty')}*`} name="cityAndCounty" />
      <StateSelect label={`${t('labels.officer.stateOrProvince')}*`} name="stateOrProvince" />
    </Form>
  )
}

export const officerSchema = Yup.object().shape({
  title: Yup.string().nullable(true).required('Officer Title is required'),
  badge: Yup.string().nullable(true).required('Officer Badge is required'),
  email: Yup.string().nullable(true).required('Officer Email is required'),
  department: Yup.string().nullable(true).required('Department / Agency Name is required'),
  cityAndCounty: Yup.string().nullable(true).required('City and County of Agency is required'),
  stateOrProvince: Yup.object()
    .shape({
      label: Yup.string().nullable(true),
      value: Yup.string().nullable(true).required('State / Province is required'),
    })
    .nullable(true)
    .required('State / Province is required'),
})
