import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import CircleIconSize from './CircleIconSize'
import { StyleProps } from 'types/global'

type CircleProps = {
  circleSize?: string
  color?: string
} & StyleProps

export const Circle = styled.div<CircleProps>`
  display: flex;
  background-color: ${palette('black')};
  color: ${({ color }) => color || palette('white')};
  width: ${({ circleSize }) => circleSize || CircleIconSize.SMALL};
  height: ${({ circleSize }) => circleSize || CircleIconSize.SMALL};
  align-items: center;
  border-radius: 50%;
  justify-content: center;
`

export default Circle
