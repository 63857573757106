import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdArrowDownward } from 'react-icons/md'
import { Label } from 'components/primitives'
import Button from 'components/Button'
import Section from 'components/Section'
import QuickNoteForm from 'forms/QuickNoteForm/QuickNoteForm'
import * as Ariakit from '@ariakit/react'

function QuickNotes() {
  const { t } = useTranslation()
  const container = Ariakit.useDisclosureContext()

  return (
    <Section
      title={t('Quick Notes')}
      action={<Button onClick={container?.hide} style={{ padding: 0 }} iconBefore={MdArrowDownward} />}
    >
      <Section.Body>
        <div style={{ width: 340 }}>
          <Label style={{ marginBottom: 20 }}>
            {t('These notes will be applied to the call details when a ticket is selected.')}
          </Label>
          <QuickNoteForm />
        </div>
      </Section.Body>
    </Section>
  )
}
export default QuickNotes
