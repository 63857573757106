import * as React from 'react'
import { getCountries } from 'libphonenumber-js'
import { alpha2ToAlpha3 } from 'i18n-iso-countries'
import Input, { InputProps } from 'components/Input'
const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
export const alpha3Options = getCountries().map((country) => ({
  label: regionNames.of(country),
  value: alpha2ToAlpha3(country),
}))
export const CountrySelect: React.FC<InputProps> = (props) => (
  <Input.Select {...props} placeholder="--" isClearable options={alpha3Options} />
)
export default CountrySelect
