import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdDirectionsCar, MdPerson } from 'react-icons/md'
import styled from 'styled-components/macro'
import Divider from 'components/Divider'
import { Flex } from 'components/primitives'
import { MarkerType } from 'types/googleMaps'
import useTempMarker from 'hooks/map/useTempMarker'
import Button from '../Button'

type Props = {
  onAdd: (type: MarkerType) => void
}

const StyledButton = styled(Button as any)({ padding: 0 })
const Separator = styled(Divider as any).attrs({
  orientation: 'vertical',
})({ borderColor: 'black', margin: '0 8px' })

function TempMarkerOptions({ onAdd }: Props) {
  const { t } = useTranslation()
  const { marker } = useTempMarker()
  if (!marker) return null

  return (
    <Flex style={{ justifyContent: 'space-between', alignItems: 'center', padding: 5 }}>
      <i>{t('labels.setMarkerAs')}</i>
      <Flex>
        <StyledButton iconBefore={MdPerson} onClick={() => onAdd(MarkerType.CALLER)} />
        <Separator />
        <StyledButton iconBefore={MdDirectionsCar} onClick={() => onAdd(MarkerType.VEHICLE)} />
      </Flex>
    </Flex>
  )
}

export default TempMarkerOptions
