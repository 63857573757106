import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { GeocodeAddress } from 'types/googleMaps'
import { ConnectedCarLocation, ConnectedCarMetrics, ConnectedMetrics } from 'types/kong'
import { MarkerType } from 'types/googleMaps'
import { LEFT_SIDEBAR_WIDTH } from 'pages/Ticket/LeftSidebar'
import { MarkerTypeLabels } from 'pages/Ticket/HeroMap/Markers/utils'
import { ConnectedCar as ConnectedCarDetails } from 'pages/Ticket/HeroMap/Markers/Tooltips'
import { Tooltip } from 'components/Tooltip'
import { reverseGeocode } from 'components/useReverseGeocoder'
import { GoogleHeroMarker } from 'pages/Ticket/HeroMap/Markers/GoogleMapMarkers'
import { useSelector } from 'react-redux'
import { ReduxState, selectSidebarState } from 'redux/appStore'
import { useGoogleMaps } from 'hooks/map'
import { useServiceProxy } from 'hooks/kong'

type MarkerTypes = {
  location: ConnectedCarLocation
  metrics: ConnectedCarMetrics | null
  vin: string
}

const defaultMetrics: ConnectedMetrics = {
  fuel: undefined,
  odometer: undefined,
  ignitionStatus: undefined,
}

export const ConnectedCarMarker = ({ location, metrics, vin }: MarkerTypes) => {
  const { t } = useTranslation()
  const { map } = useGoogleMaps()
  const isLeftSidebarOpen = useSelector((state: ReduxState) => selectSidebarState(state)('left'))

  const proxy = useServiceProxy()
  const [address, setAddress] = React.useState<GeocodeAddress | null>(null)

  // Set the address and move the marker
  React.useEffect(() => {
    const getAddress = async () => {
      const address = await reverseGeocode(proxy, location.geoLocation!.latitude, location.geoLocation!.longitude)
      if (map && address && address !== 'ZERO_RESULTS') {
        setAddress(address)
        map!.panTo(new google.maps.LatLng(location.geoLocation!.latitude, location.geoLocation!.longitude))
        map!.setZoom(12)
      } else {
        setAddress(null)
      }
    }
    getAddress()
  }, [location, map])

  const { geoLocation, timestamp: locationTimestamp } = location
  const latitude = geoLocation!.latitude
  const longitude = geoLocation!.longitude
  const vehicleMetrics = metrics?.metrics ?? defaultMetrics

  const [isHidden, setIsHidden] = React.useState(false)
  const hideTooltip = () => setIsHidden(true)
  const showTooltip = () => setIsHidden(false)

  return (
    <GoogleHeroMarker type={MarkerType.CONNECTEDCAR} latitude={latitude} longitude={longitude} onClick={showTooltip}>
      {!isHidden && (
        <Tooltip id="connectedTooltip" xOffset={isLeftSidebarOpen ? LEFT_SIDEBAR_WIDTH : undefined}>
          <ConnectedCarDetails
            title={t(MarkerTypeLabels['CONNECTEDCAR'] as string)}
            address={address}
            latitude={latitude}
            longitude={longitude}
            lastUpdatedAt={locationTimestamp as string}
            vin={vin}
            handleClose={hideTooltip}
            {...vehicleMetrics}
          />
        </Tooltip>
      )}
    </GoogleHeroMarker>
  )
}
