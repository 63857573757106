import React from 'react'
import * as Ariakit from '@ariakit/react'
import { Dialog } from '../Dialog'
import { Flex } from '../primitives'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import { calypso } from '../../theme/colors'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from 'redux/appStore'
import { useMsal } from '@azure/msal-react'
import { setCurrentUser } from 'redux/auth/authSlice'
import { useReduxCallStoreActions } from 'hooks/events'

export default function SessionTimeoutModal() {
  const [isWarned, setIsWarned] = React.useState(false)
  const [countdown, setCountdown] = React.useState(60)
  const [loggedOut, setLoggedOut] = React.useState(false)
  const warnDialog = Ariakit.useDialogStore()
  const logoutDialog = Ariakit.useDialogStore()
  const { t } = useTranslation()
  const currentUser = useSelector(selectCurrentUser)
  const { instance } = useMsal()
  const dispatch = useDispatch()
  const { reset } = useReduxCallStoreActions()

  let sessionWarning = 60000 * 24 // Warn after 24 mins of inactivity, logs user out after another 60 seconds
  let warnTimeout: any = React.useRef(null)

  const continueSession = () => {
    setIsWarned(false)
    setCountdown(60)
    warnDialog.hide()
    if (warnTimeout.current) {
      clearTimeout(warnTimeout.current)
    }
  }

  const reload = () => {
    window.location.href = window.location.pathname
  }

  React.useEffect(() => {
    if (currentUser && !loggedOut) {
      const events = ['load', 'mousedown', 'click', 'scroll', 'keypress']

      const warn = () => {
        setIsWarned(true)
      }

      const resetTimeout = () => {
        if (warnTimeout.current) {
          clearTimeout(warnTimeout.current)
        }
        warnTimeout.current = setTimeout(warn, sessionWarning)
      }

      for (let i in events) {
        window.addEventListener(events[i], resetTimeout)
      }

      warnTimeout.current = setTimeout(warn, sessionWarning)
      return () => {
        for (let i in events) {
          window.removeEventListener(events[i], resetTimeout)
        }
      }
    }
  }, [currentUser, sessionWarning, loggedOut])

  React.useEffect(() => {
    async function logout() {
      if (!loggedOut) {
        try {
          // Silent logout to prevent navigate to microsoft logout page
          instance.logoutRedirect({
            onRedirectNavigate(url) {
              return false
            },
          })
          dispatch(setCurrentUser({ user: null }))
          setLoggedOut(true)
        } catch (e) {
          console.error(e)
        }
      }
    }

    if (isWarned && countdown > 0 && currentUser) {
      warnDialog.show()
      const timer = setInterval(() => setCountdown(countdown - 1), 1000)
      return () => clearInterval(timer)
    }
    if (isWarned && countdown <= 0 && currentUser && !loggedOut) {
      logout().then((result) => {
        logoutDialog.show()
        reset()
        warnDialog.hide()
      })
    }
  }, [isWarned, countdown, currentUser, logoutDialog, warnDialog, loggedOut, dispatch, instance])

  return (
    <>
      <Ariakit.DialogProvider store={warnDialog}>
        <Dialog {...warnDialog} aria-label="Session Timeout Warning Modal" timeout={true} hideOnEsc={false}>
          <div
            style={{
              fontSize: '24px',
              background: 'lightblue',
              textAlign: 'center',
              width: '100%',
              borderTopLeftRadius: '.2em',
              borderTopRightRadius: '.2em',
              paddingTop: '7px',
              paddingBottom: '7px',
              color: calypso,
              fontWeight: 'bold',
            }}
          >
            {t('Session Expiring in ' + countdown + ' seconds.')}
          </div>
          <hr style={{ color: calypso, backgroundColor: calypso, height: '7px', marginTop: '0' }} />
          <div style={{ textAlign: 'center', paddingLeft: '5px', paddingRight: '5px' }}>
            {t(
              "To protect the security of our customers' data and information, your session will expire in " +
                countdown +
                ' seconds.',
            )}
            <br />
            {t('Click OK to use the existing session prior to expiration')}
          </div>
          <Ariakit.Separator />
          <Flex style={{ justifyContent: 'center', paddingBottom: '8px' }}>
            <Button.Primary style={{ marginRight: '25px' }} onClick={() => continueSession()}>
              {t('OK')}
            </Button.Primary>
          </Flex>
        </Dialog>
      </Ariakit.DialogProvider>
      <Ariakit.DialogProvider store={logoutDialog}>
        <Dialog {...logoutDialog} aria-label="Session Logged Out Modal" timeout={true} hideOnEsc={false}>
          <div
            style={{
              fontSize: '24px',
              background: 'lightblue',
              textAlign: 'center',
              width: '100%',
              borderTopLeftRadius: '.2em',
              borderTopRightRadius: '.2em',
              paddingTop: '7px',
              paddingBottom: '7px',
              color: calypso,
              fontWeight: 'bold',
            }}
          >
            {t('Session Expired')}
          </div>
          <hr style={{ color: calypso, backgroundColor: calypso, height: '7px', marginTop: '0' }} />
          <div style={{ textAlign: 'center', paddingLeft: '5px', paddingRight: '5px' }}>
            {t(
              "To protect the security of our customers' data and information, we have expired this session due to a period of inactivity.",
            )}
          </div>
          <Ariakit.Separator />
          <Flex style={{ justifyContent: 'center', paddingBottom: '8px' }}>
            <Button.Primary style={{ marginRight: '25px' }} onClick={() => reload()}>
              {t('Reload Page')}
            </Button.Primary>
          </Flex>
        </Dialog>
      </Ariakit.DialogProvider>
    </>
  )
}
