import * as React from 'react'
import { ULYCancelReasonCode } from 'types/urgently'
import { StylesConfig } from 'react-select/lib/styles'
import Input, { InputProps } from 'components/Input'

export const CancelUlyDispatchSelect: React.FC<InputProps> = (props) => {
  const options = Object.keys(ULYCancelReasonCode)
    .map((code) => {
      return { label: code, value: ULYCancelReasonCode[code as keyof typeof ULYCancelReasonCode] }
    })
    .filter(({ value }) => !isNaN(value))

  return (
    <Input.Select
      {...props}
      styles={{
        menuPortal: (styles: StylesConfig) => ({ ...styles, zIndex: 100000000 }),
      }}
      placeholder="--"
      isClearable
      options={options}
    />
  )
}

export default CancelUlyDispatchSelect
