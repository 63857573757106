import * as React from 'react'
import { debounce } from 'lodash-es'
import { stringify } from 'querystring'
import { useTranslation } from 'react-i18next'
import Async, { AsyncProps } from 'react-select/async'
import { Marker, MarkerType } from 'types/googleMaps'
import { GeocodeAddress, GMapsAutocompleteResponse, SuggestedPlace } from 'types/googleMaps'
import { useCreateUpdateLocation } from 'hooks/redux/useCreateUpdateLocation'
import { useTempMarker, useGoogleMaps } from 'hooks/map'
import { useDispatch, useSelector } from 'react-redux'
import { selectMapCenter, selectMapZoom } from 'redux/appStore'
import { transformExtendedAddress } from 'utils/aaa'
import { mercury, silver } from 'theme/colors'
import { convertToGeocodeAddress, reverseGeocode } from 'components/useReverseGeocoder'
import DropdownIndicator from './DropdownIndicator'
import TempMarkerOptions from './TempMarkerOptions'
import Option from './Option'
import { GMapsGeocodeResponse, GMapsPlaceResult, GMapsPredictions } from 'types/googleMaps'
import { ServiceProxyCall } from 'types/global'
import { useServiceProxy } from 'hooks/kong'
import { updateSinglePinView } from 'redux/tempMarker/tempMarkerSlice'
import { IndicatorSeparator } from 'components/IndicatorSeparator'

export const formatAddress = (title: string, vicinity: string) => `${title}<br/>${vicinity}`

type CallbackFn = (options: SuggestedPlace[] | GMapsPredictions[]) => void
type AdditionalSelectProps = {
  /** When provided it sets the marker to the corresponding MarkerType, bypassing the option selection */
  markerType?: MarkerType
  type?: string
}
type OmittedAsyncProps = Omit<AsyncProps<SuggestedPlace | GMapsPredictions, boolean, any>, 'loadOptions' | 'cacheOptions'>

type Props = OmittedAsyncProps & AdditionalSelectProps

// RDS-1175 making it 2 second instead of 250ms
const debounced = debounce(async (func) => func(), 2000)

const Places: React.FC<Props> = ({ markerType, type, ...selectProps }) => {
  const { t } = useTranslation()
  const createUpdateLocation = useCreateUpdateLocation()
  const proxy = useServiceProxy()
  const { map } = useGoogleMaps()
  const center = useSelector(selectMapCenter)
  const zoom = useSelector(selectMapZoom)
  const { marker, setMarker } = useTempMarker()
  const [place, setPlace] = React.useState<SuggestedPlace | null>(null)
  const [inputValue, setInputValue] = React.useState<string>('')
  const dispatch = useDispatch()
  const validCoordsColonSeparator = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?):\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/

  const validCoordsCommaSeparator = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/

  const handleSearchChange = (query: string = '', callback: CallbackFn) => {
    if (query.length > 1) {
      debounced(async () => {
        try {
          if (query.match(validCoordsColonSeparator)) {
            return convertLatLongCoords(query, proxy, callback, ':')
          } else if (query.match(validCoordsCommaSeparator)) {
            return convertLatLongCoords(query, proxy, callback, ',')
          } else {
            const params = {
              input: query,
              location: `${center.lat()},${center.lng()}`,
              radius: 500,
              ...(type && { types: type }),
            }
            return suggestedPlaceOptions(proxy, params, callback)
          }
        } catch (e) {
          console.error(e)
          return callback([])
        }
      })
    }
  }

  const handleFocus = () => {
    if (place) {
      const { label } = place
      if (label) {
        setInputValue(label)
      }
    }
  }

  return (
    <>
      <Async
        blurInputOnSelect={true}
        onFocus={handleFocus}
        onInputChange={(i, e) => {
          const { action } = e
          if (!i && action === 'input-change') {
            setMarker(null)
            setPlace(null)
          }
          setInputValue(i)
        }}
        inputValue={inputValue}
        isClearable
        cacheOptions
        loadOptions={handleSearchChange}
        components={{
          Option,
          DropdownIndicator,
          IndicatorSeparator: (props) => (props.hasValue ? <IndicatorSeparator {...props} /> : null),
        }}
        placeholder={t('Search for places and addresses')}
        styles={{
          control: (provided) => ({
            ...provided,
            border: `2px solid ${mercury}`,
            borderRadius: 0,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: silver,
          }),
          menu: (provided) => ({
            ...provided,
            margin: 0,
            borderRadius: 0,
            zIndex: 999,
          }),
          menuList: (provided) => ({
            ...provided,
            padding: 0,
          }),
          option: (provided) => ({
            ...provided,
            margin: 0,
            padding: 0,
          }),
        }}
        value={place}
        onChange={async (result) => {
          const place = result as SuggestedPlace & GMapsPlaceResult
          const mapInfo = { map, markerType, zoom }
          const updateFns = { createUpdateLocation, setMarker, setPlace }

          if (place) {
            dispatch(updateSinglePinView(true))
            handleMarkerPlacement(proxy, place, mapInfo, updateFns)
          } else {
            setMarker(null)
            setPlace(null)
          }
        }}
        {...selectProps}
      />
      {!markerType && (
        <TempMarkerOptions
          onAdd={(type) => {
            marker &&
              createUpdateLocation({
                ...marker,
                type,
              })
            setMarker(null)
            setPlace(null)
          }}
        />
      )}
    </>
  )
}

type MapInfo = {
  map: google.maps.Map | null
  markerType: MarkerType | undefined
  zoom: number
}
type UpdateFns = {
  createUpdateLocation: ({ type, address, latitude, longitude, href, place_id, extendedAddress }: Marker) => Promise<void>
  setMarker: (marker: Marker | null) => {
    payload: Marker | null | undefined
    type: 'tempMarker/updateTempMarker'
  }
  setPlace: React.Dispatch<React.SetStateAction<SuggestedPlace | null>>
}
async function handleMarkerPlacement(
  proxy: ServiceProxyCall,
  place: SuggestedPlace & GMapsPlaceResult,
  mapInfo: MapInfo,
  updateFns: UpdateFns,
) {
  const { map, markerType, zoom } = mapInfo
  const { createUpdateLocation, setPlace, setMarker } = updateFns
  const { position: loc, label, highlightedVicinity, href, address } = place as SuggestedPlace
  if (loc != null) {
    map && map.setCenter(new google.maps.LatLng(loc![0], loc![1]))
    map && map.setZoom(zoom < 12 ? 12 : zoom)

    const geocodeAddress = await reverseGeocode(proxy, loc[0], loc[1])
    const marker: Marker = {
      type: markerType,
      address: formatAddress(label || '', highlightedVicinity),
      latitude: loc[0],
      longitude: loc[1],
      href,
      ...(place.place_id && {
        place_id: place.place_id,
      }),
      ...(address && {
        extendedAddress: transformExtendedAddress(address),
      }),
      ...(!address &&
        geocodeAddress &&
        geocodeAddress !== 'ZERO_RESULTS' && {
          extendedAddress: transformExtendedAddress(geocodeAddress),
        }),
      ...(place && { place }),
    }
    if (markerType) createUpdateLocation(marker)
    else {
      setPlace(place as SuggestedPlace)
      if (loc) setMarker(marker)
      else setMarker(null)
    }
  }
}

type Params = {
  lat: number
  lon: number
  geocodeAddress?: GeocodeAddress | null
  separator?: string | null
}

const handleReverseGeocodeFromQuery = async (proxy: ServiceProxyCall, place: GMapsPredictions) => {
  let suggestedPlace
  try {
    const params = {
      fields: 'address_components,formatted_address,geometry,name,vicinity,place_id,types',
      place_id: place?.place_id || '',
    }

    const queryString = new URLSearchParams(params).toString()

    const { data } = await proxy<GMapsGeocodeResponse>('get', `/serviceproxy/googleMaps/geocode/json?${queryString}`, {}, {})

    if (data) {
      const latitude = data.results[0]?.geometry.location.lat
      const longitude = data.results[0]?.geometry.location.lng

      const queryParams = {
        latlng: `${latitude},${longitude}`,
        fields: 'address_components,formatted_address,geometry,name,vicinity,place_id,types',
      }
      const secondaryQueryString = new URLSearchParams(queryParams).toString()
      if (latitude && longitude) {
        const { data: geocodeData } = await proxy<GMapsGeocodeResponse>(
          'get',
          `/serviceproxy/googleMaps/geocode/json?${secondaryQueryString}`,
          {},
          {},
        )

        if (geocodeData && geocodeData.results) {
          const placeWithDetails = {
            ...place,
            ...geocodeData.results[0],
          } as GMapsPredictions & GMapsPlaceResult
          suggestedPlace = googleSuggestedPlace(placeWithDetails)
        }
      } else {
        const placeWithDetails = {
          ...place,
          ...data.results[0],
        } as GMapsPredictions & GMapsPlaceResult
        suggestedPlace = googleSuggestedPlace(placeWithDetails)
      }
    }
  } catch (e) {
    console.error(e)
    return null
  }

  return {
    category: suggestedPlace?.types,
    ...place!,
    ...suggestedPlace,
    highlightedTitle: suggestedPlace ? suggestedPlace.title : place?.description ? place?.description.split(',')[0] : '',
    highlightedVicinity: suggestedPlace
      ? suggestedPlace.highlightedVicinity
      : place?.description
        ? place?.description.split(',').slice(1)
        : '',
  }
}

const googleSuggestedPlace = (place: GMapsPredictions & GMapsPlaceResult): SuggestedPlace => {
  const googleAddress = convertToGeocodeAddress(place as any)

  let title = '',
    vicinity = ''
  if (googleAddress) {
    const { HouseNumber, Street, City, State, PostalCode } = googleAddress
    title = [HouseNumber, Street].filter(Boolean).join(' ')
    vicinity = [City, City && ',', State, PostalCode].filter(Boolean).join(' ')
  }

  const suggestedPlace = {
    label: '',
    value: [place.geometry.location.lat, place.geometry.location.lng],
    highlightedTitle: ``,
    highlightedVicinity: (googleAddress && googleAddress.Label) || 'Coordinates',
    id: place.place_id,
    title: place.types.includes('political') ? title : place.name,
    vicinity: place.types.includes('political') ? vicinity : place.formatted_address,
    position: [place.geometry.location.lat, place.geometry.location.lng],
    category: place.types,
    href: place.place_id,
    address: googleAddress,
  }

  if (place.structured_formatting) {
    suggestedPlace.label = place.structured_formatting!.main_text!
  } else {
    suggestedPlace.label = place?.formatted_address!
  }

  return suggestedPlace as SuggestedPlace
}

export async function placeDetails(proxy: ServiceProxyCall, id: GMapsPredictions['place_id']) {
  try {
    const params = {
      place_id: id,
      fields: 'address_components,formatted_address,geometry,name,vicinity,place_id,types',
    }
    const { data } = await proxy<GMapsGeocodeResponse>('get', `/serviceproxy/googleMaps/geocode/json?${stringify(params)}`)

    if (data) {
      if (data.results.length > 0) {
        const placeWithDetails = data.results[0] as unknown as GMapsPredictions & GMapsPlaceResult
        return googleSuggestedPlace(placeWithDetails)
      }
    }
  } catch (e) {
    console.error(e)
    return null
  }
}

const createSuggestedPlace = ({ lat, lon, geocodeAddress, separator }: Params): SuggestedPlace => {
  let title = '',
    vicinity = ''
  if (geocodeAddress) {
    const { HouseNumber, Street, City, State, PostalCode } = geocodeAddress
    title = [HouseNumber, Street].filter(Boolean).join(' ')
    vicinity = [City, City && ',', State, PostalCode].filter(Boolean).join(' ')
  }
  if (!separator) separator = ':'

  return {
    label: `${lat}${separator}${lon}`,
    value: [lat, lon],
    highlightedTitle: '',
    highlightedVicinity: (geocodeAddress && geocodeAddress.Label) || 'Coordinates',
    id: '',
    title,
    vicinity,
    position: [lat, lon],
    category: '',
    href: '',
    address: geocodeAddress,
  }
}

async function convertLatLongCoords(query: string, proxy: ServiceProxyCall, callback: CallbackFn, separator: string) {
  const coords = query.split(separator)
  const lat = parseFloat(coords[0])
  const lon = parseFloat(coords[1])
  if (!lat || !lon) return callback([])
  const response = await reverseGeocode(proxy, lat, lon)

  const geocodeAddress = response && response !== 'ZERO_RESULTS' ? response : response === 'ZERO_RESULTS' ? null : null
  const place = createSuggestedPlace({ lat, lon, geocodeAddress, separator })
  return callback([place])
}

type PlaceParams = {
  input: string
  location: string
  radius: number
}

async function suggestedPlaceOptions(proxy: ServiceProxyCall, params: PlaceParams, callback: CallbackFn) {
  try {
    const { data } = await proxy<GMapsAutocompleteResponse>(
      'get',
      `/serviceproxy/googleMaps/place/autocomplete/json?${stringify(params)}`,
    )

    const options = data.predictions.map(async (place) => handleReverseGeocodeFromQuery(proxy, place!))

    const results = await Promise.all(options.map((p) => p.catch((e) => e)))
    const suggestedOptions: SuggestedPlace[] = results.filter((result) => !(result instanceof Error))

    // Filter out null results before we show them in the dropdown
    return callback(suggestedOptions.filter(Boolean))
  } catch (e) {
    console.error(e)
  }
}

export default Places
