import { FormApi } from 'final-form'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { TicketCharges } from 'types/ticket'
import { EventTypes } from 'types/events'
import Complete from 'components/Complete'
import Margin from 'components/Margin'
import { Heading, Box, Label } from 'components/primitives'
import Submit from 'components/fields/Submit'
import { Form } from 'components/FormManager'
import Location from 'components/Input/Location'
import Waivers from 'components/Waivers'
import { AvailabilityIcons } from 'components/IconIndicator'
import Divider from 'components/Divider'
import MultipleChoice from 'components/Input/MultipleChoice'
import Input, { YesNoRadio, YesNoTriggers } from 'components/Input'
import { Condition } from 'components/fields'
import { CallTypes } from 'types/callstore'
import { VehicleSelect } from 'components/Select'
import { WorkflowAction } from 'components/Workflow'
import ScheduledAt from 'components/ScheduledAt'
import useCreateSoftService from 'hooks/aaa/useCreateSoftService'
import { useCreateUlySoftService, useUrgentlyProvider } from 'hooks/urgent.ly'
import { useReduxCallStoreActions, useCallEvent } from 'hooks/events'
import { isRequired } from 'utils/validators'
import JumpstartEvent from './JumpstartEvent'
import { BreakdownDetailsForm, BreakdownNote } from '../BreakdownDetails'
import { BreakdownOptions } from '../BreakdownDetails/BreakdownRadio'
import useCreateServiceEvent from '../../../hooks/events/useCreateServiceEvent'
import { useDispatch, useSelector } from 'react-redux'
import { selectFormsState, selectTicket } from 'redux/appStore'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

declare module 'types/form' {
  export interface Forms {
    [CallTypes.JUMPSTART]: FormApi
  }
}

export default function JumpstartForm() {
  const { t } = useTranslation()
  const jumpstartEvent = useCallEvent(EventTypes.JUMPSTART)
  const { update } = useReduxCallStoreActions()
  const { jumpstart } = useSelector(selectFormsState) as any
  const { vehicle } = useSelector(selectTicket)
  const towEvent = useCallEvent(EventTypes.TOW)
  const createServiceEvent = useCreateServiceEvent({ eventType: EventTypes.JUMPSTART } || null)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const submitJumpstart = async () => {
      const event = await createServiceEvent({
        ...jumpstart,
      })
      dispatch(setCurrentEvent({ eventType: EventTypes.JUMPSTART, newEvent: event.data }))
    }
    // This is for automatically submitting the jumpstart workflow form IF a tow event already exists
    if (towEvent && !jumpstartEvent) {
      submitJumpstart()
    }
  }, [towEvent])

  if (jumpstartEvent && jumpstartEvent.event) return <JumpstartEvent {...jumpstartEvent.event} />

  const PUSH_START = 'Remote Keyless Entry'
  const MANUAL = 'Key Access'

  const features =
    vehicle &&
    vehicle.equipmentInfo &&
    vehicle.equipmentInfo
      .filter(
        ({ localizedFullDescription }) =>
          localizedFullDescription === PUSH_START || localizedFullDescription === MANUAL,
      )
      .map(({ localizedFullDescription }) =>
        localizedFullDescription === PUSH_START ? t('jumpstart.ignition.pushStart') : t('jumpstart.ignition.manualKey'),
      )
      .join('/')

  return (
    <Form
      name={CallTypes.JUMPSTART}
      schema={jumpstartSchema}
      initialValues={jumpstart}
      autoSaveSync={(jumpstart) => update({ jumpstart })}
    >
      <Heading as="h5" spacing="md">
        {t('jumpstart.heading')}
      </Heading>

      <Box spacing="md">
        <Label>{t('fees')}</Label>
        <Waivers.Protection charge={TicketCharges.JUMP_START_CHARGE} />
      </Box>
      <Box spacing="md">
        <Label>{t('features')}</Label>
        <AvailabilityIcons availability={Boolean(features)} label={features || t('jumpstart.unknownKeyFeature')} />
      </Box>
      <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
      <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} />
      <Box spacing="md">
        <Location.Vehicle name="serviceLocations.vehicleLocation" />
        <Input.Hidden name="serviceLocations.vehicleLocation" validate={isRequired('Vehicle location is required')} />
      </Box>
      <Divider style={{ margin: '24px 0' }} />
      <Box spacing="md">
        <MultipleChoice
          name="vehicleStatus"
          orientation="vertical"
          label={t('jumpstart.vehicleStatus.heading')}
          choices={{
            noLightsOrRadio: t('jumpstart.vehicleStatus.noLightsOrRadio.label'),
            workingLightsAndRadio: t('jumpstart.vehicleStatus.workingLightsAndRadio.label'),
            keysWontTurn: t('jumpstart.vehicleStatus.keysWontTurn.label'),
          }}
        />
      </Box>
      <Condition when="vehicleStatus" is="noLightsOrRadio">
        <JumpstartDispatch />
      </Condition>
      <Condition when="vehicleStatus" is="workingLightsAndRadio">
        <Box spacing="md">
          <WorkingLightsAndRadio />
        </Box>
      </Condition>
      <Condition when="vehicleStatus" is="keysWontTurn">
        <Box spacing="md">
          <Heading as="h5" spacing="md">
            {t('jumpstart.vehicleStatus.keysWontTurn.heading')}
          </Heading>
          <Margin spacing="md">
            <WorkflowAction callType={CallTypes.TOW} eventType={EventTypes.TOW} label={t('tow.dispatch')} />
          </Margin>
          <Complete eventType={EventTypes.JUMPSTART} />
        </Box>
      </Condition>
    </Form>
  )
}

type JumpstartDispatchProps = {
  withNotes?: boolean
}

function JumpstartDispatch({ withNotes = true }: JumpstartDispatchProps) {
  const { t } = useTranslation()
  const { isEnterpriseTicket } = useUrgentlyProvider()
  const createSoftService = useCreateSoftService(EventTypes.JUMPSTART)
  const createUlySoftService = useCreateUlySoftService(EventTypes.JUMPSTART)
  const { jumpstart } = useSelector(selectFormsState) as any

  let safeToContinue =
    (jumpstart?.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE && jumpstart?.unsafe === 'no') ||
    (jumpstart?.breakdownOption === BreakdownOptions.PARKINGGARAGE && jumpstart?.garageAccessible === 'yes') ||
    (jumpstart?.breakdownOption === BreakdownOptions.OTHER && jumpstart?.accessible === 'yes')

  return (
    <>
      <BreakdownDetailsForm callType={CallTypes.JUMPSTART} />

      {safeToContinue && (
        <>
          <Margin spacing="md">
            <ScheduledAt callType={CallTypes.JUMPSTART} />
            <Input.Hidden name="scheduledAt" />
          </Margin>
          {withNotes && (
            <>
              <BreakdownNote
                breakdownOption={jumpstart?.breakdownOption}
                unsafe={jumpstart?.unsafe}
                garageAccessible={jumpstart?.garageAccessible}
                accessible={jumpstart?.accessible}
              />
              <Label>{`${t('notesToProvider.label')}*`}</Label>
              <Label>{t('jumpstart.question')}</Label>
              <Input.Textarea
                placeholder={t('notesToProvider.placeholder')}
                name="notes"
                spacing="lg"
                validate={isRequired(t('required.notes'))}
                maxLength={240}
              />
            </>
          )}
          <Margin spacing="md">
            <Submit
              onSubmit={async (state, caller) => {
                const { secondaryProvider = false, scheduledAt } = state.values

                if (scheduledAt) {
                  return await createUlySoftService(state, caller)
                } else {
                  if (isEnterpriseTicket) {
                    // AAA
                    return secondaryProvider
                      ? await createUlySoftService(state, caller) //Secondary
                      : await createSoftService(state, caller) //Default
                  } else {
                    // Urgently
                    return secondaryProvider
                      ? await createSoftService(state, caller) //Secondary
                      : await createUlySoftService(state, caller) //Default
                  }
                }
              }}
            >
              {t('jumpstart.save')}
            </Submit>
          </Margin>
        </>
      )}
    </>
  )
}

function WorkingLightsAndRadio() {
  const { t } = useTranslation()

  return (
    <>
      <MultipleChoice
        name="noise"
        orientation="vertical"
        label={t('jumpstart.vehicleStatus.workingLightsAndRadio.heading')}
        choices={{
          turnsOver: t('jumpstart.vehicleStatus.workingLightsAndRadio.noise.turnsOver'),
          clicking: t('jumpstart.vehicleStatus.workingLightsAndRadio.noise.clicking'),
        }}
      />
      <Condition when="noise" is="turnsOver">
        <YesNoRadio name="outOfFuel" trigger={YesNoTriggers.EITHER} label={t('jumpstart.outOfFuel')}>
          <Condition when="outOfFuel" is="yes">
            <Margin spacing="md">
              <WorkflowAction
                callType={CallTypes.FUEL}
                eventType={EventTypes.FUEL}
                label={t('fuel.save')}
                required
                nonMultiFlow
              />
            </Margin>
            <Complete eventType={EventTypes.JUMPSTART} />
          </Condition>
          <Condition when="outOfFuel" is="no">
            <JumpstartDispatch />
          </Condition>
        </YesNoRadio>
      </Condition>
      <Condition when="noise" is="clicking">
        <JumpstartDispatch />
      </Condition>
    </>
  )
}

const jumpstartSchema = Yup.object({
  vehicleStatus: Yup.string().nullable(true).required('Vehicle Status is required'),
  noise: Yup.string().nullable(true),
  outOfFuel: Yup.string().nullable(true),
})
