import { default as palette } from './palette'
import { pxToRem } from './utils'

const theme = {
  palette,
  spaces: [0, pxToRem(8), pxToRem(16), pxToRem(24)],
}

export default theme

export { default as palette } from './palette'
