import { useTranslation } from 'react-i18next'
import { FormState } from 'final-form'
import { EventTypes } from 'types/events'
import { SoftService } from 'types/callstore'
import { FormattedCaller } from 'components/fields/Submit'
import useCreateServiceEvent from 'hooks/events/useCreateServiceEvent'
import useUpdateTicketCharges from 'hooks/tickets/useUpdateTicketCharges'
import useAAAStandardServices from './useAAAStandardServices'
import { useServiceProxy } from 'hooks/kong'
import { StandardServiceCall } from 'types/aaa'
import { useDispatch } from 'react-redux'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

export const useCreateSoftService = (eventType: EventTypes) => {
  const { t } = useTranslation()
  const createServiceRequest = useAAAStandardServices([eventType])
  const createServiceEvent = useCreateServiceEvent<SoftService & { customerOptIn: boolean }>({
    eventType,
  })
  const serviceProxy = useServiceProxy()
  const dispatch = useDispatch()
  const updateTicketCharges = useUpdateTicketCharges()

  return async function (state: FormState<SoftService>, caller: FormattedCaller) {
    try {
      const service = state.values as SoftService
      const serviceRequests = createServiceRequest(service, caller)
      // Create AAA Dispatch
      const { data: dispatchData } = await serviceProxy<StandardServiceCall>(
        'post',
        `/serviceproxy/aaa/dispatch/`,
        {},
        serviceRequests[0], // only one for soft services
      )

      // Create DynamoDB Event
      if (dispatchData) {
        // Ticket charges
        await updateTicketCharges(eventType)

        if (dispatchData) {
          const event = await createServiceEvent(
            {
              ...service,
              dispatch: [dispatchData],
              customerOptIn: caller.optIn || dispatchData?.customer?.contact?.phones?.[0].smsOptIn || false,
            },
            dispatchData.callKey,
          )
          dispatch(setCurrentEvent({ eventType, newEvent: event.data }))
        }
      }
    } catch (e) {
      console.error(e)
      return t('error.aaa')
    }
  }
}

export default useCreateSoftService
