import * as React from 'react'

type Timer = {
  time: number
}

export const millisecondsToTime = (
  /** time in milliseconds */
  time: number,
): string => {
  let rem = time
  const milliseconds = rem % 1000
  rem = (rem - milliseconds) / 1000
  const secs = rem % 60
  rem = (rem - secs) / 60
  const mins = rem % 60
  const hrs = (rem - mins) / 60

  let result = `${mins}:${`00${secs}`.slice(-2)}`

  if (hrs) result = `${hrs}:${result}`

  return result
}

export function useTimer(initialTime: number = 0): Timer {
  const [time, setTime] = React.useState(initialTime)
  const [start] = React.useState(Date.now() - initialTime)

  React.useEffect(() => {
    const timerId = window.setInterval(() => {
      setTime(Date.now() - start)
    }, 1000)

    return () => {
      window.clearInterval(timerId)
    }
  }, [start])

  return { time }
}

export default useTimer
