import * as React from 'react'
import { useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { MdDone } from 'react-icons/md'
import { TowExchange } from 'types/callstore'
import { ExchangeBranchDisplay } from 'components/Exchange'
import StatusMessage from 'components/StatusMessage'
import ServiceLocationDisplay from './ServiceLocationDisplay'

export const ConfirmedVehicleLocation = () => {
  const { t } = useTranslation()
  const state = useFormState({ subscription: { values: true } })
  if (!state) return null
  const { serviceLocations } = state.values as TowExchange
  return serviceLocations?.vehicleLocation ? (
    <>
      <StatusMessage style={{ display: 'flex', alignItems: 'center' }}>
        <MdDone /> {t('Vehicle Location Set')}
      </StatusMessage>
      <ServiceLocationDisplay location={serviceLocations.vehicleLocation} />
    </>
  ) : null
}

export const ConfirmedCustomerLocation = () => {
  const { t } = useTranslation()
  const state = useFormState({ subscription: { values: true } })
  if (!state) return null
  const { serviceLocations } = state.values as TowExchange
  return serviceLocations?.callerLocation ? (
    <>
      <StatusMessage style={{ display: 'flex', alignItems: 'center' }}>
        <MdDone /> {t('Customer Location Set')}
      </StatusMessage>
      <ServiceLocationDisplay location={serviceLocations.callerLocation} />
    </>
  ) : null
}

export const ConfirmedParticipantLocation = () => {
  const { t } = useTranslation()
  const state = useFormState({ subscription: { values: true } })
  if (!state) return null
  const { serviceLocations, network } = state.values as TowExchange
  return serviceLocations?.vehicleDestinationLocation ? (
    <>
      <StatusMessage style={{ display: 'flex', alignItems: 'center' }}>
        <MdDone /> {t('Drop-off Destination Set')}
      </StatusMessage>
      <ServiceLocationDisplay location={serviceLocations.vehicleDestinationLocation} network={network} />
    </>
  ) : null
}

export const ConfirmedExchangeLocation = () => {
  const { t } = useTranslation()
  const state = useFormState({ subscription: { values: true } })
  if (!state) return null

  const { serviceLocations, agentEID, exchangeNotes, vehicleAccommodation, accommodationNotes, disabledVehicle, bypassReason } =
    state.values as TowExchange

  return serviceLocations?.exchangeLocation ? (
    <>
      <StatusMessage style={{ display: 'flex', alignItems: 'center' }}>
        <MdDone /> {t('Exchange Location Set')}
      </StatusMessage>
      <ExchangeBranchDisplay
        branch={serviceLocations?.exchangeLocation}
        agentEID={agentEID}
        exchangeNotes={exchangeNotes}
        vehicleAccommodation={vehicleAccommodation}
        accommodationNotes={accommodationNotes}
        disabledVehicle={disabledVehicle}
        bypassReason={bypassReason}
      />
    </>
  ) : null
}
