import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { MarkerType } from 'types/googleMaps'
import { useSelector } from 'react-redux'
import { SelectableGoogleHeroMarker } from '../Markers/GoogleMapMarkers'
import { Tooltip } from 'components/Tooltip'
import BranchTooltip from '../Markers/Tooltips/Branch'
import { selectMapCenter, selectTicket } from 'redux/appStore'
import useSpatialSearch from 'hooks/solr/useSpatialSearch'

type Props = {
  markerType: MarkerType
}

export const SolrLinks = ({ markerType }: Props) => {
  const { ticketNumber } = useSelector(selectTicket)
  const navigate = useNavigate()
  const center = useSelector(selectMapCenter)

  const lat = center.lat()
  const lng = center.lng()

  const { data, loading, error } = useSpatialSearch(lat, lng, 'enterprise')

  if (!data || !data.locationsResult || loading || error) return null

  return (
    <>
      {data.locationsResult.map((branch) => {
        const { groupBranchNumber, latitude, longitude, defaultLocationName, addressLines, formattedPhone } = branch

        return (
          <SelectableGoogleHeroMarker
            key={groupBranchNumber}
            id={groupBranchNumber}
            type={markerType}
            latitude={Number(latitude)}
            longitude={Number(longitude)}
            onClick={(selected) => {
              if (selected) {
                navigate(`/ticket/${ticketNumber}/place/${markerType}`)
              } else {
                navigate(`/ticket/${ticketNumber}/place/${markerType}/${groupBranchNumber}`)
              }
            }}
          >
            <Tooltip>
              <BranchTooltip
                markerType={markerType}
                title={defaultLocationName || ''}
                groupBranchNumber={groupBranchNumber}
                address={addressLines ? addressLines[0] : ''}
                formattedPhone={formattedPhone}
              />
            </Tooltip>
          </SelectableGoogleHeroMarker>
        )
      })}
    </>
  )
}

export default SolrLinks
