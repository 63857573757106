import * as React from 'react'
import { Paragraph, Label } from 'components/primitives'
import Margin from 'components/Margin'
import { isEmpty } from 'lodash-es'
import { TableRowWeekdays } from 'components/TableRow'
import { useTranslation } from 'react-i18next'
import { sortWeeklyHours } from 'components/Exchange/utils'

type Props = {
  businessHours: any
}

export const ParticipantTooltipHours: React.FC<Props> = ({ businessHours }) => {
  const { t } = useTranslation()
  const sortedBusinessHours = sortWeeklyHours(businessHours, true)
  return (
    <div style={{ fontSize: 12 }}>
      {businessHours && businessHours.towHoursOrdered && (
        <Margin spacing="md">
          {!isEmpty(businessHours.towHoursOrdered) && typeof businessHours === 'object' ? (
            <>
              <Label>{t('Tow Hours')}</Label>
              {Object.entries(sortedBusinessHours!).map(
                ([key, value]) =>
                  key !== '__typename' && (
                    <TableRowWeekdays key={key}>
                      <Paragraph>{key}</Paragraph>
                      <Paragraph>{`${value}`}</Paragraph>
                    </TableRowWeekdays>
                  ),
              )}
            </>
          ) : null}
        </Margin>
      )}
      <Margin spacing="md">
        {businessHours && businessHours.businessHoursOrdered && (
          <Margin spacing="md">
            {!isEmpty(businessHours) && typeof businessHours === 'string' && (
              <>
                <Label>{t('date.businessHours')}</Label>
                <Paragraph dangerouslySetInnerHTML={{ __html: businessHours }} />
              </>
            )}
            {!isEmpty(businessHours.businessHoursOrdered) && typeof businessHours.businessHoursOrdered === 'object' ? (
              <>
                <Label>{t('date.businessHours')}</Label>
                {Object.entries(sortedBusinessHours!).map(
                  ([key, value]) =>
                    key !== '__typename' && (
                      <TableRowWeekdays key={key}>
                        <Paragraph>{key}</Paragraph>
                        <Paragraph>{`${value}`}</Paragraph>
                      </TableRowWeekdays>
                    ),
                )}
              </>
            ) : null}
          </Margin>
        )}
      </Margin>
    </div>
  )
}
