import * as React from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { Flex, Heading } from 'components/primitives'
import Button from 'components/Button'
import Column from '../Column'
import Divider from '../Divider'
import { Dialog } from '../Dialog'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import BypassReasonSelect from '../Select/BypassReasonSelect'
import Input from 'components/Input'
import { Condition } from 'components/fields'
import { useFieldValue } from '../../hooks/utils'

export type CarouselControlProps = {
  next: () => void
  previous: () => void
  isFirst: boolean
  isLast: boolean
  showBypassModals?: boolean
}

export const CarouselControl: React.FC<CarouselControlProps> = ({ next, previous, isFirst, isLast, showBypassModals }) => {
  const bypassDialog = Ariakit.useDialogStore()
  const bypassReasonDialog = Ariakit.useDialogStore()
  const { t } = useTranslation()
  const [hasBeenWarned, setHasBeenWarned] = React.useState<boolean>(false)
  const bypassReason = useFieldValue('bypassReasonSelect')

  function handleYesClick(): void {
    bypassDialog.hide()
    bypassReasonDialog.show()
    setHasBeenWarned(true)
  }
  function handleNoClick(): void {
    bypassDialog.hide()
    setHasBeenWarned(false)
  }

  function moveRight(): void | null {
    if (isFirst && !hasBeenWarned && !bypassDialog?.getState().open && showBypassModals) {
      bypassDialog.show()
    }
    if (!hasBeenWarned && showBypassModals) {
      return null
    }
    return next()
  }

  function moveLeft(): void {
    return previous()
  }

  function handleSelect(): any {
    if (showBypassModals) {
      bypassReasonDialog.hide()
    }
    next()
  }

  return (
    <div>
      <Flex style={{ alignItems: 'flex-start' }}>
        <Button.Chevron iconBefore={MdChevronLeft} disabled={isFirst} onClick={() => moveLeft()} />
        <Button.Chevron iconBefore={MdChevronRight} disabled={isLast} onClick={() => moveRight()} />
      </Flex>
      {showBypassModals ? (
        <>
          <Ariakit.DialogProvider store={bypassDialog}>
            <Dialog {...bypassDialog} aria-label="Bypass Branch">
              <Column>
                <Heading as="h4">{t('Are you sure you want to bypass the closest location?')}</Heading>
              </Column>
              <Divider />
              <Flex style={{ justifyContent: 'center' }}>
                <Button.Primary style={{ marginRight: '25px' }} onClick={() => handleYesClick()}>
                  {t('Yes')}
                </Button.Primary>
                <Button.Primary onClick={() => handleNoClick()}>{t('No')}</Button.Primary>
              </Flex>
            </Dialog>
          </Ariakit.DialogProvider>
          <Ariakit.DialogProvider store={bypassReasonDialog}>
            <Dialog {...bypassReasonDialog} aria-label="Bypass Branch Reason" hideOnEsc={false}>
              <Column>
                <Heading as="h4">{t('Reason for bypassing?')}</Heading>
              </Column>
              <Divider />
              <BypassReasonSelect name={'bypassReasonSelect'} label={`${t('labels.bypassReasonSelect')}*`} />
              <Input.Hidden name="bypassReasonSelect.value" />
              <Condition when="bypassReasonSelect" is={(v) => v?.value !== undefined}>
                {(v: { label?: string }) => (
                  <>
                    <Input.Hidden name="bypassReason" defaultValue={v?.label ? (v.label as any) : undefined} />
                  </>
                )}
              </Condition>
              <Flex style={{ justifyContent: 'center' }}>
                <Button.Primary disabled={!bypassReason} onClick={() => handleSelect()}>
                  {t('Submit')}
                </Button.Primary>
              </Flex>
            </Dialog>
          </Ariakit.DialogProvider>
        </>
      ) : null}
    </div>
  )
}

export default CarouselControl
