import { DateTime } from 'luxon'
import { useLocalTimeZone } from 'hooks/utils'

export const useDispatchEta = (service: any, flag: boolean): { dispatchEta: string | null; zone: string | null } => {
  let lat, lon, eta
  if (service.serviceLocations && flag) {
    const { latitude, longitude } = service.serviceLocations[0].foi.location
    lat = latitude
    lon = longitude
    eta = service.status.eta
  }

  const { timezone: zone } = useLocalTimeZone({ lat, lon })

  const dispatchEta = eta && zone && DateTime.fromISO(eta, { zone })

  return {
    dispatchEta: dispatchEta ? `${dispatchEta.toFormat('t')} ${dispatchEta.offsetNameLong.split(' ')[0]}` : null,
    zone,
  }
}

export default useDispatchEta
