import { IncomingConnectedCarLocation, IncomingConnectedCarMetrics } from 'types/api'
import { ServiceProxyCall } from 'types/global'

// Retreive Connected Car Geolocation
export function searchByCurrentConnectedCarLocation(vinNumber: string, proxy: ServiceProxyCall, originIdentity?: string) {
  return proxy<IncomingConnectedCarLocation>('get', `/serviceproxy/connectedCar/current/${vinNumber}/retrieve-geolocation`, {
    'ehi-origin-identity': originIdentity,
  })
}

export function searchByHistoricalConnectedCarLocation(vinNumber: string, proxy: ServiceProxyCall, originIdentity?: string) {
  return proxy<IncomingConnectedCarLocation>('get', `/serviceproxy/connectedCar/historical/${vinNumber}/retrieve-geolocation`, {
    'ehi-origin-identity': originIdentity,
  })
}

// Retreive Connected Car Metrics ( Fuel, odometer, ignitionStatus )
export function searchByCurrentConnectedCarMetrics(vinNumber: string, proxy: ServiceProxyCall, originIdentity?: string) {
  return proxy<IncomingConnectedCarMetrics>('get', `/serviceproxy/connectedCar/current/${vinNumber}/retrieve-metrics`, {
    'ehi-origin-identity': originIdentity,
  })
}

export function searchByHistoricalConnectedCarMetrics(vinNumber: string, proxy: ServiceProxyCall, originIdentity?: string) {
  return proxy<IncomingConnectedCarMetrics>('get', `/serviceproxy/connectedCar/historical/${vinNumber}/retrieve-metrics`, {
    'ehi-origin-identity': originIdentity,
  })
}
