import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

const RouteLink = styled(Link as any)`
  display: flex;
  font-size: 16px;
  align-items: center;
  align-self: flex-start;
  flex-shrink: 0;
  text-decoration: none;
`

export default RouteLink
