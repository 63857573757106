import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import ReactSelect, { components, SelectInstance } from 'react-select'
import { SelectOption } from 'types/global'
import { mercury, monza, silver } from 'theme/colors'
import { pxToRem } from 'theme/utils'

export type SelectProps = SelectInstance<SelectOption> & FieldRenderProps<any>
type Props<T> = {
  hasError?: boolean
} & SelectInstance<T>

export function BaseSelect<T>(props: Props<T>) {
  return (
    <ReactSelect
      theme={(theme) => ({
        ...theme,
        minHeight: '2em',
        fontSize: pxToRem(16),
      })}
      components={{
        Input: (props) => (
          <>
            <input style={{ display: 'none' }} />
            <components.Input {...props} />
          </>
        ),
      }}
      styles={{
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          padding: '4px',
        }),
        clearIndicator: (baseStyles) => ({
          ...baseStyles,
          padding: '4px',
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          borderWidth: props.hasError ? '1px' : '2px',
          borderRadius: 0,
          cursor: 'pointer',
          borderColor: props.hasError ? monza : mercury,
          ':hover': {
            borderColor: props.hasError ? monza : mercury,
          },
          fontSize: pxToRem(16),
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          cursor: 'pointer',
          fontSize: pxToRem(16),
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: silver,
          fontSize: pxToRem(16),
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          padding: '0 8px',
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          padding: 0,
          fontSize: pxToRem(16),
        }),
      }}
      {...props}
    />
  )
}

export const Select: React.FC<SelectProps> = ({
  input,
  className, // React-Select conflicts with styled-components
  ...rest
}) => <BaseSelect<SelectOption> {...input} {...rest} />

export default Select
