import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AssignmentNetworks, Participant as TParticipant } from 'types/global'
import { Paragraph, Label } from 'components/primitives'
import { GoogleFeatureGroup } from 'components/GoogleMaps'
import ParticipantMarker from './ParticipantMarker'
import { isEmpty } from 'lodash-es'
import useLocalTimeZone from 'hooks/utils/useLocalTimeZone'
import LocalTime from 'components/LocalTime'
import Margin from 'components/Margin'
import { TableRowWeekdays } from 'components/TableRow'
import { UpdatedTimestamp } from 'components/Dispatch'
import { Modified } from 'types/callstore'
import { sortWeeklyHours } from 'components/Exchange/utils'

type Props = {
  participant: TParticipant & Modified
  network?: AssignmentNetworks
  showTooltip?: boolean
  showHours?: boolean
  outOfNetwork?: boolean
}

export const Participant: React.FC<Props> = ({ participant, network, showTooltip, showHours = false, outOfNetwork }) => {
  const { t } = useTranslation()

  const { entity, distanceStats, businessHours, location } = participant
  const { name, address, contactInfo } = entity
  const { timezone } = useLocalTimeZone({ lat: location!.latitude, lon: location!.longitude })
  const sortedBusinessHours = sortWeeklyHours(businessHours, false)

  return (
    <>
      <Margin spacing="md">
        {participant.modified && <UpdatedTimestamp timestamp={participant.modified} />}
        {name}
        <br />
        <span>
          {distanceStats && distanceStats.distance && <>{`${distanceStats.distance.toFixed(2)} ${distanceStats.units}`}</>}
        </span>
        {outOfNetwork && <Paragraph>{t('labels.outOfNetwork')}</Paragraph>}
      </Margin>
      {contactInfo && contactInfo.primaryPhone && <Paragraph spacing="md">{contactInfo.primaryPhone}</Paragraph>}
      {address && (
        <>
          <Paragraph>{address.addressLine1}</Paragraph>
          <Paragraph spacing="md">{`${address.city}, ${address.administrativeArea} ${address.postalCode}`}</Paragraph>
        </>
      )}
      {showHours && (
        <>
          {timezone && (
            <Margin spacing="md">
              <Label>{t('date.currentTime')}</Label>
              <LocalTime timeZone={timezone} />
            </Margin>
          )}
          {businessHours && businessHours.towHoursOrdered && (
            <Margin spacing="md">
              {!isEmpty(businessHours.towHoursOrdered) && typeof businessHours === 'object' ? (
                <>
                  <Label>{t('Tow Hours')}</Label>
                  {Object.entries(sortedBusinessHours!).map(
                    ([key, value]) =>
                      key !== '__typename' && (
                        <TableRowWeekdays key={key}>
                          <Paragraph>{key}</Paragraph>
                          <Paragraph>{`${value}`}</Paragraph>
                        </TableRowWeekdays>
                      ),
                  )}
                </>
              ) : null}
            </Margin>
          )}
          {businessHours && businessHours.businessHoursOrdered && (
            <Margin spacing="md">
              {!isEmpty(businessHours) && typeof businessHours === 'string' && (
                <>
                  <Label>{t('date.businessHours')}</Label>
                  <Paragraph dangerouslySetInnerHTML={{ __html: businessHours }} />
                </>
              )}
              {!isEmpty(businessHours.businessHoursOrdered) && typeof businessHours.businessHoursOrdered === 'object' ? (
                <>
                  <Label>{t('date.businessHours')}</Label>
                  {Object.entries(sortedBusinessHours!).map(
                    ([key, value]) =>
                      key !== '__typename' && (
                        <TableRowWeekdays key={key}>
                          <Paragraph>{key}</Paragraph>
                          <Paragraph>{`${value}`}</Paragraph>
                        </TableRowWeekdays>
                      ),
                  )}
                </>
              ) : null}
            </Margin>
          )}
        </>
      )}

      {network && (
        <GoogleFeatureGroup>
          <ParticipantMarker participant={participant} network={network} showTooltip={showTooltip} />
        </GoogleFeatureGroup>
      )}
    </>
  )
}

export default Participant
