import * as React from 'react'
import * as Yup from 'yup'
import { FormApi } from 'final-form'
import { useTranslation } from 'react-i18next'
import { DynamoEvents, EventTypes } from 'types/events'
import { CallTypes } from 'types/callstore'
import { Heading, Label } from 'components/primitives'
import { Form } from 'components/FormManager'
import { useReduxCallStoreActions, useCallEvent } from 'hooks/events'
import DriveIn from './DriveIn'
import ExchangeEvent from './ExchangeEvent'
import Margin from 'components/Margin'
import Waivers from 'components/Waivers/Waivers'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

declare module 'types/form' {
  export interface Forms {
    [CallTypes.EXCHANGE]: FormApi
  }
}

const ExchangeForm: React.FC = () => {
  const { t } = useTranslation()
  const { update } = useReduxCallStoreActions()
  const { exchange } = useSelector(selectFormsState) as any
  const exchangeEvent = useCallEvent(EventTypes.EXCHANGE)

  if (exchangeEvent) return <ExchangeEvent {...(exchangeEvent.event as DynamoEvents)} />

  return (
    <Form
      name={CallTypes.EXCHANGE}
      schema={exchangeSchema}
      initialValues={exchange}
      autoSaveSync={(exchange) => update({ exchange })}
    >
      <Heading as="h5" spacing="md">
        {t('driveInExchangeHeading')}
      </Heading>
      <Margin spacing="md">
        <Label>{t('labels.protections')}</Label>
        <Waivers />
      </Margin>

      <DriveIn />
    </Form>
  )
}

export default ExchangeForm

const exchangeSchema = Yup.object({})
