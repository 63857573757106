import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import { Heading } from 'components/primitives'
import { white, calypso } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import { CallHistoryEventProps } from './CallEventSummary'
import styled from 'styled-components/macro'
import { EventTypeToCallType } from 'utils/callstore'

const HeadingBar = styled.div`
  background-color: ${calypso};
  margin: -16px -48px 8px -16px;
  padding: 1rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
`

const CallEventHeading: React.FC<CallHistoryEventProps> = ({ event, onClick }) => {
  const { t } = useTranslation()
  const timestamp = event.audit?.[0]?.createTimestamp || ''

  return (
    <div>
      <HeadingBar>
        <MdClose style={{ cursor: 'pointer' }} color={white} onClick={() => onClick(null)} />
      </HeadingBar>
      <Heading as="h5">{EventTypeToCallType[event.eventType]}</Heading>
      <Heading as="h6" style={{ fontSize: pxToRem(14) }}>
        {t('date.standardWithTime', { date: new Date(timestamp) })}
      </Heading>
    </div>
  )
}

export default CallEventHeading
