import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Participant as TParticipant, AssignmentNetworks } from 'types/global'
import { GeocodeAddress } from 'types/googleMaps'
import { Label } from 'components/primitives'
import { NetworkNames } from 'components/Select/NetworkSelect'
import Participant from './Participant'
import SmartAssignmentCarousel from './SmartAssignmentCarousel'
import { useEffect } from 'react'
import { useCallerLocation, useVehicleLocation } from 'hooks/redux/useLocations'
import { reverseGeocode } from 'components/useReverseGeocoder'
import { areZipsFromSameIsland } from 'utils/HawaiiUtils'
import { LocationPayload } from 'types/location'
import { useServiceProxy } from 'hooks/kong'
import { useSelector } from 'react-redux'
import { selectSmartAssignment } from 'redux/appStore'

export type Props = {
  label?: string
  network: AssignmentNetworks
  onSetAssignment?: (participant: TParticipant | LocationPayload) => void
}

export const Results: React.FC<Props> = ({ label = 'Destination', network, onSetAssignment }) => {
  const { t } = useTranslation()
  const proxy = useServiceProxy()
  const { assignment, participant } = useSelector(selectSmartAssignment)

  const vehicleLoc = useVehicleLocation()
  const customerLoc = useCallerLocation()
  const lat = vehicleLoc?.latitude || customerLoc?.latitude
  const lon = vehicleLoc?.longitude || customerLoc?.longitude
  const [vehicleAddress, setVehicleAddress] = React.useState<GeocodeAddress | null>(null)
  const isOnSameIsland = (value: any) => {
    return areZipsFromSameIsland(vehicleAddress?.PostalCode?.substring(0, 5), value.entity.address.postalCode.substring(0, 5))
  }

  useEffect(() => {
    const getAddress = async () => {
      if (lat && lon) {
        const geocodeAddress = await reverseGeocode(proxy, lat, lon)
        if (geocodeAddress !== 'ZERO_RESULTS') setVehicleAddress(geocodeAddress)
      }
    }
    getAddress()
  }, [lat, lon])
  // removed proxy from deps to stop the looping every time it hits the service proxy

  if (!assignment) return null

  const { participants } = assignment

  if (!participant) {
    let items = participants
    if (vehicleAddress && vehicleAddress?.State === 'HI') {
      items = items.filter(isOnSameIsland)
    }

    if (!items || items.length === 0)
      return (
        <>
          <Label spacing="lg">{t('network.noParticipants', { network: NetworkNames[network] })}</Label>
        </>
      )

    return <SmartAssignmentCarousel items={items as any} label={label} network={network} onSetAssignment={onSetAssignment} />
  }
  return (
    <>
      <Label>{label}</Label>
      <Participant participant={participant as any} network={network} showHours={true} />
    </>
  )
}

export default Results
