import { TicketCharges, AAATicketChargePrices, ULYTicketChargePrices } from 'types/ticket'
import { EventTypes } from 'types/events'
import { useProtectionWaiver, useIsTicketWritable } from 'hooks/tickets'
import { useSelector } from 'react-redux'
import { selectCurrentUser, selectTicket } from 'redux/appStore'
import { useServiceProxy } from 'hooks/kong'

const hasTicketCharge = [EventTypes.FUEL, EventTypes.LOCKOUT, EventTypes.JUMPSTART, EventTypes.TIRES_CHANGE_SPARE]

export const SoftServiceTicketCharges: { [key: string]: TicketCharges } = {
  [EventTypes.JUMPSTART]: TicketCharges.JUMP_START_CHARGE,
  [EventTypes.LOCKOUT]: TicketCharges.KEY_LOCK_OUT_CHARGE, // TODO: LOST_KEYS_CHARGE
  [EventTypes.FUEL]: TicketCharges.FUEL_DELIVERY_CHARGE,
  [EventTypes.TIRES_CHANGE_SPARE]: TicketCharges.FLAT_TIRE_CHARGE,
}

export const useUpdateTicketCharges = () => {
  const { ticketNumber, brandId } = useSelector(selectTicket)
  const currentUser = useSelector(selectCurrentUser)
  const hasRoadsideProtection = useProtectionWaiver()
  const isWritable = useIsTicketWritable()
  const serviceProxy = useServiceProxy()

  return async (eventType: EventTypes) => {
    if (isWritable && !hasRoadsideProtection && hasTicketCharge.includes(eventType)) {
      try {
        await serviceProxy(
          'POST',
          `/serviceproxy/ticket/${ticketNumber}/miscCharge`,
          {},
          {
            createdUserId: currentUser!.nameID,
            chargeDescription: SoftServiceTicketCharges[eventType],
            chargeAmount:
              brandId === 'ENTERPRISE'
                ? AAATicketChargePrices[SoftServiceTicketCharges[eventType]]
                : ULYTicketChargePrices[SoftServiceTicketCharges[eventType]],
          },
        )
      } catch (e) {
        console.error(e)
      }
    }
  }
}

export default useUpdateTicketCharges
