import { useServiceProxy } from 'hooks/kong'
import { Case, ULYCancelReasonCode } from 'types/urgently'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'redux/appStore'

type Props = {
  cancelReason: ULYCancelReasonCode
  cancelNotes?: string
  caseId: string
  serviceId: string
}

export const useCancelUlyDispatch = () => {
  const currentUser = useSelector(selectCurrentUser)
  const serviceProxy = useServiceProxy()

  const agentEID = currentUser?.nameID || 'N/A'
  const cancelledNotes = `Cancelled by ${agentEID.toUpperCase()}. `

  async function cancelUlyDispatch({ cancelReason, cancelNotes, caseId, serviceId }: Props) {
    // Perform fetch
    const resp = await serviceProxy<Case>(
      'post',
      `/serviceproxy/urgently/cases/${caseId}/services/${serviceId}/cancel`,
      {
        'Content-Type': 'application/json',
      },
      {
        cancelReason,
        notes: cancelledNotes + cancelNotes,
      },
    )
    return resp
  }

  return cancelUlyDispatch
}

export default useCancelUlyDispatch
