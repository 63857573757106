import React from 'react'
import MdSpinner from 'react-md-spinner'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'components/Dialog'
import * as Ariakit from '@ariakit/react'
import useMount from 'hooks/utils/useMount'
import { LocateText } from './DetailsStyles'
import { CustomerConsent } from './CustomerConsent'
import { RedirectRequest } from '@azure/msal-browser'
import { msalConfig } from 'components/Auth/getAzureConfig'
import { useMsal } from '@azure/msal-react'
import useConnectedSearch from './useConnectedSearch'
import { useDispatch } from 'react-redux'
import { setConnectedState, setLastVinSearched } from 'redux/connectedSearch/connectedSearchSlice'

export type ConnectedAuthProps = {
  onLocate: (vin: string) => Promise<void>
  isHistorical?: boolean
  loading: boolean
  vin: string
}

// Forces user to re-log in with credentials
const loginRedirectRequest: RedirectRequest = {
  scopes: msalConfig.scopes,
  authority: msalConfig.authority,
  redirectUri: window.origin,
  prompt: 'login',
}

export function ConnectedAuthDialog({ onLocate, loading, vin, isHistorical }: ConnectedAuthProps) {
  const consentDialog = Ariakit.useDialogStore()
  const { t } = useTranslation()
  const { instance } = useMsal()
  const dispatch = useDispatch()
  const { lastVinSearched } = useConnectedSearch()

  useMount(() => {
    async function checkAuthentiction() {
      const instanceRedirect = await instance.handleRedirectPromise()
      // Agent has validated and has been routed back -- perform search on vehicle
      if (instanceRedirect !== null) {
        onLocate(vin)
      } else if (instanceRedirect === null) {
        dispatch(setConnectedState({ connectedLoading: false }))
        dispatch(setLastVinSearched(null))
      }
    }
    if (lastVinSearched !== null && lastVinSearched === vin) checkAuthentiction()
  })

  async function handleValidateSSOClick() {
    if (lastVinSearched !== vin) {
      dispatch(setLastVinSearched(vin))
      dispatch(
        setConnectedState({ connectedLoading: true, connectedError: null, connectedLocation: null, connectedMetrics: null }),
      )
      setTimeout(() => {
        instance.loginRedirect(loginRedirectRequest)
      }, 450)
    } else {
      onLocate(vin)
    }
  }

  return (
    <Ariakit.DialogProvider>
      <Ariakit.DialogDisclosure
        style={{ fontSize: 16, fontFamily: 'Open Sans, sans-serif', backgroundColor: 'unset', border: 0, padding: 0 }}
      >
        <LocateText onClick={isHistorical ? handleValidateSSOClick : consentDialog.show}>
          {lastVinSearched === vin && loading ? <MdSpinner singleColor="blue" size={22} /> : t('connectedCar.locate')}
        </LocateText>
      </Ariakit.DialogDisclosure>
      <Dialog {...consentDialog} aria-label="Locate Dialog">
        <CustomerConsent handleOnValidate={handleValidateSSOClick} vin={vin} />
      </Dialog>
    </Ariakit.DialogProvider>
  )
}

export default ConnectedAuthDialog
