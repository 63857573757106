import { Vehicle } from './global'

export type CaseServiceCall = {
  case: Case
  contactInfo: UlyContact
  customer: UlyCustomer
  vehicle: UlyVehicle
  services: UlyServices[]
  rides: UlyRides[]
}

export type Case = {
  id: string
  ulyCaseId: string
  clientCode: string
  emergency: number
  vip: number
  tags: UlyTags[]
  status: number
}

export type UlyTags = {
  tag: string
  value: string
}

export type UlyContact = {
  callbackName: string
  callbackNumber: string
  countryPhoneCode: string
  isMobilePhone: number
  textOptIn: number
  language: string
}

export type UlyVehicle = {
  id: string
  make: string
  model: string
  year: string
  color: string
  vin: string
  licensePlate: string
  transmission: number
  fuelType: number
  gvw: string
  owned: number
  class: string
  state: string
}

export type UlyCustomer = {
  id: string
  name: string
  phone: string
  countryPhoneCode: string
  policy: string
}

export type UlyServiceLocation = {
  street: string
  city: string
  state: string
  zip: string
  country: string
  longitude: number
  latitude: number
  timeZone: string
  address: string
  locationType: number
  locationName: string
}

export type UlyPaymentInfo = {
  payer: string
  authorizedToken: string
}

export type UlyImpoundFacility = {
  businessName: string
  countryPhoneCode: string
  phone: string
  fax: string
}

export type UlyPickupLocation = {
  street: string
  city: string
  state: string
  zip: string
  country: string
  longitude: number
  latitude: number
  timeZone: string
  address: string
  locationType: number
  impoundFacility: UlyImpoundFacility
  locationName: string
}

export type UlyDropoffLocation = {
  street: string
  city: string
  state: string
  zip: string
  country: string
  longitude: number
  latitude: number
  timeZone: string
  address: string
  locationType: number
  facilityCode: string
  locationName: string
}

export type UlyExchangeLocation = {
  street: string
  city: string
  state: string
  zip: string
  country: string
  longitude: number
  latitude: number
  timeZone: string
  address: string
  locationType: number
  locationName: string
}

export type UlyRides = {
  ulyServiceId: string
  code: string
  name: string
  notes: string
  statusCode: number
  statusName: string
  passengerCount: number
  luggageCount: number
  pickupLocation: UlyPickupLocation
  dropoffLocation: UlyDropoffLocation
}

export type UlyRide = {
  roundTrip: number
  passengerCount: number
  luggageCount: number
  notes: string
  pickupLocation: UlyPickupLocation
  dropoffLocation: UlyDropoffLocation
  paymentInfo: UlyPaymentInfo[]
}

export type UlyCancelDetails = {
  cancelReason: number
  notes: string
}

export type UlyDisablementReason = {
  code: string
  notes: string
}

export type UlyServices = {
  ulyServiceId: string
  cancellationDetails: UlyCancelDetails
  code: string
  name: string
  eta: string
  notes: string
  scheduledAt: string
  statusCode: number
  statusName: string
  tags: UlyTags[]
  serviceLocation: UlyServiceLocation
  pickupLocation: UlyPickupLocation
  dropoffLocation: UlyDropoffLocation
  exchangeLocation: UlyExchangeLocation
  paymentInfo: UlyPaymentInfo[]
  disablementReason: UlyDisablementReason
}

export type ULYCustomerContact = Pick<UlyCustomer, 'name' | 'phone'> &
  Pick<UlyContact, 'textOptIn' | 'isMobilePhone' | 'countryPhoneCode' | 'callbackName' | 'callbackNumber'>

export enum ULYCancelReasonCode {
  'No Longer Needed' = 1,
  'Too Expensive',
  'Taking too long',
  'Other',
}

export enum UlyServiceCodes {
  'Storage' = 1001, // 1001
  'Towing' = 2001, // 2001
  'Auto Lockout', // 2002
  'Jump Start', // 2003
  'Fuel', // 2004
  'Flat Tire', // 2005
  'Emergency', // 2006
  'Winch', // 2007
  'Key Replacement', // 2008
  'Dealer Tire Service', // 2009
  'Motorcycle Tow', // 2010
  'Home Lockout', // 2011
  'Tire Replacement', // 2012
  'Medium/Heavy Duty RSA' = 2020, // 2020
  'Medium Duty Tow', // 2021
  'Heavy Duty Tow', // 2022
  'Secondary Tow', // 2023
  'Accident Tow', // 2024
  'Short Term Mobility' = 2050, // 2050
  'Repair Shop', // 2051
  'Appliance Repair' = 2060,
  'HVAC', // 2061
  'Plumbing', // 2062
  'Electrical', // 2063
  'Mobile Mechanic', // 2064
  'Tire Delivery', // 2065
  'Battery Replacement', // 2066
  'Transport', // 2067
  'Tow Exchange', // 2068
}

export enum UlyStatusCodes {
  New = 0, // 0
  FOUND_PROVIDERS, // 1
  CONTACTING_PROVIDERS, // 2
  Assigned, // 3
  PROVIDER_ACCEPT_JOB_PROCESSING, // 4
  PROVIDER_ARRIVED, // 5
  CREDIT_CARD_AUTHORIZED, // 6
  CREDIT_CARD_AUTHORIZATION_VOIDED, // 7
  COMPLETE_BY_PROVIDER, // 8
  CANCELLED_BY_PROVIDER, // 9
  COMPLETED_BY_PROVIDER_REQUIRES_TOW,
  PROVIDER_LEFT_SITE,
  CANCELLED_BY_CUSTOMER = 21, // 21
  CANCELLED_BY_DRIVER_FOR_CUSTOMER,
  NO_PROVIDERS_FOUND = 24,
  COMPLETED_BY_PROVIDER_GOA,
  DEMO = 27,
  CANCELLED_BY_CUSTOMER_CHARGEABLE = 28, // 28
  CANCELLED_NO_PROVIDERS_FOUND,
  CANCELLED_NOT_IN_SERVICE_AREA,
  JOB_FAILED_UNSUPPORTED_VEHICLE = 32,
  JOB_FAILED_CARD_REFUSED,
  REQUEST_TO_CANCEL_FROM_DRIVER,
  NO_CREDIT_CARD_ON_FILE = 88, // 88
  PAYMENT_FAILED, // 89
  ALREADY_COMPLETE, // 90
  CLOSED_EXPIRED, // 91
  CLOSED_PAID_BY_CASH, // 92
  CLOSED_GOA_NO_PAYMENT, // 93
  CLOSED_PAYMENT_FAILED, // 94
  CLOSED_GOA_PAYMENT_FAILED, // 95
  CLOSED_ALL_PROVIDERS_REJECT, // 96
  CLOSED_CANCELLED, // 97
  CLOSED_NO_PAYMENT_NEEDED, // 98
  CLOSED_GOA, // 99
  CLOSED_CANCELLED_CHARGEABLE = 102, // 102
  POTENTIAL_ONLY = 200, // 200
  TEST_ONLY = 300, // 300
  INCOMPLETE = 400, // 400
  REOPEN = 1000, // 1000
}

export enum UlyLocationTypeCodes {
  'General Parking Garage' = 0, // 0
  'General Parking Lot', // 1
  'Residence/work (driveway)', // 2
  'Residence/work (parking garage)', // 3
  'Residence/work (parking lot)', // 4
  'Residence/work (street)', // 5
  'Roadside (highway)', // 6
  'Roadside (street)', // 7
  'Impound Facility', // 8
  'Other', // 9
}

export enum ULY_PARTNER_CODES {
  Coverage,
  Customer,
  Partner,
}

export enum ULY_CLIENT_CODES {
  ALAMO = 'alamo',
  NATIONAL = 'national',
  ENTERPRISE = 'enterprise.carshare',
}

export type ServiceLocationTypes =
  | UlyServiceLocation
  | UlyPickupLocation
  | UlyDropoffLocation
  | UlyExchangeLocation
  | undefined

export type PartialVehicle = Pick<
  Vehicle,
  'year' | 'make' | 'model' | 'licensePlate' | 'licensePlateState' | 'vin' | 'color'
>

export type PartialVehicleWithStringYear = Pick<
  Vehicle,
  'make' | 'model' | 'licensePlate' | 'licensePlateState' | 'vin' | 'color'
> & {
  year: string
}

export type PartialULYDispatch = {
  caseId: string
}
