import * as React from 'react'
import { IconType } from 'react-icons'
import styled, { css } from 'styled-components/macro'
import MdSpinner from 'react-md-spinner'
import { pxToRem } from 'theme/utils'
import { theme, ifProp, palette } from 'styled-tools'
import { CustomIconType } from 'icons'
import { StyleProps } from 'types/global'
import { calypso, dustyGray } from 'theme/colors'

const BaseButton = styled.button`
  cursor: pointer;
  font-size: ${pxToRem(12)};
  font-weight: 400;
  list-style-type: none;
  padding: ${theme('spaces.1')} ${theme('spaces.2')};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  margin: 0;
`

export type BaseButtonProps = {
  iconBefore?: IconType | CustomIconType
  iconAfter?: IconType | CustomIconType
  iconSize?: number | string
  iconColor?: string
  onClick?: () => void
  loading?: boolean
  children?: React.ReactNode
  hasError?: boolean
} & StyleProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>

const ButtonComponent = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  ({ iconBefore: IconBefore, iconAfter: IconAfter, iconSize = 24, iconColor, children, style = {}, loading, ...props }, ref) => {
    const marginLeft = IconBefore && 6
    const marginRight = IconAfter && 6

    return (
      <BaseButton ref={ref} style={style} type="button" role="group" {...props}>
        {IconBefore && <IconBefore size={iconSize} fill={iconColor} />}
        {children && (
          <span style={{ marginLeft, marginRight }}>{loading ? <MdSpinner singleColor="white" size={22} /> : children}</span>
        )}
        {IconAfter && <IconAfter size={iconSize} fill={iconColor} />}
      </BaseButton>
    )
  },
)

export const Button = styled(ButtonComponent as any)``

export const PrimaryButton = styled(Button as any)`
  background: ${ifProp('disabled', palette('wildSand'), palette('calypso'))};
  color: ${ifProp('disabled', palette('black'), palette('white'))};
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  min-width: ${pxToRem(156)};
  min-height: ${pxToRem(36)};
`

export const SecondaryButton = styled(Button as any)`
  background: ${ifProp('disabled', palette('white'), palette('white'))};
  color: ${ifProp('disabled', palette('black'), palette('black'))};
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  min-width: ${pxToRem(156)};
  min-height: ${pxToRem(36)};
  border: 1px solid ${palette('silver')};
`

export const RegularButton = styled(Button as any)`
  background: ${ifProp('disabled', palette('wildSand'), 'none')};
  color: ${ifProp('disabled', palette('black'), palette('calypso'))};
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
`

export const LinkButton = styled(Button as any)`
  font-size: ${pxToRem(14)};
  color: ${palette('dustyGray')};
  text-decoration: underline;
  text-transform: none;
`

export const ChevronButton = styled(Button as any).attrs(({ disabled }) => ({
  iconColor: disabled ? dustyGray : calypso,
}))`
  padding: 0 ${theme('spaces.2')};
`

export const WorkflowButton = styled(Button as any)`
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px ${palette('silver')};
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  font-size: ${pxToRem(16)};
  font-weight: 600;
  justify-content: flex-start;
  text-transform: capitalize;
  width: 100%;
  margin: ${theme('spaces.1')} 0;
  opacity: ${ifProp('disabled', 0.4, 1)};
  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid ${palette('monza')};
    `}
`

export default Button
