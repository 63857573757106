import React, { SVGProps, FC } from 'react'
import { IconContext } from 'react-icons'
import { CustomIconTypeProps } from './types'

const SVG: React.FC<CustomIconTypeProps & { path: string }> = ({ size, color, path }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill={color}>
    <path d={path} />
  </svg>
)

type IconBaseProps = { path?: string; icon?: FC<SVGProps<SVGSVGElement>> } & {
  size?: string
} & SVGProps<SVGSVGElement>

const IconBase: React.FC<IconBaseProps> = ({ path, icon: Icon, size, fill, ...props }) => (
  <IconContext.Consumer>
    {({ size: contextSize, ...rest }) =>
      path ? (
        <SVG color="currentColor" size={size || contextSize} path={path} {...rest} />
      ) : (
        Icon && (
          <Icon
            width={size || contextSize || props.width}
            height={size || contextSize || props.height}
            fill={fill || 'currentColor'}
            {...rest}
            {...props}
          />
        )
      )
    }
  </IconContext.Consumer>
)

export default IconBase
