import { Branch, BranchInfoFragment, Brands, Maybe, SelectOption } from 'types/global'

export type GetCountries = { name: string; iso2: string; dial: string; format: string }

export enum TicketCharges {
  LOST_KEYS_CHARGE = 'LOST_KEYS_CHARGE',
  KEY_LOCK_OUT_CHARGE = 'KEY_LOCK_OUT_CHARGE',
  JUMP_START_CHARGE = 'JUMP_START_CHARGE',
  FLAT_TIRE_CHARGE = 'FLAT_TIRE_CHARGE',
  FUEL_DELIVERY_CHARGE = 'FUEL_DELIVERY_CHARGE',
  OTHER_CHARGE = 'OTHER_CHARGE',
  IMPOUND_OR_TOW_FEE = 'IMPOUND_OR_TOW_FEE',
}

export type Standard = {
  hours: ReadonlyArray<Hours>
  closed: boolean
  open24Hours: boolean
}

export type HourInfo = {
  standard: Standard
  day: string
}

export type Hours = {
  open: string
  close: string
}

export const AAATicketChargePrices: { [key: string]: string } = {
  [TicketCharges.FUEL_DELIVERY_CHARGE]: '55.00',
  [TicketCharges.KEY_LOCK_OUT_CHARGE]: '55.00',
  [TicketCharges.JUMP_START_CHARGE]: '55.00',
  [TicketCharges.FLAT_TIRE_CHARGE]: '55.00',
}

export const ULYTicketChargePrices: { [key: string]: string } = {
  [TicketCharges.FUEL_DELIVERY_CHARGE]: '75.00',
  [TicketCharges.KEY_LOCK_OUT_CHARGE]: '75.00',
  [TicketCharges.JUMP_START_CHARGE]: '67.00',
  [TicketCharges.FLAT_TIRE_CHARGE]: '75.00',
}

export interface TransformedTicket {
  account: Account
  brandId: Brands
  fulfillmentSystem: FulfillmentSystem
  reservationNumber: string
  ticketNumber: string
  ticketStatus?: TicketStatus
  sortTimestamp?: string
  primaryDriver: TransformedDriver | null
  additionalDriver: Driver[]
  drivers: (TransformedDriver | null)[]
  vehicle: TransformedVehicle | null
  vehicleHistory: (TransformedVehicle | null)[]
  pickupInfo: TransformedPickupReturnInfo
  returnInfo: TransformedPickupReturnInfo
  note?: TicketNote[]
  protection?: Protection[]
}

export interface MultiRetrieveResponse {
  signatureEvents: SignatureEvent[]
  startChargesDateTime: string
  endChargesDateTime: string
  rateQuoteDateTime: string
  account: Account
  brandId: Brands
  fulfillmentSystem: FulfillmentSystem
  reservationNumber: string
  ticketNumber: string
  ticketStatus: TicketStatus
  driver: Drivers
  vehicle: Vehicles
  pickupInfo: MultiRetrievePickupReturnInfo
  returnInfo: MultiRetrievePickupReturnInfo
  note: TicketNote[]
  protection: Protection[]
  billTo: BillTo[]
  vehicleRateSegment: VehicleRateSegment[]
  referral: Referral
  legacyIdentifier: LegacyIdentifier
  rentalTypeCode: string
  generalCondition: GeneralCondition[]
  reservedSippCode: string
  businessLeisureFlag: string
  financiallyResponsibleLocationId: string
  equipment: Equipment[]
}

export interface SeekResponse {
  rentalTypeCode: string | null
  carClass: CarClass
  protectionCarClass: string
  type: string
  doNotRent: boolean
  firstNameOnRental: string
  lastNameOnRental: string
  frictionlessEnrolled: boolean
  deliveryAddress: Address
  workflow: Workflow[]
  statusUpdate: CreationUpdateStatus
  reservationExperienceEligibility: ReservationExperienceEligibility[]
  accountInfo: AccountInfo
  brandId: Brands
  fulfillmentSystem: FulfillmentSystem
  reservationNumber: string
  sortTimestamp: string
  ticketNumber: string
  status: string
  renter: Renter
  pickupInfo: SeekPickupReturnInfo
  returnInfo: SeekPickupReturnInfo
  vehicleInfo: Vehicle
}

export interface Address {
  city: string
  countryCode: string
  countryDescription: string
  countrySubdivision: string
  country: string
  line1: string
  line2: string
  line3?: string
  postalCode: string
  type: number
}

export interface Renter {
  firstName: string
  lastName: string
  membershipLevel: string
  membershipLevelCode: number
  membershipProgram: string
  membershipTierLevelCode: string
  loyaltyDescription: string
  loyaltyId: string
  loyaltyTierCodeDescription: string
  address: Address
  individualId: string
}

export interface MessageRewrite {
  code: string
  reason: string
}

export interface Rewrite {
  rewriteMessages: MessageRewrite
  type: string
}

export interface Workflow {
  type: string
  status: string
  urn: string
  creation: CreationUpdateStatus
}

export interface CreationUpdateStatus {
  dateTime: string
  callingApplication: string
  originApplication: string
  originIdentity: string
}

export interface ReservationExperienceEligibility {
  lastCalculatedDateTime: any
  relatedContexts: string[]
  urn: string
  type: string
  isQualified: boolean
}

export interface CarClass {
  driven: string
  reserved: string | null
}

export interface SeekPickupReturnInfo {
  dateTime: string
  longTermExpectedReturnDateTime: string
  peoplesoftId: string
  methodOfDelivery: string
  originalPeoplesoftId: string
  covid19Spoke: boolean
  covid19SpokeStationId: string
  counterId: string
  comment: string
  stationId: string
  groupBranchId: string
  method: string
}

export interface Account {
  name: string
  number: string
  rule?: string[]
}

export interface AccountInfo {
  customerAccountName: string
  customerAccountNumber: string
  rateSourceCustomerAccountName: string | null
}

export interface LicensePlate {
  number: string
  countrySubdivision: string
}

export type TransformedVehicle = Pick<
  Vehicle,
  | 'make'
  | 'model'
  | 'year'
  | 'color'
  | 'vin'
  | 'vinLast8'
  | 'sippCode'
  | 'lastRecordedOdometer'
  | 'nextPmOdometer'
  | 'equipmentInfo'
  | 'installedDateTime'
  | 'unitNumber'
> & {
  licensePlate: string | null
  licensePlateState: string | null
}
export interface Vehicle {
  make: string
  model: string
  year: number
  color: string
  vin: string
  vinLast8: string
  licensePlate: LicensePlate
  sippCode: string
  vehicleAssignedDateTime: string
  series: string
  vehicleUnassignedDateTime: string
  endOdometer: string
  startOdometer: string
  lastRecordedOdometer: string
  nextPmOdometer: string
  unitNumber: string
  installedDateTime: string
  equipmentInfo: EquipmentInfo[]
}

export interface DriverPhone {
  countryCode: SelectOption
  countryOption: Maybe<SelectOption>
  number: string
  type: number
}

export interface LegalId {
  countryCode: string
  countrySubdivision: string
  expirationDate: string
  issueDate: string
  number: string
  type: string
}

export interface Drivers {
  primaryDriver: Driver
  additionalDriver: Driver[]
}

export interface EquipmentInfo {
  shortDescription: string
  localizedFullDescription: string
  categoryCode: string
  categoryDescription: string
  value: string
}

export interface TicketNote {
  createDateTime: string
  userId: string
  text: string
  enteredByUser: string
}

export interface Vehicles {
  currentVehicle: Vehicle
  vehicleHistory: Vehicle[]
}

export interface Protection {
  startDateTime: string
  endDateTime: string
  type: string
  productPremiumId: string
  id: string
  description: string
  superType: string
  applicabilityCode: string
  deductible: number
  fullProtectionIndicator: boolean
  coverageLimit: number
}

export interface MultiRetrievePickupReturnInfo {
  location: MultiRetrievePickupReturnLocation
  dateTime: string
  method: string
}

export interface MultiRetrievePickupReturnLocation {
  stationId: string
  groupBranchId: string
  phoneNumber: string
  branchDescription: string
  address: Address
  dateTime: string
  comment: string
  method: string
}

export interface Driver {
  individualId: string
  address: Address[]
  dateOfBirth: string
  dayOfBirth: string
  monthOfBirth: string
  salutation: string
  firstName: string
  lastName: string
  legalId: LegalId[]
  phone: (DriverPhone | undefined)[]
  suffix: string
  emailAddress: string
  insuranceInfo: InsuranceInfo
}

export interface LegalId {
  countryCode: string
  countrySubdivision: string
  expirationDate: string
  issueDate: string
  number: string
  type: string
}

export interface InsuranceInfo {
  carrierName: string
  policyIdentifier: string
}

export interface SignatureEvent {
  method: string
  timestamp: string
  ticketStatus: string
  type: string
}

export interface BillTo {
  customerAccountName: string
  customerAccountNumber: string
  authorizationStatus: string
  claimType: string
  claimNumber: string
  maxBillableAmount: number
  authorizationPercentage: number
}

export interface Rate {
  hourlyRate: number
  dailyRate: number
  weeklyRate: number
  monthlyRate: number
}

export interface ContractualCondition {
  id: string
  rentalStartDate: string
}

export interface VehicleRateSegment {
  startDateTime: string
  endDateTime: string
  sippCode: string
  rate: Rate
  contractualCondition: ContractualCondition
}

export interface Referral {
  employeeReferral: EmployeeReferral
  accountReferral: AccountReferral
}

export interface EmployeeReferral {
  employeeId: string
}

export interface AccountReferral {
  customerAccountNumber: string
  customerAccountName: string
}

export interface LegacyIdentifier {
  contractNumber: string
  documentNumber: string
}

export interface RuleSummary {
  displayDuringBooking: boolean
  displayDuringRental: boolean
  printOnRentalAgreement: boolean
  comment: string
}

export interface Condition {
  type: string
  text: string
}

export interface GeneralCondition {
  condition: Condition
  ruleSummary: RuleSummary
}

export interface Equipment {
  type: string
  quantity: string
}

export type TransformedPickupReturnInfo = {
  dateTime: string
  method: string
  groupBranchId: string
  branch: BranchInfoFragment | Branch
}

export type TransformedDriver = Omit<Driver, 'insuranceInfo' | 'salutation'> & {
  fullName: string
}

export enum TicketSearchStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  NON_IDEAL_STATE = 'NON_IDEAL_STATE',
  IN_PROGRESS = 'IN_PROGRESS',
  NONE = 'NONE',
}

export enum FulfillmentSystem {
  ECARS = 'ECARS',
  ODYSSEY = 'ODYSSEY',
}

export enum TicketStatus {
  OPEN = 'OPEN',
  PREWRITE = 'PREWRITE',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  CLOSEPENDED = 'CLOSEPENDED',
  DOUBLE_RENT_PEND = 'DOUBLE_RENT_PEND',
}

export type TicketState = {
  ticket?: TransformedTicket
  ticketError?: any
}
