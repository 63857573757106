import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash-es'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Heading, Flex } from 'components/primitives'
import Card from 'components/Card'
import TicketSearchForm from 'forms/TicketSearchForm'
import { TicketSearchFormValues } from 'forms/TicketSearchForm/TicketSearchForm'
import { theme } from 'styled-tools'
import Actions from './Actions'
import TicketSearchResult from './TicketSearchResult'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'
import { pxToRem } from 'theme/utils'
import { useMount } from 'hooks/utils/useMount'
import TicketLink from 'components/TicketLink'
import { SeekResponse, TicketSearchStatus, TransformedTicket } from 'types/ticket'
import { composeSearchTerms } from 'redux/ticket/ticketHelpers'
import { useFleetVehicleSearch } from 'hooks/kong'
import { useDispatch } from 'react-redux'
import { updateTicket, updateTicketError } from 'redux/ticket/ticketSlice'
import { useServiceProxy } from 'hooks/kong/useServiceProxy'
import { loadTicketByRA } from 'utils/ticket'
import { useReduxCallStoreActions } from 'hooks/events'
import * as Ariakit from '@ariakit/react'
import { useFlags } from 'launchdarkly-react-client-sdk'

type Props = {
  previousSearchObject?: any
  setPreviousSearchObject?: any
}

const Container = styled(Flex as any)`
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${theme('spaces.2')};
  position: relative;
`

const SearchCard = styled(Card as any)`
  width: ${pxToRem(380)};
  padding: ${theme('spaces.3')};
`

export const TicketSearch: React.FC<Props> = ({ previousSearchObject, setPreviousSearchObject }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const searchCardControls = useAnimation()
  const startSearchTime = React.useRef(new Date())
  const [showPreviousResults, setShowPreviousResults] = React.useState<boolean>(false)
  const dispatch = useDispatch()
  const { reset } = useReduxCallStoreActions()
  const flags = useFlags()
  const tabStore = Ariakit.useTabStore({ defaultSelectedId: 'tab-1' })

  const SearchStatusMessage = {
    SEARCHING: t('Searching Ticket'),
    LOADING: t('Loading Ticket Details'),
    NONE: '',
  }
  const [searchStatusMessage, setSearchStatusMessage] = React.useState<string>(SearchStatusMessage.NONE)
  const [ticketSearchStatus, setTicketSearchStatus] = React.useState<TicketSearchStatus>(TicketSearchStatus.NONE)

  const [multiTicketSearch, setMultiTicketSearch] = React.useState<SeekResponse[]>([])
  const serviceProxy = useServiceProxy()

  /**
   * Loads an array of tickets by some `searchTerms`
   * @param searchTerms An object including the terms to filter the results by
   */
  async function searchForTicket(searchTerms: object) {
    const body = { searchTerms: composeSearchTerms(searchTerms), resultDomain: 'TICKET' }
    return await serviceProxy<SeekResponse[]>('post', '/serviceproxy/seek', {}, body)
  }

  /**
   * Fetches a ticket from multi retrieve and/or SEEK services and loads it into the cache.
   * Routes to ticket detail on success, sets a failure search state otherwise.
   */
  const searchTicketByRAOrRes = async (ticketOrResNumber: string) => {
    startSearchTime.current = new Date()
    const searchTerm = ticketOrResNumber.toUpperCase()
    setTicketSearchStatus(TicketSearchStatus.NONE)
    setSearchStatusMessage(SearchStatusMessage.LOADING)
    let ticketNumber

    try {
      // try SEEK first
      const searchResult = await searchForTicket({ reservationNumber: searchTerm })
      // pick the first ticket search result, as reservation numbers are unique
      const ticket = searchResult.data[0]

      // throw if no results came back
      if (!ticket) throw new Error()
      try {
        // no results came back, try MR
        if (isEmpty(searchResult.data)) throw new Error()
        // only one result came back

        if (searchResult.data.length === 1) {
          try {
            // load ticket by RA via multiretrieve
            const result = await loadTicketByRA(searchResult.data[0].ticketNumber, serviceProxy, flags)
            ticketNumber = result?.ticketNumber

            // Only reset when searches change
            reset()
            await new Promise((resolve) => setTimeout(resolve, 1000))

            if (result) {
              dispatch(updateTicket({ ticket: result }))
              setTicketSearchStatus(TicketSearchStatus.SUCCESS)
              setSearchStatusMessage(SearchStatusMessage.NONE)
            }
          } catch (e) {
            onTicketSearchFailure(e)
          }
        } else {
          // many results came back from SEEK
          await onTicketSearchSuccess(searchResult.data)
        }
      } catch (e) {
        onTicketSearchFailure(e)
      }
    } catch (e) {
      // if seek fails, try multiRetrieve
      try {
        const result = await loadTicketByRA(searchTerm, serviceProxy, flags)
        ticketNumber = result?.ticketNumber
        if (result) {
          // Only reset when searches change
          reset()
          await new Promise((resolve) => setTimeout(resolve, 1000))
          dispatch(updateTicket({ ticket: result }))
          setTicketSearchStatus(TicketSearchStatus.SUCCESS)
          setSearchStatusMessage(SearchStatusMessage.NONE)
        }
      } catch (e) {
        onTicketSearchFailure(e)
      }
    }
    if (ticketNumber) {
      navigate(`/ticket/${ticketNumber}`)
    }
  }

  /**
   * Fetches a list of tickets from SEEK services based on the passed in search terms.
   * On success, displays a list of tickets or routes to ticket detail if only one is returned. Sets a failure search state otherwise.
   */
  const searchTicketByTerms = async ({ vinLast8, licensePlate, licensePlateState, ...rest }: TicketSearchFormValues) => {
    startSearchTime.current = new Date()
    setTicketSearchStatus(TicketSearchStatus.IN_PROGRESS)
    setSearchStatusMessage(SearchStatusMessage.SEARCHING)
    const limits = {
      ticketStatus: rest.searchClosed || rest.ticketStatus === 'CLOSED,CLOSEPENDED' ? 'CLOSED,CLOSEPENDED' : 'OPEN',
    }
    var monthsToSearch = 2
    if (rest.searchClosed || limits.ticketStatus === 'CLOSED,CLOSEPENDED') monthsToSearch = 6

    let timesSearched = 0
    const startDate = new Date()
    const endDate = new Date()
    startDate.setMonth(startDate.getMonth() - 1)
    const aggregate = [] as SeekResponse[]
    while (timesSearched < monthsToSearch) {
      try {
        const { data } = await searchForTicket(
          !isEmpty(vinLast8)
            ? {
                vinLast8,
                ...limits,
                pickupDateTimeFrom: startDate.toISOString(),
                pickupDateTimeTo: endDate.toISOString(),
              }
            : {
                ...rest,
                ...limits,
                licensePlate: licensePlate,
                licensePlateState: licensePlateState,
                pickupDateTimeFrom: startDate.toISOString(),
                pickupDateTimeTo: endDate.toISOString(),
              },
        )

        if (isEmpty(data)) {
          if (timesSearched === monthsToSearch - 1 && aggregate.length === 0) {
            onTicketSearchNonIdealState()
          }
        } else {
          data.forEach((ticket) => {
            aggregate.push(ticket)
          })
        }
      } catch (e) {
        onTicketSearchFailure(e)
      }
      if (timesSearched === monthsToSearch - 1) {
        if (aggregate.length > 0) {
          onTicketSearchSuccess(aggregate)
        } else {
          onTicketSearchFailure({ error: 'MultiSearch Aggregate length greater than or equal to 0' })
        }
      }
      ++timesSearched
      startDate.setMonth(startDate.getMonth() - 1)
      endDate.setMonth(endDate.getMonth() - 1)
    }
  }

  /**
   * Sets search result in component state. If there's only one result, attempt to load the ticket
   * via multi retrive services.
   */
  const onTicketSearchSuccess = async (ticket: TransformedTicket | TransformedTicket[] | SeekResponse | SeekResponse[]) => {
    if ((ticket as TransformedTicket[]).length === 1) {
      searchTicketByRAOrRes(ticket[0].ticketNumber)
    } else {
      // Only reset when searches change
      reset()
      await new Promise((resolve) => setTimeout(resolve, 1000))
      dispatch(updateTicket({ ticket: ticket as TransformedTicket }))
      setMultiTicketSearch(ticket as SeekResponse[])
      setTicketSearchStatus(TicketSearchStatus.SUCCESS)
      setSearchStatusMessage(SearchStatusMessage.NONE)
    }
  }
  /**
   * Clears search status message and sets failure search status which triggers the form shake animation.
   */
  const onTicketSearchFailure = (e: any) => {
    console.log('hitting ', e)
    dispatch(updateTicketError({ error: e }))
    setSearchStatusMessage(SearchStatusMessage.NONE)
    setTicketSearchStatus(TicketSearchStatus.FAILURE)
  }
  /**
   * Clears search status and sets non ideal state (No results came back)
   */
  const onTicketSearchNonIdealState = () => {
    setSearchStatusMessage(SearchStatusMessage.NONE)
    setTicketSearchStatus(TicketSearchStatus.NON_IDEAL_STATE)
  }
  /**
   * Utility function to toggle conditions in which the form should shake.
   */
  const isErrorState = React.useCallback(
    () => [TicketSearchStatus.NON_IDEAL_STATE, TicketSearchStatus.FAILURE].includes(ticketSearchStatus),
    [ticketSearchStatus],
  )

  useFleetVehicleSearch()

  // fade in mount animation on search card
  useMount(() => {
    searchCardControls.start({ opacity: 1 })
  })

  // shake animation on errors
  React.useEffect(() => {
    if (isErrorState())
      searchCardControls.start({
        x: 0,
        transition: {
          from: -10,
          type: 'spring',
          stiffness: 1000,
          damping: 10,
        },
      })
  }, [isErrorState, searchCardControls, ticketSearchStatus])

  // Sorting callback to sort tickets from most recent to least recent
  const sortTicketsByDates = (result1: { sortTimestamp?: string }, result2: { sortTimestamp?: string }) => {
    return new Date(result2.sortTimestamp || '').getTime() - new Date(result1.sortTimestamp || '').getTime()
  }

  // Array.sort doesn't work on readonly arrays.
  // Make copy of readonly array to sort.
  let sortedTickets = multiTicketSearch.slice().sort(sortTicketsByDates)

  if (previousSearchObject.wasPreviousSearch && ticketSearchStatus !== TicketSearchStatus.SUCCESS) {
    sortedTickets = previousSearchObject.sortedTickets
  }
  return (
    <Flex style={{ overflow: 'hidden', flex: 1 }}>
      <Container>
        <motion.div animate={searchCardControls} initial={false}>
          <Flex>
            <div style={{ padding: `0 ${pxToRem(24)}` }}>
              <SearchCard>
                <Heading as="h4" spacing="sm">
                  {t('Search')}
                </Heading>
                <Ariakit.TabProvider store={tabStore}>
                  <TicketSearchForm
                    onSearchByRAOrRes={searchTicketByRAOrRes}
                    onSearchByTerms={searchTicketByTerms}
                    errorMessage={isErrorState() ? t('No ticket(s) could be found. Please try again.') : undefined}
                    searchStatusMessage={searchStatusMessage}
                    previousSearchObject={previousSearchObject}
                    setPreviousSearchObject={setPreviousSearchObject}
                    showPreviousResults={showPreviousResults}
                    setShowPreviousResults={setShowPreviousResults}
                  />
                </Ariakit.TabProvider>
              </SearchCard>
            </div>
            <AnimatePresence>
              {(ticketSearchStatus === TicketSearchStatus.SUCCESS ||
                (showPreviousResults && previousSearchObject.sortedTickets.length)) && (
                <motion.div
                  key="results"
                  animate={{ width: 340, opacity: 1, x: 0 }}
                  initial={{ width: 0, opacity: 0, x: -300 }}
                  exit={{ width: 0, opacity: 0, x: -300 }}
                  style={{
                    alignSelf: 'flex-start',
                    maxHeight: pxToRem(560),
                    overflow: 'hidden',
                    overflowY: 'scroll',
                  }}
                >
                  {sortedTickets.map((result) => {
                    const formattedResult = {
                      ticketNumber: result.ticketNumber,
                      primaryDriver: result.renter,
                      vehicle: result.vehicleInfo,
                      pickupInfo: result.pickupInfo,
                    } as unknown as TransformedTicket
                    return (
                      <motion.div
                        key={result.ticketNumber}
                        whileHover={{ y: 5 }}
                        style={{ marginBottom: pxToRem(24), width: 320 }}
                      >
                        <TicketLink
                          link={`/ticket/${result.ticketNumber}`}
                          onMouseEnter={async () => {
                            const ticket = await loadTicketByRA(result.ticketNumber, serviceProxy, flags)
                            // Only reset when searches change
                            reset()
                            await new Promise((resolve) => setTimeout(resolve, 1000))
                            dispatch(updateTicket({ ticket }))
                          }}
                          onClick={() => {
                            setPreviousSearchObject({
                              wasPreviousSearch: true,
                              sortedTickets: sortedTickets,
                            })
                          }}
                        >
                          <TicketSearchResult {...formattedResult} />
                        </TicketLink>
                      </motion.div>
                    )
                  })}
                </motion.div>
              )}
            </AnimatePresence>
          </Flex>
        </motion.div>
      </Container>
      <Actions />
    </Flex>
  )
}

export default TicketSearch
