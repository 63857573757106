import * as Ariakit from '@ariakit/react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { pxToRem } from 'theme/utils'

export const Tab = styled(Ariakit.Tab).attrs({
  type: 'button',
})`
  cursor: pointer;
  color: ${palette('dustyGray')};
  display: inline-flex;
  position: relative;
  font-size: ${pxToRem(14)};
  flex: 1;
  user-select: none;
  outline: none;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;
  height: 2rem;
  padding: 0 1rem;
  min-width: 2.5rem;
  border: none;
  border-bottom: 2px solid transparent;

  &:hover {
    cursor: pointer;
  }

  &[aria-selected='true'] {
    border-color: ${palette('calypso')};
    color: black;
  }

  &[disabled] {
    pointer-events: none;
  }
`

export const TabList = styled(Ariakit.TabList)`
  display: flex;
  align-items: center;
  list-style: none;
`

export const TabPanel = styled(Ariakit.TabPanel)`
  &:focus {
    outline: none;
  }
`
