import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Paragraph, Heading } from 'components/primitives'
import Divider from 'components/Divider'
import styled from 'styled-components/macro'
import Card from 'components/Card'
import { palette } from 'styled-tools'
import { TransformedTicket } from 'types/ticket'

type Props = {
  ticketNumber: string
  action?: React.ReactChild
} & TransformedTicket

const TicketResult = styled(Card as any)`
  width: 320px;
  overflow: hidden;
  text-overflow: clip;

  &:hover {
    background: ${palette('wildSand')};
  }

  will-change: background;
  transition: background 0.3s;
`

const TicketSearchResult: React.FC<Props> = ({ ticketNumber, primaryDriver, vehicle, pickupInfo: { dateTime } }) => {
  const { t } = useTranslation()
  let headingText = t('notFound.driverProfile')
  if (primaryDriver && primaryDriver.firstName && primaryDriver.lastName) {
    headingText = `${primaryDriver.firstName} ${primaryDriver.lastName}`
  }
  const rentalStartDate = new Date(dateTime).toLocaleDateString()

  return (
    <TicketResult>
      <Heading as="h4">{headingText}</Heading>
      <Paragraph>
        {t('RA')}#: {ticketNumber}
      </Paragraph>
      <Paragraph>
        {t('Rental Start')}: {rentalStartDate}
      </Paragraph>
      <Divider />
      {vehicle ? (
        <>
          <Heading as="h4">
            {vehicle.year} {vehicle.make} {vehicle.model}
          </Heading>
          <Paragraph>
            {t('VIN')}: {vehicle.vinLast8}
          </Paragraph>
          <Paragraph>{vehicle.color}</Paragraph>
          <Paragraph>
            {vehicle.licensePlate} {vehicle.licensePlateState}
          </Paragraph>
        </>
      ) : (
        t('notFound.vehicleInfo')
      )}
    </TicketResult>
  )
}

export default TicketSearchResult
