import * as React from 'react'
import { components, OptionProps } from 'react-select'
import { Flex, Heading } from 'components/primitives'
import { dustyGray, mercury } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import { GMapsPredictions, SuggestedPlace } from 'types/googleMaps'
import { getIconByCategory } from 'icons'

const iconProps = {
  size: pxToRem(24),
  color: dustyGray,
}

export const Option: React.FC<OptionProps<SuggestedPlace | GMapsPredictions, boolean, any>> = ({ data, ...rest }) => {
  const { highlightedTitle, highlightedVicinity, category } = data as SuggestedPlace
  const Icon = getIconByCategory(category)
  return (
    <components.Option data={{ highlightedTitle, highlightedVicinity, category } as SuggestedPlace} {...rest}>
      <Flex
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          margin: `0 ${pxToRem(16)}`,
        }}
      >
        <Flex style={{ justifyContent: 'center', alignItems: 'center', marginRight: pxToRem(16) }}>
          <Icon {...iconProps} />
        </Flex>
        <Flex
          style={{
            padding: `${pxToRem(16)} 0`,
            borderBottom: `1px solid ${mercury}`,
            flexDirection: 'column',
            height: '100%',
            flex: 2,
          }}
        >
          <Heading as="h5" style={{ fontSize: pxToRem(14) }} dangerouslySetInnerHTML={{ __html: highlightedTitle }} />
          <Heading as="h6" style={{ fontSize: pxToRem(12) }} dangerouslySetInnerHTML={{ __html: highlightedVicinity }} />
        </Flex>
      </Flex>
    </components.Option>
  )
}

export default Option
