import { useCallerLocation, useVehicleLocation } from 'hooks/redux/useLocations'
import useSpatialSearch from 'hooks/solr/useSpatialSearch'
import { Branch } from 'types/branch'
import { getIncidentLocationCoordinates } from 'utils/location'

// Retrieves nearest branch to vehicle for Recovery callType. Uses caller if vehicle not available
export const useNearestBranch = () => {
  const callerLocation = useCallerLocation()
  const vehicleLocation = useVehicleLocation()
  const { lat: latitude, lon: longitude } = getIncidentLocationCoordinates(vehicleLocation || callerLocation)
  const location = vehicleLocation || callerLocation
  const countryShortCode = location?.address.countryShortCode

  const { data } = useSpatialSearch(latitude, longitude, 'TRIBRAND')
  if (!data || !data.result || data.result.length === 0) return null
  const sortedData = [...data.result]
    .filter((branch) => branch.address.country_code === countryShortCode)
    // filter out licensee branches. They should not receive Recovery notices
    .filter((branch) => !branch.additional_data.group_branch_number.startsWith('L'))
    .sort(function (a: Branch, b: Branch) {
      return a.additional_data.calculated_distance - b.additional_data.calculated_distance
    })

  return sortedData[0]
}

export default useNearestBranch
