import * as React from 'react'

export function useCarousel<T>(items: ReadonlyArray<T>, onCycle?: () => void) {
  const [index, setIndex] = React.useState<number>(0)

  const cycle = (newIndex: number) => () => {
    setIndex(newIndex)
    if (onCycle) onCycle()
  }

  const isFirst = index === 0
  const isLast = index === items.length - 1
  const next = isLast ? cycle(0) : cycle(index + 1)
  const previous = cycle(index - 1)
  const item = items[index]

  return {
    next,
    previous,
    isFirst,
    isLast,
    item,
  }
}

export default useCarousel
