import React from 'react'
import { useTranslation } from 'react-i18next'
import { Error } from 'components/fields'
import Margin from 'components/Margin'
import Condition from 'components/fields/Condition'
import { InputProps } from 'components/Input'
import MultipleChoice from './MultipleChoice'

export enum YesNoTriggers {
  YES = 'yes',
  NO = 'no',
  EITHER = 'yes|no',
}

type Props = { trigger?: YesNoTriggers } & InputProps

const YesNoRadio: React.FC<Props> = ({ name, trigger = YesNoTriggers.YES, children, ...rest }) => {
  const { t } = useTranslation()
  const choices = {
    [YesNoTriggers.YES]: t('yes'),
    [YesNoTriggers.NO]: t('no'),
  }
  return (
    <Margin spacing="md">
      <MultipleChoice name={name} choices={choices} {...rest} />
      <Error name={name} />
      {children && (
        <Condition
          when={name}
          is={(trigger === YesNoTriggers.EITHER && (v => v)) || trigger}
          children={children}
        />
      )}
    </Margin>
  )
}

export default YesNoRadio
