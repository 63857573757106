import { Participant, ParticipantMetadata } from './global'
import { LocationPayload } from './location'

export type Assignment = {
  assignmentId?: string
  assignmentType: AssignmentTypes
  assignmentOperation: AssignmentOperations
  assignmentDetails: AssignmentDetails
  dispatchStatus?: string
  consumerTrackingCode?: string
}

export enum AssignmentTypes {
  VEHICLE = 'VEHICLE',
}

export enum AssignmentOperations {
  NEW = 'NEW',
  ADD_ASSIGNEE = 'ADD_ASSIGNEE',
  REASSIGN_ASSIGNEE = 'REASSIGN_ASSIGNEE',
  CANCEL_ASSIGNEE = 'CANCEL_ASSIGNEE',
}

export type AssignmentDetails = {
  vehicle?: AssignmentVehicle
  assignee?: Assignee
  additionalInformation?: NameValuePair[]
  searchAddress?: AssignmentSearchAddress
}

export type AssignmentVehicle = {
  vin?: string
  color?: string
  registration?: string
  make?: string
  model?: string
  year?: number
  currentLocation?: AssignmentLocation
}

export type Assignee = {
  assignedEntityId?: string
}

export type AssignmentSearchAddress = {
  addressLineOne?: string
  administrativeArea?: string
  city?: string
  country?: string
  postalCode?: string
  latitude?: number
  longitude?: number
}

export type AssignmentLocation = {
  latitude?: number
  longitude?: number
  addressLineOne?: string
  city?: string
  administrativeArea?: string
  postalCode?: string
  country?: string
}

export type NameValuePair = {
  name: string
  value: any
}

export type SmartAssignment = {
  assignment?: Assignment | undefined
  participants: Participant[]
  participantsMetadata?: ParticipantMetadata
}

export interface SmartAssignmentState {
  assignment?: SmartAssignment | null
  participant: Participant | null
  customDropoffDestination?: LocationPayload | null
}
