import packageJson from '../../../package.json'
import { browserName, fullBrowserVersion, deviceType } from 'react-device-detect'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'redux/appStore'
import { accessTokenContext } from 'AppProviders'
import { config } from 'config'

type LogRequestBody = {
  logType: 'HTTP_REQUEST' | 'HTTP_RESPONSE' | 'HTTP_ERROR'
  host: string
  'App Name': string
  'App Version': string
  browser: string
  browserVersion: string
  device: string
  Locale: string
  screenHeight: string
  screenWidth: string
  Timezone: string
  userId: string
  request?: any
  response?: any
  error?: any
}

export function useLogger() {
  const { serviceProxyUrl } = config
  const loggingInstance = axios.create()
  const accessToken = accessTokenContext.useAccessTokenContext()
  loggingInstance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
  loggingInstance.defaults.headers.common['Accept'] = 'application/json; version=1.0.0'
  const deviceTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone
  const currentUser = useSelector(selectCurrentUser)
  const browserInfo = {
    host: window.location.hostname,
    'App Name': 'HERO',
    'App Version': packageJson.version,
    browser: browserName,
    browserVersion: fullBrowserVersion,
    device: deviceType,
    Locale: 'en-US',
    screenHeight: window.innerHeight.toString(),
    screenWidth: window.innerWidth.toString(),
    Timezone: deviceTimeZone,
    userId: currentUser?.nameID ?? '',
  }

  const writeLog = (type, data) => {
    const body: LogRequestBody = {
      logType: type,
      ...browserInfo,
    }
    if ((type = 'HTTP_REQUEST')) {
      body.request = data
    } else if ((type = 'HTTP_RESPONSE')) {
      body.response = data
    } else if ((type = 'HTTP_ERROR')) {
      body.error = data
    }
    let endpoint = '/serviceproxy/logging'
    if (window.location.hostname !== 'localhost') {
      endpoint = serviceProxyUrl + endpoint.replace('serviceproxy', 'proxy')
    }
    loggingInstance.post(endpoint, body)
  }

  return writeLog
}
