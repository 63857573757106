import * as React from 'react'
import { useCallerLocation, useVehicleLocation } from 'hooks/redux/useLocations'
import { SelectableGoogleHeroMarker } from 'pages/Ticket/HeroMap/Markers/GoogleMapMarkers'
import { MarkerType } from 'types/googleMaps'
import { Tooltip } from 'components/Tooltip'
import { kilometersToMiles } from 'utils/conversions'
import { LEFT_SIDEBAR_WIDTH } from 'pages/Ticket/LeftSidebar'
import ExchangeTooltip from './ExchangeTooltip'
import { useGoogleMaps } from 'hooks/map'
import { LocationPayload } from 'types/location'
import { Branch, Brands } from 'types/branch'

type Props = {
  branchData: Branch
}

const ExchangeMarker: React.FC<Props> = ({ branchData }) => {
  const latitude = branchData.gps.latitude
  const longitude = branchData.gps.longitude
  const peopleSoftId = branchData.id
  const locationNameTranslation = branchData.name
  const brand = branchData.brand as Brands
  const calculatedDistance = branchData.additional_data.calculated_distance
  const formattedPhone = branchData.additional_data.formatted_phone
  const formattedCityStateZip = branchData.additional_data.formatted_city_state_zip
  const addressLines = branchData.address.street_addresses
  const { map } = useGoogleMaps()
  const vehicleLocation = useVehicleLocation()
  const callerLocation = useCallerLocation()
  const eitherLocation = vehicleLocation || callerLocation
  const mapRef = React.useRef<google.maps.Map | null>(map)
  const vehicleOrCallerRef = React.useRef<LocationPayload | undefined | null>(eitherLocation)
  const callerRef = React.useRef<LocationPayload | undefined | null>(callerLocation)
  //map zoom is a larger view the lower the zoom value. 5 is a wider zoom than 12 which is wider than 30 etc.
  const MINIMUM_ZOOM = 12

  React.useEffect(() => {
    const eitherLocation = vehicleLocation || callerLocation

    mapRef.current = map
    vehicleOrCallerRef.current = eitherLocation || null
    callerRef.current = callerLocation || null
  })

  React.useEffect(() => {
    if (mapRef.current && latitude && longitude && vehicleOrCallerRef.current) {
      if (vehicleOrCallerRef.current) {
        const branchCoords = new google.maps.LatLng(latitude, longitude)
        const vehicleCoords = new google.maps.LatLng(vehicleOrCallerRef.current.latitude, vehicleOrCallerRef.current.longitude)

        const bounds = new google.maps.LatLngBounds()
        bounds.extend(vehicleCoords)
        bounds.extend(branchCoords)

        if (callerRef.current && callerRef !== vehicleOrCallerRef) {
          const callerCoords = new google.maps.LatLng(callerRef.current.latitude, callerRef.current.longitude)
          bounds.extend(callerCoords)
        }

        mapRef.current.fitBounds(bounds, {
          top: 30,
          left: 30,
          bottom: 104,
          right: 104,
        })
        //if getZoom returns undefined we want to go ahead and set the zoom, so set it to MINIMUM_ZOOM + 1 in that case to pass the check.
        if ((mapRef.current.getZoom() || MINIMUM_ZOOM + 1) > MINIMUM_ZOOM) {
          mapRef.current.setZoom(MINIMUM_ZOOM)
        }
      }
    }
    // Only fit bounds when exchange marker lat/lon changes
  }, [latitude, longitude])

  return (
    <SelectableGoogleHeroMarker
      id={peopleSoftId}
      type={MarkerType.EXCHANGE}
      latitude={latitude}
      longitude={longitude}
      onClick={() => {}}
    >
      <Tooltip sticky xOffset={LEFT_SIDEBAR_WIDTH}>
        <ExchangeTooltip
          brand={brand}
          name={locationNameTranslation}
          calculatedDistance={`${kilometersToMiles(calculatedDistance)} miles`}
          street={addressLines[0]}
          formattedPhone={formattedPhone}
          formattedCityStateZip={formattedCityStateZip}
        />
      </Tooltip>
    </SelectableGoogleHeroMarker>
  )
}

export default ExchangeMarker
