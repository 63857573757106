import {
  Button,
  LinkButton,
  PrimaryButton,
  RegularButton,
  SecondaryButton,
  ChevronButton,
  WorkflowButton,
} from './Button'

export { default as StyledLinkButton } from './StyledLinkButton'

export default Object.assign(Button, {
  Primary: PrimaryButton,
  Regular: RegularButton,
  Secondary: SecondaryButton,
  Link: LinkButton,
  Chevron: ChevronButton,
  Workflow: WorkflowButton,
})
