import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Divider from './Divider'
import { Flex, Label } from 'components/primitives'
import { silver } from 'theme/colors'

export const OrDivider: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Flex style={{ alignItems: 'center' }}>
      <Divider style={{ flex: 1 }} />
      <Label style={{ fontSize: 14, margin: 8, color: silver }}>{t('OR')}</Label>
      <Divider style={{ flex: 1 }} />
    </Flex>
  )
}

export default OrDivider
