import { Branch, Brands } from 'types/global'
import { DateTime } from 'luxon'
import { format } from 'date-fns'
import { LocationPayload } from 'types/location'
import { ExchangeSearchQuery } from './ExchangeTypes'
import { isEmpty } from 'lodash-es'

enum LongDays {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

enum ShortDays {
  Mon = 'Mon',
  Tues = 'Tues',
  Wed = 'Wed',
  Thurs = 'Thurs',
  Fri = 'Fri',
  Sat = 'Sat',
  Sun = 'Sun',
}

export type ExchangeSearchProps = {
  searchLocation?: LocationPayload | null
}

export type ResultState = {
  data: ExchangeSearchQuery | null
  loading: boolean
  error: any
  reset: any
  fetch: any
}

export const defaultState = {
  data: null,
  loading: false,
  error: null,
}

// About 200 miles
export const vehicleExchangeRadiusInKM = 322

export const emptyCarClasses = {
  exchangeSearch: {
    carClassAvailability: [],
  },
}

export const getBrandBranches = (locationsResult: Branch[], filterBrand: Brands) =>
  locationsResult
    .filter(
      ({ additional_data }) =>
        additional_data?.attributes &&
        !(
          additional_data?.attributes?.includes('EXCLUDED_FROM_ROADSIDE_EXCHANGE') ||
          additional_data?.attributes?.includes('EXOTICS')
        ),
    )
    .filter(({ brand }) => brand === filterBrand)
    .map((branch) => ({
      pickup_location_id: branch.id,
      return_location_id: branch.id,
      branchData: branch,
    }))

export const generateTimestamp = (timezone: string | null, type: 'return' | 'pickup') => {
  const dateTime = timezone
    ? DateTime.local()
        .plus({ minutes: type === 'pickup' ? 30 : 5, days: type === 'return' ? 30 : 0 })
        .toJSDate()
        .toLocaleString('en-US', { timeZone: timezone })
    : DateTime.local()
        .plus({ minutes: type === 'pickup' ? 30 : 5, days: type === 'return' ? 30 : 0 })
        .toJSDate()

  const formattedTime = format(dateTime, 'YYYY-MM-DDTHH:mm')
  return formattedTime
}

export const sortWeeklyHours = (businessHours: any, useShortNames: boolean) => {
  if (!businessHours) return ''
  let weekDayOrder = useShortNames ? Object.keys(ShortDays) : Object.keys(LongDays)

  const todaysDate = new Date()

  //skew the weekDayOrder based on today's day of the week
  for (let i = 0; i < todaysDate.getDay() - 1; i++) {
    weekDayOrder.push(weekDayOrder.shift() || '')
  }

  //pick which set of hours to use
  let weekDays
  if (isEmpty(businessHours.businessHoursOrdered)) {
    //no business hours (full names), use tow hours (short names)
    weekDays = businessHours.towHoursOrdered

    //convert short weekday names to long if we're not using short names
    if (!useShortNames) {
      weekDays = makeDaysUnabbreviated(weekDays)
    }
  } else {
    //business hours (full names) used
    weekDays = businessHours.businessHoursOrdered

    //Convert to short if using short names
    if (useShortNames) {
      weekDays = makeDaysAbbreviated(weekDays)
    }
  }

  //sort week days by enum
  const sortedWeekDays = Object.keys(weekDays).sort((a, b) => weekDayOrder.indexOf(a) - weekDayOrder.indexOf(b))

  //add hours strings back to object
  const sortedWeekObject = sortedWeekDays.reduce((acc, key) => {
    acc[key] = weekDays[key]
    return acc
  }, {})

  return sortedWeekObject
}

const makeDaysUnabbreviated = (weekdays: any) => {
  let newWeekDays = {
    Monday: '',
    Tuesday: '',
    Wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
  }
  //translate short days to long days
  for (const day of Object.keys(weekdays)) {
    const hoursString = weekdays[day]
    const dayNum = Object.keys(ShortDays).indexOf(day)
    const fullDayName = Object.keys(LongDays)[dayNum] as keyof typeof newWeekDays
    newWeekDays[fullDayName] = hoursString
  }
  return newWeekDays
}

const makeDaysAbbreviated = (weekdays: any) => {
  let newWeekDays = {
    Mon: '',
    Tues: '',
    Wed: '',
    Thurs: '',
    Fri: '',
    Sat: '',
    Sun: '',
  }
  //translate long days to short days
  for (const day of Object.keys(weekdays)) {
    const hoursString = weekdays[day]
    const dayNum = Object.keys(LongDays).indexOf(day)
    const fullDayName = Object.keys(ShortDays)[dayNum] as keyof typeof newWeekDays
    newWeekDays[fullDayName] = hoursString
  }
  return newWeekDays
}
