import * as React from 'react'
import { Brands } from 'types/global'
import Input from 'components/Input'

export const BrandFields: React.FC = () => (
  <>
    <Input.Radio name="brand" value={(Brands.ENTERPRISE as unknown) as HTMLInputElement}>
      {Brands.ENTERPRISE}
    </Input.Radio>
    <Input.Radio name="brand" value={(Brands.NATIONAL as unknown) as HTMLInputElement}>
      {Brands.NATIONAL}
    </Input.Radio>
    <Input.Radio name="brand" value={(Brands.ALAMO as unknown) as HTMLInputElement}>
      {Brands.ALAMO}
    </Input.Radio>
  </>
)

export default BrandFields
