import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import { AssignmentNetworks } from 'types/global'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { NetworkNames } from 'components/Select/NetworkSelect'
import { SelectOption } from 'types/global'
import Input from 'components/Input'
import { isRequired } from 'utils/validators'
import { Condition } from 'components/fields'
import VehicleConditionSelect, { VehicleConditions } from './VehicleConditionSelect'

type VehicleConditionProps = {
  prevCondition?: SelectOption<VehicleConditions>
}

export const VehicleCondition = (props: VehicleConditionProps) => {
  const { prevCondition } = props
  const { t } = useTranslation()
  const { towExchange, tow, lockout } = useSelector(selectFormsState) as any
  const form = useForm()
  const vehicleConditionValue = towExchange
    ? towExchange.vehicleCondition
    : tow
    ? tow.vehicleCondition
    : ''

  React.useEffect(() => {
    if (prevCondition) {
      form.change('vehicleConditionSelect', prevCondition)
    }
  }, [prevCondition, form])

  React.useEffect(() => {
    if (vehicleConditionValue === 'Accident/Collision/Vandalism Tow') {
      form.change('network', {
        label: NetworkNames[AssignmentNetworks.BODY],
        value: AssignmentNetworks.BODY,
      })
    }
    if (
      vehicleConditionValue === 'Flat Tire, No Spare' ||
      vehicleConditionValue === 'Flat Tire (more than 2) Tow'
    ) {
      form.change('network', {
        label: NetworkNames[AssignmentNetworks.TIRE],
        value: AssignmentNetworks.TIRE,
      })
    }
  }, [vehicleConditionValue, form])

  React.useEffect(() => {
    if (lockout?.lostKeys === 'yes') {
      form.change('vehicleConditionSelect', {
        label: t(`vehicleCondition.lostKey`),
        value: VehicleConditions.LOST_KEY,
      })
    } else if (lockout?.lostKeys === 'no' && lockout?.keysLocation === 'trunk') {
      form.change('vehicleConditionSelect', {
        label: t(`vehicleCondition.keysLocked`),
        value: VehicleConditions.KEYS_LOCKED,
      })
    }
  }, [form, lockout, t])

  return (
    <>
      <VehicleConditionSelect
        name="vehicleConditionSelect"
        label={t('tow.unattended.vehicleCondition')}
      />
      <Input.Hidden
        name="vehicleConditionSelect.value"
        validate={isRequired(t('required.vehicleCondition'))}
      />
      <Condition when="vehicleConditionSelect" is={v => v?.value === VehicleConditions.OTHER}>
        <Input.Textarea
          name="vehicleCondition"
          label={`${t('tow.unattended.vehicleConditionNote')}*`}
          maxLength={100}
          validate={isRequired(t('required.vehicleCondition'))}
        />
      </Condition>
      <Condition when="vehicleConditionSelect" is={v => v?.value !== VehicleConditions.OTHER}>
        {(v: SelectOption<VehicleConditions>) => (
          <>
            <Input.Hidden
              name="vehicleCondition"
              defaultValue={v?.value ? t(`vehicleCondition.${v.value}`) : undefined}
            />
          </>
        )}
      </Condition>
    </>
  )
}

export default VehicleCondition
