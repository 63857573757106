import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { UlyDropoffLocation, UlyPickupLocation, UlyRides } from 'types/urgently'
import Margin from 'components/Margin'
import { Label, Paragraph } from 'components/primitives'

export const DispatchUlyRideInfo = ({ ride }: { ride: UlyRides }) => {
  const { t } = useTranslation()

  const { pickupLocation, dropoffLocation } = ride

  return (
    <>
      <Margin spacing="sm">
        <Paragraph as="b" spacing="sm">
          {ride.name}
        </Paragraph>
      </Margin>
      <Margin spacing="sm">
        <Label>{t('case.passengerCount')}</Label>
        <Paragraph spacing="sm">{ride.passengerCount}</Paragraph>
      </Margin>
      <Margin spacing="sm">
        <Label>{t('case.luggageCount')}</Label>
        <Paragraph spacing="sm">{ride.luggageCount}</Paragraph>
      </Margin>

      {pickupLocation && <PickupLocation pickupLocation={pickupLocation} />}
      {dropoffLocation && <DropoffLocation dropoffLocation={dropoffLocation} />}
    </>
  )
}

const PickupLocation = ({ pickupLocation }: { pickupLocation: UlyPickupLocation }) => {
  const { t } = useTranslation()
  return (
    <Margin spacing="sm">
      <Label>{t('case.pickupLocation')}</Label>
      <Paragraph spacing="sm">{pickupLocation.address}</Paragraph>
      <Label>{t('case.coordinates')}</Label>
      <Paragraph spacing="sm">{`${pickupLocation.latitude}, ${pickupLocation.longitude}`}</Paragraph>
      {pickupLocation.impoundFacility && (
        <Margin spacing="sm">
          <Label>{t('case.impoundFacility')}</Label>
          <Paragraph spacing="sm">{pickupLocation.impoundFacility.businessName}</Paragraph>
          <Label>{t('case.phone')}</Label>
          <Paragraph spacing="sm">{pickupLocation.impoundFacility.phone}</Paragraph>
          <Label>{t('case.fax')}</Label>
          <Paragraph spacing="sm">{pickupLocation.impoundFacility.fax}</Paragraph>
        </Margin>
      )}
    </Margin>
  )
}
const DropoffLocation = ({ dropoffLocation }: { dropoffLocation: UlyDropoffLocation }) => {
  const { t } = useTranslation()
  return (
    <Margin spacing="sm">
      <Label>{t('case.dropoffLocation')}</Label>
      <Paragraph spacing="sm">{dropoffLocation.address}</Paragraph>
      {dropoffLocation.locationName && (
        <>
          <Label>{t('case.locationName')}</Label>
          <Paragraph spacing="sm">{dropoffLocation.locationName}</Paragraph>
        </>
      )}
    </Margin>
  )
}

export default DispatchUlyRideInfo
