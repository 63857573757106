import { upperFirst, isEmpty } from 'lodash'
import { Info } from 'luxon'
import { AnyObject } from 'types/location'
import { SmartAssignment } from 'types/entegral'

const days = ['MF', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun', ...Info.weekdays()].map((day) =>
  day.toLowerCase(),
)

export const mapEntegralBusinessHours = (smartAssignment: SmartAssignment) => {
  const newAssignmentData = { ...smartAssignment }
  let { participants } = newAssignmentData

  participants = participants.map((participant) => {
    let businessHours: AnyObject | null = null
    let towHours: AnyObject | null = null

    if (participant.properties && Object.keys(participant.properties).some((key) => key.match(/businesshours/gi))) {
      businessHours = Object.entries(participant.properties).reduce((acc, [key, value]) => {
        if (key.match(/businesshours/gi) && value) {
          const newKey = key.replace(/businesshours/gi, '')
          return newKey.length > 0 ? { ...acc, [upperFirst(newKey)]: value } : acc
        } else {
          return acc
        }
      }, {})
    }

    if (participant.properties && Object.keys(participant.properties).some((key) => key.match(/Roadside/gi))) {
      towHours = Object.entries(participant.properties).reduce((acc, [key, value]) => {
        if (key.match(/Roadside/gi) && value) {
          const newKey = key.replace(/Roadside/gi, '').replace(/businesshours/gi, '')
          return newKey.length > 0 ? { ...acc, [upperFirst(newKey)]: value } : acc
        } else {
          return acc
        }
      }, {})
    }

    let businessHoursOrdered = {}
    let towHoursOrdered = {}

    if (!isEmpty(businessHours) && businessHours !== null) {
      Object.keys(businessHours)
        .sort((a, b) => days.indexOf(a.toLowerCase()) - days.indexOf(b.toLowerCase()))
        .filter((time) => !time.includes('Roadside'))
        .forEach((key) => (businessHoursOrdered[key] = businessHours![key]))

      participant.businessHours = { ...participant.businessHours, businessHoursOrdered }
    }
    if (!isEmpty(towHours) && towHours !== null) {
      Object.keys(towHours)
        .sort((a, b) => days.indexOf(a.toLowerCase()) - days.indexOf(b.toLowerCase()))
        .filter((time) => !time.includes('Question'))
        .forEach((key) => (towHoursOrdered[key] = towHours![key]))

      participant.businessHours = { ...participant.businessHours, towHoursOrdered }
    } else {
      participant.businessHours = businessHours
    }

    return participant
  })

  newAssignmentData.participants = participants

  return newAssignmentData
}
