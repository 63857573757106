import * as React from 'react'
import { Participant as TParticipant } from 'types/global'
import { Flex, Label } from 'components/primitives'
import useCarousel, { CarouselControl, CarouselControlProps } from 'components/Carousel'
import ParticipantItem from './ParticipantItem'
import { Props } from './Results'

type CProps = {
  items: ReadonlyArray<TParticipant>
} & Props

const SmartAssignmentCarousel = ({ items, ...rest }: CProps) => {
  const { item, ...controls } = useCarousel(items)

  return <CarouselItem item={item} {...rest} {...controls} />
}

type CIProps = {
  item: TParticipant
} & Props &
  CarouselControlProps
const CarouselItem = ({ item, label, network, onSetAssignment, ...controls }: CIProps) => {
  return (
    <>
      <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Label>{label}</Label>
        <CarouselControl showBypassModals={false} {...controls} />
      </Flex>

      <ParticipantItem participant={item} network={network} onSetAssignment={onSetAssignment} />
    </>
  )
}

export default SmartAssignmentCarousel
