import * as React from 'react'
import styled from 'styled-components/macro'
import { Flex } from 'components/primitives'
import { ifProp, theme } from 'styled-tools'
import { StyleProps } from 'types/global'

const Container = styled(Flex as any)`
  padding: ${theme('spaces.1')} 0;
  align-items: center;

  &:hover {
    cursor: ${ifProp('onClick', 'pointer', 'inherit')};
  }
`

export type ListItemProps = {
  onClick?: () => void
  children: React.ReactNode
} & StyleProps

function ListItem(props: ListItemProps) {
  return <Container {...props} />
}

export default ListItem
