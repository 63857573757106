import * as React from 'react'

type Props = {
  scroll: boolean
}

const { body } = document

export class BodyScroll extends React.Component<Props> {
  // @ts-ignore
  componentDidUpdate({ scroll: prevScroll }) {
    const { scroll } = this.props
    if (scroll !== prevScroll) {
      if (!scroll) {
        body.style.overflow = 'hidden'
      } else {
        body.style.overflow = 'unset'
      }
    }
  }
  componentWillUnmount() {
    body.style.overflow = 'unset'
  }

  render() {
    return null
  }
}

export default BodyScroll
