import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AaaStatusCode } from 'types/aaa'
import { Flex, Heading } from 'components/primitives'
import { StyledLinkButton } from 'components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentEvent, ReduxState, selectTicketHistoryEvents } from 'redux/appStore'
import { DynamoEvents, ICurrentEvent } from 'types/events'
import { monza } from 'theme/colors'
import { cancelDialogContext } from './DispatchStatus'
import { updateLocation } from 'redux/location/locationSlice'
import { MarkerType } from 'types/googleMaps'

const EditableStates = [AaaStatusCode.TR, AaaStatusCode.AK, AaaStatusCode.RE, AaaStatusCode.AS]

type Props = {
  title: string
  dispatchStatus?: AaaStatusCode | null
  readOnly?: boolean
  edit?: boolean
  ulyHeader?: boolean
  onClick?: () => void
  isUnattended?: boolean
}

const DispatchHeader: React.FC<Props> = ({
  title,
  ulyHeader = false,
  dispatchStatus,
  readOnly: customReadOnly,
  edit,
  onClick,
  isUnattended,
}) => {
  const { t } = useTranslation()
  const event = useSelector((state: ReduxState) => selectCurrentEvent(state)()) || (undefined as unknown as ICurrentEvent)
  const events = useSelector(selectTicketHistoryEvents)
  const dispatch = useDispatch()
  const readOnly = event?.readOnly
  const { cancelled } = cancelDialogContext.useCancelDialogContext()
  const editable =
    dispatchStatus && EditableStates.includes(dispatchStatus) && !(customReadOnly !== undefined ? customReadOnly : readOnly)
  const [isCancelled, setIsCancelled] = React.useState(false)

  React.useEffect(() => {
    if (cancelled) {
      setIsCancelled(true)
    }
  }, [cancelled])

  return (
    <Flex style={{ justifyContent: 'space-between' }} spacing="md">
      <Heading as="h6" style={isUnattended ? { color: monza, fontWeight: 'bold' } : {}}>
        {title}
      </Heading>
      {ulyHeader
        ? null
        : editable &&
          !isCancelled && (
            <StyledLinkButton
              onClick={() => {
                if (edit) {
                  function setRecentLocationData(events: DynamoEvents[]) {
                    const recentEventWithLocations = events.find((event) => event.serviceLocations)

                    if (recentEventWithLocations) {
                      const serviceLocs = recentEventWithLocations.serviceLocations
                      if (serviceLocs) {
                        if (serviceLocs.callerLocation) {
                          let callerLoc = serviceLocs.callerLocation
                          dispatch(
                            updateLocation({
                              name: 'callerLocation',
                              location: {
                                ...callerLoc,
                                serviceLocationType: MarkerType.CALLER,
                              },
                            }),
                          )
                        }
                        if (serviceLocs.vehicleLocation) {
                          let vehicleLoc = serviceLocs.vehicleLocation
                          dispatch(
                            updateLocation({
                              name: 'vehicleLocation',
                              location: {
                                ...vehicleLoc,
                                serviceLocationType: MarkerType.VEHICLE,
                              },
                            }),
                          )
                        }
                        if (serviceLocs.exchangeLocation) {
                          let exchangeLoc = serviceLocs.exchangeLocation
                          dispatch(
                            updateLocation({
                              name: 'exchangeLocation',
                              location: {
                                ...exchangeLoc,
                                serviceLocationType: MarkerType.EXCHANGE,
                              },
                            }),
                          )
                        }
                        if (serviceLocs.vehicleDestinationLocation) {
                          let vehicleDestinationLoc = serviceLocs.vehicleDestinationLocation
                          dispatch(
                            updateLocation({
                              name: 'vehicleDestinationLocation',
                              location: {
                                ...vehicleDestinationLoc,
                                serviceLocationType: MarkerType.VEHICLEDESTINATION,
                              },
                            }),
                          )
                        }
                      }
                    }
                  }

                  // Events should be populated if you're hitting edit here
                  setRecentLocationData(events!)
                }

                onClick && onClick()
              }}
            >
              {edit ? t('Cancel') : t('Edit')}
            </StyledLinkButton>
          )}
    </Flex>
  )
}

export default DispatchHeader
