import * as React from 'react'
import { useFeatureGroup } from 'components/GoogleMaps'
import IncidentLocationsMarkers from '../Markers/GoogleMapMarkers/IncidentLocationsMarkers'
import RentalReturnMarkers from '../Markers/GoogleMapMarkers/RentalReturnMarkers'
import { useDispatch, useSelector } from 'react-redux'
import { selectMapViewport, selectSinglePinView, selectTicket } from 'redux/appStore'
import { setViewport } from 'redux/map/mapSlice'
import { useGoogleMaps } from 'hooks/map'
import { useNavigate } from 'react-router-dom'
import { useQueryParams } from 'components/LocationProvider'
import { stringify } from 'querystring'
import { useMount } from 'hooks/utils'

export const GoogleLocationsLayer: React.FC = () => {
  const { pickupInfo, returnInfo } = useSelector(selectTicket)
  const navigate = useNavigate()
  const {
    query: { lat, lng, z, ...rest },
    location,
  } = useQueryParams()
  const featureGroup = useFeatureGroup()
  const { map } = useGoogleMaps()
  const viewport = useSelector(selectMapViewport)
  const singlePinView = useSelector(selectSinglePinView)
  const dispatch = useDispatch()
  const markerCount = React.useRef(featureGroup?.getLength())

  useMount(() => {
    dispatch(setViewport({ shouldFitBounds: true }))
  })

  React.useEffect(() => {
    const fitBounds = (bounds: google.maps.LatLngBounds) => {
      if (map && bounds) {
        map.fitBounds(bounds, {
          top: 10,
          left: 10,
          bottom: 104,
          right: 104,
        })
        dispatch(setViewport({ shouldFitBounds: false }))
        google.maps.event.addListenerOnce(map, 'idle', function () {
          map.setCenter(bounds.getCenter())
          const shouldAdjustBounds = markerCount.current !== featureGroup?.getLength() ? true : viewport.shouldFitBounds
          markerCount.current = featureGroup?.getLength()
          if (!shouldAdjustBounds) {
            if (map.getZoom()! > 13) map.setZoom(13)
            else if (markerCount.current === 1) map.setZoom(18)
          }

          const queryParams = {
            ...rest,
            lat: bounds.getCenter().lat(),
            lng: bounds.getCenter().lng(),
            z: map.getZoom(),
          }
          navigate(`${location.pathname}?${stringify(queryParams)}`, { replace: true })
        })
      }
    }
    const shouldAdjustBounds = markerCount.current !== featureGroup?.getLength() ? true : viewport.shouldFitBounds
    markerCount.current = featureGroup?.getLength()
    if (pickupInfo?.branch && returnInfo?.branch && featureGroup && shouldAdjustBounds && !singlePinView) {
      // Establish empty bounds
      const bounds = new google.maps.LatLngBounds()

      // Loop through all of the markers of the given featureGroup
      // add in the bounds for each marker for repositioning
      featureGroup.forEach((marker) => {
        bounds.extend(marker.getPosition())
      })
      fitBounds(bounds)
    }
  }, [
    featureGroup,
    singlePinView,
    pickupInfo,
    returnInfo,
    viewport.shouldFitBounds,
    dispatch,
    location.pathname,
    map,
    navigate,
    rest,
  ])

  return (
    <>
      <RentalReturnMarkers />
      <IncidentLocationsMarkers />
    </>
  )
}

export default GoogleLocationsLayer
