import { useEffect } from 'react'

// React hook that resolves an async function or a function that returns a promise - Should handle unmounting.
export function useAsync(asyncFn: (...args: any[]) => Promise<any>, onSuccess: (data: any) => any) {
  useEffect(() => {
    let isActive = true
    if (asyncFn)
      asyncFn().then((data) => {
        if (isActive) onSuccess(data)
      })
    return () => {
      isActive = false
    }
  }, [asyncFn, onSuccess])
}
