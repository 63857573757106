import * as React from 'react'
import styled from 'styled-components/macro'
import { Flex } from 'components/primitives'
import BoxShadow from 'components/BoxShadow'
import UserMenuPopover from 'components/UserMenuPopover'
import { ReactComponent as HLogo } from './Hero_Logo.svg'
import { pxToRem } from 'theme/utils'
import { palette, theme } from 'styled-tools'
import background from 'theme/assets/GoogleMapImage.jpg'

const HeroLogo = styled(HLogo as any).attrs({
  role: 'img',
  'aria-label': 'Hero Logo',
})`
  grid-area: logo;
  object-fit: contain;
  height: auto;
  width: ${pxToRem(200)};
`

const Toolbar = styled.div`
  flex: 0 0 auto;
  display: grid;
  padding: ${theme('spaces.2')};
  background-color: ${palette('calypso')};
  color: ${palette('white')};
  grid-template-rows: 1fr;
  grid-template-columns: auto;
  grid-template-areas: 'logo user';
  align-items: center;
`

const MainContent = styled(Flex as any)`
  flex: 1 1 auto;
  overflow: hidden;
  background: url(${background}), linear-gradient(to bottom, #d8e1eb, #e5e5e5);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
`

export const MainLayout = ({ children }) => (
  <Flex style={{ flexDirection: 'column', height: '100vh', position: 'relative', overflow: 'hidden' }}>
    <Toolbar as={BoxShadow}>
      <HeroLogo />
      <div style={{ gridArea: 'user', justifySelf: 'end' }}>
        <UserMenuPopover />
      </div>
    </Toolbar>
    <MainContent>{children}</MainContent>
  </Flex>
)

export default MainLayout
