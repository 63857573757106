import { MdInfoOutline } from 'react-icons/md'
import styled from 'styled-components/macro'
import { palette, theme } from 'styled-tools'
import { ListItem } from 'components/List'
import { dustyGray } from 'theme/colors'
import { pxToRem } from 'theme/utils'

export const Container = styled(ListItem as any)`
  &:hover {
    background-color: ${palette('wildSand')};
    border-radius: ${pxToRem(20)};
    margin: 0 -${theme('spaces.1')};
    padding: ${theme('spaces.1')};
  }
`

export const InfoIcon = styled(MdInfoOutline as any)`
  display: none;
  margin-left: ${theme('spaces.1')};

  ${Container as any}:hover & {
    display: block;
  }
`

export const LocateText = styled(ListItem.Text as any)`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`

export const TitleText = styled(ListItem.Text as any)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 5;
`

export const infoIconContext = {
  color: dustyGray,
  size: '24',
}
