import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import { Heading, Flex, Paragraph } from 'components/primitives'
import Margin from 'components/Margin'
import RouteLink from 'components/RouteLink'
import ScrollBox from 'components/ScrollBox'
import { pxToRem } from 'theme/utils'
import { ListItem } from '../PlaceList/ListItem'
import { useSelector } from 'react-redux'
import { selectMapViewport, selectTicket } from 'redux/appStore'
import useSpatialSearch from 'hooks/solr/useSpatialSearch'

export const BranchInfo = () => {
  const viewport = useSelector(selectMapViewport)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { ticketNumber } = useSelector(selectTicket)

  const latitude = (typeof viewport.center.lat === 'number' ? viewport.center.lat : viewport.center.lat()) as unknown as number
  const longitude = (typeof viewport.center.lng === 'number' ? viewport.center.lng : viewport.center.lng()) as unknown as number

  const { data, loading, error } = useSpatialSearch(latitude, longitude)

  if (!data || !data.locationsResult || loading || error) return null

  return (
    <>
      <Margin use={RouteLink} to="../.." spacing="md">
        <MdClose />
        Branches
      </Margin>
      <ScrollBox style={{ paddingTop: 0 }}>
        {data.locationsResult.map((branchLink) => {
          const { groupBranchNumber: id, locationNameTranslation: title, addressLines, formattedCityStateZip } = branchLink

          const address = addressLines
            ? addressLines.join('<br/>').concat('<br/>').concat(formattedCityStateZip)
            : formattedCityStateZip

          return (
            <ListItem
              key={id!}
              onClick={() => {
                navigate(`/ticket/${ticketNumber}/place/BRANCHES/${id}`)
              }}
            >
              <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Heading as="h5" style={{ fontSize: pxToRem(18) }}>
                  {title}
                </Heading>
              </Flex>
              <Paragraph
                style={{ fontStyle: 'italic', marginTop: '4px', fontSize: pxToRem(16) }}
                dangerouslySetInnerHTML={{ __html: address || t('N/A') }}
              />
            </ListItem>
          )
        })}
      </ScrollBox>
    </>
  )
}

export default BranchInfo
