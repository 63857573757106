import * as React from 'react'
import styled from 'styled-components/macro'
import { Heading } from 'components/primitives'
import { theme, palette } from 'styled-tools'
import Card from 'components/Card'
import ScrollBox from 'components/ScrollBox'
import { pxToRem } from 'theme/utils'

const StyledCard = styled(Card as any)`
  display: flex;
  flex-direction: column;
  width: ${pxToRem(340)};
  max-height: 100%;
  margin-left: 24px;
`

const Header = styled.div`
  padding: 0 ${theme('spaces.2')} ${theme('spaces.1')};
  margin: 0 -${theme('spaces.2')};
  border-bottom: 1px solid ${palette('mercury')};
  width: 100%;
`

const EventPopupCard: React.FC<{ heading?: React.ReactNode }> = ({ heading, children }) => {
  return (
    <StyledCard>
      {heading && (
        <Header>
          {typeof heading === 'string' ? <Heading as="h5">{heading}</Heading> : heading}
        </Header>
      )}
      <ScrollBox>{children}</ScrollBox>
    </StyledCard>
  )
}

export default EventPopupCard
