import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

export type SidebarProps = {
  collapsedWidth?: number
  width?: number
  children: React.ReactNode
}

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${palette('wildSand')};
`

export default Sidebar
