import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdCheckCircle, MdHighlightOff } from 'react-icons/md'
import { TicketCharges, AAATicketChargePrices, ULYTicketChargePrices } from 'types/ticket'
import { useProtectionWaiver } from 'hooks/tickets'
import { cardinal, mountainMeadow } from 'theme/colors'
import { IconIndicator } from 'components/IconIndicator'
import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'

type Props = {
  charge?: TicketCharges
}

function ProtectionWaiver({ charge }: Props) {
  const { brandId } = useSelector(selectTicket)
  const { t } = useTranslation()
  const isProtected = useProtectionWaiver()

  const amount =
    charge &&
    parseFloat(brandId === 'ENTERPRISE' ? AAATicketChargePrices[charge] : ULYTicketChargePrices[charge]).toFixed(2)

  const label =
    amount && !isNaN(parseFloat(amount))
      ? t('waivers.roadsideProtectionWithAmount', { amount })
      : t('waivers.roadsideProtection')

  return (
    <>
      {isProtected ? (
        <IconIndicator icon={MdCheckCircle} iconColor={mountainMeadow} label={label} />
      ) : (
        <IconIndicator icon={MdHighlightOff} iconColor={cardinal} label={label} />
      )}
    </>
  )
}

export default ProtectionWaiver
