// http://chir.ag/projects/name-that-color
export type Colors =
  | 'black'
  | 'buttercup'
  | 'calypso'
  | 'camelot'
  | 'cardinal'
  | 'denim'
  | 'dustyGray'
  | 'elm'
  | 'gallery'
  | 'hotPink'
  | 'mercury'
  | 'mountainMeadow'
  | 'monza'
  | 'orangeRoughy'
  | 'shiraz'
  | 'silver'
  | 'vividViolet'
  | 'white'
  | 'wildSand'

export const black = '#000000'
export const calypso = '#386997'
export const camelot = '#8F2D56'
export const cardinal = '#B21B2D'
export const denim = '#125ED6'
export const dustyGray = '#797979'
export const elm = '#218380'
export const gallery = '#EFEFEF'
export const hotPink = '#FC669E'
export const mercury = '#e9e9e9'
export const monza = '#D0021B'
export const mountainMeadow = '#169A5A'
export const shiraz = '#B80E35'
export const silver = '#c7c7c7'
export const white = '#ffffff'
export const wildSand = '#f5f5f5'
export const orangeRoughy = '#D05B14'
export const buttercup = '#F5A623'
export const vividViolet = '#7D2E8B'

export default {
  black,
  buttercup,
  calypso,
  camelot,
  cardinal,
  denim,
  dustyGray,
  elm,
  gallery,
  hotPink,
  mercury,
  mountainMeadow,
  monza,
  orangeRoughy,
  shiraz,
  silver,
  vividViolet,
  white,
  wildSand,
} as { [colorName in Colors]: string }
