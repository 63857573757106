import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Label, Paragraph } from 'components/primitives'
import Margin, { MarginProps } from 'components/Margin'
import { UpdatedTimestamp } from './Dispatch'
import { Modified } from './CallStore'
import { LocationPayload } from 'types/location'

export type Props = {
  label?: string
  location?: (LocationPayload & Modified) | null
} & Pick<MarginProps, 'spacing'>

export const IncidentLocation: React.FC<Props> = ({ label, location, spacing = 'none', children }) => {
  return (
    <Margin spacing={spacing}>
      {label && <Label>{label}</Label>}
      {location?.modified && <UpdatedTimestamp timestamp={location.modified} />}
      <LocationAddress location={location} />
      {children}
    </Margin>
  )
}

export const LocationAddress: React.FC<Pick<Props, 'location'>> = ({ location }) => {
  return location && location.addressDescription ? (
    <Paragraph
      dangerouslySetInnerHTML={{
        __html: `${location.addressDescription}<br/>(${location.latitude}, ${location.longitude}) `,
      }}
    />
  ) : null
}

export const Caller: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  return <IncidentLocation label={t('labels.callerLocation')} {...props} />
}

export const Vehicle: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  return <IncidentLocation label={t('labels.vehicleLocation')} {...props} />
}

export const VehicleDestination: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  return <IncidentLocation label={t('labels.vehicleDestinationLocation')} {...props} />
}

export const CallerDestination: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  return <IncidentLocation label={t('labels.callerDestinationLocation')} {...props} />
}

export default Object.assign(IncidentLocation, {
  Caller,
  Vehicle,
  VehicleDestination,
  CallerDestination,
})
