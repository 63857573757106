import * as React from 'react'
import { SelectOption } from 'types/global'
import { InputProps } from 'components/Input'
import { Select } from 'components/Input/FinalFormInputs'
import { getCountries, getCountryCallingCode } from 'libphonenumber-js'

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })

export const countryOptions = getCountries().map((country) => {
  return {
    label: `${regionNames.of(country)} +${getCountryCallingCode(country)}`,
    value: country,
  }
})

export const findCountryOption = (value: string) => countryOptions.find((o) => o.value === value) || defaultCountryOption

export const defaultCountryOption = countryOptions.find((o) => o.value === 'US') as SelectOption<any>

const CountryCodeSelect: React.FC<InputProps> = (props) => (
  <Select {...props} options={countryOptions} defaultValue={defaultCountryOption as any} isSearchable={true} />
)

export default CountryCodeSelect
