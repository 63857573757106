import * as React from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import styled from 'styled-components/macro'
import { Input } from 'components/primitives'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { palette } from 'styled-tools'
import { pxToRem } from 'theme/utils'

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 16px;
`

export const Checked = styled(MdCheckBox as any)`
  margin-right: ${pxToRem(8)};
  color: ${palette('calypso')};
  min-width: ${pxToRem(24)};
`

export const Unchecked = styled(MdCheckBoxOutlineBlank as any)`
  margin-right: ${pxToRem(8)};
  color: ${palette('silver')};
  min-width: ${pxToRem(24)};
`

export function UncontrolledCheckbox({ label }: { label: string }) {
  const [checked, setChecked] = React.useState(false)
  return (
    <StyledLabel style={{ cursor: 'pointer' }} onClick={() => setChecked(!checked)}>
      {checked ? <Checked /> : <Unchecked />}
      <b>{label}</b>
    </StyledLabel>
  )
}

type InputProps = React.ComponentProps<typeof Input> &
  FieldRenderProps<HTMLInputElement> & { label?: string }

export const CheckboxComponent: React.FC<InputProps> = ({ input, meta, label, ...rest }) => (
  <StyledLabel>
    {input.checked ? <Checked /> : <Unchecked />}
    <Input {...input} {...rest} />
    {label}
  </StyledLabel>
)

type CheckboxProps = {
  name: string
  label?: string
  value?: string
  onClick?: () => void
}

export const Checkbox: React.FC<CheckboxProps> = (props) => (
  <Field type="checkbox" component={CheckboxComponent as any} {...props} />
)

type RegisteredCheckboxProps = {
  label?: string
} & FieldRenderProps<HTMLInputElement>

export const RegisteredCheckbox: React.FC<RegisteredCheckboxProps> = ({
  input,
  children,
  ...rest
}) => (
  <StyledLabel>
    {input.checked ? <Checked /> : <Unchecked />}
    <Input type="checkbox" {...(input as any)} {...rest} />
    {children}
  </StyledLabel>
)

export default Checkbox
