import axios from 'axios'
import { config } from '../../config'

export const getLocator = async (query: string | null | undefined) => {
  const { solrLocator } = config
  const url = `${solrLocator}/${query}`

  return await axios.get(url)
}

export default getLocator
