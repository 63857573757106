import React from 'react'
import { Paragraph } from 'components/primitives'
import { IconType } from 'react-icons'
import { MdCheckCircle, MdHighlightOff } from 'react-icons/md'
import { mountainMeadow, cardinal } from 'theme/colors'

type AvailabilityIconsProps = {
  availability: boolean
  label: string
  notAvailableLabel?: string
}

export function AvailabilityIcons({
  availability,
  label,
  notAvailableLabel,
}: AvailabilityIconsProps) {
  const props = availability
    ? {
        icon: MdCheckCircle,
        iconColor: mountainMeadow,
        label,
      }
    : {
        icon: MdHighlightOff,
        iconColor: cardinal,
        label: notAvailableLabel || label,
      }

  return <IconIndicator {...props} />
}

const commonMargin = { marginLeft: 8 }

type IconIndicatorProps = {
  icon: IconType
  iconColor: string
  label: string
}
export function IconIndicator({ icon: Icon, iconColor, label }: IconIndicatorProps) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon color={iconColor} />
      <Paragraph style={commonMargin}>{label}</Paragraph>
    </div>
  )
}

export default IconIndicator
