import * as React from 'react'
import styled from 'styled-components/macro'
import use from 'reuse'
import { switchProp, theme } from 'styled-tools'
import { Spaces } from 'types/global'

export type MarginProps = {
  spacing?: Spaces
  gutter?: Spaces
  style?: React.CSSProperties
  children?: React.ReactNode
}

const MarginComponent = ({ use: T, ...props }: { use: React.ComponentType }) =>
  React.createElement(T, props)

const applySpaces = (prop: string) =>
  switchProp(prop, {
    none: 0,
    sm: theme('spaces.1'),
    md: theme('spaces.2'),
    lg: theme('spaces.3'),
  })

const Margin = styled(MarginComponent as any)<MarginProps>`
  margin-bottom: ${applySpaces('spacing')};
  margin-right: ${applySpaces('gutter')};
  ${theme('Margin')}
`

export default use(Margin, 'div')
