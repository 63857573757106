import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Participant as TParticipant } from 'types/global'
import Margin from 'components/Margin'
import Button from 'components/Button'
import { setSmartAssignmentParticipant } from 'redux/smartAssignment/smartAssignmentSlice'
import Participant from './Participant'
import { Props } from './Results'
import { useDispatch } from 'react-redux'
import { updateLocation } from 'redux/location/locationSlice'
import { MarkerType } from 'types/googleMaps'

type ParticipantItemProps = {
  participant: TParticipant
  outOfNetwork?: boolean
} & Props

const ParticipantItem: React.FC<ParticipantItemProps> = ({ network, onSetAssignment, participant, outOfNetwork }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <>
      <Participant participant={participant} network={network} outOfNetwork={outOfNetwork} showHours={true} />
      <Margin spacing="md">
        <Button.Primary
          style={{ width: '100%' }}
          onClick={() => {
            dispatch(setSmartAssignmentParticipant(participant))
            onSetAssignment && onSetAssignment(participant)
            const [streetNumber, street] = participant.entity.address!.addressLine1!.split(' ')

            const addressDescription = [
              `${participant.entity.name}<br/>`,
              participant.entity.address!.addressLine1!,
              `${participant.entity.address!.city} ${participant.entity.address!.administrativeArea}`,
              participant.entity.address!.postalCode,
              participant.entity.address!.country,
            ]
            dispatch(
              updateLocation({
                name: 'vehicleDestinationLocation',
                location: {
                  latitude: participant.location!.latitude!,
                  longitude: participant.location!.longitude!,
                  address: {
                    city: participant.entity.address!.city!,
                    country: participant.entity.address!.country!,
                    countryShortCode: participant.entity.address!.country!,
                    postalCode: participant.entity.address!.postalCode!,
                    state: participant.entity.address!.administrativeArea!,
                    street,
                    streetNumber,
                  },
                  ...participant,
                  addressDescription: addressDescription.join(', '),
                  serviceLocationType: MarkerType.VEHICLEDESTINATION,
                },
              }),
            )
          }}
        >
          {t('network.setDestination')}
        </Button.Primary>
      </Margin>
    </>
  )
}

export default ParticipantItem
