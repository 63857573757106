import React from 'react'
import { useSelector } from 'react-redux'
import { selectTicketHistoryEvents } from 'redux/appStore'
import { DynamoEvents } from 'types/events'

const sortEventsByDates = (result1: DynamoEvents, result2: DynamoEvents) => {
  return (
    new Date(result2.audit?.[0]?.createTimestamp || '').getTime() - new Date(result1.audit?.[0]?.createTimestamp || '').getTime()
  )
}

export function useCallEventsPagination() {
  const [events, setEvents] = React.useState<DynamoEvents[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const historyEvents = useSelector(selectTicketHistoryEvents)

  React.useEffect(() => {
    const fetchEvents = () => {
      try {
        const sortedPaginatedEvents = historyEvents!.slice().sort(sortEventsByDates)

        setEvents(sortedPaginatedEvents)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }
    if (historyEvents) {
      fetchEvents()
    }
  }, [historyEvents])

  return { data: { events }, loading }
}
