import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { selectCurrentUser, selectTicket } from 'redux/appStore'

export function useUrgentlyProvider() {
  const { brandId } = useSelector(selectTicket)
  const currentUser = useSelector(selectCurrentUser)
  const isUrgentlyUser = currentUser?.isUrgentlyUser || false
  const isEnterpriseTicket = brandId === 'ENTERPRISE'

  // Check for customer's transportation method
  const form = useForm()
  const formState = form.getState().values
  const isThirdParty = formState.rideAlong === false
  const shouldUseUrgently = !isEnterpriseTicket || isThirdParty

  return { isEnterpriseTicket, shouldUseUrgently, isThirdParty, isUrgentlyUser }
}

export default useUrgentlyProvider
