import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'

export function useDamageWaiver() {
  const { protection } = useSelector(selectTicket)
  const isDamageWaived =
    protection && protection.some(({ type }) => damageWaiverList.includes(type))

  return isDamageWaived
}

export default useDamageWaiver

export const damageWaiverList = [
  'CDW',
  'CDW1',
  'CDW2',
  'CDW3',
  'CDW4',
  'CDW5',
  'CDW6',
  'CDW7',
  'CDW9',
  'CDW10',
  'CDW11',
  'CDW12',
  'CDW13',
  'CDW14',
  'CDW15',
  'CDW16',
  'CDW17',
  'CDW18',
  'CDW19',
  'CDW99',
  'CDW500',
  'CDW800',
  'CDWAADP',
  'CDWFED',
  'CDWKIOSK',
  'CDWVAL',
  'CONCDW1',
  'CONCDW4',
  'CONLDW1',
  'CONLDW4',
  'DW',
  'LDW',
  'LDW1',
  'LDW2',
  'LDW3',
  'LDW4',
  'LDW5',
  'LDW6',
  'LDW7',
  'LDW8',
  'LDW9',
  'LDW10',
  'LDW11',
  'LDW12',
  'LDW13',
  'LDW14',
  'LDW15',
  'LDW16',
  'LDW17',
  'LDW18',
  'LDW19',
  'LDW20',
  'LDW21',
  'LDW99',
  'LDW150',
  'LDW500',
  'LDW800',
  'LDWC0R4',
  'LDWCO',
  'LDWCOR',
  'LDWCOR1',
  'LDWCOR2',
  'LDWCOR3',
  'LDWCOR4',
  'LDWCOR5',
  'LDWCOR6',
  'LDWCOR7',
  'LDWCOR8',
  'LDWCOR9',
  'LDWCOR10',
  'LDWCOR11',
  'LDWCOR12',
  'LDWCOR13',
  'LDWCOR14',
  'LDWCOR15',
  'LDWCOR16',
  'LDWCOR17',
  'LDWCOR18',
  'LDWCOR19',
  'LDWCOR20',
  'LDWCOR21',
  'LDWCORPW',
  'LDWCORPX',
  'LDWCORPY',
  'LDWCORPZ',
  'LDWEFP',
  'LDWFED',
  'LDWHT',
  'LDWM03',
  'LDWMO',
  'LDWMO2',
  'LDWMO4',
  'LDWNADP',
  'LDWNADP1',
  'LDWNADP2',
  'LDWNFDP',
  'LDWTOUR1',
  'LDWTOUR2',
  'LDWVAL',
  'LDWWE',
  'SDW500',
  'SDW501',
  'SDW800',
  'SDW801',
]
