import { useNavigate } from 'react-router'
import * as React from 'react'
import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

type Props = {
  link: string
  onClick?: () => void
  onMouseEnter?: () => void
}
const TicketLink: React.FC<Props> = ({ link, onClick, onMouseEnter, children }) => {
  const navigate = useNavigate()
  return (
    <RouteLink
      onMouseEnter={onMouseEnter}
      onClick={() => {
        navigate(link)
        if (onClick) onClick()
      }}
    >
      {children}
    </RouteLink>
  )
}

const RouteLink = styled.div`
  display: flex;
  font-size: 16px;
  align-items: center;
  align-self: flex-start;
  flex-shrink: 0;
  text-decoration: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: ${theme('spaces.3')};
  }
`

export default TicketLink
