import * as React from 'react'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import Margin from 'components/Margin'
import { Label, Paragraph } from 'components/primitives'
import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import { BranchInfo } from './utils'

export const ExchangeBranch = ({ branchInfo }: { branchInfo: BranchInfo }) => {
  const { t } = useTranslation()
  const form = useForm()
  const ticket = useSelector(selectTicket)

  React.useEffect(() => {
    form.change('exchangeBranch', branchInfo)
    form.change('ticketNumber', ticket.ticketNumber)
  }, [branchInfo, form, ticket.ticketNumber])

  return (
    <Margin>
      <Label>{t('Exchange Branch Location')}</Label>
      {branchInfo ? (
        <>
          <Paragraph spacing="sm">
            {branchInfo.brand} - {branchInfo.gpbr}
          </Paragraph>
          <Paragraph spacing="sm">{branchInfo.phone}</Paragraph>
          <Paragraph>{branchInfo.address}</Paragraph>
          <Paragraph spacing="sm">{branchInfo.formattedCityStateZip}</Paragraph>
        </>
      ) : (
        <Paragraph spacing="sm">{t('No Exchange Branch Location')}</Paragraph>
      )}
    </Margin>
  )
}

export default ExchangeBranch
