import * as React from 'react'
import { pxToRem } from 'theme/utils'
import { elm } from 'theme/colors'
import IconBase from './IconBase'

const RentalIconMarker = () => (
  <IconBase
    width={pxToRem(24)}
    height={pxToRem(24)}
    fill={elm}
    style={{ marginRight: 8 }}
    path="M36 64 60 64 60 40 76 40 48 12 20 40 36 40 36 64ZM20 72 76 72 76 80 20 80 20 72Z"
  />
)

export default RentalIconMarker
