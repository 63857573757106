import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { LinkButton } from './Button'

export const StyledLinkButton = styled(LinkButton as any)`
  padding: 0;
  color: ${palette('calypso')};
`

export default StyledLinkButton
