import * as React from 'react'

export enum TransferStatuses {
  DEFAULT,
  SUCCESS,
}

type TransferStatusContextType = [TransferStatuses, React.Dispatch<React.SetStateAction<TransferStatuses>>]

export const TransferStatusContext = React.createContext<TransferStatusContextType | null>(null)

export const TransferStatus = ({ children }) => {
  const transferStatus = React.useState<TransferStatuses>(TransferStatuses.DEFAULT)

  return <TransferStatusContext.Provider value={transferStatus}>{children}</TransferStatusContext.Provider>
}

export function useTransferStatus(): TransferStatusContextType {
  const transferStatus = React.useContext(TransferStatusContext)
  if (!transferStatus) throw new Error('No TransferStatusContext found.')
  return transferStatus
}

export default TransferStatus
