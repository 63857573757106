import { useCallback, useEffect, useState } from 'react'
import { stringify } from 'querystring'
import { useServiceProxy } from 'hooks/kong'
import { isNil } from 'lodash'
import { GMapsTimezoneResponse } from 'types/googleMaps'
import { IncomingGMapsTimezoneResponse } from 'types/api'
import { deepMapSanitize } from 'utils/deepMap'

type Params = {
  lat?: number | string | null
  lon?: number | string | null
}

type ReturnType = {
  timezone: string | null
  timezoneError: boolean
}

export const useLocalTimeZone = ({ lat, lon }: Params): ReturnType => {
  const [timezone, setTimezone] = useState(null)
  const [timezoneError, setTimezoneError] = useState(false)
  const [previouslyCalledCoords, setPreviouslyCalledCoords] = useState<Params | null>(null)
  /**
   * The timestamp parameter of Google Time Zone API expects the desired date and time to be expressed in seconds since midnight, January 1, 1970 UTC.
   * For the current time, this is calculated by first instantiating the date object to get the current local time of the user's computer,
   * then adding to it its UTC offset (in minutes) using Date.getTimezoneOffset():
   * Example:
   * function getTimeStamp() {
   *    const currentDate = new Date()
   *    const currentTime = currentDate.getTime() / 1000
   *    const timezoneOffset = currentDate.getTimezoneOffset() * 60
   *    return currentTime + timezoneOffset // Current UTC date/time expressed as seconds since midnight, January 1, 1970 UTC
   * }
   * Note: If you need a specific date - pass in date into the new Date() instanstiation
   */
  const [currentTimestamp] = useState(() => {
    const currentDate = new Date()
    const currentTime = currentDate.getTime() / 1000
    const timezoneOffset = currentDate.getTimezoneOffset() * 60
    return currentTime + timezoneOffset
  })
  const serviceProxy = useServiceProxy()

  const getLocalTimezone = useCallback(
    async (retries: number) => {
      setPreviouslyCalledCoords({ lat, lon })
      const googleParams = {
        location: `${lat},${lon}`,
        timestamp: currentTimestamp,
      }
      try {
        const { data } = await serviceProxy<IncomingGMapsTimezoneResponse>(
          'get',
          `/serviceproxy/googleMaps/timezone/json?${stringify(googleParams)}`,
        ).then((response) => ({ ...response, data: deepMapSanitize(response.data) as GMapsTimezoneResponse }))
        if (data && data.status === 'OK') setTimezone(data.timeZoneId as any)
      } catch (e) {
        if (!retries) {
          setTimezoneError(true)
          return
        }
        setTimeout(() => getLocalTimezone(--retries), 1000)
      }
    },
    [lat, lon, currentTimestamp, serviceProxy],
  )

  useEffect(() => {
    if (!isNil(lat) && !isNil(lon) && lat !== previouslyCalledCoords?.lat && lon !== previouslyCalledCoords?.lon) {
      getLocalTimezone(2)
    }
  }, [lat, lon, getLocalTimezone, timezone, previouslyCalledCoords?.lat, previouslyCalledCoords?.lon])

  return { timezone, timezoneError }
}

export default useLocalTimeZone
