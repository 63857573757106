import React from 'react'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import * as Ariakit from '@ariakit/react'
import { Dialog } from 'components/Dialog'
import { CancelUlyDispatchState } from './CancelUlyDispatchState'

export function CancelUlyDispatch({ caseId, serviceId }: { caseId: string; serviceId: string }) {
  const { t } = useTranslation()
  const cancelDialog = Ariakit.useDialogStore()

  return (
    <Ariakit.DialogProvider store={cancelDialog}>
      <Ariakit.DialogDisclosure store={cancelDialog} style={{ backgroundColor: 'unset', border: 0, padding: 0 }}>
        <Button.Primary style={{ width: '100%' }}>{t('cancelDispatch.cancelService')}</Button.Primary>
      </Ariakit.DialogDisclosure>
      <Dialog {...cancelDialog} aria-label="Cancel dispatch">
        <CancelUlyDispatchState caseId={caseId} serviceId={serviceId} />
      </Dialog>
    </Ariakit.DialogProvider>
  )
}

export default CancelUlyDispatch
