import * as React from 'react'
import { MarkerType } from 'types/googleMaps'
import ErrorBoundary from 'components/ErrorBoundary'
import { GoogleFeatureGroup } from 'components/GoogleMaps'
import HotelLinks from './HotelLinks'

type Props = {
  markerType: MarkerType
}

export const HotelsLayer: React.FC<Props> = ({ markerType }) => {
  return (
    <ErrorBoundary>
      <GoogleFeatureGroup>
        <HotelLinks markerType={markerType} />
      </GoogleFeatureGroup>
    </ErrorBoundary>
  )
}

export default HotelsLayer
