import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import { MarkerType } from 'types/googleMaps'
import { Tooltip } from 'components/Tooltip'
import useLocalTimeZone from 'hooks/utils/useLocalTimeZone'
import { useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import { SelectableHeroMarker } from './SelectableHeroMarker'
import BranchTooltip from '../Tooltips/Branch'
import { BranchInfoFragment } from 'types/branch'

type Props = {
  markerType: MarkerType
  pickupTime?: String
  returnTime?: String
} & BranchInfoFragment

export const GoogleBranchMarker: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const {
    addressLines,
    formattedCityStateZip,
    groupBranchNumber,
    markerType,
    defaultLocationName,
    latitude,
    longitude,
    formattedPhone,
  } = props
  const { ticketNumber } = useSelector(selectTicket)
  const { timezone: localTimeZone } = useLocalTimeZone({ lat: latitude, lon: longitude })

  return (
    <SelectableHeroMarker
      id={groupBranchNumber}
      type={markerType}
      latitude={Number(latitude)}
      longitude={Number(longitude)}
      onClick={(selected) => {
        if (selected) {
          navigate(`/ticket/${ticketNumber}`)
        } else {
          navigate(`/ticket/${ticketNumber}/branch/${groupBranchNumber}`)
        }
      }}
    >
      <Tooltip>
        <BranchTooltip
          {...props}
          title={defaultLocationName || ''}
          groupBranchNumber={groupBranchNumber}
          address={addressLines ? addressLines[0] : ''}
          formattedCityStateZip={formattedCityStateZip}
          localTimeZone={localTimeZone}
          formattedPhone={formattedPhone}
        />
      </Tooltip>
    </SelectableHeroMarker>
  )
}

export default GoogleBranchMarker
