import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash-es'
import { PartialDispatch } from 'types/global'
import { Heading } from 'components/primitives'
import Margin from 'components/Margin'
import { SoftService } from '../CallStore'
import DispatchStatus from './DispatchStatus'
import DispatchCenter from './DispatchCenter'

type Props = {
  dispatch?: SoftService['dispatch'] | PartialDispatch
  disableCancel?: boolean
}

function DispatchService({ dispatch, disableCancel }: Props) {
  const { t } = useTranslation()
  if (!dispatch) return null
  const dispatches = isArray(dispatch) ? dispatch : [dispatch]

  return (
    <>
      {dispatches.map(({ service, callKey }, i) => {
        const { dispatchCenter, status } = service
        const rap = callKey!.split('-').pop()

        const isLast = i === dispatches.length - 1
        const isRentalPickup = dispatches.length > 1 && i === 0
        const isDisabledVehicle = dispatches.length > 1 && isLast

        return (
          <Margin key={i} spacing={isLast ? 'none' : 'md'}>
            {isRentalPickup && (
              <Heading as="h5" spacing="sm">
                {t('Rental Pickup')}
              </Heading>
            )}
            {isDisabledVehicle && (
              <Heading as="h5" spacing="sm">
                {t('Disabled Vehicle')}
              </Heading>
            )}
            <DispatchCenter center={dispatchCenter} rap={rap} />
            <DispatchStatus callKey={callKey} fallbackStatus={status} disableCancel={disableCancel} service={service} />
          </Margin>
        )
      })}
    </>
  )
}

export default DispatchService
