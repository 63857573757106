import * as React from 'react'
import { MdLocalPhone, MdLocationOn } from 'react-icons/md'
import { Flex, Paragraph } from 'components/primitives'
import styled from 'styled-components/macro'
import Button from 'components/Button'
import Section from 'components/Section'
import { calypso, gallery } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import { GMapsHotelDetails } from 'types/googleMaps'

const StyledFlex = styled(Flex as any)`
  justify-content: flex-start;
  align-items: center;
`

export const HotelLocation: React.FC<GMapsHotelDetails> = ({ name, vicinity, international_phone_number }) => {
  return (
    <Section title={name}>
      <Section.Body style={{ borderBottom: `1px solid ${gallery}`, padding: `0 0 ${pxToRem(16)}` }}>
        <StyledFlex>
          <Button iconBefore={MdLocalPhone} iconColor={calypso} />
          <Paragraph>{international_phone_number}</Paragraph>
        </StyledFlex>
        <StyledFlex>
          <Button iconBefore={MdLocationOn} iconColor={calypso} />
          <Flex style={{ flexDirection: 'column', flex: 1 }}>{vicinity}</Flex>
        </StyledFlex>
      </Section.Body>
    </Section>
  )
}

export default HotelLocation
