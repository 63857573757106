import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdCheck } from 'react-icons/md'
import MdSpinner from 'react-md-spinner'
import { Participant } from 'types/global'
import { Heading } from 'components/primitives'
import Button from 'components/Button'
import { CallTypeToEventType } from 'utils/callstore'
import { Dialog } from 'components/Dialog'
import * as Ariakit from '@ariakit/react'
import Column from 'components/Column'
import CallForm from 'forms/CallForm'
import { useCreateEhiTicketNote, useUpdateCreateQSP } from 'hooks/tickets'
import { useDispatch, useSelector } from 'react-redux'
import { selectAllEvents, selectCurrentUser, selectFormsState, selectTicket } from 'redux/appStore'
import { calypso } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import { ErrorMessage } from 'components/fields/Error'
import { isParticipant } from 'utils/typeChecks'
import { isEmpty, isNil } from 'lodash-es'
import { CallFormValues } from 'types/form'
import { EventTypes } from 'types/events'
import { useCreateServiceEvent, useUpdateEvent } from 'hooks/events'
import { LocationPayload } from 'types/location'
import { FulfillmentSystem } from 'types/ticket'
import { resetQuickNotesState } from 'redux/quickNotes/quickNotesSlice'

const eventTypesToInclude = [EventTypes.FEATURE, EventTypes.DROP, EventTypes.TRANSFER, EventTypes.CALLBACK]

const CallLogger: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loggingCall, setLoggingCall] = React.useState(false)
  const ticket = useSelector(selectTicket)
  const { fulfillmentSystem } = ticket
  const currentUser = useSelector(selectCurrentUser)
  const submitDialog = Ariakit.useDialogStore()
  const createEhiTicketNote = useCreateEhiTicketNote()
  const forms = useSelector(selectFormsState) as any
  const allEvents = useSelector(selectAllEvents)
  const eventType = CallTypeToEventType[forms.callType]
  const createCallEvent = useCreateServiceEvent({ eventType } as any)
  const updateEventItem = useUpdateEvent()
  const updateCreateQSP = useUpdateCreateQSP()
  const { exchange, tow, towExchange } = useSelector(selectFormsState) as any
  const [error, setError] = React.useState<string | null>(null)
  const dispatch = useDispatch()

  const getLocationName = (location: Participant & LocationPayload) => {
    if (isParticipant(location)) {
      const { name } = location.entity!
      return name
    }

    return ''
  }

  /**
   *
   * @param notes Branch notes entered from Agent from current workflow
   * Check if a tow has been dispatched or if an exchange is requested
   *  - Return newly generated notes with verbiage
   */
  function addOneLinerToBranchNotes({ callType, notes }: CallFormValues) {
    // Is Tow with Exchange
    if (!isEmpty(towExchange) && towExchange) {
      const { towLocation, branch } = towExchange
      return `| ${t('labels.towDispatched')} ${towLocation ? ' - ' + towLocation.name + ' ' : ''}| ${t('labels.exchangeRequested')} ${
        branch?.defaultLocationName ? ' - ' + branch.defaultLocationName + ' ' : ''
      }| ${notes}`
    } else if (!isEmpty(tow)) {
      // Is Tow with NO Exchange
      const towDestination = tow?.serviceLocations.vehicleDestinationLocation

      return `| ${t('labels.towDispatched')} ${towDestination ? ' - ' + getLocationName(towDestination) + ' ' : ''}| ${notes}`
    } else if (!isEmpty(exchange)) {
      // Is Exchange
      return `| ${t('labels.exchangeRequested')} ${
        exchange?.branch?.groupBranchNumber ? ' - ' + exchange.branch.groupBranchNumber + ' ' : ''
      }| ${notes}`
    }

    return notes
  }

  /**
   *
   * @param notes Branch notes entered from Agent from current workflow
   * Grab Agent EID from AuthQuery
   *  - If no data returns (catchall) - return notes regularly
   *  - Prepend Agent EID to ticket notes
   *  - Return newly generated notes with EID
   */
  function addNotesEID(notes: string) {
    if (!currentUser) return notes
    return `${currentUser.nameID.toUpperCase()}: ${notes}`
  }

  const handleSubmit = async (values: object) => {
    // Type assign and pre-pend EID to notes
    const callFormValues = values as CallFormValues
    const notesWithOneLiner = addOneLinerToBranchNotes(callFormValues)
    const notesWithEID = addNotesEID(notesWithOneLiner)

    // Render Modal and Spinner
    setLoggingCall(true)
    submitDialog.show()

    // DynamoDB Calls
    // Only submit a create event if the call types are one of the following:
    //
    try {
      if (eventTypesToInclude.includes(eventType)) {
        const createEventValues = {
          ...callFormValues,
          branchNotes: callFormValues.notes,
        }
        await createCallEvent(createEventValues)
      } else {
        Object.entries(allEvents).forEach(([key, value]) => {
          if (!isNil(value)) {
            // update the existing events from this call with branch notes when ending the call
            updateEventItem({
              ...value.event!,
              attributes: { ...value.event!.attributes, branchNotes: callFormValues.notes },
            })
          }
        })
      }
    } catch (e) {
      console.error(e)
    }

    // EHI Calls
    // Save the note to EHI's ticket notes
    // 1. Create ticket notes via ECARS or ODY depending on type
    try {
      if (fulfillmentSystem === FulfillmentSystem.ECARS) {
        await createEhiTicketNote(notesWithEID)
      }

      if (fulfillmentSystem === FulfillmentSystem.ODYSSEY) {
        await updateCreateQSP(notesWithEID, callFormValues.qspIsOpen)
      }
    } catch (error) {
      console.error(error)
      setError(t('error.ehiNote') as string)
    }

    setLoggingCall(false)
    dispatch(resetQuickNotesState())
  }

  return (
    <>
      <Ariakit.DialogProvider store={submitDialog}>
        <Dialog {...submitDialog} aria-label="Submitting Call">
          {loggingCall ? (
            <Column>
              <Heading as="h4">{t('Submitting Call')}</Heading>
              <MdSpinner singleColor={calypso} size={64} style={{ margin: `${pxToRem(32)} 0` }} />
            </Column>
          ) : (
            <Column>
              <Heading as="h4">{t('Successful Submission')}</Heading>
              <MdCheck color={calypso} size={pxToRem(64)} style={{ margin: `${pxToRem(32)} 0` }} />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <Button.Primary onClick={() => navigate('/ticket')}>{t('Back to Search')}</Button.Primary>
            </Column>
          )}
        </Dialog>
      </Ariakit.DialogProvider>

      <CallForm onSubmit={handleSubmit} />
    </>
  )
}

export default CallLogger
