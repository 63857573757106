import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdPerson, MdPhone } from 'react-icons/md'
import { Label, Paragraph } from 'components/primitives'
import List, { ListItem } from 'components/List'
import Margin from 'components/Margin'
import { DynamoContacts } from 'types/contacts'
import { DynamoEvents } from 'types/events'

const CallerDetails: React.FC<{ event: DynamoEvents; caller: DynamoContacts | null }> = ({ event, caller }) => {
  const { audit, attributes } = event
  const branchNotes = attributes?.branchNotes || attributes?.attributes?.branchNotes || ''
  const { t } = useTranslation()

  const relationship = caller?.type
  const options = [
    {
      icon: MdPerson,
      text: [
        caller?.firstName,
        caller?.lastName,
        !caller && t('REDACTED'),
        relationship && `| ${t(`callerRelationship.${relationship}`)}`,
      ]
        .filter(Boolean)
        .join(' '),
    },
    {
      icon: MdPhone,
      text: `${caller?.phone?.countryCode?.label} ${caller?.phone?.number}` || t('REDACTED'),
    },
  ]
  let smsOptIn = event.attributes.customerOptIn ? 'Yes' : 'No'

  return (
    <>
      <Paragraph>{t('Caller Details')}</Paragraph>
      <Margin spacing="md">
        <List>
          {options.map(({ icon, text }, i) => (
            <ListItem key={i}>
              <ListItem.Icon icon={icon} />
              <ListItem.Text>{text}</ListItem.Text>
            </ListItem>
          ))}
          <ListItem>
            <ListItem.Text>{`SMS Opt In | ${smsOptIn}`}</ListItem.Text>
          </ListItem>
        </List>
      </Margin>
      <Label>{t('labels.agentEID')}</Label>
      <Paragraph spacing="md">{audit[0]?.eId}</Paragraph>
      <Label>{t('labels.branchNotes')}</Label>
      <Paragraph spacing="md">{branchNotes}</Paragraph>
    </>
  )
}

export default CallerDetails
