import React from 'react'
import * as Ariakit from '@ariakit/react'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'components/Dialog'
import { CancelDispatchState } from './CancelDispatchState'

export function CancelDispatch({ callKey }: { callKey: string }) {
  const { t } = useTranslation()
  const dialog = Ariakit.useDialogContext()

  return (
    <>
      <Button.Primary onClick={dialog?.show} style={{ width: '100%' }}>
        {t('cancelDispatch.cancelService')}
      </Button.Primary>
      <Dialog {...dialog!} aria-label="Cancel dispatch">
        <CancelDispatchState callKey={callKey} />
      </Dialog>
    </>
  )
}

export default CancelDispatch
