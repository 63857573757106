import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DynamoEvents } from 'types/events'
import { AddEventAction, CallHistoryState, SetCallHistoryAction } from 'types/callhistory'

const initialState: CallHistoryState = {
  events: null,
}

const callHistorySlice = createSlice({
  name: 'CallHistory',
  initialState,
  reducers: {
    updateTicketHistoryEvents: (state: CallHistoryState, action: PayloadAction<SetCallHistoryAction>) => {
      const sortEventsByDates = (result1: DynamoEvents, result2: DynamoEvents) => {
        return (
          new Date(result2.audit?.[0]?.createTimestamp || '').getTime() -
          new Date(result1.audit?.[0]?.createTimestamp || '').getTime()
        )
      }
      let sortedEvents = action.payload.historyEvents?.slice().sort(sortEventsByDates)

      state.events = sortedEvents
    },
    addTicketHistoryEvent: (state: CallHistoryState, action: PayloadAction<AddEventAction>) => {
      if (state.events) state.events = [action.payload.event, ...state.events]
      return state
    },
    updateTicketHistoryEvent: (state: CallHistoryState, action: PayloadAction<AddEventAction>) => {
      if (state.events) {
        const eventIndex = state.events.findIndex((event) => event.eventIdentifier === action.payload.event.eventIdentifier)
        state.events[eventIndex] = action.payload.event
      }
      return state
    },
    resetCallHistoryEvents: (state: CallHistoryState) => {
      state.events = null
      return state
    },
  },
})

export const { addTicketHistoryEvent, updateTicketHistoryEvent, updateTicketHistoryEvents, resetCallHistoryEvents } =
  callHistorySlice.actions

export default callHistorySlice.reducer
