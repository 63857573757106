import React from 'react'
import * as Ariakit from '@ariakit/react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-final-form'
import SmartAssignment from 'components/SmartAssignment'
import { ServiceLocationType } from 'types/location'
import { selectFormsState } from 'redux/appStore'

export const VehicleDropoffDestination = () => {
  const form = useForm()
  const controlsContext = Ariakit.useCompositeContext()
  const forms = useSelector(selectFormsState)

  return (
    <>
      <SmartAssignment
        exitOnClick={() => forms.Tow.change('towType', null)}
        onSetAssignment={(participant) => {
          form.change('serviceLocations.vehicleDestinationLocation', {
            ...participant,
            serviceLocationType: ServiceLocationType.VEHICLEDESTINATION,
          })
          controlsContext?.move(controlsContext?.next())
        }}
      />
    </>
  )
}

export default VehicleDropoffDestination
