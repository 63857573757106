import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

export const SectionToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme('spaces.1')} ${theme('spaces.2')};
`

export default SectionToolbar
