import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import styled from 'styled-components/macro'
import { Flex, Paragraph } from 'components/primitives'
import { palette } from 'styled-tools'
import { pxToRem } from 'theme/utils'
import Margin from 'components/Margin'
import { motion } from 'framer-motion'

export const ProgressContainer = styled.div`
  width: 100%;
  height: ${pxToRem(16)};
  border: 1px solid ${palette('silver')};
  border-radius: ${pxToRem(24)};
`

export const Progress = styled(motion.div as any)`
  height: 100%;
  border-radius: ${pxToRem(24)};
  background-color: ${palette('calypso')};
`

function ProgressBar() {
  const controlsContext = Ariakit.useCompositeContext()
  const currentStepid = controlsContext?.useState('activeId')
  const controlsItems = controlsContext?.useState('items') || []
  const current = controlsItems.findIndex(({ id }) => id === currentStepid) + 1
  const total = controlsItems.length
  const percent = (current / total) * 100

  return (
    <>
      <Margin spacing="md">
        <Flex style={{ justifyContent: 'center' }}>
          <Paragraph>{`${current} of ${total}`}</Paragraph>
        </Flex>
        <ProgressContainer>
          <Progress animate={{ width: `${percent}%` }} initial={{ width: 0 }} />
        </ProgressContainer>
      </Margin>
    </>
  )
}

export default ProgressBar
