import { isNil, isObject } from 'lodash-es'

function mapObject(obj: any, fn: (...args: any) => void) {
  return Object.keys(obj).reduce((res, key) => {
    res[key] = fn(obj[key])
    return res
  }, {})
}

export function deepMap(obj: any, fn: (...args: any) => void) {
  const deepMapper = (val: any) => (isObject(val) ? deepMap(val, fn) : fn(val))
  if (Array.isArray(obj)) {
    return obj.map(deepMapper)
  }
  if (isObject(obj)) {
    return mapObject(obj, deepMapper)
  }
  return obj
}

export function deepMapSanitize(obj: any) {
  return deepMap(obj, (v) => (isNil(v) ? '' : v))
}
