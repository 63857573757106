import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash-es'
import { Label, Paragraph } from 'components/primitives'
import SmartAssignment from 'components/SmartAssignment'
import DispatchService from 'components/Dispatch/DispatchService'
import IncidentLocation from 'components/IncidentLocation'
import DispatchHeader from 'components/Dispatch/DispatchHeader'
import useAAADispatch from 'hooks/aaa/useAAADispatch'
import EventVehicle from 'components/EventVehicle'
import DispatchUlyService from 'components/Dispatch/DispatchUlyService'
import NoExchangeTow from '../Update/NoExchangeTow'
import UpdateTowForm from '../Update/UpdateTowForm'
import { BreakdownOptions } from 'forms/CallForm/BreakdownDetails/BreakdownRadio'
import { selectSmartAssignment } from 'redux/appStore'
import { DynamoEvents } from 'types/events'
import { Participant } from 'types/global'
import { isParticipant } from 'utils/typeChecks'

export const NoExchangeEvent = (event: DynamoEvents) => {
  const { t } = useTranslation()
  const [edit, setEdit] = React.useState<boolean>(false)
  const participant = useSelector(selectSmartAssignment)
  const {
    serviceCase,
    dispatch,
    unattended,
    keysLocation,
    keysLocationOther,
    rideAlong,
    customerSupplied,
    serviceLocations,
    network,
    rideAlongLocation,
    rideAlongSameAsDropoff,
    customDropoffDestination,
    notes,
    breakdownOption,
    unsafe,
    accessible,
    garageAccessible,
    vehicle,
    vehicleCondition,
  } = event.attributes || {}

  const vehicleLocation = serviceLocations?.vehicleLocation || ''

  const callKey = dispatch?.[0]?.callKey
  const caseId = serviceCase ? serviceCase?.caseId : null
  const { dispatch: aaaDispatch } = useAAADispatch(callKey)
  const unattendedText = unattended ? ' - Unattended' : ''

  if (edit)
    return (
      <>
        <DispatchHeader
          title={t('labels.tow') + unattendedText}
          dispatchStatus={aaaDispatch?.service?.status?.status}
          edit={edit}
          onClick={() => {
            setEdit(!edit)
          }}
          isUnattended={unattended}
        />
        <UpdateTowForm dispatchEvent={event} onUpdated={() => setEdit(false)}>
          <NoExchangeTow dispatchEvent={event} />
        </UpdateTowForm>
      </>
    )

  const participantLocation =
    participant?.participant || (serviceLocations?.vehicleDestinationLocation as Participant | undefined | null)
  return (
    <>
      <DispatchHeader
        title={t('labels.tow') + unattendedText}
        ulyHeader={!isNil(caseId)}
        dispatchStatus={aaaDispatch?.service?.status?.status}
        edit={edit}
        onClick={() => {
          setEdit(!edit)
        }}
        isUnattended={unattended}
      />
      <EventVehicle vehicleInDispatch={vehicle} vehicleConditionFromEvent={vehicleCondition} />
      <IncidentLocation label={t('Disabled Vehicle Location')} location={vehicleLocation} spacing="md" />
      <Label>{t('Breakdown Location')}</Label>
      <Paragraph spacing="sm">{breakdownOption}</Paragraph>
      {unsafe && (
        <>
          <Label>{t('Blocking traffic or unsafe')}</Label>
          <Paragraph spacing="sm">{unsafe}</Paragraph>
        </>
      )}
      {breakdownOption === BreakdownOptions.PARKINGGARAGE && garageAccessible && (
        <>
          <Label>{t('Vehicle is garage accessible')}</Label>
          <Paragraph spacing="sm">{garageAccessible}</Paragraph>
        </>
      )}
      {breakdownOption !== BreakdownOptions.PARKINGGARAGE && accessible && (
        <>
          <Label>{t('Vehicle is accessible')}</Label>
          <Paragraph spacing="sm">{accessible}</Paragraph>
        </>
      )}
      <Label>{t('labels.disabledVehicleDropoff')}</Label>
      {isParticipant(participantLocation) ? (
        <SmartAssignment.Participant participant={participantLocation} network={network?.value} showTooltip={false} />
      ) : (
        customDropoffDestination && <IncidentLocation location={customDropoffDestination} spacing="md" />
      )}

      <Label>{t('labels.customersTransportationMethod')}</Label>
      <Paragraph spacing="md">
        {customerSupplied
          ? t('Customer Supplied')
          : rideAlong
            ? t('Ride Along')
            : unattended
              ? t('Third Party (Unattended)')
              : t('Third Party')}
      </Paragraph>

      {rideAlong && (
        <>
          <Label>{t('labels.callerLocation')}</Label>
          <Paragraph spacing="md">{t('Same as disabled vehicle')}</Paragraph>

          <Label>{t('Customer Dropoff Location')}</Label>
          {rideAlongSameAsDropoff ? (
            <Paragraph spacing="md">{t('Same as vehicle dropoff destination')}</Paragraph>
          ) : (
            <Paragraph spacing="md" dangerouslySetInnerHTML={{ __html: rideAlongLocation! }} />
          )}
        </>
      )}

      {unattended && keysLocation && (
        <>
          <Label>{t('Where are the keys located?')}</Label>
          <Paragraph spacing="md">{keysLocationOther || keysLocation.label}</Paragraph>
        </>
      )}
      {notes && (
        <>
          <Label>{t('notesToProvider.label')}</Label>
          <Paragraph spacing="md">{notes}</Paragraph>
        </>
      )}
      {dispatch && <DispatchService dispatch={dispatch} />}
      {serviceCase && caseId && <DispatchUlyService caseId={caseId} />}
    </>
  )
}

export default NoExchangeEvent
