import { isSameLocation } from 'utils/location'
import { format } from 'date-fns'
import { StandardServiceCall } from 'types/aaa'
import { LocationPayload, LocationState } from 'types/location'

export function pruneUpdateDispatchData(dispatchData?: StandardServiceCall) {
  if (!dispatchData) return null
  const {
    callKey,
    service: { dispatchCenter, status },
  } = dispatchData

  return {
    callKey,
    service: { dispatchCenter, status },
  }
}

export function buildTimestamp(zone: string) {
  return `${format(new Date(), 'MM/DD/YYYY - h:mm A')} ${zone}`
}

export function applyTimestamp(oldServiceLocation: LocationPayload, newServiceLocation: LocationPayload, zone: string) {
  return isSameLocation(oldServiceLocation, newServiceLocation)
    ? oldServiceLocation
    : { ...newServiceLocation, modified: buildTimestamp(zone) }
}

export function applyTimestamps(oldServiceLocations: LocationState, newServiceLocations: LocationState, zone: string) {
  return Object.values(oldServiceLocations).map((location, i) =>
    applyTimestamp(location!, newServiceLocations[i], zone),
  )
}
