import React from 'react'
import { useTranslation } from 'react-i18next'
import { CallTypes } from 'types/callstore'
import Complete from 'components/Complete'
import Margin from 'components/Margin'
import { EventTypes } from 'types/events'
import { Condition } from 'components/fields'
import { WorkflowAction } from 'components/Workflow'
import { useCallEvent } from 'hooks/events/call/useCallEvents'

function MultiChoiceActions() {
  const { t } = useTranslation()
  const towEvent = useCallEvent(EventTypes.TOW)

  return (
    <>
      <Margin spacing="md" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Margin spacing="md" style={{ width: '100%' }}>
          <Condition when="personalBelongings" is="yes">
            {towEvent ? (
              <>
                <WorkflowAction
                  callType={CallTypes.TOW}
                  eventType={EventTypes.TOW}
                  additionalService={EventTypes.LOCKOUT}
                  required
                  label={t('tow.dispatch')}
                />
                <Complete eventType={EventTypes.LOCKOUT} />
              </>
            ) : (
              <>
                <WorkflowAction
                  callType={CallTypes.TOW}
                  eventType={EventTypes.TOW}
                  additionalService={EventTypes.LOCKOUT}
                  required
                  label={t('tow.dispatch')}
                />
                <Complete eventType={EventTypes.LOCKOUT} />
              </>
            )}
          </Condition>
          <Condition when="personalBelongings" is="no">
            <WorkflowAction callType={CallTypes.TOW} eventType={EventTypes.TOW} required label={t('tow.dispatch')} />
            <Complete eventType={EventTypes.LOCKOUT} />
          </Condition>
        </Margin>
      </Margin>
    </>
  )
}

export default MultiChoiceActions
