import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Error } from 'components/fields'
import Margin from 'components/Margin'
import MultipleChoice from 'components/Input/MultipleChoice'

export enum BreakdownOptions {
  ROADWAYINTERSTATE = 'Roadway/Interstate',
  PARKINGGARAGE = 'Parking Garage',
  OTHER = 'Other',
}

type Props = { name: string }
const choices = Object.values(BreakdownOptions).reduce<Record<string, string>>((values, value) => {
  values[value] = value
  return values
}, {})

function BreakdownRadio({ name }: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Margin spacing="md">
        <MultipleChoice
          name={name}
          choices={choices}
          orientation="vertical"
          label={t('breakdownDetails.heading')}
        />
        <Error name={name} />
      </Margin>
    </>
  )
}

export default BreakdownRadio
