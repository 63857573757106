import * as React from 'react'
import styled from 'styled-components/macro'
import { Flex, Heading } from 'components/primitives'
import { MdClose } from 'react-icons/md'
import { theme, palette } from 'styled-tools'
import Card from 'components/Card'
import ScrollBox from 'components/ScrollBox'
import { pxToRem } from 'theme/utils'
import { calypso, dustyGray } from 'theme/colors'
import { usePopupManager } from './PopupManager'
import Button from '../Button/Button'
import { FC, SVGProps } from 'react'

const StyledCard = styled(Card as any)`
  display: flex;
  flex-direction: column;
  width: ${pxToRem(340)};
  max-height: 100%;
`

const Header = styled(Flex as any)`
  justify-content: space-between;
  padding: 0 ${theme('spaces.2')} ${theme('spaces.1')};
  margin: 0 -${theme('spaces.2')};
  border-bottom: 1px solid ${palette('mercury')};
`

type Props = {
  heading: string
  icon?: FC<SVGProps<SVGSVGElement>>
  onClick?: () => void
}

const PopupCard: React.FC<Props> = ({ heading, icon, onClick, children }) => {
  const { close } = usePopupManager()

  return (
    <StyledCard>
      {heading && (
        <Header>
          <div style={{ display: 'flex' }}>
            {typeof heading === 'string' ? <Heading as="h5">{heading}</Heading> : heading}
            {icon && (
              <Button
                iconBefore={icon}
                iconColor={calypso}
                onClick={onClick}
                style={{ padding: '0 0 0 5px' }}
              />
            )}
          </div>
          <MdClose style={{ cursor: 'pointer' }} color={dustyGray} onClick={close} />
        </Header>
      )}
      <ScrollBox>{children}</ScrollBox>
    </StyledCard>
  )
}

export default PopupCard
