import * as React from 'react'
import { Field as FinalFormField, FieldProps, FieldRenderProps } from 'react-final-form'
import styled from 'styled-components/macro'
import { Label, Field as PrimitiveField, Input as PrimitiveInput } from 'components/primitives'
import use, { UseProp } from 'reuse'
import { palette } from 'styled-tools'
import Margin, { MarginProps } from 'components/Margin'
import Error from 'components/fields/Error'

const StyledInput = styled(PrimitiveInput as any)<{ hasError: boolean }>`
  ${({ hasError }) => hasError && `border: 1px solid ${palette('monza')}`} ::placeholder {
    text-transform: none;
  }
`

type FormFieldProps = { as: UseProp } & FieldRenderProps<any>
const FormField: React.FC<FormFieldProps> = ({ as: T, ...props }) => {
  const {
    input,
    meta: { touched, error },
  } = props
  return <T hasError={Boolean(touched && error)} {...input} {...props} />
}
const InputComponent = use(FormField, StyledInput)
export type InputProps = { label?: string } & FieldProps<HTMLInputElement | string, any> &
  MarginProps

export const Input: React.FC<InputProps> = ({
  label,
  component = StyledInput,
  spacing = 'md',
  gutter,
  ...props
}) => (
  <Margin spacing={spacing} gutter={gutter} style={{ width: '100%' }}>
    <PrimitiveField>
      <Label style={{ flex: 1 }}>
        {label}
        <FinalFormField
          // @ts-ignore - reuse types conflicting
          component={InputComponent}
          as={component}
          {...props}
        />
      </Label>
      <Error name={props.name} />
    </PrimitiveField>
  </Margin>
)

// Don't render error for individual Radio inputs
export const RadioInput: React.FC<InputProps> = (props) => (
  <Margin spacing="sm">
    <FinalFormField {...props} />
  </Margin>
)

export const Hidden: React.FC<InputProps> = (props) => (
  <FinalFormField {...props} render={() => <></>} />
)

export default Input
