import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CallerRelationship } from 'types/callstore'
import Input, { InputProps } from 'components/Input'

const CallerRelationshipSelect: React.FC<InputProps> = (props) => {
  const { t } = useTranslation()
  const options = Object.values(CallerRelationship).map((relationship) => ({
    label: t(`callerRelationship.${relationship}`),
    value: relationship,
  }))
  return <Input.Select options={options} {...props} />
}

export default CallerRelationshipSelect
