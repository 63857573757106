import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Input as PrimitiveInput } from 'components/primitives'

export function Input({
  input,
  meta,
  ...rest
}: FieldRenderProps<HTMLInputElement> & React.ComponentProps<typeof PrimitiveInput>) {
  return <PrimitiveInput {...input} {...rest} />
}

export default Input
