import { pxToRem } from 'theme/utils'

export type CircleIconSizeType = {
  SMALL: string
  MEDIUM: string
  LARGE: string
  XLARGE: string
}

export const CircleIconSize: CircleIconSizeType = {
  SMALL: pxToRem(32),
  MEDIUM: pxToRem(36),
  LARGE: pxToRem(40),
  XLARGE: pxToRem(48),
}

export default CircleIconSize
