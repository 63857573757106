import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TicketState, TransformedTicket } from 'types/ticket'

const initialState: TicketState = {
  ticket: undefined,
  ticketError: undefined,
}

const ticketSlice = createSlice({
  name: 'Ticket',
  initialState,
  reducers: {
    updateTicket: (state: TicketState, action: PayloadAction<{ ticket?: TransformedTicket }>) => {
      state.ticket = action.payload.ticket
    },
    updateTicketError: (state: TicketState, action: PayloadAction<{ error?: any }>) => {
      state.ticketError = action.payload.error
    },
    resetTicket: (state: TicketState, action: PayloadAction<{}>) => {
      state = initialState
      return state
    },
  },
})

export const { updateTicket, updateTicketError, resetTicket } = ticketSlice.actions

export default ticketSlice.reducer
