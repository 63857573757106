import React from 'react'
import StatusMessage from 'components/StatusMessage'
import { useTranslation } from 'react-i18next'

type Props = {
  timestamp: string
}

export function UpdatedTimestamp({ timestamp }: Props) {
  const { t } = useTranslation()
  return <StatusMessage>{t('dispatchUpdated', { time: timestamp })}</StatusMessage>
}
export default UpdatedTimestamp
