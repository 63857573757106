import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTicket } from 'redux/appStore'
import { setFleetVehicle, setFleetVehicleHistory } from 'redux/fleetVehicle/fleetVehicleSlice'
import { TransformedVehicle } from 'types/ticket'
import { FleetVehicleRequest, FleetVehicle } from 'types/kong'
import { ServiceProxyCall } from 'types/global'
import { useServiceProxy } from './useServiceProxy'
import { deepMapSanitize } from 'utils/deepMap'

// Retrieve Vehicle Fleet Details ( fuelCategory, vin, telematics, rental status )
async function searchByFleetVehicleRetrieveDetails(unitNumber: string, proxy: ServiceProxyCall) {
  return proxy<FleetVehicleRequest>('get', `/serviceproxy/fleetVehicle/${unitNumber}`).then((response) => ({
    ...response,
    data: deepMapSanitize(response.data) as FleetVehicleRequest,
  }))
}

export function useFleetVehicleSearch() {
  const ticket = useSelector(selectTicket)
  const serviceProxy = useServiceProxy()
  const dispatch = useDispatch()
  // Primary Vehicle
  useEffect(() => {
    async function getFleetVehicleDetails(unit: string) {
      const fleetVehicleDetails = await searchByFleetVehicleRetrieveDetails(unit, serviceProxy)

      if (fleetVehicleDetails?.data) {
        dispatch(setFleetVehicle({ vehicle: fleetVehicleDetails.data.fleetVehicle || {} }))
      }
    }

    if (ticket && ticket.vehicle && ticket.vehicle.unitNumber !== '') {
      getFleetVehicleDetails(ticket.vehicle.unitNumber)
    }
  }, [ticket, ticket?.vehicle?.unitNumber])

  // Secondary Vehicles
  useEffect(() => {
    async function getFleetVehicleHistoryDetails(unit: string) {
      const fleetVehicleDetails = await searchByFleetVehicleRetrieveDetails(unit, serviceProxy)
      if (fleetVehicleDetails?.data) dispatch(setFleetVehicleHistory({ vehicleHistory: [fleetVehicleDetails.data.fleetVehicle] }))
    }
    if (ticket && ticket.vehicleHistory && ticket.vehicleHistory.length > 0) {
      ticket.vehicleHistory.forEach(
        async (vehicle) => await getFleetVehicleHistoryDetails((vehicle as TransformedVehicle).unitNumber),
      )
    }
  }, [ticket, ticket?.vehicleHistory?.length])
}

export function getFleetVehicle(vehicle: FleetVehicle, unit: string) {
  if (vehicle) {
    const unitFromUrn = vehicle.urn?.split('vehicle:fleetVehicle:')[1]
    if (unitFromUrn === unit) return vehicle
  }
}
export function getFleetVehicleHistory(vehicleHistory: FleetVehicle[], unit: string) {
  if (vehicleHistory && vehicleHistory.length > 0) {
    return vehicleHistory.find((vehicle) => {
      const unitFromUrn = vehicle.urn?.split('vehicle:fleetVehicle:')[1]
      return unitFromUrn === unit
    })
  }
}
