import { stringify } from 'querystring'
import * as React from 'react'
import { useServiceProxy } from 'hooks/kong'
import { GeocodeAddress } from 'types/googleMaps'
import { ServiceProxyCall } from 'types/global'
import { GMapsGeocodeResponse, GMapsGeocodeResults } from 'types/googleMaps'
import { IncomingGMapsGeocodeResponse } from 'types/api'
import { deepMapSanitize } from 'utils/deepMap'
import { AxiosResponse } from 'axios'

export async function reverseGeocode(
  proxy: ServiceProxyCall,
  lat: string | number,
  lng: string | number,
): Promise<GeocodeAddress | null | 'ZERO_RESULTS'> {
  try {
    const params = {
      latlng: `${lat},${lng}`,
      result_type: 'street_address|route',
    }

    const response = await proxy<IncomingGMapsGeocodeResponse>(
      'get',
      `/serviceproxy/googleMaps/geocode/json?${stringify(params)}`,
    )

    const geocodeData = {
      ...response,
      data: {
        results: deepMapSanitize(response.data.results),
      },
    } as AxiosResponse<GMapsGeocodeResponse>
    if (geocodeData.data.status === 'ZERO_RESULTS') return 'ZERO_RESULTS'
    if (geocodeData.data.results?.length === 0) return null

    const isHighwayResult = geocodeData.data.results.find((result) =>
      result.address_components?.find(
        (addr) => addr.short_name && addr.short_name?.match(/-[0-9]{2,3}/) && addr.types[0] === 'route',
      ),
    )

    const view = isHighwayResult ? isHighwayResult : geocodeData.data.results[0]

    if (!view) return null

    return convertToGeocodeAddress(view)
  } catch (e) {
    console.error(e)
    return null
  }
}

type ReturnType = {
  address: GeocodeAddress | null
  loading: boolean
}

export const useReverseGeocoder = (latlng: google.maps.LatLng | null): ReturnType => {
  const serviceProxy = useServiceProxy()
  const [address, setAddress] = React.useState<GeocodeAddress | null>(null)
  const [latlon, setLatlon] = React.useState<google.maps.LatLng | null>(null)
  const loading = latlon !== latlng

  React.useEffect(() => {
    if (latlng) {
      const { lat, lng } = latlng as google.maps.LatLng
      const func = async () => {
        const address = await reverseGeocode(serviceProxy, lat(), lng())
        if (address && address !== 'ZERO_RESULTS') {
          setAddress(address)
          setLatlon(latlng)
        } else {
          setAddress(null)
          setLatlon(null)
        }
      }
      func()
    }
  }, [serviceProxy, latlng])

  return { address, loading }
}

export function convertToGeocodeAddress(googleAddress: GMapsGeocodeResults): GeocodeAddress {
  const { address_components: address } = googleAddress
  const HouseNumber = address!.find((addr) => addr && addr.types.includes('street_number'))?.long_name || 'xxxxxx'
  const Street = address!.find((addr) => addr && addr.types.includes('route'))?.long_name || 'xxxxxx'
  const Label = googleAddress.formatted_address || 'xxxxxx'
  const City =
    address!.find(
      (addr) =>
        addr &&
        (addr.types.includes('locality') ||
          addr.types.includes('sublocality') ||
          addr.types.includes('sublocality_level_1') ||
          addr.types.includes('administrative_area_level_3') ||
          addr.types.includes('administrative_area_level_4') ||
          addr.types.includes('neighborhood')),
    )?.long_name || 'xxxxxx'
  const Country = address!.find((addr) => addr && addr.types.includes('country'))?.long_name || 'xxxxxx'
  const CountryShortCode = address!.find((addr) => addr && addr.types.includes('country'))?.short_name || 'xx'
  const County = address!.find((addr) => addr && addr.types.includes('administrative_area_level_2'))?.long_name || 'xxxxxx'
  const PostalCode = address!.find((addr) => addr && addr.types.includes('postal_code'))?.long_name || 'xxxxxx'
  const State =
    address!.find((addr) => addr && addr.types.includes('administrative_area_level_1'))?.short_name?.toUpperCase() || 'xx'
  return {
    HouseNumber,
    Street,
    Label,
    City,
    Country,
    CountryShortCode,
    County,
    PostalCode,
    State,
  } as GeocodeAddress
}

export default useReverseGeocoder
