import * as React from 'react'
import { pxToRem } from 'theme/utils'
import { elm } from 'theme/colors'
import IconBase from './IconBase'

const BodyShopIconMarker = () => (
  <IconBase
    width={pxToRem(24)}
    height={pxToRem(24)}
    fill={elm}
    style={{ marginRight: 8 }}
    path="M36 45 36 54 33 54 33 45 27.5455 45 21.6029 66.7894C21.2469 68.0945 20.0615 69 18.7086 69L9.9278 69C8.2709 69 6.9278 67.6569 6.9278 66 6.9278 65.7334 6.9633 65.4679 7.0335 65.2106L12.5455 45 12 45C10.3431 45 9 43.6569 9 42L9 33C9 31.3431 10.3431 30 12 30L42 30C43.6569 30 45 31.3431 45 33L45 42C45 43.6569 43.6569 45 42 45L36 45ZM18 3 39 3 39 16.5C39 22.299 34.299 27 28.5 27 22.701 27 18 22.299 18 16.5L18 3ZM21 6 21 9 36 9 36 6 21 6ZM51 39C52.6569 39 54 37.6569 54 36 54 34.3431 52.6569 33 51 33 49.3431 33 48 34.3431 48 36 48 37.6569 49.3431 39 51 39ZM63 39C64.6569 39 66 37.6569 66 36 66 34.3431 64.6569 33 63 33 61.3431 33 60 34.3431 60 36 60 37.6569 61.3431 39 63 39ZM57 45C58.6569 45 60 43.6569 60 42 60 40.3431 58.6569 39 57 39 55.3431 39 54 40.3431 54 42 54 43.6569 55.3431 45 57 45ZM57 33C58.6569 33 60 31.6569 60 30 60 28.3431 58.6569 27 57 27 55.3431 27 54 28.3431 54 30 54 31.6569 55.3431 33 57 33ZM63 51C64.6569 51 66 49.6569 66 48 66 46.3431 64.6569 45 63 45 61.3431 45 60 46.3431 60 48 60 49.6569 61.3431 51 63 51ZM63 27C64.6569 27 66 25.6569 66 24 66 22.3431 64.6569 21 63 21 61.3431 21 60 22.3431 60 24 60 25.6569 61.3431 27 63 27Z"
  />
)

export default BodyShopIconMarker
