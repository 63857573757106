import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Margin from 'components/Margin'
import { createCancelDialogContext } from 'components/Dispatch/Cancel/useCancelServiceDialog'
import { Label, Paragraph, Heading } from 'components/primitives'
import { useULYDispatch } from 'hooks/urgent.ly'
import { DispatchUlyStatus } from './DispatchUlyStatus'
import DispatchUlyServiceInfo from './DispatchUlyServiceInfo'
import DispatchUlyRideInfo from './DispatchUlyRideInfo'
import { selectTicketHistoryEvents } from 'redux/appStore'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

type Props = {
  caseId?: string | null
  disableCancel?: boolean
}

function useUlyWebHook(caseId: string) {
  const eventHistory = useSelector(selectTicketHistoryEvents)
  const [event, setEvent] = useState({})
  useEffect(() => {
    const refreshedEvent = eventHistory?.filter((e) => e.provider === 'uly' && e.attributes.serviceCase.caseId === caseId)
    setEvent(refreshedEvent[0])
  }, [eventHistory])
  return event
}

export const cancelDialogContext = createCancelDialogContext()

function DispatchUlyService({ caseId, disableCancel }: Props) {
  const { t } = useTranslation()
  const { dispatch: ulyDispatch, loading, error } = useULYDispatch(caseId)
  const webhookEvent = useUlyWebHook(caseId)
  const [status, setStatus] = useState()
  const { roadsideIsUlyHookActive } = useFlags()

  useEffect(() => {
    if (webhookEvent?.attributes?.status) setStatus(webhookEvent.attributes.status)
    else setStatus(ulyDispatch?.services[0].statusCode)
  }, [webhookEvent, ulyDispatch])

  if (error)
    return (
      <>
        <Heading as="h4">{t('case.case')}</Heading>
        <Label>{t('Error Loading Details...')}</Label>
      </>
    )

  if (loading)
    return (
      <>
        <Heading as="h4">{t('case.case')}</Heading>
        <Label>{t('Retrieving...')}</Label>
      </>
    )

  return (
    <>
      <Heading as="h4">{t('case.case')}</Heading>
      <div style={{ marginLeft: 8 }}>
        <Margin spacing="sm">
          <Label>{t('case.caseId')}</Label>
          <Paragraph spacing="sm">{ulyDispatch?.case.ulyCaseId}</Paragraph>
        </Margin>

        <Margin spacing="none">
          {ulyDispatch && ulyDispatch.services && (
            <>
              <Label>{t('case.services')}</Label>
              {ulyDispatch.services.map(
                (service, i) =>
                  service && (
                    <Margin key={i} spacing="md">
                      <DispatchUlyServiceInfo service={service} />
                      <cancelDialogContext.Provider>
                        <DispatchUlyStatus
                          caseId={caseId}
                          ulyStatus={roadsideIsUlyHookActive ? status : service.statusCode}
                          ulyServiceId={service.ulyServiceId}
                          disableCancel={disableCancel}
                          cancelDetails={service.cancellationDetails}
                        />
                      </cancelDialogContext.Provider>
                    </Margin>
                  ),
              )}
            </>
          )}
        </Margin>
        <Margin spacing="none">
          {ulyDispatch && ulyDispatch.rides && (
            <>
              <Label>{t('case.rides')}</Label>
              {ulyDispatch.rides.map(
                (ride, i) =>
                  ride && (
                    <Margin key={i} spacing="md">
                      <DispatchUlyRideInfo ride={ride} />
                      <DispatchUlyStatus
                        caseId={caseId}
                        ulyStatus={ride.statusCode!}
                        ulyServiceId={ride.ulyServiceId}
                        disableCancel
                      />
                    </Margin>
                  ),
              )}
            </>
          )}
        </Margin>
      </div>
    </>
  )
}

export default DispatchUlyService
