import * as React from 'react'
import { GoogleMapsDisplay, useViewport } from 'components/GoogleMaps'
import { InteractiveLayers } from './GoogleLayers/InteractiveLayers'
import TempMarkersLayer from './GoogleLayers/TempMarkersLayer'
import { HeroLayersProvider } from './Layers'
import HeroContextMenu from './HeroContextMenu'

export const HeroMapWithGoogle: React.FC = () => {
  useViewport()
  return (
    <HeroLayersProvider>
      <GoogleMapsDisplay>
        <InteractiveLayers />
        <TempMarkersLayer />
        <HeroContextMenu />
      </GoogleMapsDisplay>
    </HeroLayersProvider>
  )
}
