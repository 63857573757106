import * as React from 'react'
import { IconContext, IconType } from 'react-icons'
import { ListItem } from 'components/List'
import { calypso } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import { ConnectedCarIcon, EVCarIcon, EVConnectedCarIcon, IconBase } from 'icons'
import { Container, TitleText, infoIconContext, InfoIcon } from './DetailsStyles'
import { ConnectedCarMarker } from './ConnectedCarMarker'
import { ConnectedError } from './ConnectedError'
import ConnectedAuthDialog from './ConnectedAuthDialog'
import useConnectedSearch from './useConnectedSearch'
import { getFleetVehicle, getFleetVehicleHistory } from 'hooks/kong'
import { setConnectedVehicleState } from 'redux/connectedSearch/connectedSearchSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser, selectFleetVehicle, selectFleetVehicleHistory } from 'redux/appStore'
import isEmpty from 'lodash-es/isEmpty'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { config } from 'config'

type Props = {
  title: string
  icon: IconType | React.FC<React.CSSProperties & unknown>
  onClick?: () => void
  historicalVehicle?: boolean
  vin: string
  unit: string
}

const ConnectedDetailItem: React.FC<Props> = (connectedProps) => {
  const { title, icon, onClick, historicalVehicle = false, vin, unit } = connectedProps
  const { roadsideIsAdmin } = useFlags()
  const currentUser = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  // Functions and Data
  const { searchForPrimaryConnectedCar, searchForHistoryConnectedCar } = useConnectedSearch()
  const { ...connectedData } = useConnectedSearch()
  const fleetVehicle = useSelector(selectFleetVehicle)
  const fleetVehicleHistory = useSelector(selectFleetVehicleHistory)

  const [isElectric, setIsElectricVehicle] = React.useState(false)

  React.useEffect(() => {
    if (unit) {
      async function isElectricVehicle() {
        const fleetVehicleRetrieveDetails = getFleetVehicle(fleetVehicle, unit)
        const fuelCategorySplit = fleetVehicleRetrieveDetails?.vehicleAsset?.specification?.fuelCategory?.split('fuelCategory:')
        const fuelCategoryString = fuelCategorySplit && fuelCategorySplit[1]
        const fuelType = fleetVehicleRetrieveDetails?.vehicleAsset?.specification?.fuelType
        setIsElectricVehicle(fuelCategoryString === 'ELECTRIC' || fuelType === 'Electric')
      }
      isElectricVehicle()
    }
  }, [unit, setIsElectricVehicle, fleetVehicle])

  // Connected Metrics and Location
  const { connectedMetrics, connectedLocation, connectedVehicles } = connectedData
  // Connected Status
  const { connectedLoading, connectedError, lastVinSearched } = connectedData

  const isVehicleConnected = connectedVehicles.find((veh) => veh.vin === vin)?.connectedStatus || false

  const isAdminAgent = useAdminFeatureFlag()

  function useAdminFeatureFlag() {
    if (config.envName !== 'prod') {
      return roadsideIsAdmin
    } else {
      return currentUser && currentUser.roles && currentUser.roles.includes('usr.grp-admin')
    }
  }

  const metricOrNoErrorOrNonSearchedVehicle = connectedError === 'METRICS' || connectedError === null || lastVinSearched !== vin

  // Allow all users to see
  const isPrimaryVehicle = !historicalVehicle && isVehicleConnected && metricOrNoErrorOrNonSearchedVehicle

  // Allow only admin users to see
  const isHistoricalVehicle = historicalVehicle && isAdminAgent && isVehicleConnected && metricOrNoErrorOrNonSearchedVehicle

  React.useEffect(() => {
    const fleetUnit = fleetVehicle.urn?.split('fleetVehicle:')[1]
    if (unit && fleetUnit === unit) {
      async function setConnectedStatus() {
        const vehicle = getFleetVehicle(fleetVehicle, unit)

        if (vehicle?.telematics) {
          const { activationStatus } = vehicle?.telematics
          if (activationStatus && activationStatus.toLowerCase() === 'activated') {
            dispatch(setConnectedVehicleState({ vin, connectedStatus: true }))
          } else {
            dispatch(setConnectedVehicleState({ vin, connectedStatus: false }))
          }
        } else {
          dispatch(setConnectedVehicleState({ vin, connectedStatus: false }))
        }
      }

      setConnectedStatus()
    }
  }, [unit, fleetVehicle, dispatch, vin])

  React.useEffect(() => {
    if (unit && fleetVehicleHistory.some((vehicle) => vehicle.urn?.split('fleetVehicle:')[1] === unit)) {
      async function setConnectedStatus() {
        const vehicle = getFleetVehicleHistory(fleetVehicleHistory, unit)

        if (vehicle?.telematics) {
          const { activationStatus } = vehicle?.telematics
          if (activationStatus && activationStatus.toLowerCase() === 'activated') {
            dispatch(setConnectedVehicleState({ vin, connectedStatus: true }))
          } else {
            dispatch(setConnectedVehicleState({ vin, connectedStatus: false }))
          }
        } else {
          dispatch(setConnectedVehicleState({ vin, connectedStatus: false }))
        }
      }

      setConnectedStatus()
    }
  }, [unit, fleetVehicleHistory, dispatch, vin])

  return (
    <>
      <Container>
        {isVehicleConnected || isElectric ? (
          <IconBase
            icon={
              isVehicleConnected && isElectric
                ? EVConnectedCarIcon
                : !isVehicleConnected && isElectric
                  ? EVCarIcon
                  : ConnectedCarIcon
            }
            width={pxToRem(24)}
            height={pxToRem(24)}
            fill={calypso}
            style={{ marginRight: 8 }}
          />
        ) : (
          <ListItem.Icon icon={icon} />
        )}
        <TitleText onClick={onClick} style={{ cursor: 'pointer', opacity: historicalVehicle ? 0.5 : undefined }}>
          {title}
        </TitleText>

        {isPrimaryVehicle && (
          <ConnectedAuthDialog onLocate={(vin) => searchForPrimaryConnectedCar(vin, unit)} loading={connectedLoading} vin={vin} />
        )}

        {isHistoricalVehicle && (
          <ConnectedAuthDialog
            onLocate={(vin) => searchForHistoryConnectedCar(vin, unit)}
            isHistorical={isHistoricalVehicle}
            loading={connectedLoading}
            vin={vin}
          />
        )}

        {onClick && (
          <IconContext.Provider value={infoIconContext}>
            <InfoIcon onClick={onClick} style={{ cursor: 'pointer' }} />
          </IconContext.Provider>
        )}
      </Container>

      {lastVinSearched === vin && isVehicleConnected && <ConnectedError error={connectedError} />}
      {lastVinSearched === vin && isVehicleConnected && connectedLocation !== null && !isEmpty(connectedLocation) && (
        <ConnectedCarMarker location={connectedLocation} metrics={connectedMetrics} vin={vin} />
      )}
    </>
  )
}

const ConnectedSearchComponent = (props: Props) => <ConnectedDetailItem {...props} />

export default ConnectedSearchComponent
