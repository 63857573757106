import { CallerRelationship } from 'types/callstore'
import { AnyObject, LocationState } from 'types/location'
import { SelectOption } from './global'

export type Audit = {
  eId: string
  createTimestamp: string
}

export type DynamoEventItems = { Items: DynamoEvents[] }

export type DynamoEvents = {
  attributes: AnyObject
  eventIdentifier: string
  eventType: EventTypes
  notes: string[]
  rentalContext: string
  audit: Audit
  serviceLocations: LocationState
  timestamp: string
  vehicleContext: string
  vehicleStatus: string
  provider?: string
}

export enum EventTypes {
  ACCIDENT = 'accident',
  ADDITIONAL = 'additional',
  CALL = 'call',
  CALLBACK = 'callback',
  DISPATCH = 'dispatch',
  DROP = 'drop',
  ESCALATE = 'escalate',
  EXCHANGE = 'exchange',
  EXCHANGE_TURNDOWN = 'exchangeTurndown',
  FEATURE = 'feature',
  FUEL = 'fuel',
  JUMPSTART = 'jumpstart',
  LAW = 'law',
  LDR = 'ldr',
  LOCKOUT = 'lockout',
  RECOVERY = 'recovery',
  TIRES = 'tires',
  TIRES_CHANGE_SPARE = 'tiresChangeSpare',
  TOW = 'tow',
  TRANSFER = 'transfer',
  WINCH = 'winch',
}

export enum EventStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export const EventTypeLabels: { [K in EventTypes]?: string } = {
  [EventTypes.LOCKOUT]: 'Lockout',
  [EventTypes.WINCH]: 'Winch',
}

export type LawEnforcementLog = {
  agent: string
  rentalGPBR: string
  ticketNumber: string
  name: string
  title: string
  badge: string
  mobilePhone: string
  deskPhone?: string
  deskPhoneExt?: string
  email: string
  department: string
  cityAndCounty: string
  stateOrProvince: string
  isActionable: boolean
  vehicle: SelectOption
  notes?: string
}

export type NormalizedPhone = {
  number: string
  countryCode: string
}

export type CallLogAttributes = {
  callerName: string
  phone: NormalizedPhone
  branchId: string
  callerRelationship?: CallerRelationship
}

export type TransferAttributes = {
  branchId: string
  stationId: string
}

export type EventAttributes = TransferAttributes | LawEnforcementLog | CallLogAttributes
// used for CallHistory events. Could possibly use CallHistoryState instead?
export type EventFeed = {
  events?: any
  loading: boolean
  error?: boolean
}

export type ICurrentEvent = {
  event?: DynamoEvents
  readOnly?: boolean
}

export type ICurrentEventState = Record<EventTypes, ICurrentEvent | null | undefined>

export type SetCurrentEventAction = {
  newEvent: DynamoEvents
  eventType: EventTypes
}
