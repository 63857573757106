import React from 'react'
import { useTranslation } from 'react-i18next'
import { EventTypes } from 'types/events'
import { Heading, Label, Paragraph } from 'components/primitives'
import Complete from 'components/Complete'
import { YesNoRadio, YesNoTriggers } from 'components/Input'
import Input from 'components/Input'
import Submit from 'components/fields/Submit'
import { Condition } from 'components/fields'
import ScheduledAt from 'components/ScheduledAt'
import Margin from 'components/Margin'
import { useCreateSoftService } from 'hooks/aaa'
import { useCreateUlySoftService, useUrgentlyProvider } from 'hooks/urgent.ly'
import { cardinal } from 'theme/colors'
import { isRequired } from 'utils/validators'
import { CallTypes } from 'types/callstore'
import { useSelector } from 'react-redux'
import { selectFormsState, selectTicket } from 'redux/appStore'
import { BreakdownDetailsForm, BreakdownNote } from '../BreakdownDetails'
import { BreakdownOptions } from '../BreakdownDetails/BreakdownRadio'

type OnstarProps = {
  isOnstar?: boolean
}

export const LockoutDispatch = () => {
  const { t } = useTranslation()
  const { isEnterpriseTicket } = useUrgentlyProvider()
  const createSoftService = useCreateSoftService(EventTypes.LOCKOUT)
  const createUlySoftService = useCreateUlySoftService(EventTypes.LOCKOUT)
  const { lockout } = useSelector(selectFormsState) as any

  let safeToContinue =
    (lockout?.breakdownOption === BreakdownOptions.ROADWAYINTERSTATE && lockout?.unsafe === 'no') ||
    (lockout?.breakdownOption === BreakdownOptions.PARKINGGARAGE && lockout?.garageAccessible === 'yes') ||
    (lockout?.breakdownOption === BreakdownOptions.OTHER && lockout?.accessible === 'yes')

  return (
    <>
      <BreakdownDetailsForm callType={CallTypes.KEYS} />
      {safeToContinue && (
        <>
          <Margin spacing="md">
            <ScheduledAt callType={CallTypes.KEYS} />
            <Input.Hidden name="scheduledAt" />
          </Margin>
          <BreakdownNote
            breakdownOption={lockout?.breakdownOption}
            unsafe={lockout?.unsafe}
            garageAccessible={lockout?.garageAccessible}
            accessible={lockout?.accessible}
          />
          <Input.Textarea
            label={`${t('notesToProvider.label')}*`}
            placeholder={t('notesToProvider.placeholder')}
            name="notes"
            maxLength={240}
            validate={isRequired(t('required.notes'))}
          />
          <Margin spacing="md">
            <Submit
              onSubmit={async (state, caller) => {
                const { secondaryProvider = false, scheduledAt } = state.values

                if (scheduledAt) {
                  return await createUlySoftService(state, caller)
                } else {
                  if (isEnterpriseTicket) {
                    // AAA
                    return secondaryProvider
                      ? await createUlySoftService(state, caller) //Secondary
                      : await createSoftService(state, caller) //Default
                  } else {
                    // Urgently
                    return secondaryProvider
                      ? await createSoftService(state, caller) //Secondary
                      : await createUlySoftService(state, caller) //Default
                  }
                }
              }}
            >
              {t('lockout.dispatch')}
            </Submit>
          </Margin>
        </>
      )}
    </>
  )
}

function OnstarActions({ isOnstar }: OnstarProps) {
  const { t } = useTranslation()
  const { vehicle } = useSelector(selectTicket)

  return isOnstar ? (
    <>
      <Heading as="h5" spacing="sm" style={{ color: cardinal }}>
        {t('lockout.onstar.tips')}
      </Heading>
      <Label>{t('labels.vin')}</Label>
      <Paragraph spacing="sm">{vehicle!.vin}</Paragraph>
      <Label>{t('lockout.onstar.phone')}</Label>
      <Paragraph spacing="md">(888) 466-7827</Paragraph>
      <YesNoRadio name="onStarSuccess" trigger={YesNoTriggers.EITHER} label={t('lockout.onstar.hasUnlocked')} />
      <Condition when="onStarSuccess" is="yes">
        <Complete eventType={EventTypes.LOCKOUT} />
      </Condition>
      <Condition when="onStarSuccess" is="no">
        <LockoutDispatch />
      </Condition>
    </>
  ) : (
    <LockoutDispatch />
  )
}

export default OnstarActions
