import * as React from 'react'
import { FormState } from 'final-form'
import { useTranslation } from 'react-i18next'
import { EventTypes } from 'types/events'
import Submit from 'components/fields/Submit'
import { useCreateServiceEvent } from 'hooks/events'
import { useDispatch } from 'react-redux'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

type Props = {
  eventType: EventTypes
  onComplete?: (state: FormState<any>) => void
}

export const Complete: React.FC<Props> = ({ eventType, onComplete }) => {
  const { t } = useTranslation()
  const createServiceEvent = useCreateServiceEvent({ eventType })
  const dispatch = useDispatch()

  const onSubmit = async (state: FormState<any>) => {
    try {
      const event = await createServiceEvent(state.values)
      dispatch(setCurrentEvent({ eventType, newEvent: event.data }))

      if (onComplete) onComplete(state)

      return null
    } catch (err) {
      console.error(err)
      return t('error.complete')
    }
  }

  return <Submit onSubmit={onSubmit}>{t('labels.complete')}</Submit>
}

export default Complete
