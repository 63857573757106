import { Maybe } from 'types/global'

export enum Brands {
  ALAMO = 'ALAMO',
  ENTERPRISE = 'ENTERPRISE',
  NATIONAL = 'NATIONAL',
}

export type BranchInfoFragment = {
  latitude: string
  longitude: string
  defaultLocationName: string
  locationNameTranslation: string
  locationType: string
  formattedCityStateZip: string
  formattedPhone: string
  addressLines: string[]
  city: string
  state: string
  postalCode: string
  countryCode: string
  brand: Brands
  groupBranchNumber: string
  stationId: string
  peopleSoftId: string
  attributes: string[]
  calculatedDistance?: Maybe<number> | undefined
  closed: boolean
}

export type AgeOptions = { value: number; selected: boolean; label: string }
export type AttributesWithType = { type: string; name: string; code: string }

export type BranchAdditionalData = {
  age_options: AgeOptions
  attributes: string[]
  attributes_with_type: AttributesWithType
  bookable: boolean
  booking_url: string
  calculated_distance: number
  closed: boolean
  co_located_brand: string
  co_located_station_id: string
  default_location_name: string
  enable_drive_alliance: boolean
  formatted_city_state_zip: string
  formatted_phone: string
  group_branch_number: string
  is24HourLocation: boolean
  java_time_zone: string
  open_sundays: boolean
  physical_location: true
  update_timestamp: string
}

export type BranchAddress = {
  city: string
  country_code: string
  country_subdivision_code: string
  postal: string
  street_addresses: string[]
}

export type BusinessType = {
  description: string
  code: string
}

export type BranchPhone = {
  dialing_code: string
  phone_number: string
  priority: number
}

export type Branch = {
  additional_data: BranchAdditionalData
  address: BranchAddress
  after_hour_notice_period: number
  after_hours_pickup: boolean
  after_hours_return: boolean
  airline_details: any[]
  airport_code: string
  alternative_policies: any[]
  available_business_types: any[]
  booking_urls: any[]
  brand: string
  business_types: BusinessType[]
  currency_code: string
  dc_eligible_car_classes: any[]
  filter_tags: any[]
  gps: { longitude: number; latitude: number }
  hours: any[]
  id: string
  indicators: any[]
  level_of_service_wayfindings: any[]
  location_hierarchies: any[]
  location_type: string
  methods_of_delivery: string[]
  name: string
  offered_car_classes: any[]
  paragraphs: any[]
  phones: BranchPhone[]
  policies: any[]
  rental_checklists: any[]
  serviceLocationType?: string
  station_id: string
  type: string
  universal_checkin_policies: any[]
  vehicle_availability_phones: any[]
  wayfindings: any[]
}

export interface BranchResponse {
  result?: Branch[] | null
  brandsInResult?: string[] | null
  radiusUsedInKilometers: number
  locationsResult?: BranchInfoFragment[] | null
}
