import { MultiRetrieveResponse } from 'types/ticket'
import { ServiceProxyCall } from 'types/global'
import { transformMultiRetrieveTicket } from 'redux/ticket/ticketHelpers'
import axios from 'axios'
import { config } from '../config'
import { DynamoEventItems } from 'types/events'
import { FleetVehicleRequest } from 'types/kong'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import { IncomingMultiRetrieveResponse } from 'types/api'
import { deepMapSanitize } from './deepMap'

export async function getFleetVehicleDetails(unitNumber: string, proxy: ServiceProxyCall) {
  const fleetVehicleDetails = await proxy<FleetVehicleRequest>('get', `/serviceproxy/fleetVehicle/${unitNumber}`)

  if (fleetVehicleDetails.data) {
    return fleetVehicleDetails.data
  }
  return undefined
}

export const fetchEvents = async (ticketNumber: string, serviceProxy: ServiceProxyCall) => {
  try {
    const { data } = await serviceProxy<DynamoEventItems>(
      'get',
      `/serviceproxy/events-table/items?rentalContext=${config.rentalAgreementUrn}${ticketNumber}`,
    ).then((response) => ({ ...response, data: deepMapSanitize(response.data) as DynamoEventItems }))

    return data
  } catch (e) {
    console.error(e)
  }
}

/**
 * Loads a ticket by RA number
 * @param ra RA number
 */
export async function loadTicketByRA(ra: string, serviceProxy: ServiceProxyCall, ldConfig: LDFlagSet) {
  const { solrLocator } = config
  const { data } = await serviceProxy<IncomingMultiRetrieveResponse>('get', `/serviceproxy/multiRetrieve/${ra.toUpperCase()}`)
  const ticketData = deepMapSanitize(data) as MultiRetrieveResponse
  if (ticketData) {
    const { data: solrData } = await axios.get(`${solrLocator}/branch:${ticketData.pickupInfo.location.groupBranchId}`)
    if (ticketData.returnInfo.location.groupBranchId !== ticketData.pickupInfo.location.groupBranchId) {
      const returnBranch = await axios.get(`${solrLocator}/branch:${ticketData.returnInfo.location.groupBranchId}`)
      solrData.branches.push(returnBranch.data.branches[0])
    }

    return transformMultiRetrieveTicket(ticketData, solrData, ldConfig)
  }
}
