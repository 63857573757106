import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { Branch } from 'types/global'
import { useCallerLocation } from 'hooks/redux/useLocations'
import Margin from 'components/Margin'
import Button from 'components/Button'
import { ConfirmedExchangeLocation, ConfirmedCustomerLocation } from 'components/Dispatch/ConfirmedLocations'
import Exchange, { ExchangeBranchDisplay } from 'components/Exchange'
import Location from 'components/Input/Location'
import { Step } from 'components/Step'
import { DynamoEvents, EventTypes } from 'types/events'
import { ICurrentEvent } from 'types/events'
import { useCallEvent } from 'hooks/events'
import { ServiceLocationType } from 'types/location'

type Props = {
  dispatchEvent?: DynamoEvents
}

export default function DeliveryOnlySteps({ dispatchEvent }: Props) {
  const { t } = useTranslation()
  const callerLocation = useCallerLocation()
  const controlsContext = Ariakit.useCompositeContext()
  const towEvent = useCallEvent(EventTypes.TOW) as ICurrentEvent
  const event = dispatchEvent || towEvent.event

  if (!event || !event || !event.attributes) return null

  const { agentEID, serviceLocations, exchangeNotes, vehicleAccommodation, accommodationNotes, disabledVehicle, bypassReason } =
    event?.attributes

  return (
    <>
      <>
        <Step step="1">
          <Location.Caller name={'serviceLocations.callerLocation'} serviceLocationType={ServiceLocationType.CALLER} edit />
          <Margin spacing="sm">
            <Button.Primary onClick={() => controlsContext?.move(controlsContext?.next())}>
              {t('labels.confirmVehicleDelivery')}
            </Button.Primary>
          </Margin>
          <ExchangeBranchDisplay
            branch={serviceLocations.exchangeLocation as Branch}
            agentEID={agentEID}
            exchangeNotes={exchangeNotes}
            vehicleAccommodation={vehicleAccommodation}
            accommodationNotes={accommodationNotes}
            disabledVehicle={disabledVehicle}
            bypassReason={bypassReason}
          />
        </Step>
        <Step step="2">
          <ConfirmedCustomerLocation />
          <Exchange
            name="serviceLocations.exchangeLocation"
            isTowExchange={true}
            searchLocation={callerLocation}
            onSetBranch={() => controlsContext?.move(controlsContext?.next())}
          />
        </Step>
        <Step step="3">
          <ConfirmedCustomerLocation />
          <ConfirmedExchangeLocation />
        </Step>
      </>
    </>
  )
}
