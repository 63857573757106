import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormApi } from 'final-form'
import { useForm } from 'react-final-form'
import * as Yup from 'yup'
import { TicketCharges } from 'types/ticket'
import { EventTypes } from 'types/events'
import { Heading, Label } from 'components/primitives'
import Button from 'components/Button'
import { Condition } from 'components/fields'
import { Form } from 'components/FormManager'
import Margin from 'components/Margin'
import Waivers from 'components/Waivers'
import { CallTypes, TowType } from 'types/callstore'
import { useCallEvent, useReduxCallStoreActions } from 'hooks/events'
import CallTypeIcon from '../CallTypeSelection/CallTypeIcon'
import NoExchange from './NoExchange'
import TowExchange from './TowExchange'
import TowEvent from './TowEvent'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'

declare module 'types/form' {
  export interface Forms {
    [CallTypes.TOW]: FormApi
  }
}

const exchangeIcon = () => <CallTypeIcon callType={CallTypes.EXCHANGE} gutter="sm" />
const noExchangeIcon = () => <CallTypeIcon callType={CallTypes.TOW} gutter="sm" />

const towSchema = Yup.object({
  towType: Yup.string(),
})

export const TowForm: React.FC = () => {
  const { t } = useTranslation()
  const towEvent = useCallEvent(EventTypes.TOW)
  const { towForm } = useSelector(selectFormsState) as any
  const { update } = useReduxCallStoreActions()

  if (towEvent && towEvent?.event) {
    return (
      <>
        <TowEvent {...towEvent?.event} />
      </>
    )
  }

  return (
    <>
      <Form
        name={CallTypes.TOW}
        schema={towSchema}
        initialValues={towForm}
        autoSaveSync={(towForm) => update({ towForm })}
      >
        <Heading as="h5" spacing="md">
          {t('Tow')}
        </Heading>

        <Margin spacing="md">
          <Label>{t('fees')}</Label>
          <Waivers charge={TicketCharges.IMPOUND_OR_TOW_FEE} />
        </Margin>

        <Condition when="towType" is={(v) => !v}>
          <TowOptions />
        </Condition>
        <Condition when="towType" is={TowType.EXCHANGE}>
          <TowExchange />
        </Condition>
        <Condition when="towType" is={TowType.NO_EXCHANGE}>
          <NoExchange />
        </Condition>
      </Form>
    </>
  )
}

const TowOptions = () => {
  const { t } = useTranslation()
  const form = useForm()

  return (
    <>
      <Button.Workflow
        iconBefore={exchangeIcon}
        onClick={() => {
          form.change('towType', TowType.EXCHANGE)
        }}
      >
        {t('labels.exchange')}
      </Button.Workflow>

      <Button.Workflow
        iconBefore={noExchangeIcon}
        onClick={() => {
          form.change('towType', TowType.NO_EXCHANGE)
        }}
      >
        {t('tow.standard')}
      </Button.Workflow>
    </>
  )
}

export default TowForm
