import * as React from 'react'
import useFieldValue from 'hooks/utils/useFieldValue'
import DirectionBranchInfo from './DirectionBranchInfo'
import DirectionVehicleInfo from './DirectionVehicleInfo'

export const TowExchangeInfo = () => {
  const towDirection = useFieldValue('towDirection')

  if (towDirection === 'branch') return <DirectionBranchInfo />
  if (towDirection === 'disabledVehicle') return <DirectionVehicleInfo />
  return null
}

export default TowExchangeInfo
