import { ServiceLocationType } from 'types/location'

export enum PriortyCode {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
  P6 = 'P6',
  P7 = 'P7',
  P8 = 'P8',
  R1 = 'R1',
  R2 = 'R2',
}

export enum TroubleCode {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  T5 = 'T5',
  T6 = 'T6',
  T7 = 'T7',
  T8 = 'T8',
  T9 = 'T9',
  A7 = 'A7',
  B7 = 'B7',
  T0 = 'T0',
}

export enum FoiType {
  ADDRESS = 'ADDRESS',
  POINT = 'POINT',
  AAR = 'AAR',
  DEALER = 'DEALER',
  ERS = 'ERS',
  RENTAL = 'RENTAL',
}

export enum AaaContactType {
  AGENT = 'AGENT',
  SYSTEM = 'SYSTEM',
  CUSTOMER = 'CUSTOMER',
  TOWDEST = 'TOWDEST',
  DRIVER = 'DRIVER',
  FACILITY = 'FACILITY',
}

export enum AaaEmailType {
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}

export enum AaaPhoneType {
  HOME = 'HOME',
  CELLULAR = 'CELLULAR',
  BUSINESS = 'BUSINESS',
  FAX = 'FAX',
}

export enum AaaAddressType {
  PHYSICAL = 'PHYSICAL',
  MAILING = 'MAILING',
}

export enum LocationCode {
  MD = 'MD',
  PL = 'PL',
  RD = 'RD',
  RS = 'RS',
  SH = 'SH',
  PS = 'PS',
  SF = 'SF',
  AR = 'AR',
  DL = 'DL',
  IT = 'IT',
}

export enum AaaCommentType {
  AUDIT = 'AUDIT',
  INTERNAL = 'INTERNAL',
  NORMAL = 'NORMAL',
}

export enum PaymentResponsibilityCode {
  C = 'C',
  S = 'S',
  P = 'P',
}

export enum AaaStatusCode {
  TR = 'TR',
  RE = 'RE',
  AK = 'AK',
  SP = 'SP',
  AS = 'AS',
  DI = 'DI',
  ER = 'ER',
  OL = 'OL',
  TW = 'TW',
  CA = 'CA',
  CP = 'CP',
  CL = 'CL',
  HD = 'HD',
}

export enum AaaDriveType {
  FWD = 'FWD',
  RWD = 'RWD',
  _4WD = '_4WD',
}

export enum AaaFuelType {
  GAS = 'GAS',
  HYBRID = 'HYBRID',
  ELECTRIC = 'ELECTRIC',
  DIESEL = 'DIESEL',
  FUELCELL = 'FUELCELL',
}

export enum AaaVehicleType {
  PS = 'PS',
  RV = 'RV',
  MC = 'MC',
}

export enum AaaVisibility {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum AaaCallType {
  MEMBER = 'MEMBER',
  PARTNER = 'PARTNER',
  RAP = 'RAP',
  PUBLIC_AGENCY = 'PUBLIC_AGENCY',
}

export type AaaServiceLocation = {
  readonly serviceLocationType?: ServiceLocationType | string
  readonly foi: AaaFoi
  readonly driverDirections?: string
  readonly dropBoxInfo?: string
  readonly mileageCalculated?: number
  readonly mileageOverride?: number
}

export type AaaFoi = {
  readonly foiType: FoiType
  readonly location: AaaLocation
  readonly contacts?: AaaContact[]
  readonly name?: string
  readonly id?: string
}

export type AaaLocation = {
  readonly address?: AaaAddress
  readonly latitude?: number
  readonly longitude?: number
  readonly locationCode?: LocationCode
  readonly landmark?: string
  readonly highway?: string
  readonly mileMarker?: string
  readonly restrictedRoadway?: string
  readonly grid?: string
  readonly zone?: string
}

export type AaaContact = {
  readonly contactType: AaaContactType
  readonly emails?: AaaEmail[]
  readonly firstName?: string
  readonly middleName?: string
  readonly lastName?: string
  readonly suffix?: string
  readonly title?: string
  readonly phones?: AaaPhone[]
  readonly userId?: string
}

export type AaaEmail = {
  readonly address: string
  readonly emailType: AaaEmailType
  readonly optIn?: boolean
}

export type AaaPhone = {
  readonly phoneExtension?: string
  readonly phoneNumber: string
  readonly phoneType: AaaPhoneType
  readonly preferred?: boolean
  readonly smsOptIn?: boolean
}

export type AaaAddress = {
  readonly addressType: AaaAddressType
  readonly streetNumber?: string
  readonly street?: string
  readonly extendedStreet?: string
  readonly crossStreet?: string
  readonly city?: string
  readonly county?: string
  readonly state?: string
  readonly postalCode?: string
  readonly country?: string
  readonly full?: string
  readonly streetDirection?: string
  readonly streetName?: string
  readonly streetNamePrefix?: string
  readonly streetNameSuffix?: string
}

export type StandardServiceCall = {
  readonly externalId?: string
  readonly customer: AaaCustomer
  readonly service: AaaService
  readonly vehicle: AaaVehicle
  readonly comments: ReadonlyArray<AaaComment>
  readonly callKey?: string
  readonly callDate?: string
  readonly callId?: number
  readonly version?: number
  readonly channel: AaaChannel
}

export type AaaCustomer = {
  readonly contact: AaaContact
  readonly customerId?: string
  readonly address?: AaaAddress
  readonly languagePreference?: string
  readonly organization?: AaaOrg
}

export type AaaOrg = {
  readonly code: string
  readonly name: string
}

export type AaaService = {
  readonly callType: AaaCallType
  readonly serviceLocations: ReadonlyArray<AaaServiceLocation>
  readonly requestedServices: ReadonlyArray<AaaTroubleCode>
  readonly collision?: boolean
  readonly goodwillIndicator?: boolean
  readonly flatbedRequested?: boolean
  readonly legacyPriorityCode?: string
  readonly mediumDutyIndicator?: string
  readonly needsFlatbed?: boolean
  readonly rideAlongRequested?: boolean
  readonly rideAlongNotes?: string
  readonly numberOfPassengers?: number
  readonly paymentResponsibilityCode?: PaymentResponsibilityCode
  readonly priorityCode?: string
  readonly redFlag?: boolean
  /** Response specific */
  readonly dispatchCenter?: AaaCenter
  readonly status?: AaaStatus
  readonly facility?: AaaFoi
  readonly providedServices?: AaaProvidedService
  readonly charges?: AaaCharge
  readonly apdSeqId?: string
}

export type AaaVehicle = {
  readonly color: string
  readonly condition?: string | null
  readonly driveType?: AaaDriveType
  readonly fuelType?: AaaFuelType
  readonly make: string
  readonly model: string
  readonly odometer?: number
  readonly specialEquipmentNeeds?: string
  readonly state?: string
  readonly tag?: string
  readonly trim?: string
  readonly vehicleType: AaaVehicleType
  readonly vin?: string
  readonly year?: number
  readonly externalId?: string
  readonly unattended: boolean
  readonly unattendedNotes?: string | null
}

export type AaaComment = {
  readonly commentType: AaaCommentType
  readonly text: string
  readonly commentDate?: string
  readonly contact?: AaaContact
  readonly system?: AaaSystem
  readonly visibility?: AaaVisibility
}

export type AaaTroubleCode = {
  readonly code: string
  readonly description: string
  readonly troubleCodeType: string
}

export type AaaCenter = {
  readonly name?: string
  readonly centerId?: number
  readonly org?: AaaOrg
  readonly contact?: AaaContact
}

export type AaaStatus = {
  readonly eta?: string
  readonly modifiedDate?: string
  readonly pta?: string
  readonly status?: AaaStatusCode
  readonly updateCode?: string
  readonly waitTime?: number
}

export type AaaProvidedService = {
  readonly charge?: AaaCharge
  readonly providedServiceType?: string
  readonly quantity?: number
  readonly service?: string
  readonly unit?: string
}

export type AaaCharge = {
  readonly amount?: string
  readonly chargeType?: string
}

export type AaaSystem = {
  readonly systemId: string
  readonly systemOrganization: AaaOrg
}

export type AaaChannel = {
  readonly channelType: string
  readonly contact?: AaaContact
}

export enum CancelReasonCode {
  C1 = 'C1',
  C2 = 'C2',
  E = 'E',
  M = 'M',
  O = 'O',
  W = 'W',
  Z = 'Z',
}
