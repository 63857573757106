import * as React from 'react'
import { MdArrowDropDown } from 'react-icons/md'
import styled from 'styled-components/macro'
import Button from 'components/Button'
import { pxToRem } from 'theme/utils'
import { calypso } from 'theme/colors'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from 'redux/auth/authSlice'
import { selectCurrentUser } from 'redux/appStore'
import { useNavigate, useLocation } from 'react-router-dom'
import AppRoutes from '../../routes'

const StyledMenuDisclosure = styled(Ariakit.MenuButton)`
  cursor: pointer;
  font-family: inherit;
  font-size: ${pxToRem(12)};
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  color: white;
`

const StyledMenu = styled(Ariakit.Menu)`
  background-color: white;
  width: 160px;
  justify-content: flex-start;
  z-index: 10002;
  cursor: pointer;
  margin-top: 8px;
  color: black;

  .menuItem[data-active-item='true'] {
    cursor: pointer;
    background-color: ${calypso};
    color: white;
    box-shadow: inset 0 0 160px 160px rgba(255, 255, 255, 0.1);
  }
`

function UserMenuPopover() {
  const { instance } = useMsal()
  const dispatch = useDispatch()
  const menuStore = Ariakit.useMenuStore({ placement: 'bottom' })

  const { t } = useTranslation()
  const currentUser = useSelector(selectCurrentUser)
  const location = useLocation()
  const navigate = useNavigate()

  const handleLogout = () => {
    instance.logoutRedirect({
      // Silent logout to prevent navigate to microsoft logout page
      onRedirectNavigate(url) {
        return false
      },
    })
    dispatch(setCurrentUser({ user: null }))
  }

  return currentUser ? (
    <Ariakit.MenuProvider store={menuStore}>
      <StyledMenuDisclosure store={menuStore}>
        {currentUser.nameID}
        <MdArrowDropDown size={24} />
      </StyledMenuDisclosure>

      <StyledMenu store={menuStore} className="menu" aria-label="Menu">
        <Ariakit.MenuItem
          store={menuStore}
          className="menuItem"
          aria-label={location.pathname.includes('version') ? 'Menu Item TicketSearch' : 'Menu Item Version'}
          onClick={() => {
            location.pathname.includes('version') ? navigate(AppRoutes.TICKET) : navigate(AppRoutes.VERSION)
          }}
        >
          <Button style={{ width: '100%', justifyContent: 'flex-start', color: 'inherit' }}>
            {location.pathname.includes('version') ? t('Ticket Search') : t('Version')}
          </Button>
        </Ariakit.MenuItem>
        <Ariakit.MenuItem
          store={menuStore}
          className="menuItem"
          aria-label="Menu Item Logout"
          onClick={async () => {
            try {
              handleLogout()
            } catch (e) {
              console.error(e)
            }
          }}
        >
          <Button style={{ width: '100%', justifyContent: 'flex-start', color: 'inherit' }}>{t('logout')}</Button>
        </Ariakit.MenuItem>
      </StyledMenu>
    </Ariakit.MenuProvider>
  ) : null
}

export default UserMenuPopover
