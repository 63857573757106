import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { MdDone, MdErrorOutline } from 'react-icons/md'
import { palette, theme } from 'styled-tools'
import Button from 'components/Button'
import { ListItem } from 'components/List'
import { pxToRem } from 'theme/utils'

type Props = {
  title: string
  isOpen: Boolean
  onClick?: () => void
}

const Container = styled(ListItem as any)`
  &:hover {
    background-color: ${palette('wildSand')};
    border-radius: ${pxToRem(20)};
    margin: 0 -${theme('spaces.1')};
    padding: ${theme('spaces.1')};
  }
`

const LineItem: React.FC<Props> = ({ title, isOpen, onClick }) => {
  const { t } = useTranslation()

  return (
    <Container onClick={onClick}>
      <ListItem.Icon
        icon={isOpen ? MdErrorOutline : MdDone}
        color={isOpen ? 'cardinal' : 'mountainMeadow'}
      />
      <ListItem.Text style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {title}
      </ListItem.Text>
      {isOpen && <Button.Regular>{t('Confirm')}</Button.Regular>}
    </Container>
  )
}

export default LineItem
