import { Route, Routes } from 'react-router-dom'
import * as React from 'react'
import { SidebarPosition } from 'types/global'
import Sidebar from './Sidebar'
import { Branch as BranchInfo, Default, BranchList, HotelList } from './routes'
import Hotel from './routes/Hotel/Hotel'

export const LEFT_SIDEBAR_WIDTH = 420

function LeftSidebar() {
  return (
    <Sidebar side={SidebarPosition.LEFT}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 16,
          height: '100%',
          width: LEFT_SIDEBAR_WIDTH,
        }}
      >
        <Routes>
          <Route path="/">
            <Route index element={<Default />} />

            <Route path="/branch">
              <Route path=":branchId/*" element={<BranchInfo />} />
            </Route>

            <Route path="/place/BRANCHES">
              <Route index element={<BranchList />} />
              <Route path=":branchId/*" element={<BranchInfo />} />
            </Route>

            <Route path="/hotel">
              <Route path=":hotelId/*" element={<Hotel />} />
            </Route>

            <Route path="/place/HOTELS">
              <Route index element={<HotelList />} />
              <Route path=":hotelId/*" element={<Hotel />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </Sidebar>
  )
}

export default LeftSidebar
