import * as React from 'react'
import { IconContext, IconType } from 'react-icons'
import { Flex } from 'components/primitives'
import { CustomIconType } from 'icons'
import colors, { Colors } from 'theme/colors'

export type ListItemIconProps = {
  icon: IconType | CustomIconType
  color?: Colors
}

const iconContext = {
  color: colors.calypso,
  size: '24',
}

function ListItemIcon({ icon: Icon, color }: ListItemIconProps) {
  return (
    <Flex gutter="sm">
      <IconContext.Provider value={color ? { ...iconContext, color: colors[color] } : iconContext}>
        <Icon />
      </IconContext.Provider>
    </Flex>
  )
}

export default ListItemIcon
