import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { Label, Paragraph } from 'components/primitives'
import { AaaStatusCode, AaaStatus } from 'types/aaa'
import useDispatchEta from './useDispatchEta'
import { DateTime } from 'luxon'
import { CancelDispatch } from 'components/Dispatch/Cancel/CancelDispatch'
import { createCancelDialogContext } from './Cancel/useCancelServiceDialog'
import { useAAADispatchQuery } from 'hooks/aaa/useAAADispatch'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const cancelDialogContext = createCancelDialogContext()

// Translated values
export const AaaStatusCodeLabels = {
  [AaaStatusCode.TR]: 'aaaStatus.TRANSMITED',
  [AaaStatusCode.RE]: 'aaaStatus.RECEIVED',
  [AaaStatusCode.AK]: 'aaaStatus.ACKNOWLEDGED',
  [AaaStatusCode.SP]: 'aaaStatus.SPOTTED',
  [AaaStatusCode.AS]: 'aaaStatus.ASSIGNED',
  [AaaStatusCode.DI]: 'aaaStatus.DISPATCHED',
  [AaaStatusCode.ER]: 'aaaStatus.ENROUTE',
  [AaaStatusCode.OL]: 'aaaStatus.ON_LOCATION',
  [AaaStatusCode.TW]: 'aaaStatus.TOW_IN_PROGRESS',
  [AaaStatusCode.CA]: 'aaaStatus.CANCELLED',
  [AaaStatusCode.CP]: 'aaaStatus.CLEAR_PENDING',
  [AaaStatusCode.CL]: 'aaaStatus.CLOSED',
  [AaaStatusCode.HD]: 'aaaStatus.HOLD',
}

type StatusProps = {
  callKey?: string | null
  fallbackStatus?: AaaStatus | null
  disableCancel?: boolean
  service?: any
}

export const DispatchStatus = ({ callKey, fallbackStatus, disableCancel, service }: StatusProps) => {
  const { roadsideIsAaaHookActive } = useFlags()
  const { dispatchEta, zone } = useDispatchEta(service, roadsideIsAaaHookActive)
  const { t } = useTranslation()
  const { data } = useAAADispatchQuery(callKey)
  let aaaStatus = fallbackStatus || {}

  if (roadsideIsAaaHookActive) {
    if (service.status) aaaStatus = service.status
  } else {
    if (data?.service.status) aaaStatus = data.service.status
  }

  const { status, waitTime, modifiedDate } = aaaStatus
  const { cancelled } = cancelDialogContext.useCancelDialogContext()

  const modifiedStatus = modifiedDate && zone && DateTime.fromISO(modifiedDate, { zone })

  return (
    <>
      <Label>{t('labels.status')}</Label>
      <Paragraph spacing="sm">
        {`${cancelled ? t('cancelDispatch.cancelled') : status ? t(AaaStatusCodeLabels[status]) : t('labels.n/a')}`}
      </Paragraph>
      <Label>{t('labels.modifiedStatus')}</Label>
      <Paragraph spacing="sm">
        {`${
          modifiedStatus
            ? `(${modifiedStatus.toFormat('MM/dd/yyyy - t')} ${modifiedStatus.offsetNameLong.split(' ')[0]})`
            : t('labels.n/a')
        }`}
      </Paragraph>
      <Label>{t('labels.dispatchVendor')}</Label>
      <Paragraph spacing="sm">{t('headings.aaa')}</Paragraph>
      {/* Don't show ETA/Wait time if status is closed or cancelled */}
      {status !== AaaStatusCode.CA && status !== AaaStatusCode.CL && !cancelled && (
        <>
          <Label>{t('labels.eta')}</Label>
          <Paragraph spacing="sm">{dispatchEta || t('labels.n/a')}</Paragraph>
          <Label style={{ fontWeight: 'bold' }}>{t('labels.waitTime')}</Label>
          <Paragraph spacing="sm">{waitTime ? `${waitTime} ${t('labels.mins')}` : t('labels.n/a')}</Paragraph>
          {!disableCancel && callKey && status && <CancelDispatch callKey={callKey} />}
        </>
      )}
    </>
  )
}

const DispatchStatusWithContext = (props) => {
  const dialogStore = Ariakit.useDialogStore()
  return (
    <cancelDialogContext.Provider>
      <Ariakit.DialogProvider store={dialogStore}>
        <DispatchStatus {...props} />
      </Ariakit.DialogProvider>
    </cancelDialogContext.Provider>
  )
}

export default DispatchStatusWithContext
