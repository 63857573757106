import React from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Condition } from 'components/fields'
import { CarKeySelect } from 'components/Select'
import Input from 'components/Input'
import Margin from 'components/Margin'
import { isRequired } from 'utils/validators'

export const UnattendedTow = () => {
  const { t } = useTranslation()
  return (
    <>
      <Margin spacing="md">
        <Checkbox name="unattended" label={t('tow.unattended.title')} />
      </Margin>

      <Condition when="unattended" is={true}>
        {/* TODO: Figure out validation issues */}
        <CarKeySelect name="keysLocation" label={t('tow.unattended.keysLocation')} />
        <Input.Hidden name="keysLocation.value" validate={isRequired(t('required.keysLocation'))} />
        <Condition when="keysLocation.value" is="other">
          <Input.Textarea
            name="keysLocationOther"
            label={`${t('tow.unattended.locationOther')}*`}
            maxLength={100}
            validate={isRequired(t('required.location'))}
          />
        </Condition>
      </Condition>
    </>
  )
}

export default UnattendedTow
