import * as React from 'react'
import { MdClose } from 'react-icons/md'
import { MdError } from 'react-icons/md'
import { Field } from 'react-final-form'
import { Flex } from 'components/primitives'
import Margin from 'components/Margin'
import Input from 'components/Input'
import { useCallEvent, useReduxCallStoreActions, useCallFormState } from 'hooks/events'
import CallTypeIcon from 'forms/CallForm/CallTypeSelection/CallTypeIcon'
import { white, black, monza } from 'theme/colors'
import { isRequired } from 'utils/validators'
import { CallTypeToEventType } from 'utils/callstore'
import { EventTypes } from '../../types/events'
import { useSelector } from 'react-redux'
import { selectFormsState } from 'redux/appStore'
import { ICurrentEvent } from 'types/events'
import CallTypes from 'types/callstore'

type Props = {
  callType: CallTypes
  edit: boolean
  setEdit: (value: React.SetStateAction<boolean>) => void
  hasError?: boolean
  callEvent?: ICurrentEvent | null
}

const WorkflowItem: React.FC<Props> = ({ callType, edit, setEdit, callEvent, hasError }) => {
  const forms = useSelector(selectFormsState)
  const { update } = useReduxCallStoreActions()
  const { workflow } = useSelector(selectFormsState) as any
  const state = useCallFormState()

  const open = () => forms.CallForm.change('callType', callType)
  const remove = () => {
    const filtered = workflow.filter((c) => c !== callType)
    const current = state.values.callType
    if (current === callType) {
      if (filtered.length > 0) forms.CallForm.change('callType', filtered[0])
    }
    setEdit(false)
    update({ workflow: filtered.length === 1 ? [] : filtered })
  }

  return (
    <Margin gutter="sm">
      <Flex style={{ flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
        <CallTypeIcon
          callType={callType}
          onClick={open}
          checked={state && state.values && state.values.callType === callType}
          completed={!!callEvent}
        />
        {hasError && (
          <MdError
            size={16}
            color={monza}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              borderRadius: '50%',
              background: white,
            }}
          />
        )}
        {edit && (
          <MdClose
            size={16}
            color={black}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: 0,
              left: 0,
              border: `1px solid ${black}`,
              borderRadius: '50%',
              background: white,
            }}
            onClick={remove}
          />
        )}
      </Flex>
    </Margin>
  )
}

export const WorkflowItemField = ({ callType, ...props }: Props) => {
  const event = useCallEvent(CallTypeToEventType[callType])
  const { workflow } = useSelector(selectFormsState) as any
  const winchEvent = useCallEvent(EventTypes.WINCH)
  const towEvent = useCallEvent(EventTypes.TOW)
  const ldrEvent = useCallEvent(EventTypes.LDR)
  const accidentEvent = useCallEvent(EventTypes.ACCIDENT)
  const recoveryEvent = useCallEvent(EventTypes.RECOVERY)
  const jumpstartEvent = useCallEvent(EventTypes.JUMPSTART)
  const tireEvent = useCallEvent(EventTypes.TIRES)
  const exchangeEvent = useCallEvent(EventTypes.EXCHANGE)
  const keysEvent = useCallEvent(EventTypes.LOCKOUT)
  const { tires } = useSelector(selectFormsState) as any
  const forms = useSelector(selectFormsState) as any

  // TO DO - Can we clean this up to be more dynamic for each event and calltype ??
  // NOTE: if all required events are completed in the workflow, then change to the call type's form to automatically submit/complete it
  React.useEffect(() => {
    if (workflow.includes('Winch' as CallTypes) && towEvent && ldrEvent && !winchEvent) {
      forms.CallForm.change('callType', 'Winch')
    }
    if (workflow.includes('Accident' as CallTypes) && !accidentEvent && ((towEvent && ldrEvent) || recoveryEvent)) {
      forms.CallForm.change('callType', 'Accident')
    }
    if (workflow.includes('Start' as CallTypes) && towEvent && !jumpstartEvent) {
      forms.CallForm.change('callType', 'Start')
    }
    if (workflow.includes('Tires' as CallTypes) && !tireEvent) {
      if (
        (tires?.condition === 'Losing air' && exchangeEvent) ||
        (tires?.condition === 'Completely flat' && towEvent) ||
        (tires?.condition === 'Two Flats' && towEvent && ldrEvent) ||
        (tires?.condition === 'Three or More Tires Flat/Stolen' && recoveryEvent && ldrEvent)
      ) {
        forms.CallForm.change('callType', 'Tires')
      }
    }
    if (workflow.includes('Keys' as CallTypes) && towEvent && !keysEvent) {
      forms.CallForm.change('callType', 'Keys')
    }
  }, [
    forms.CallForm,
    ldrEvent,
    towEvent,
    winchEvent,
    recoveryEvent,
    accidentEvent,
    jumpstartEvent,
    tireEvent,
    workflow,
    tires,
    exchangeEvent,
    keysEvent,
  ])

  return (
    <Field name={callType} validate={isRequired()}>
      {({ input, meta: { touched, error } }) => {
        const hasError = !!!event && Boolean(touched && error)
        return (
          <>
            <WorkflowItem callType={callType} {...props} hasError={hasError} callEvent={event} />
            <Input.Hidden {...input} defaultValue={event ? (true as any) : null} />
          </>
        )
      }}
    </Field>
  )
}

export default WorkflowItemField
