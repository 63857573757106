import { useEffect, useState } from 'react'
import { useServiceProxy } from 'hooks/kong'
import { isNil } from 'lodash-es'
import { stringify } from 'querystring'

import { GMapsHotelDetails, GMapsHotelDetailsResponse } from 'types/googleMaps'
import { IncomingHotelDetailsResponse } from 'types/api'
import { AxiosResponse } from 'axios'
import { deepMapSanitize } from 'utils/deepMap'
export const useGoogleMapsHotelDetails = (placeId: string) => {
  const [data, setData] = useState<null | GMapsHotelDetails>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<null | any>(null)
  const proxy = useServiceProxy()

  useEffect(() => {
    if (!isNil(placeId)) {
      const fetchData = async () => {
        setLoading(true)
        try {
          const params = {
            place_id: placeId,
            fields: 'address_components,formatted_address,geometry,name,vicinity,place_id,types,international_phone_number',
          }

          const response = await proxy<IncomingHotelDetailsResponse>(
            'get',
            `/serviceproxy/googleMaps/place/details/json?${stringify(params)}`,
          )

          const hotelData = {
            ...response,
            data: {
              result: deepMapSanitize(response.data.result),
            },
          } as AxiosResponse<GMapsHotelDetailsResponse>
          const { result } = hotelData.data
          setData(result)
          setLoading(false)
        } catch (err) {
          setError(err)
          setLoading(false)
        }
      }
      fetchData()
    }
  }, [placeId])

  return { data, loading, error }
}
