import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import Margin from 'components/Margin'
import Exchange, { ExchangeBranchDisplay } from 'components/Exchange'
import { Step } from 'components/Step'
import Input from 'components/Input'
import ServiceLocationDisplay from 'components/Dispatch/ServiceLocationDisplay'
import {
  ConfirmedCustomerLocation,
  ConfirmedExchangeLocation,
  ConfirmedParticipantLocation,
} from 'components/Dispatch/ConfirmedLocations'
import Location from 'components/Input/Location'
import Button from 'components/Button'
import SmartAssignment from 'components/SmartAssignment'
import { useCallerLocation } from 'hooks/redux/useLocations'
import { DynamoEvents, EventTypes } from 'types/events'
import { useCallEvent } from 'hooks/events'
import { ServiceLocationType } from 'types/location'

type Props = {
  dispatchEvent?: DynamoEvents
}

export default function ReverseTwoWayTowSteps({ dispatchEvent }: Props) {
  const { t } = useTranslation()
  const controlsContext = Ariakit.useCompositeContext()
  const form = useForm()
  const towEvent = useCallEvent(EventTypes.TOW)
  const event = dispatchEvent || towEvent?.event
  const callerLocation = useCallerLocation()

  if (!event) return null
  const {
    serviceLocations,
    agentEID,
    vehicle,
    network,
    exchangeNotes,
    vehicleAccommodation,
    accommodationNotes,
    disabledVehicle,
    bypassReason,
  } = event?.attributes

  return (
    <>
      <>
        <Step step="1">
          <Location.Caller
            name="serviceLocations.callerLocation"
            label={t('labels.deliverNewVehicle')}
            serviceLocationType={ServiceLocationType.CALLER}
            edit
          />
          <Margin spacing="sm">
            <Button.Primary
              onClick={() => {
                controlsContext?.move(controlsContext?.next())
              }}
            >
              {t('labels.confirmDisabledVehicle')}
            </Button.Primary>
          </Margin>
          <ExchangeBranchDisplay
            branch={serviceLocations.exchangeLocation}
            agentEID={agentEID}
            exchangeNotes={exchangeNotes}
            vehicleAccommodation={vehicleAccommodation}
            accommodationNotes={accommodationNotes}
            disabledVehicle={disabledVehicle}
            bypassReason={bypassReason}
          />
          <ServiceLocationDisplay location={serviceLocations.exchangeLocation} network={network} />
        </Step>
        <Step step="2">
          <Margin spacing={'md'}>
            <ConfirmedCustomerLocation />
            <Exchange
              name="serviceLocations.exchangeLocation"
              searchLocation={callerLocation}
              isTowExchange={true}
              onSetBranch={() => {
                controlsContext?.move(controlsContext?.next())
              }}
            />
            <ServiceLocationDisplay location={serviceLocations.vehicleDestinationLocation} network={network} />
          </Margin>
        </Step>
        <Step step="3">
          <ConfirmedCustomerLocation />
          <ConfirmedExchangeLocation />
          <Input.Hidden name="vehicle" defaultValue={vehicle as any} />
          <SmartAssignment
            onSetAssignment={(participant) => {
              form.change('serviceLocations.vehicleDestinationLocation', {
                ...participant,
                serviceLocationType: ServiceLocationType.VEHICLEDESTINATION,
              })
              controlsContext?.move(controlsContext?.next())
            }}
          />
        </Step>
        <Step step="4">
          <ConfirmedCustomerLocation />
          <ConfirmedExchangeLocation />
          <ConfirmedParticipantLocation />
        </Step>
      </>
    </>
  )
}
