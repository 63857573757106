import * as React from 'react'
import { useTranslation } from 'react-i18next'
import MdSpinner from 'react-md-spinner'
import { Heading, Paragraph } from 'components/primitives'
import styled from 'styled-components/macro'
import Sidebar from 'components/Sidebar'
import { calypso } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import { CarCrashIcon, IconBase } from 'icons'
import { motion, MotionProps, AnimatePresence } from 'framer-motion'
import { LEFT_SIDEBAR_WIDTH } from './LeftSidebar'
import { useDispatch } from 'react-redux'
import { updateTicketError } from 'redux/ticket/ticketSlice'

const Indicator = styled(motion.div as any)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const indicatorProps: MotionProps = {
  animate: { opacity: 1 },
  initial: { opacity: 0, width: 256, position: 'absolute' },
  exit: { opacity: 0 },
  transition: { delay: 0.3 },
}

function Loading({ hasError, ticketNumber }: { hasError: boolean; ticketNumber: string }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  React.useEffect(() => {
    return () => {
      dispatch(updateTicketError({ error: undefined }))
    }
  }, [dispatch])

  return (
    <motion.div
      animate={{ width: LEFT_SIDEBAR_WIDTH }}
      initial={{ width: 0 }}
      style={{ height: '100%' }}
      transition={{ delay: 0.3 }}
    >
      <Sidebar style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Indicator {...indicatorProps}>
          <AnimatePresence>
            {!hasError ? (
              <Indicator key="loading">
                <MdSpinner singleColor={calypso} size={64} />
                <Heading as="h6" style={{ marginTop: pxToRem(16), color: calypso }}>
                  {t('loadingTicket')}
                </Heading>
              </Indicator>
            ) : (
              <Indicator key="error" {...indicatorProps}>
                <IconBase icon={CarCrashIcon} width={pxToRem(100)} height={pxToRem(64)} fill={calypso} />
                <Paragraph style={{ marginTop: pxToRem(16), color: calypso, textAlign: 'center' }}>
                  {t('ticketLoadingError.description', { ticketNumber })}
                  <br />
                  {t('ticketLoadingError.action')}
                </Paragraph>
                <Paragraph
                  style={{
                    marginTop: pxToRem(16),
                    color: calypso,
                    textAlign: 'center',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: t('ticketLoadingError.contact', { href: '#' }),
                  }}
                />
              </Indicator>
            )}
          </AnimatePresence>
        </Indicator>
      </Sidebar>
    </motion.div>
  )
}

export default Loading
