import * as React from 'react'
import { StyleProps } from 'types/global'

export type Placement = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'top' | 'bottom' | 'left' | 'right' | 'auto'

export type FloatProps = {
  placement: Placement
  children: React.ReactNode
} & StyleProps

const placementStyles = {
  topLeft: {
    top: 0,
    left: 0,
  },
  topRight: {
    top: 0,
    right: 0,
  },
  bottomLeft: {
    bottom: 0,
    left: 0,
  },
  bottomRight: {
    bottom: 0,
    right: 0,
  },
  top: {
    top: 0,
    left: '50%',
    transform: 'translateY(-50%)',
  },
  bottom: {
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  left: {
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  right: {
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  auto: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}

export const Float: React.FC<FloatProps> = ({ placement, style = {}, ...props }) => {
  return (
    <div style={{ all: 'unset', position: 'absolute', ...style, ...placementStyles[placement] }} {...props}>
      {props.children}
    </div>
  )
}

export default Float
