import * as React from 'react'
import Input, { InputProps } from 'components/Input'

export const ReasonSelect: React.FC<InputProps> = props => (
  <Input.Select placeholder="--" options={reasons} isClearable {...props} />
)
export default ReasonSelect

const reasons = [
  { label: 'Impounded Vehicle', value: 'impound' },
  { label: 'Car on Blocks', value: 'blocks' },
  { label: 'Vehicle Blocked In or Inaccessible', value: 'inaccessible' },
  { label: 'Three or More Flat Tires', value: 'tires' },
  { label: 'Accident Tow', value: 'tow' },
  { label: 'Police Towing Vehicle', value: 'police' },
  { label: 'Other', value: 'other' },
]
