import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'
import styled from 'styled-components/macro'
import { Heading, Flex, Label } from 'components/primitives'
import { palette, theme } from 'styled-tools'
import { TransformedVehicle } from 'types/ticket'
import Card from 'components/Card'
import PopupManager, { PopupDisplay, usePopupManager } from 'components/PopupManager'
import { dustyGray } from 'theme/colors'
import { pxToRem } from 'theme/utils'
import ScrollBox from 'components/ScrollBox'
import EquipmentInfoList from './EquipmentInfoList'
import { AvailabilityIcons } from 'components/IconIndicator'
import { useSelector } from 'react-redux'
import { selectFleetVehicle } from 'redux/appStore'

const StyledCard = styled(Card as any)`
  display: flex;
  flex-direction: column;
  width: ${pxToRem(340)};
  max-height: 100%;
`

export const Header = styled(Flex as any)`
  justify-content: space-between;
  padding: 0 ${theme('spaces.2')} ${theme('spaces.1')};
  margin: 0 -${theme('spaces.2')};
  border-bottom: 1px solid ${palette('mercury')};
`

const InfoRow: React.FC<{ label: string; text?: string | null }> = ({ label, text }) => {
  const { t } = useTranslation()

  return (
    <Flex style={{ flexDirection: 'column', marginTop: pxToRem(16) }}>
      <Label>{t(label)}</Label>
      <span>{text || t('N/A')}</span>
    </Flex>
  )
}

function formatToMiles(odo: number) {
  return odo
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const VehicleDetails: React.FC<TransformedVehicle> = ({
  year,
  make,
  model,
  equipmentInfo,
  vin,
  color,
  licensePlate,
  licensePlateState,
  sippCode,
  lastRecordedOdometer,
  nextPmOdometer,
  unitNumber,
}) => {
  const { t } = useTranslation()
  const hasSpareTire =
    Array.isArray(equipmentInfo) &&
    equipmentInfo.some(({ shortDescription }) => shortDescription && shortDescription === 'Spare tire')
  const { close } = usePopupManager()
  const fleetVehicle = useSelector(selectFleetVehicle)
  const fleetMake = fleetVehicle?.vehicleAsset?.specification?.make?.name ?? make
  const fleetModel = fleetVehicle?.vehicleAsset?.specification?.model?.name ?? model

  return (
    <PopupManager>
      <PopupDisplay marginLeft={pxToRem(360)} />
      <StyledCard>
        <Header>
          <Heading as="h5">
            {year} {fleetMake} {fleetModel}
          </Heading>
          <MdClose style={{ cursor: 'pointer' }} color={dustyGray} onClick={close} />
        </Header>
        <ScrollBox>
          {equipmentInfo && <EquipmentInfoList equipmentInfo={equipmentInfo} />}
          <AvailabilityIcons availability={hasSpareTire} label={t('tires.hasSpare')} notAvailableLabel={t('tires.hasNoSpare')} />
          <div>
            <InfoRow label="Car Class" text={sippCode} />
            <InfoRow label="VIN" text={vin} />
            <InfoRow label="Unit Number" text={unitNumber} />
            <InfoRow label="Color" text={color} />
            <InfoRow label="Plate & State" text={`${licensePlate} ${licensePlateState}`} />
            <InfoRow label="Next Maintenance Odometer" text={formatToMiles(parseInt(nextPmOdometer))} />
            <InfoRow label="Last Recorded Odometer" text={formatToMiles(parseInt(lastRecordedOdometer))} />
          </div>
        </ScrollBox>
      </StyledCard>
    </PopupManager>
  )
}

export default VehicleDetails
