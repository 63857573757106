import * as React from 'react'
import { Label, Paragraph } from 'components/primitives'
import useFieldValue from 'hooks/utils/useFieldValue'
import { useTranslation } from 'react-i18next'
import Margin from 'components/Margin'
import { YesNoTriggers } from 'components/Input'
import { LocationLabels } from 'components/Input/Location'
import { Branch } from 'types/global'
import { isLocation } from 'utils/typeChecks'
import IncidentLocation from 'components/IncidentLocation'
import { LocationPayload, LocationState, ServiceLocationType } from 'types/location'
import useLocations from 'hooks/redux/useLocations'
import { useSelector } from 'react-redux'
import { selectSmartAssignment } from 'redux/appStore'

export const DirectionVehicleInfo = () => {
  const { t } = useTranslation()
  const serviceLocations = useLocations()
  const rideAlong = useFieldValue('rideAlong')
  const customerSupplied = useFieldValue('customerSupplied')
  const branch = useFieldValue('branch')
  const { assignment } = useSelector(selectSmartAssignment)
  const { callerLocation, exchangeLocation, vehicleLocation, vehicleDestinationLocation, callerDestinationLocation } =
    serviceLocations

  return (
    <Margin spacing="md">
      <Label>{t('labels.customersTransportationMethod')}</Label>
      <Paragraph spacing="md">
        {customerSupplied ? t('Customer Supplied') : rideAlong ? t('Ride Along') : t('Third Party Transportation')}
      </Paragraph>
      {serviceLocations &&
        Object.values(serviceLocations as LocationState).map((location: LocationPayload | null, i: number) => {
          if (!location) return null

          // Don't show vehicle if it's the only location
          if (
            vehicleLocation &&
            !callerLocation &&
            !vehicleDestinationLocation &&
            !callerDestinationLocation &&
            !exchangeLocation &&
            !assignment
          )
            return null

          const isLast = i === Object.entries(serviceLocations).length - 1

          if (
            isLocation(location) &&
            location.serviceLocationType !== ServiceLocationType.BRANCH &&
            location.serviceLocationType !== ServiceLocationType.EXCHANGE
          ) {
            return (
              <React.Fragment key={i}>
                <IncidentLocation location={location} label={t(LocationLabels[location.serviceLocationType])} spacing="md" />
              </React.Fragment>
            )
          }

          if (
            location.serviceLocationType === ServiceLocationType.BRANCH ||
            location.serviceLocationType === ServiceLocationType.EXCHANGE
          ) {
            return (
              <React.Fragment key={i}>
                {rideAlong && branch && <BranchInfo />}
                <Margin spacing={isLast ? 'none' : 'md'}>
                  <IncidentLocation location={location} label={t('labels.disabledVehicleDropoff')} spacing="md" />
                </Margin>
              </React.Fragment>
            )
          }

          return null
        })}
      <ThirdPartyInfo />
    </Margin>
  )
}

const BranchInfo = () => {
  const { t } = useTranslation()
  const branch: Branch = useFieldValue('branch')
  const agentEID = useFieldValue('agentEID')

  return (
    <>
      <Label>{t('Exchange Branch Location')}</Label>
      <Paragraph spacing="md">
        {branch.brand} - {branch.additional_data?.group_branch_number}
      </Paragraph>
      <Paragraph spacing="md">{branch.additional_data?.formatted_phone}</Paragraph>
      <Paragraph>{branch?.address?.street_addresses[0]}</Paragraph>
      <Paragraph spacing="md">{branch?.additional_data?.formatted_city_state_zip}</Paragraph>
      {agentEID && (
        <>
          <Label>{t('labels.exchangeApproval')}</Label>
          <Margin spacing="md">{agentEID}</Margin>
        </>
      )}
    </>
  )
}

export const ThirdPartyInfo = () => {
  const { t } = useTranslation()
  const rideAlong = useFieldValue('rideAlong')
  const customerWithVehicle = useFieldValue('customerWithVehicle')
  const locations = useLocations()
  const branch = locations.exchangeLocation
  const callerLocation = locations.callerLocation
  const callerLocationSet = useFieldValue('callerLocationSet')

  const caller = customerWithVehicle === YesNoTriggers.YES || (callerLocationSet && callerLocation)

  if (rideAlong === false) {
    return (
      <>
        {caller && (
          <IncidentLocation.Caller location={callerLocation} spacing={branch ? 'md' : 'none'}>
            {!callerLocation && <Paragraph>{t('Customer is with disabled vehicle')}</Paragraph>}
          </IncidentLocation.Caller>
        )}
        {branch && <BranchInfo />}
      </>
    )
  }

  return null
}

export default DirectionVehicleInfo
