export type EquipmentInfoDescriptions =
  | 'AWD'
  | 'Fuel - Diesel'
  | 'Drivetrain'
  | 'Fuel - Electric'
  | 'Elec Mtr - Charge Cap (KWh)'
  | 'Elec Mtr - Power'
  | 'Elec Mtr - Range (Mi/Km)'
  | '4WD'
  | 'FWD'
  | 'Fuel - Gaseous Compatible'
  | 'Fuel - Gas Hybrid'
  | 'Remote Keyless Entry'
  | 'Fuel - Natural Gas'
  | 'OnStar'
  | 'Tires - OWL'
  | 'Fuel - Propane'
  | 'Key Access'
  | 'RWD'
  | 'Fuel - Reg Unl'
  | 'Roadside Assist'
  | 'Tires - Run Flat'
  | 'Spare Tire'
  | 'Tire Size'
  | 'Trunk Capacity'
  | 'Phone - Wireless Connect'

export const allEquipmentInfo: EquipmentInfoDescriptions[] = [
  'AWD',
  'Fuel - Diesel',
  'Drivetrain',
  'Fuel - Electric',
  'Elec Mtr - Charge Cap (KWh)',
  'Elec Mtr - Power',
  'Elec Mtr - Range (Mi/Km)',
  '4WD',
  'FWD',
  'Fuel - Gaseous Compatible',
  'Fuel - Gas Hybrid',
  'Remote Keyless Entry',
  'Fuel - Natural Gas',
  'OnStar',
  'Tires - OWL',
  'Fuel - Propane',
  'Key Access',
  'RWD',
  'Fuel - Reg Unl',
  'Roadside Assist',
  'Tires - Run Flat',
  'Spare Tire',
  'Tire Size',
  'Trunk Capacity',
  'Phone - Wireless Connect',
]
