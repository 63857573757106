// @ts-ignore
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'components/primitives'
import Input from 'components/Input'
import { StateSelect } from 'components/Select'
import OrDivider from 'components/OrDivider'

export const VehicleFormFields: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <Flex style={{ marginBottom: '16px' }}>
        <Input.Checkbox label={t('Search Closed Tickets')} name="searchClosed" />
      </Flex>
      <Flex>
        <Input
          label={t('Plate Number*')}
          name="licensePlate"
          style={{ textTransform: 'uppercase', width: '100%' }}
        />
      </Flex>
      <Flex>
        <StateSelect label={t('Plate State*')} name="licensePlateState" />
      </Flex>
      <OrDivider />
      <Input
        label={t('Last 8 of VIN*')}
        name="vinLast8"
        maxLength={8}
        style={{ textTransform: 'uppercase' }}
      />
    </>
  )
}

export default VehicleFormFields
