import * as React from 'react'
import * as Ariakit from '@ariakit/react'
import { isEmpty } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import { Flex } from 'components/primitives'
import { useUpdateEvent } from 'hooks/events'
import Button from 'components/Button'
import { usePopupManager } from 'components/PopupManager'
import { LDREvent } from 'types/ldrEvent'

type Props = {
  event: LDREvent
  onSubmit: () => void
}

export const Submit: React.FC<Props> = ({ event, onSubmit }) => {
  const { t } = useTranslation()
  const dialog = Ariakit.useDialogContext()
  const updateEvent = useUpdateEvent()
  const { close } = usePopupManager()
  const form = useForm()
  const [saving, setSaving] = React.useState<boolean>(false)

  return (
    <Flex style={{ justifyContent: 'space-between' }}>
      <Button.Primary
        loading={saving}
        onClick={async () => {
          if (!saving) {
            setSaving(true)
            await form.submit()
            const { valid, values, errors } = form.getState()
            if (valid && isEmpty(errors)) {
              await updateEvent({ ...event, attributes: { ...event.attributes, claimNumber: values.claimNumber } })
              onSubmit()
              dialog?.show()
            } else {
              setSaving(false)
            }
          }
        }}
      >
        {t('Complete')}
      </Button.Primary>
      <Button onClick={close}>{t('Cancel')}</Button>
    </Flex>
  )
}

export default Submit
