import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { FleetVehicleState, FleetVehicleAction, FleetVehicleHistoryAction } from 'types/kong'

const initialState: FleetVehicleState = {
  vehicle: {},
  vehicleHistory: [],
}

const fleetVehicleSlice = createSlice({
  name: 'FleetVehicle',
  initialState,
  reducers: {
    setFleetVehicle: (state: FleetVehicleState, action: PayloadAction<FleetVehicleAction>) => {
      state.vehicle = action.payload.vehicle
    },
    setFleetVehicleHistory: (state: FleetVehicleState, action: PayloadAction<FleetVehicleHistoryAction>) => {
      state.vehicleHistory = [...state.vehicleHistory, ...action.payload.vehicleHistory]
    },
    resetFleetVehicleState: (state: FleetVehicleState) => {
      state = { ...initialState }
      return state
    },
  },
})

export const { setFleetVehicle, setFleetVehicleHistory, resetFleetVehicleState } = fleetVehicleSlice.actions

export default fleetVehicleSlice.reducer
