import * as React from 'react'
import Input, { InputProps } from 'components/Input'
import { AssignmentNetworks } from 'types/global'

export const NetworkNames: { [key: string]: 'Service Centers' | 'Tire Shops' | 'Body Shops' } = {
  [AssignmentNetworks.SERVICE]: 'Service Centers',
  [AssignmentNetworks.TIRE]: 'Tire Shops',
  [AssignmentNetworks.BODY]: 'Body Shops',
}

export const NetworkSelect: React.FC<InputProps> = props => {
  const options = Object.values(AssignmentNetworks).map(network => ({
    label: NetworkNames[network],
    value: network,
  }))

  return <Input.Select {...props} placeholder="--" isClearable options={options} />
}

export default NetworkSelect
