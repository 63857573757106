import React from 'react'
import { TicketCharges } from 'types/ticket'
import DamageWaiver from './DamageWaiver'
import ProtectionWaiver from './ProtectionWaiver'

type Props = {
  charge?: TicketCharges
}

function Waivers({ charge }: Props) {
  return (
    <>
      <ProtectionWaiver charge={charge} />
      <DamageWaiver />
    </>
  )
}

export default Waivers
