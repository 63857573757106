import * as React from 'react'
import { useGoogleMaps } from 'hooks/map'

export const GoogleFeatureGroupContext = React.createContext<google.maps.MVCArray | null>(null)

export const GoogleFeatureGroup = ({ children }) => {
  const { map } = useGoogleMaps()
  const [featureGroup, setFeatureGroup] = React.useState<google.maps.MVCArray | null>(null)

  React.useEffect(() => {
    if (!featureGroup && map) {
      const layer = new google.maps.MVCArray()
      layer.set('map', map)
      setFeatureGroup(layer)
    }
  }, [featureGroup, map])

  return (
    <>
      {featureGroup && <GoogleFeatureGroupContext.Provider value={featureGroup}>{children}</GoogleFeatureGroupContext.Provider>}
    </>
  )
}

export function useFeatureGroup() {
  const featureGroup = React.useContext(GoogleFeatureGroupContext)

  // FeatureGroup is not necessarily going to be set. The marker will attempt to attach itself to a feature group if it is part of one, otherwise it will attach itself to the map
  // if (!featureGroup) throw new Error("Couldn't access featureGroup, is FeatureGroupContext set?")

  return featureGroup
}

export default GoogleFeatureGroup
