import { useEffect, useRef } from 'react'

export function useEventPolling(
  asyncCallback: () => Promise<any>,
  dependencies: any[] = [],
  interval: number = 50000, // 50 seconds,
) {
  const timeoutIdRef = useRef<any>(null)
  useEffect(() => {
    let isPollingStopped = false

    async function fireEventPolling() {
      try {
        await asyncCallback()
      } finally {
        timeoutIdRef.current = !isPollingStopped && setTimeout(fireEventPolling, interval)
      }
    }

    fireEventPolling()
    return () => {
      isPollingStopped = true // Should prevent any race conditions
      clearTimeout(timeoutIdRef.current)
    }
  }, [...dependencies, interval])
}

export default useEventPolling
