import styled from 'styled-components/macro'
import * as Ariakit from '@ariakit/react'
import { palette } from 'styled-tools'

const Divider = styled(Ariakit.Separator)`
  border: 0.5px solid ${palette('mercury')};
  opacity: 1;
`

export default Divider
