import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MdDirectionsCar, MdFiberManualRecord, MdPerson } from 'react-icons/md'
import styled from 'styled-components/macro'
import { Flex } from 'components/primitives'
import { useCreateUpdateLocation } from 'hooks/redux/useCreateUpdateLocation'
import { MarkerType } from 'types/googleMaps'
import CircleIcon, { CircleIconSize } from 'components/CircleIcon'
import { pxToRem } from 'theme/utils'
import ContextMenu from 'components/ContextMenu'
import { useGoogleMaps } from 'hooks/map'
import { useContextMenuEvent, TARGET_OFFSET } from 'hooks/map/useContextMenuEvent'
import { AaaAddress } from 'types/aaa'

export const Row = styled(Flex as any)`
  padding: ${pxToRem(8)};
  align-items: center;
  /* padding: 0; */
  width: 100%;
  font-size: ${pxToRem(12)};
  font-weight: 400;
  text-transform: uppercase;
`

export const Pill = styled.div`
  padding: ${pxToRem(8)};
  padding: ${pxToRem(4)} ${pxToRem(8)};
  background-color: black;
  color: white;
  border-radius: ${pxToRem(24)};
`

function getContextContainerPoint(map: google.maps.Map, latLng: google.maps.LatLng) {
  if (!map) return null
  const mapProjection = map.getProjection()

  if (mapProjection && latLng) {
    const scale = Math.pow(2, map.getZoom()!)
    const nw = new google.maps.LatLng(map.getBounds()!.getNorthEast().lat(), map.getBounds()!.getSouthWest().lng())
    const worldCoordinateNW = mapProjection.fromLatLngToPoint(nw)
    const worldCoordinate = mapProjection.fromLatLngToPoint(latLng)

    if (worldCoordinate && worldCoordinateNW) {
      const pixelOffset = new google.maps.Point(
        Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
        Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
      )
      return pixelOffset
    }
  }
  return null
}

export const HeroContextMenu: React.FC = () => {
  const { map } = useGoogleMaps()
  const { t } = useTranslation()
  const createUpdateLocation = useCreateUpdateLocation()
  const getContextMenu = useContextMenuEvent()
  const contextMenu = getContextMenu()

  if (!contextMenu) return null
  const lat = contextMenu?.event?.latLng?.lat()
  const lng = contextMenu?.event?.latLng?.lng()
  const containerPoint = getContextContainerPoint(map!, new google.maps.LatLng(lat!, lng!))
  const { address, clear } = contextMenu
  let streetAddress = `${lat!.toFixed(5)}:${lng!.toFixed(5)}`

  if (!createUpdateLocation) return null
  const addressParts = [address?.HouseNumber, address?.Street].filter(Boolean)
  streetAddress = addressParts.length > 0 ? addressParts.join(' ') : `${lat!.toFixed(5)}:${lng!.toFixed(5)}`

  // Replace first comma with <br/> - marking where the string should be split in two
  const addr = address?.Label && address.Label.replace(',', '<br/>')

  const handleLocationAdd = (type: MarkerType) => () => {
    createUpdateLocation({
      type,
      latitude: lat!,
      longitude: lng!,
      address: addr || `${lat!.toFixed(5)}, ${lng!.toFixed(5)}`,
      extendedAddress: address as Partial<AaaAddress>,
    })
    clear()
  }

  const items = [
    [
      <CircleIcon
        ghost
        icon={MdFiberManualRecord}
        iconSize={12}
        style={{ backgroundColor: 'inherit' }}
        circleSize={CircleIconSize.MEDIUM}
      />,
      <Pill>{streetAddress}</Pill>,
      () => {},
    ],
    [
      <CircleIcon icon={MdPerson} circleSize={CircleIconSize.MEDIUM} />,
      <Pill>{t('menu.caller')}</Pill>,
      handleLocationAdd(MarkerType.CALLER),
    ],
    [
      <CircleIcon icon={MdDirectionsCar} circleSize={CircleIconSize.MEDIUM} />,
      <Pill>{t('menu.vehicle')}</Pill>,
      handleLocationAdd(MarkerType.VEHICLE),
    ],
  ]

  const loadingItems = [
    [
      <CircleIcon
        ghost
        icon={MdFiberManualRecord}
        iconSize={12}
        style={{ backgroundColor: 'inherit' }}
        circleSize={CircleIconSize.MEDIUM}
      />,
      <Pill>{streetAddress}</Pill>,
      () => {},
    ],
    [<CircleIcon icon={MdPerson} circleSize={CircleIconSize.MEDIUM} />, <Pill>{t('Loading Address...')}</Pill>, () => {}],
  ]

  if (!containerPoint) return null

  return (
    <ContextMenu containerPoint={containerPoint} xOffset={TARGET_OFFSET} yOffset={TARGET_OFFSET}>
      {(anchors) => {
        const sortedMenu = sortMenu(items, anchors)
        const loadingMenu = sortMenu(loadingItems, anchors)
        const menuItems = contextMenu.isLoading && !contextMenu.address ? loadingMenu : sortedMenu

        return menuItems.map(([first, second, onClick, justifyContent], i) => {
          return (
            <Row
              key={i}
              onClick={onClick}
              style={{
                justifyContent: justifyContent || 'flex-start',
              }}
            >
              {first}
              <div style={{ padding: '8px' }} />
              {second}
            </Row>
          )
        })
      }}
    </ContextMenu>
  )
}

export function sortMenu(items: Array<any>, anchors: Array<string>): Array<any> {
  if (anchors.includes('top')) {
    if (anchors.includes('left')) {
      return items.map(([icon, pill, onClick]) => [pill, icon, onClick, 'flex-end']).reverse()
    } else {
      return items.reverse()
    }
  } else if (anchors.includes('left')) {
    return items.map(([icon, pill, onClick]) => [pill, icon, onClick, 'flex-end'])
  }
  return items
}

export default HeroContextMenu
