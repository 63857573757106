import { Branch } from './branch'

export enum AvailableStatusCodes {
  AVAILABLE = 'AVAILABLE',
  ON_REQUEST = 'ON_REQUEST',
  VERIFY = 'VERIFY',
  UNAVAILABLE = 'UNAVAILABLE',
  NOT_OFFERED = 'NOT_OFFERED',
}

export enum CarClassCategories {
  CORE = 'CORE',
  PREMIUM_SEDAN = 'PREMIUM_SEDAN',
  SPORT = 'SPORT',
  HYBRID_ELECTRIC = 'HYBRID_ELECTRIC',
  SUV = 'SUV',
  LARGE_SUV = 'LARGE_SUV',
  VANS = 'VANS',
  PASSENGER_VANS = 'PASSENGER_VANS',
  TRUCKS = 'TRUCKS',
}

export type CarClassAvailability = {
  pickupLocationId: string
  returnLocationId: string
  carClasses: CarClassStatus[]
  branchData: Branch
}

export type CarClassStatus = {
  code: string
  status: AvailableStatusCodes
}

export type CarClasses =
  | Core
  | Premium_Sedan
  | Sport
  | Hybrid_Electric
  | Suv
  | Large_Suv
  | Vans
  | Passenger_Vans
  | Trucks

export enum Core {
  ECAR = 'ECAR',
  CCAR = 'CCAR',
  ICAR = 'ICAR',
  SCAR = 'SCAR',
  SXAR = 'SXAR',
  FCAR = 'FCAR',
}

export enum Premium_Sedan {
  PCAR = 'PCAR',
  LCAR = 'LCAR',
  PWAR = 'PWAR',
  PXAR = 'PXAR',
}

export enum Sport {
  STAR = 'STAR',
  LTAR = 'LTAR',
  SSAR = 'SSAR',
  SYMR = 'SYMR',
  FYMR = 'FYMR',
  GYMR = 'GYMR',
  PYMR = 'PYMR',
  EYMR = 'EYMR',
  IYMR = 'IYMR',
}

export enum Hybrid_Electric {
  ICAM = 'ICAM',
  ICAE = 'ICAE',
  SCAH = 'SCAH',
  FCAH = 'FCAH',
}

export enum Suv {
  IFAR = 'IFAR',
  SFAR = 'SFAR',
  RFAR = 'RFAR',
  UFAR = 'UFAR',
  WFAR = 'WFAR',
  LYMR = 'LYMR',
  WZAR = 'WZAR',
  PGAR = 'PGAR',
  IJAR = 'IJAR',
  FJAR = 'FJAR',
  CFAR = 'CFAR',
  IRAR = 'IRAR',
  SRAR = 'SRAR',
}

export enum Large_Suv {
  FRAR = 'FRAR',
  FFAR = 'FFAR',
  PRAR = 'PRAR',
  PFAR = 'PFAR',
  LFAR = 'LFAR',
}

export enum Vans {
  MVAR = 'MVAR',
  SVAR = 'SVAR',
}

export enum Passenger_Vans {
  RVAR = 'RVAR',
  FVAR = 'FVAR',
}

export enum Trucks {
  JKAR = 'JKAR',
  SKAR = 'SKAR',
  SPAR = 'SPAR',
  PPAR = 'PPAR',
}
