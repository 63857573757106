import * as React from 'react'
import Input, { InputProps } from 'components/Input'

export const VehicleAccommodationSelect: React.FC<InputProps> = props => {
  if (props.defaultToCore) {
    return (
      <Input.Select
        options={vehicleClasses}
        isClearable
        {...props}
        placeholder="How many passengers?"
      />
    )
  }

  return <Input.Select placeholder="--" options={vehicleClasses} isClearable {...props} />
}
export default VehicleAccommodationSelect

const vehicleClasses = [
  { label: 'Core - 5 or less passengers', value: 'core' },
  { label: '6+ Passengers', value: 'sixPlus' },
  { label: '15 Passenger', value: 'fifteenPlus' },
  { label: 'Cargo', value: 'cargo' },
  { label: 'Pickup Truck', value: 'pickupTruck' },
]
