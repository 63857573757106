import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { mergeWith } from 'lodash-es'
import { Forms, FormState, FormStore, RegisterFormAction, UnregisterFormAction } from 'types/form'
import { defaultState, handleArrayCheck } from './formHelper'

const initialState: FormState = {
  forms: {} as any,
}

const formSlice = createSlice({
  name: 'Form',
  initialState,
  reducers: {
    registerForm: (state: FormState, action: PayloadAction<RegisterFormAction>) => {
      const { name, form } = action.payload
      return { forms: { ...state.forms, [name]: mergeWith({}, { ...state.forms[name] }, { ...form }) } }
    },
    unregisterForm: (state: FormState, action: PayloadAction<UnregisterFormAction>) => {
      const { [action.payload.name]: unwanted, ...rest } = state.forms
      return { forms: { ...rest } as Forms }
    },
    updateFormState: (state: FormState, action: PayloadAction<Partial<FormStore>>) => {
      state.forms = mergeWith({}, { ...state.forms }, { ...action.payload }, handleArrayCheck)
    },
    resetFormState: (state: FormState) => {
      state.forms = { ...defaultState } as any
      return state
    },
  },
})

export const { registerForm, unregisterForm, updateFormState, resetFormState } = formSlice.actions

export default formSlice.reducer
