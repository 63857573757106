import { isFunction } from 'lodash-es'
import * as React from 'react'
import { useFormState } from 'react-final-form'
import { get } from 'lodash-es'

type ConditionFn = (value: any) => boolean

export const Condition: React.FC<{
  when: string
  is: string | number | boolean | ConditionFn
  children: React.ReactNode | ((value: any) => React.ReactNode)
}> = ({ when, is, children }) => {
  const state = useFormState({ subscription: { values: true } })
  if (!state) return null
  const value = get(state.values, when)
  const comparison = isFunction(is) ? is(value) : value === is
  if (comparison) {
    return isFunction(children) ? children(value) : children
  }
  return null
}

export default Condition
