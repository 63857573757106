import React from 'react'
import * as Ariakit from '@ariakit/react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'
import { Heading } from 'components/primitives'
import Button from 'components/Button'
import { CallTypes } from 'types/callstore'
import { isRequired } from 'utils/validators'
import Input from 'components/Input'
import CallTypeIcon from 'forms/CallForm/CallTypeSelection/CallTypeIcon'
import {
  setSmartAssignmentParticipant,
  setSmartAssignmentCustomDropoffDestination,
} from 'redux/smartAssignment/smartAssignmentSlice'
import ActionWrapper from './ActionWrapper'
import { useDispatch } from 'react-redux'

export const TransportationMethod = () => {
  const { t } = useTranslation()
  const controlsContext = Ariakit.useCompositeContext()

  const form = useForm()
  const dispatch = useDispatch()

  const callTypeIcon = () => <CallTypeIcon callType={CallTypes.ADDITIONAL} gutter="sm" />

  return (
    <>
      <ActionWrapper
        backOnClick={() => {
          dispatch(setSmartAssignmentParticipant(null))
          dispatch(setSmartAssignmentCustomDropoffDestination(null))
          form.change('dropoffDestination', null)
          controlsContext?.move(controlsContext?.previous())
        }}
      >
        <Heading as="h5" spacing="md">
          {t("What will be the customer's method of transportation?")}
        </Heading>

        <Button.Workflow
          iconBefore={callTypeIcon}
          onClick={() => {
            form.change('customerSupplied', true)
            controlsContext?.move('4')
          }}
        >
          {t('tow.exchange.customerSupplied')}
        </Button.Workflow>

        <Button.Workflow
          iconBefore={callTypeIcon}
          onClick={() => {
            form.change('rideAlong', false)
            controlsContext?.move(controlsContext?.next())
          }}
        >
          {t('tow.exchange.stm')}
        </Button.Workflow>

        <Button.Workflow
          iconBefore={callTypeIcon}
          onClick={() => {
            form.change('rideAlong', true)
            controlsContext?.move(controlsContext?.next())
          }}
        >
          {t('labels.rideAlong')}
        </Button.Workflow>

        <Input.Hidden name="rideAlong" validate={isRequired()} />
      </ActionWrapper>
    </>
  )
}

export default TransportationMethod
