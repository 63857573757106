import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { initialQuickNotesState } from './quickNotesHelpers'
import { QuickNotesState, QuickNotesAction } from 'types/global'

const initialState: QuickNotesState = {
  ...initialQuickNotesState(),
}

const quickNotesSlice = createSlice({
  name: 'QuickNotes',
  initialState,
  reducers: {
    updateQuickNotes: (state: QuickNotesState, action: PayloadAction<QuickNotesAction>) => {
      state.callerName = action.payload.callerName
      state.countryCode = action.payload.countryCode
      state.number = action.payload.number
      state.notes = action.payload.notes
    },
    resetQuickNotesState: (state: QuickNotesState) => {
      state = initialState
      return state
    },
  },
})

export const { updateQuickNotes, resetQuickNotesState } = quickNotesSlice.actions

export default quickNotesSlice.reducer
