import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ExchangeSearchState } from 'types/exchange'

const initialState: ExchangeSearchState = {
  data: null,
}

const exchangeSearchSlice = createSlice({
  name: 'ExchangeSearch',
  initialState,
  reducers: {
    setExchangeSearch: (state: ExchangeSearchState, action: PayloadAction<ExchangeSearchState>) => {
      state.data = action.payload.data
    },
    resetExchangeSearchState: (state: ExchangeSearchState) => {
      state = initialState
      return state
    },
  },
})

export const { setExchangeSearch, resetExchangeSearchState } = exchangeSearchSlice.actions

export default exchangeSearchSlice.reducer
