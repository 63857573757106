import React from 'react'
import Margin from 'components/Margin'
import { BackNavigation } from 'components/Step'

export const ActionWrapper = ({
  children,
  backOnClick,
}: //TODO: Update type
{
  children: any
  backOnClick?: () => void
}) => {
  return (
    <Margin spacing="md">
      <BackNavigation onClick={backOnClick} />
      {children}
    </Margin>
  )
}

export default ActionWrapper
