import { debounce } from 'lodash-es'
import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { DropdownIndicatorProps } from 'react-select'
import Async, { AsyncProps } from 'react-select/async'
import { SelectOption } from 'types/global'
import { mercury, silver } from 'theme/colors'
import { default as DefaultDropdownIndicator } from './DropdownIndicator'
import Option from './Option'
import getLocator from 'hooks/solr/getLocator'
import { BranchInfoFragment } from 'types/global'
import { IndicatorSeparator } from 'components/IndicatorSeparator'

type LocatorCity = {
  latitude: String
  longitude: String
  longName: String
  shortName?: String
  longCode?: String
  countryCode?: String
  cityId?: String
}

type AirportOption = BranchInfoFragment & SelectOption
type BranchOption = BranchInfoFragment & SelectOption & { isBranch: true }
type CityOption = LocatorCity & SelectOption
type LocationOption = AirportOption | CityOption | BranchOption

const debouncedFunc = debounce(async (func) => func(), 250)

type OmittedAsyncProps = Omit<AsyncProps<SelectOption, boolean, any>, 'loadOptions' | 'cacheOptions'>
type AdditionalSelectProps = {
  DropdownIndicator?: React.FC<DropdownIndicatorProps<SelectOption>>
}
type Props = OmittedAsyncProps & FieldRenderProps<any> & AdditionalSelectProps

type CallbackFn = (options: LocationOption[]) => void

const LocationSearch: React.FC<Props> = ({ DropdownIndicator, input, ...props }) => {
  const { t } = useTranslation()

  const handleSearchChange = (query: string = '', callback: CallbackFn) => {
    if (query.length < 3) {
      return callback([])
    }
    debouncedFunc(async () => {
      const { data } = await getLocator(encodeURIComponent(query))

      const { airports = [], cities = [], branches = [] } = data
      const airportOptions = airports.map(
        (airport) =>
          ({
            ...airport,
            label: airport.locationNameTranslation,
            value: airport.locationNameTranslation,
          }) as BranchOption,
      )

      const branchOptions = branches.map(
        (branch) =>
          ({
            ...branch,
            isBranch: true,
            locationType: 'BRANCH',
            label: branch.locationNameTranslation,
            value: branch.locationNameTranslation,
          }) as BranchOption & { isBranch: true },
      )

      const cityOptions = cities.map(
        (city) =>
          ({
            ...city,
            label: city.longName,
            value: city.longName,
          }) as CityOption,
      )

      return callback([...airportOptions, ...branchOptions, ...cityOptions])
    })
  }

  return (
    <Async
      isClearable
      cacheOptions
      loadOptions={handleSearchChange}
      components={{
        Option,
        DropdownIndicator: DropdownIndicator || DefaultDropdownIndicator,
        IndicatorSeparator: (props) => (props.hasValue ? <IndicatorSeparator {...props} /> : null),
      }}
      placeholder={t('Address, City, etc.')}
      styles={{
        control: (provided) => ({
          ...provided,
          border: `2px solid ${mercury}`,
          borderRadius: 0,
        }),
        placeholder: (provided) => ({
          ...provided,
          color: silver,
        }),
        menu: (provided) => ({
          ...provided,
          margin: 0,
          borderRadius: 0,
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0,
        }),
        option: (provided) => ({
          ...provided,
          margin: 0,
          padding: 0,
        }),
      }}
      {...props}
      {...input}
    />
  )
}

export default LocationSearch
